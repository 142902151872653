import { FontWeights, getTheme, IconButton, mergeStyleSets, Modal } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const TrainDetailModal = (props) => {
    // ===============================================================
    const titleId = useId('title');
    const theme = getTheme();

    const cancelIcon = { iconName: 'Cancel' };
    // ===============================================================

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex'
        },
        header: [
            theme.fonts.large,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden'
        }
    });

    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px'
        },
        rootHovered: {
            color: theme.palette.neutralDark
        }
    };

    // ===============================================================
    function showRingBlockerDetails(ringBlockers) {
        if (!ringBlockers || ringBlockers.length === 0) return;

        return (
            <table style={{ minHeight: '70px', minWidth: '300px', maxWidth: '800px' }}>
                <thead>
                    <tr>
                        <th style={{ paddingLeft: '20px' }}> Id</th>
                        <th style={{ paddingLeft: '10px' }}>Title</th>
                        <th style={{ paddingLeft: '10px' }}>Area Path</th>
                    </tr>
                </thead>
                <tbody>
                    {ringBlockers.map((ringBlocker) => (
                        <tr key={ringBlocker.id}>
                            <td style={{ padding: '10px' }}>
                                <a href={ringBlocker._links.html['href']} target="_blank" rel="noopener noreferrer">
                                    {ringBlocker.id}
                                </a>
                            </td>
                            <td style={{ padding: '10px' }}>{ringBlocker.fields['System.Title']}</td>
                            <td style={{ padding: '10px' }}>{ringBlocker.fields['System.AreaPath']}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    function DisplayBuildMetadata(details) {
        if (!details) return;
        return (
            <>
                <table style={{ minHeight: '100px', minWidth: '500px', maxWidth: '500px' }}>
                    <thead>
                        <tr>
                            <th style={{ paddingLeft: '20px' }}>Build link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details[0].buildMetaData.map((buildMetadata) => (
                            <tr key={buildMetadata.buildNumber}>
                                <td>
                                    <a href={buildMetadata.link}>{buildMetadata.buildNumber}</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <br />
            </>
        );
    }

    // ===============================================================
    return (
        <>
            <Modal titleAriaId={titleId} isOpen={props.isModalOpen} onDismiss={props.hideModal} isBlocking={false}>
                <div className={contentStyles.header}>
                    <span id={titleId}>
                        {props.data.trainName} : {props.data.ring}
                    </span>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.hideModal} />
                </div>

                <hr />

                <div className={contentStyles.body}>
                    {props.data.ringBlockers?.length > 0 && (
                        <>
                            <h3>Ring Blockers : {props.data?.ringBlockers?.length}</h3>
                            {showRingBlockerDetails(props.data.ringBlockers)}
                            <hr />
                        </>
                    )}

                    <>
                        <h3>Build Metadata</h3>
                        {DisplayBuildMetadata(props.data.details)}
                        <hr />
                    </>
                </div>
            </Modal>
        </>
    );
};

TrainDetailModal.propTypes = {};
TrainDetailModal.defaultProps = {};

export default TrainDetailModal;
