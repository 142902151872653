import { InteractionRequiredAuthError, PopupRequest, SilentRequest } from '@azure/msal-browser';

import { msalInstance } from '../App';

/**
 * Get the access token for the API.
 *
 * @param scopes The scopes to request.
 *
 * @returns The access token or null if the user is not logged in.
 */
export const getAccessToken = async (scopes: string[]): Promise<string | null> => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length <= 0) {
        console.log('No accounts');
        return null;
    }

    const silentRequest: SilentRequest = {
        scopes: scopes,
        account: accounts[0]
    };

    try {
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        return response.accessToken;
    } catch (error) {
        console.log('Error getting token silently', error);
        if (error instanceof InteractionRequiredAuthError) {
            const interactiveRequest: PopupRequest = {
                scopes: scopes,
                account: accounts[0]
            };

            const response = await msalInstance.acquireTokenPopup(interactiveRequest);
            return response.accessToken;
        }
        return null;
    }
};

/**
 * Gets the Bearer token header for the API.
 *
 * @param name The name of the token.
 * @param scopes The scopes to request.
 * @returns The Bearer token header.
 */
export const getBearerTokenHeader = async (name?: string, scopes?: string[]) => {
    if (!scopes) {
        scopes = [];
    }
    const token = await getAccessToken(scopes);
    if (token === null) {
        throw new Error(`No access token available for ${scopes}`);
    }
    return `Bearer ${token}`;
};
