/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAccessToken } from '../../auth/authService';
import { ApiServiceConfig } from '../configs/apiServiceConfig';
import ApiResponseError from '../models/error/ApiResponseError';

/**
 * API service.
 */
class ApiService {
    private apiServiceConfig: ApiServiceConfig;

    /**
     * Constructor.
     * Initializes an instance of the ApiService class.
     *
     * @param apiServiceConfig The service scope.
     */
    constructor(apiServiceConfig: ApiServiceConfig) {
        this.apiServiceConfig = apiServiceConfig;
    }

    /**
     * Fetch with authorization.
     *
     * @param url URL.
     * @param options Options.
     * @returns Response.
     */
    private async fetchWithAuthorization(url: string, options: RequestInit): Promise<Response> {
        const headers = {
            ...options.headers,
            Authorization: `Bearer ${await getAccessToken(this.apiServiceConfig.scopes)}`
        };
        const requestOptions: RequestInit = {
            ...options,
            headers
        };
        return fetch(url, requestOptions);
    }

    /**
     * Extract data from response.
     *
     * @param response Response.
     * @returns Data.
     */
    private async extractDataFromResponse(response: Response): Promise<any> {
        const contentType = response.headers.get('content-type');

        if (contentType && (contentType.includes('application/json') || contentType.includes('application/problem+json'))) {
            return await response.json();
        } else {
            return await response.text();
        }
    }

    /**
     * Get.
     *
     * @param url URL.
     * @returns Data.
     */
    async get<T>(url: string): Promise<T> {
        try {
            const response = await this.fetchWithAuthorization(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Client-Id': 'ReportingPortal'
                }
            });

            const data = await this.extractDataFromResponse(response);

            // Handle Unauthorized error
            if (response.status === 401) {
                throw new ApiResponseError(response.status, 'Unauthorized');
            }

            // Handle other API failures
            if (!response.ok) {
                throw new ApiResponseError(response.status, data.detail);
            }

            return data as T;
        } catch (error) {
            console.error('Error in API Service', error);
            throw error;
        }
    }

    /**
     * Post.
     *
     * @param url URL.
     * @param body Body.
     * @returns Data.
     */
    async post<T>(url: string, body: any): Promise<T> {
        try {
            const response = await this.fetchWithAuthorization(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            const data = await this.extractDataFromResponse(response);

            // Handle Unauthorized error
            if (response.status === 401) {
                throw new ApiResponseError(response.status, 'Unauthorized');
            }
            // Handle other API failures
            if (!response.ok) {
                throw new ApiResponseError(response.status, data.detail);
            }
            return data as T;
        } catch (error) {
            console.error('Error in API Service', error);
            throw error;
        }
    }

    /**
     * Put.
     *
     * @param url URL.
     * @param body Body.
     * @returns Data.
     */
    async put<T>(url: string, body: any): Promise<T> {
        try {
            const response = await this.fetchWithAuthorization(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            const data = await this.extractDataFromResponse(response);

            // Handle Unauthorized error
            if (response.status === 401) {
                throw new ApiResponseError(response.status, 'Unauthorized');
            }

            // Handle other API failures
            if (!response.ok) {
                throw new ApiResponseError(response.status, data.detail);
            }

            return data as T;
        } catch (error) {
            console.log('Error in API Service', error);
            throw error;
        }
    }
}

export default ApiService;
