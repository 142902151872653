import { InteractionType } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

import { msalInstance } from '../App';

import ApiResponseError from './models/error/ApiResponseError';

import type { Group, User } from '@microsoft/microsoft-graph-types/';

/**
 * MsGraphService class provides methods to interact with the MS Graph API.
 */
class MsGraphService {
    private graphClient: Client;

    /**
     * Constructs a new MsGraphService instance.
     */
    constructor() {
        // @microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
            account: msalInstance.getAllAccounts()[0],
            interactionType: InteractionType.None,
            scopes: ['User.Read.All', 'Directory.Read.All']
        });

        this.graphClient = Client.initWithMiddleware({ authProvider: authProvider });
    }

    /**
     * Retrieve logged in user information from the Microsoft Graph API.
     *
     * @returns The user information.
     */
    async getUser(): Promise<User> {
        try {
            return await this.graphClient.api(`/me`).get();
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieve the photo of the logged in user from the Microsoft Graph API.
     *
     * @returns The photo of the user.
     */
    async getPhoto(): Promise<string | null> {
        try {
            const response = await this.graphClient.api(`/me/photo/$value`).get();

            return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(response);
            });
        } catch (error) {
            console.log(error);
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieve the manager of the logged in user from the Microsoft Graph API.
     *
     * @returns The manager of the user.
     */
    async getManager(): Promise<User> {
        try {
            return await this.graphClient.api(`/me/manager`).get();
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieve the direct reports of the user from the Microsoft Graph API.
     *
     * @param userId The user to retrieve the direct reports for.
     * @param filter The OData filter query to apply to the request.
     * @param select The OData select query to apply to the request.
     * @returns The direct reports of the user.
     */
    async getDirectReports(userId?: string, filter?: string, select?: string): Promise<User[]> {
        try {
            let graphCall;
            if (userId) graphCall = this.graphClient.api(`/users/${userId}/directReports`);
            else graphCall = this.graphClient.api(`/me/directReports`);

            if (filter) graphCall.filter(filter).header('ConsistencyLevel', 'eventual').count(true);
            if (select) graphCall.select(select);

            const reports = await graphCall.get();

            return reports.value as User[];
        } catch (error) {
            console.log(error);
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieve the groups that the logged in user is a member of from the Microsoft Graph API.
     *
     * @param filter The filter to apply to the request.
     * @param select The fields to select.
     * @returns The groups that the user is a member of.
     */
    async getTransitiveMemberOf(filter?: string, select?: string): Promise<Group[]> {
        try {
            const graphCall = this.graphClient.api('/me/transitiveMemberOf');

            if (filter) graphCall.filter(filter).header('ConsistencyLevel', 'eventual').count(true);
            if (select) graphCall.select(select);

            let page = await graphCall.get();

            let memberOf = page.value as Group[];

            while (page['@odata.nextLink']) {
                page = await this.graphClient.api(page['@odata.nextLink']).get();
                memberOf = memberOf.concat(page['value'] as Group[]);
            }

            return memberOf;
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Handles API errors and throws custom error messages based on the error code.
     *
     * @param error The API error to handle.
     * @private
     */
    private handleApiError(error: ApiResponseError): void {
        let errorMessage = 'Failed to perform the operation.';

        if (error.statusCode === 404) {
            errorMessage = 'Requested resource not found.';
        } else if (error.statusCode === 403) {
            errorMessage = 'Unable to connect to the server. Please try again later.';
        } else if (error.statusCode === 500) {
            errorMessage = 'Internal server error. Please try again later.';
        } else if (error.errorDetail) {
            errorMessage = error.errorDetail;
        }

        throw new Error(errorMessage);
    }
}

export default MsGraphService;
