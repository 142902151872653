import { Link, Separator, Text } from '@fluentui/react';
import React from 'react';

import { Card } from '../../../components/Card';
import { RolloutParsedLog } from '../../../services/models/FeatureFlag/History/RolloutParsedLog';

interface IRolloutLogListViewProps {
    logs: RolloutParsedLog[];
}

type GroupedLogsByDateAndTime = {
    date: string;
    timeWiseLogs: {
        time: string;
        logs: RolloutParsedLog[];
    }[];
};

/**
 * Renders the rollout log list view.
 *
 * @param props The rollout log list view props.
 * @returns The rollout log list view.
 */
const RolloutLogListView: React.FC<IRolloutLogListViewProps> = (props) => {
    const { logs } = props;
    const groupedLogs = getGroupedLogsByDateAndTime(logs);

    // ========================= Render ========================= //
    return (
        <Card>
            {groupedLogs.map((dateLog) => (
                <>
                    <Text variant="mediumPlus" block style={{ fontWeight: 'bold', color: '#464775' }}>
                        {getFormattedDate(dateLog.date)}
                    </Text>
                    {dateLog.timeWiseLogs.map((timeLog) => (
                        <>
                            <Text variant="medium" style={{ color: '#464775' }} nowrap block>
                                {getFormattedTime(timeLog.time)}
                            </Text>

                            <ul style={{ marginTop: '5px' }}>
                                {timeLog.logs.map((item, index) => (
                                    <li key={`${index}_${timeLog.time}`}>
                                        <Text>
                                            [{item.client}] [{item.kind}] {item.cloud} {item.ring}{' '}
                                            <Link href={item.url} target="_blank">
                                                {item.id}
                                            </Link>{' '}
                                            rollout set to {item.allocationPercentage}%
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ))}
                    <Separator />
                </>
            ))}
        </Card>
    );
};

// ========================= Helper Functions ========================= //
/**
 * Groups the rollout logs by date and time.
 *
 * @param rolloutLogs The rollout logs.
 * @returns The grouped logs.
 */
const getGroupedLogsByDateAndTime = (rolloutLogs: RolloutParsedLog[]): GroupedLogsByDateAndTime[] => {
    const groupedLogs = rolloutLogs
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
        .reduce((result: GroupedLogsByDateAndTime[], log) => {
            const formattedDate = log.timestamp.toLocaleDateString();
            const formattedDateTime = log.timestamp.toLocaleString();

            const existingDate = result.find((item) => item.date === formattedDate);

            if (existingDate) {
                const existingHour = existingDate.timeWiseLogs.find((item) => item.time === formattedDateTime);
                if (existingHour) {
                    existingHour.logs.push(log);
                } else {
                    existingDate.timeWiseLogs.push({
                        time: formattedDateTime,
                        logs: [log]
                    });
                }
            } else {
                result.push({
                    date: formattedDate,
                    timeWiseLogs: [
                        {
                            time: formattedDateTime,
                            logs: [log]
                        }
                    ]
                });
            }
            return result;
        }, []);

    return groupedLogs;
};

/**
 * Gets the formatted date.
 *
 * @param date The date.
 * @returns The formatted date.
 */
const getFormattedDate = (date: string): string => {
    if (!date) return '';
    return new Date(date).toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
};

/**
 * Gets the formatted time.
 *
 * @param date The date.
 * @returns The formatted time.
 */
const getFormattedTime = (date: string): string => {
    if (!date) return '';
    return new Date(date).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZoneName: 'long'
    });
};

// ========================= Export ========================= //
export default RolloutLogListView;
