import {
    FontWeights,
    IComboBoxStyles,
    IDetailsListStyles,
    IIconProps,
    IStackStyles,
    IStackTokens,
    assign,
    getTheme,
    mergeStyleSets
} from '@fluentui/react';

import { ICardStyles } from '../../../components/Card';

// -----------------------------------------------------
// Default styles
const defaultStackTokens: IStackTokens = { childrenGap: 10 };

const defaultDetailsListStyles: Partial<IDetailsListStyles> = {
    headerWrapper: {
        '.ms-DetailsHeader': {
            height: '2.5em'
        },
        '.ms-DetailsHeader-cell': {
            backgroundColor: 'rgb(73, 73, 73)',
            height: '2.5em',
            color: 'white',
            lineHeight: '2.5em'
        },
        '.ms-DetailsHeader-cell:hover': {
            backgroundColor: 'rgb(91, 91, 91)',
            color: 'white'
        }
    },
    contentWrapper: {
        '.ms-List-cell': {
            minHeight: '3em'
        },
        '.ms-List-cell:nth-child(even) .ms-DetailsRow': {
            backgroundColor: '#eee'
        }
    }
};

const defaultCardStyles: Partial<ICardStyles> = {
    root: {
        minWidth: '24em',
        minHeight: '96%'
    }
};

const defaultTrainCardStyles: Partial<ICardStyles> = {
    root: {
        minWidth: '14rem',
        maxWidth: '14rem',
        borderRadius: '0.3rem',
        borderLeft: '0.3rem solid',
        transition: 'all 0.1s ease-in-out',
        selectors: {
            '&:hover': {
                transform: 'scale(1.03) perspective(1px)',
                cursor: 'pointer',
                boxShadow: '0 10px 10px 10px rgba(196, 189, 189, 0.7)',
                backgroundColor: 'rgba(240, 246, 252, 1)'
            }
        }
    },
    header: {
        display: 'flex',
        paddingLeft: '0.3rem'
    },
    body: {
        fontSize: '0.9rem',
        padding: '0.3rem'
    },
    footer: { fontSize: '0.8rem', color: 'grey', width: '90%' }
};
// -----------------------------------------------------

export const shiproomRootStackTokens: IStackTokens = { ...defaultStackTokens };
export const shiproomRootPivotCardStyles: Partial<ICardStyles> = {
    root: {
        minWidth: '100%'
    }
};

export const scheduleDetailsListStyles: Partial<IDetailsListStyles> = {
    ...defaultDetailsListStyles,
    root: {
        minWidth: '24em',
        width: 'max-content'
    }
};

export const scheduleCardStyles: Partial<ICardStyles> = {
    ...defaultCardStyles,
    root: {
        backgroundColor: 'transparent'
    }
};

export const ringStatusDetailsListStyles: Partial<IDetailsListStyles> = {
    ...defaultDetailsListStyles,
    root: {
        minWidth: '26em',
        minHeight: '96%'
    }
};

export const ringStatusCardStyles: Partial<ICardStyles> = {
    ...defaultCardStyles,
    root: {
        backgroundColor: 'transparent'
    }
};

export const ringStatusReleaseScheduleStyles: Partial<ICardStyles> = {
    root: {
        backgroundColor: 'white',
        width: 'fit-content',
        minWidth: '100%'
    }
};

export const hoverableCollapseIconStyles: Partial<ICardStyles> = {
    root: {
        cursor: 'pointer',
        ':hover': {
            color: 'blue'
        }
    }
};

export const shiproomClientReportStackTokens: IStackTokens = { ...defaultStackTokens };
export const trainDetailsStackTokens: IStackTokens = { ...defaultStackTokens };
export const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
        width: '300px',
        maxWidth: 300
    }
};
export const trainFilteringCardStyles: Partial<ICardStyles> = {
    root: {
        maxWidth: '50%',
        padding: '0.01rem'
    }
};
export const trainHeaderCardStyles: Partial<ICardStyles> = {
    root: {
        minWidth: '14rem',
        maxWidth: '14rem',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '0.3rem',
        paddingTop: '0.3rem',
        borderLeft: '0.3rem solid transparent'
    },
    header: {
        fontSize: 'large'
    }
};
export const trainHeaderMainCardStyles = {
    ...trainHeaderCardStyles,
    root: assign({}, trainHeaderCardStyles.root, {
        minWidth: '13.2rem',
        maxWidth: '13.2rem',
        marginRight: '0.5rem',
        position: 'sticky',
        zIndex: 1,
        left: 0
    })
};
export const trainHeaderRowStackTokens: IStackTokens = { ...defaultStackTokens };
export const trainHeaderRowStackStyles: IStackStyles = {
    root: {
        position: 'sticky',
        top: 0,
        zIndex: 2
    }
};

export const trainProgressionRowStackTokens: IStackTokens = { ...defaultStackTokens };
export const trainMainCardStyles: Partial<ICardStyles> = {
    root: {
        minWidth: '13.2rem',
        maxWidth: '13.2rem',
        position: 'sticky',
        zIndex: 1,
        left: 0,
        minHeight: '7rem',
        fontSize: '0.9em',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '0.3rem solid transparent',
        transition: 'all 0.1s ease-in-out',
        marginRight: '0.5rem',
        selectors: {
            '&:hover': {
                transform: 'scale(1.03) perspective(1px)',
                cursor: 'pointer',
                boxShadow: '0 10px 10px 10px rgba(196, 189, 189, 0.7)',
                backgroundColor: 'rgba(240, 246, 252, 1)'
            }
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.6rem',
        fontSize: '1rem'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.6rem',
        fontSize: '0.9rem'
    }
};

export const trainMainCardColorMap: Record<string, string> = {
    Active: 'rgba(0,120,212,1)',
    Completed: 'rgba(16,124,16,1)',
    Abandoned: 'rgba(102,102,102,1)'
};

export const trainRingCardStyles: Partial<ICardStyles> = {
    ...defaultTrainCardStyles
};

export const trainRingCardStyleMap: Record<string, Partial<ICardStyles>> = {
    Blocked: {
        root: {
            borderLeftColor: 'rgba(218,10,0,1)'
        }
    },
    Active: {
        root: {
            borderLeftColor: 'rgba(0,120,212,1)'
        }
    },
    Completed: {
        root: {
            borderLeftColor: 'rgba(16,124,16,1)'
        }
    },
    Abandoned: {
        root: {
            background: 'linear-gradient(to bottom right, white 50% 51%, rgba(0, 0, 0, 0.25) 51% 52%, white 52%)',
            opacity: 0.6
        }
    },
    Default: {
        root: {
            borderLeftColor: 'rgba(102,102,102,1)'
        }
    }
};

export const trainScheduleCardStyles: Partial<ICardStyles> = {
    ...defaultTrainCardStyles,
    body: {
        display: 'flex',
        justifyContent: 'center',
        verticalAlign: 'bottom',
        paddingTop: '1rem'
    },
    header: {
        display: 'flex',
        minHeight: '0.8rem'
    }
};

export const theme = getTheme();
export const trainModalCancelIcon: IIconProps = { iconName: 'Cancel' };

export const trainModalContentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    header: [
        theme.fonts.large,
        {
            borderBottom: `0.07rem solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '4px 12px 4px 24px',
            backgroundColor: 'rgba(240, 246, 252, 0.7)'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        minWidth: '800px',
        backgroundColor: 'rgba(240, 246, 252, 0.7)',
        overflowY: 'hidden'
    }
});

export const collapseIconStyles: Record<string, Partial<IIconProps>> = {
    collapsed: {
        iconName: 'ChevronUp'
    },
    expanded: {
        iconName: 'ChevronDown'
    }
};

export const trainModalIconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

export const ringBlockersTableStyles = {
    border: '1px solid black',
    borderCollapse: 'collapse' as const,
    minHeight: '70px',
    minWidth: '300px',
    maxWidth: '1200px'
};
export const ringBlockersTableCellStyles = {
    padding: '10px',
    border: '1px solid black'
};
export const ringBlockersTableHeaderStyles = {
    paddingLeft: '10px',
    border: '1px solid black'
};

export const badgeLoadingStyles = {
    border: '1px solid #8a8886',
    backgroundColor: '#fff',
    borderRadius: '3px',
    color: '#8a8886',
    fontSize: '12px',
    padding: '3px 5px',
    margin: '5px 10px 5px 5px',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '200px'
};

export const baseBadgeStyles = {
    border: '1px solid',
    borderRadius: '3px',
    fontSize: '12px',
    display: 'inline-block',
    padding: '3px 5px',
    margin: '5px 10px 5px 5px',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
};

export const successBadgeStyles = {
    ...baseBadgeStyles,
    borderColor: '#107c10',
    backgroundColor: '#dff6dd',
    color: '#107c10'
};

export const errorBadgeStyles = {
    ...baseBadgeStyles,
    borderColor: '#d83b01',
    backgroundColor: '#fed9cc',
    color: '#d83b01'
};
