import {
    DetailsListLayoutMode,
    IColumn,
    Icon,
    Link,
    Selection,
    SelectionMode,
    Stack,
    Text,
    TooltipHost,
    ShimmeredDetailsList,
    FontIcon,
    Coachmark,
    TeachingBubbleContent,
    DirectionalHint
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

import IconWithBadge from '../../../components/IconWithBadge/IconWithBadge';
import { getADOWorkItemURL } from '../../../utils/adoUtility';
import { extraSmallIconClass, gapStackTokensMedium, gapStackTokensSmall, mediumIconClass } from '../styles/FFv2Style';
import { FlightStatusFilter, ProcessedFlightRollout, SortOptions } from '../types/Types';
import { copyAndSort } from '../utilities/FFv2Utils';

type FlightTableProps = {
    flights: ProcessedFlightRollout[] | undefined;
    flightStatusFilter: FlightStatusFilter;
    setSelectedFlight: (flight: ProcessedFlightRollout | undefined) => void;
    teachingBubbleStep?: number;
    progressToNextStep?: () => void;
    dismissTeachingBubble?: () => void;
};

/**
 * Displays the flight info for a user.
 *
 * @param props The props for the component.
 * @returns The flight info for a user.
 */
const FlightTable: React.FC<FlightTableProps> = (props) => {
    const { flights, flightStatusFilter, setSelectedFlight } = props;
    const tableColumns = useRef(prepareColumns());
    const navigate = useNavigate();
    const targetTable = React.useRef<HTMLDivElement>(null);

    const [sortOptions, setSortOptions] = useState<SortOptions>({
        key: 'lastUpdatedTimestamp',
        isSortedDescending: true
    });

    const displayedFlights = useMemo(
        () => (flights ? copyAndSort<ProcessedFlightRollout>(flights, sortOptions.key, sortOptions.isSortedDescending) : undefined),
        [flights, sortOptions]
    );

    const selection = useConst(
        new Selection({
            onSelectionChanged: () => {
                const selectedRow = selection.getSelection()[0] as ProcessedFlightRollout;
                if (selectedRow) {
                    setSelectedFlight(selectedRow);
                } else {
                    setSelectedFlight(undefined);
                }
            }
        })
    );

    useEffect(() => {
        if (flights) {
            selection.setAllSelected(false);
        }
    }, [flights]);

    return (
        <>
            {props.teachingBubbleStep === 4 && (
                <Coachmark
                    target={targetTable.current}
                    positioningContainerProps={{
                        directionalHint: DirectionalHint.topCenter,
                        doNotLayer: false
                    }}
                >
                    <TeachingBubbleContent
                        headline="Flight table"
                        hasCloseButton
                        isWide={true}
                        closeButtonAriaLabel="Close"
                        onDismiss={props.dismissTeachingBubble}
                        primaryButtonProps={{
                            children: 'Done',
                            onClick: props.progressToNextStep
                        }}
                        footerContent="4 of 4"
                    >
                        <li>Hover over the status icon to expand a list of blockers.</li>
                        <li>Hover over the feature flag names to see details.</li>
                        <li>Click on the flight name to access more information.</li>
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            <div ref={targetTable}>
                <ShimmeredDetailsList
                    setKey="displayedFlights"
                    items={displayedFlights || []}
                    enableShimmer={!displayedFlights}
                    columns={tableColumns.current}
                    selectionMode={SelectionMode.single}
                    selection={selection}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onItemInvoked={onItemInvoked}
                />
            </div>
            {displayedFlights && displayedFlights.length === 0 && (
                <Stack verticalAlign="center" styles={{ root: { minHeight: '200px' } }} tokens={gapStackTokensMedium}>
                    <Stack.Item align="center">
                        <Icon iconName="Arrivals" className={mediumIconClass} />
                    </Stack.Item>
                    <Stack.Item align="center">
                        <Text>
                            There are currently no{' '}
                            {flightStatusFilter.title === 'All' || flightStatusFilter.title.toLocaleLowerCase() + ' '}
                            flights to show.
                        </Text>
                    </Stack.Item>
                </Stack>
            )}
        </>
    );

    function onItemInvoked(item: ProcessedFlightRollout): void {
        setSelectedFlight(item);
        navigate('/featureFlight/' + item.rolloutId, { state: { isOpenedFromMyFlights: true } });
    }

    function onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
        const currColumn: IColumn = tableColumns.current?.filter((currCol) => column.key === currCol.key)[0];
        tableColumns.current = tableColumns.current.map((col: IColumn) => ({
            ...col,
            isSortedDescending: col === currColumn ? !currColumn.isSortedDescending : true,
            isSorted: col === currColumn
        }));
        setSortOptions({
            key: currColumn.fieldName ? currColumn.fieldName : currColumn.key,
            isSortedDescending: !currColumn.isSortedDescending
        });
    }

    function prepareColumns(): IColumn[] {
        const columns: IColumn[] = [
            {
                key: 'isSubscribed',
                name: 'Subscribed',
                iconName: '',
                isIconOnly: true,
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: ProcessedFlightRollout) => {
                    return item.isSubscribed ? (
                        <Icon
                            iconName="FavoriteStarFill"
                            styles={{
                                root: {
                                    color: 'gold',
                                    fontSize: 16
                                }
                            }}
                        />
                    ) : undefined;
                }
            },
            {
                key: 'boardingPassId',
                name: '  Feature ID',
                iconName: 'WorkItem',
                fieldName: 'boardingPassId',
                minWidth: 60,
                maxWidth: 100,
                onColumnClick: onColumnClick,
                onRender: (item: ProcessedFlightRollout) => {
                    const workItemLink = getADOWorkItemURL(item.boardingPassId);
                    return (
                        <Text>
                            <Link title="ADO work item" href={workItemLink} target="_blank">
                                {item.boardingPassId}
                            </Link>
                        </Text>
                    );
                }
            },
            {
                key: 'status',
                name: 'Status',
                isRowHeader: true,
                minWidth: 40,
                maxWidth: 40,
                onRender: (item: ProcessedFlightRollout) =>
                    item.blockers && item.blockers.length > 0 ? (
                        <TooltipHost
                            closeDelay={500}
                            content={
                                <Stack tokens={gapStackTokensSmall}>
                                    <Text>{item.blockers.length} Flight Blocker(s):</Text>
                                    {item.blockers.map((blocker) => {
                                        return (
                                            <Text key={blocker.id}>
                                                <Link href={getADOWorkItemURL(blocker.id)} target="_blank">
                                                    {blocker.id}
                                                </Link>
                                            </Text>
                                        );
                                    })}
                                </Stack>
                            }
                        >
                            <IconWithBadge iconName="BugSolid" badgeText={item.blockers.length.toString()} />
                        </TooltipHost>
                    ) : item.state.runningStatus === 'Running' &&
                      item.state.progressingStatus === 'Pre-Deployment' &&
                      item.state.failingGates &&
                      item.state.failingGates.length > 0 ? (
                        <TooltipHost
                            closeDelay={500}
                            content={
                                <Stack tokens={gapStackTokensSmall}>
                                    <Text>{item.state.failingGates.length} Blocking Gate(s): </Text>
                                    {item.state.failingGates.map((gate) => (
                                        <Text key={gate}>{gate}</Text>
                                    ))}
                                    <Link
                                        href={
                                            'https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/62395/FFv2-Pre-Deployment-Gates'
                                        }
                                        target="_blank"
                                    >
                                        FFv2 Gates Wiki
                                    </Link>
                                </Stack>
                            }
                        >
                            <Icon iconName="Error" />
                        </TooltipHost>
                    ) : (
                        <TooltipHost
                            content={
                                <Text>
                                    {item.state.runningStatus === 'Running' && item.state.progressingStatus
                                        ? item.state.progressingStatus
                                        : item.state.runningStatus}
                                </Text>
                            }
                        >
                            <Icon iconName="StatusCircleInner" styles={{ root: { color: getIconByStatus(item.state.runningStatus) } }} />
                        </TooltipHost>
                    )
            },
            {
                key: 'flightName',
                name: 'Flight Name',
                fieldName: 'rolloutName',
                minWidth: 80,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: ProcessedFlightRollout) => (
                    <ReactLink
                        to={'/featureFlight/' + item.rolloutId}
                        state={{ isOpenedFromMyFlights: true }}
                        target="_self"
                        style={{ textDecoration: 'none' }}
                    >
                        <Text>
                            <Link>
                                {item.rolloutName.replace('[FF]', '')} <FontIcon iconName="ChevronRight" className={extraSmallIconClass} />
                            </Link>
                        </Text>
                    </ReactLink>
                )
            },
            {
                key: 'lastUpdated',
                name: 'Last updated',
                fieldName: 'lastUpdatedTimestamp',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isSorted: true,
                isSortedDescending: true,
                sortAscendingAriaLabel: 'Sorted oldest to newest',
                sortDescendingAriaLabel: 'Sorted newest to oldest',
                onColumnClick: onColumnClick,
                data: 'Date',
                onRender: (item: ProcessedFlightRollout) => {
                    return <Text>{new Date(item.lastUpdatedTimestamp).toLocaleDateString()}</Text>;
                },
                isPadded: true
            },
            {
                key: 'createdBy',
                name: 'Created by',
                fieldName: 'createdBy',
                minWidth: 50,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: onColumnClick,
                onRender: (item: ProcessedFlightRollout) => {
                    return <Text>{item.createdBy.displayName}</Text>;
                },
                isPadded: true
            },
            {
                key: 'stage',
                name: 'Stage',
                minWidth: 50,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: onColumnClick,
                onRender: (item: ProcessedFlightRollout) => {
                    return <Text>{item.state.stageName}</Text>;
                }
            },
            {
                key: 'releaseId',
                name: '  Release',
                iconName: 'Airplane',
                fieldName: 'releaseId',
                minWidth: 70,
                maxWidth: 70,
                onColumnClick: onColumnClick,
                onRender: (item: ProcessedFlightRollout) => {
                    return (
                        <Text>
                            <Link title="ADO release" href={item.ADOLink} target="_blank">
                                {item.releaseId}
                            </Link>
                        </Text>
                    );
                }
            },
            {
                key: 'rolloutId',
                name: '  Rollout',
                iconName: 'Airplane',
                fieldName: 'rolloutId',
                minWidth: 70,
                maxWidth: 70,
                onColumnClick: onColumnClick,
                onRender: (item: ProcessedFlightRollout) => {
                    return (
                        <Text>
                            <Link title="ECS rollout" href={item.ECSLink} target="_blank">
                                {item.rolloutId}
                            </Link>
                        </Text>
                    );
                }
            },
            {
                key: 'experimentLink',
                name: '  Experiment',
                iconName: 'TestBeaker',
                fieldName: 'experimentId',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: onColumnClick,
                onRender: (item: ProcessedFlightRollout) => {
                    return (
                        <Text>
                            {item.experimentLink ? (
                                <Link title="ECS experiment" href={item.experimentLink} target="_blank">
                                    {item.experimentId}
                                </Link>
                            ) : (
                                item.experimentId
                            )}
                        </Text>
                    );
                }
            },
            {
                key: 'featureFlags',
                name: 'Feature flags',
                fieldName: 'featureFlags',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                isMultiline: true,
                onRender: (item: ProcessedFlightRollout) => {
                    return (
                        <TooltipHost content={<pre>{JSON.stringify(item.featureFlags, null, 3)}</pre>}>
                            <Text block nowrap>
                                <Link href={`/featureFlagReport?rolloutId=${item.id}`}>{Object.keys(item.featureFlags).join(',')}</Link>
                            </Text>
                        </TooltipHost>
                    );
                },
                isPadded: true
            }
        ];
        return columns;
    }
};

function getIconByStatus(status: string) {
    switch (status) {
        case 'Running':
        case 'Pending Approval':
            // Green
            return '#00ad56';
        case 'Paused':
            // Red
            return '#d13438';
        case 'Ready To Burn In':
            // Blue
            return '#0078d4';
        case 'Queued':
            // Yellow
            return '#ffaa44';
        case 'Cancelled':
            // Grey
            return '#a0aeb2';
        case 'Completed':
            // Brown
            return '#8e562e';
        default:
            return 'white';
    }
}

export default FlightTable;
