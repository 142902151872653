import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { ProcessedFlightRollout } from '../types/Types';
import { flattenJSON } from '../utilities/FFv2Utils';

type FeatureFlagConfigsCardProps = {
    flight: ProcessedFlightRollout;
};

/**
 * Displays a card with the flag configs for the feature flight.
 *
 * @param props The props for the component.
 * @returns The flag settings card.
 */
const FeatureFlagConfigsCard: React.FC<FeatureFlagConfigsCardProps> = (props) => {
    function flagsIntoListItems(): { flag: string; value: unknown }[] {
        if (!props.flight?.featureFlags) {
            return [];
        }

        const flattenedFlags = flattenJSON(props.flight?.featureFlags as { [key: string]: unknown });

        return Object.entries(flattenedFlags).map(([key, value]) => {
            return {
                flag: key,
                value: value
            };
        });
    }

    const columns = [
        { key: 'flagName', name: 'Flag', fieldName: 'flag', minWidth: 400, isResizable: true, isMultiline: true },
        { key: 'flagValue', name: 'Value', fieldName: 'value', minWidth: 200, isResizable: false }
    ];

    return (
        <Card styles={{ root: { height: 'calc(100% - 1.4rem)' } }}>
            <CardHeader>Feature flag configurations</CardHeader>
            <DetailsList
                items={flagsIntoListItems()}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                usePageCache={true}
            />
        </Card>
    );
};

export default FeatureFlagConfigsCard;
