import { DatePicker, IComboBoxStyles, IDatePickerProps, ITimePickerProps, Stack, TimePicker } from '@fluentui/react';
import { set } from 'date-fns';
import React, { useState } from 'react';

const stackTokens = { childrenGap: 3 };
const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { minWidth: 100, maxWidth: 100, maxHeight: 200 },
    callout: { minWidth: 100 }
};

type DateAndTimePickerProps = {
    defaultDate: Date;
    datePickerProps: IDatePickerProps;
    timePickerProps: ITimePickerProps;
    onChange: (date: Date) => void;
};

/**
 * A Date and Time Picker component.
 *
 * @param props The props for the component.
 * @returns The component to display the date and time picker.
 */
export const DateAndTimePicker: React.FC<DateAndTimePickerProps> = (props) => {
    const { datePickerProps, timePickerProps } = props;

    const [datePickerDate, setDatePickerDate] = useState<Date>(props.defaultDate);
    const [timePickerDate, setTimePickerDate] = useState<Date>(props.defaultDate);

    return (
        <Stack horizontal tokens={stackTokens}>
            <DatePicker
                {...datePickerProps}
                onSelectDate={(selectedDate) => {
                    if (!selectedDate) return;
                    setDatePickerDate(selectedDate);
                    // set time to value from timepicker date
                    const newDate = set(selectedDate, {
                        hours: timePickerDate.getHours(),
                        minutes: timePickerDate.getMinutes(),
                        seconds: 0,
                        milliseconds: 0
                    });
                    props.onChange(newDate);
                }}
                value={datePickerDate}
            />
            <TimePicker
                {...timePickerProps}
                onChange={(_ev, date: Date) => {
                    setTimePickerDate(date);
                    // set date to value from datepicker date
                    const newDate = set(datePickerDate, {
                        hours: date.getHours(),
                        minutes: date.getMinutes(),
                        seconds: 0,
                        milliseconds: 0
                    });
                    props.onChange(newDate);
                }}
                value={timePickerDate}
                dateAnchor={datePickerDate}
                styles={comboBoxStyles}
            />
        </Stack>
    );
};
