import ReactWebChat, { StyleOptions, createDirectLine, createStore, createStyleSet } from 'botframework-webchat';
import React, { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';

import { createWebChatFrameworkMiddleware } from './middlewares/webChatFrameworkMiddleware';

import './styles/webChat.css';

interface WebChatProps {
    onFetchToken: () => void;
    token: string | undefined;
}

/**
 * A component to render the web chat.
 *
 * @param props The properties.
 * @returns JSX (The actual view).
 */
const WebChat: React.FC<WebChatProps> = (props) => {
    const { onFetchToken, token } = props;

    const reactStoreDisapatcher = useAppDispatch();
    const directLine = useMemo(() => createDirectLine({ token }), [token]);
    const botStore = useMemo(() => createStore({}, createWebChatFrameworkMiddleware(reactStoreDisapatcher)), []);

    const currentUserData = useAppSelector((state) => state.userData);

    const styleOptions: StyleOptions = {
        botAvatarImage: '/arnold.png',
        botAvatarInitials: 'A',
        botAvatarBackgroundColor: 'transparent',

        userAvatarImage: currentUserData.photo,
        userAvatarInitials: 'U',
        userAvatarBackgroundColor: 'transparent',

        bubbleFromUserNubOffset: 'bottom',
        bubbleNubOffset: 'bottom',

        hideUploadButton: true
    };

    useEffect(() => {
        onFetchToken();
    }, [onFetchToken]);

    return token ? (
        <ReactWebChat
            directLine={directLine}
            store={botStore}
            styleSet={styleSet}
            styleOptions={styleOptions}
            userID={currentUserData.email}
            username={currentUserData.name}
        />
    ) : (
        <div className="connect-spinner">
            <div className="content">
                <p>Please wait while we are connecting.</p>
            </div>
        </div>
    );
};

const styleSet = createStyleSet({
    backgroundColor: 'transparent',

    bubbleBackground: '#F5F5F5',
    bubbleFromUserBackground: '#d5dbf5',

    sendBoxHeight: 50,
    timestampFormat: 'relative',

    sendBoxTextWrap: true,

    bubbleBorderRadius: 10,
    bubbleFromUserBorderRadius: 10,
    avatarSize: 30
});

export default WebChat;
