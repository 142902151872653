import { IMaskedTextField, Stack, TextField, TooltipHost } from '@fluentui/react';
import React, { FormEvent, createRef, useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { desktopTCNS, webRWC } from '../configs/defaults';
import { gapStackTokensLarge } from '../styles/FFv2Style';
import { textFieldStyles } from '../styles/StartFlightFormStyle';
import { isValidClientVersion } from '../utilities/FFv2Utils';

type MinimumBuildVersionInputProps = {
    client: string;
    reset: boolean;
    showAllErrors: boolean;
    update: (value: string) => void;
    defaultValue?: string;
};

/**
 * Renders an input field for entering the minimum build version for a feature flight.
 *
 * @param props - The component's props.
 * @returns The JSX element representing the minimum build version input field.
 */
const MinimumBuildVersionInput: React.FC<MinimumBuildVersionInputProps> = (props) => {
    // ========================= States =========================
    const { client, reset, showAllErrors } = props;
    const defaultValue = props.defaultValue ?? '';
    const [minimumBuildVersion, setMinimumBuildVersion] = React.useState<string>(defaultValue);

    const maskedTextFieldRef = createRef<IMaskedTextField>();

    // ========================= Hooks =========================
    useEffect(() => {
        if (props.showAllErrors && maskedTextFieldRef.current) {
            maskedTextFieldRef.current.focus();
            maskedTextFieldRef.current.blur();
        }
    }, [showAllErrors]);

    useEffect(() => {
        setMinimumBuildVersion(defaultValue);
    }, [reset]);

    useEffect(() => {
        if (maskedTextFieldRef.current && minimumBuildVersion.length) {
            maskedTextFieldRef.current.focus();
            maskedTextFieldRef.current.blur();
        }
    }, [client]);

    // ========================= Event handlers =========================
    const handleMinimumVersionInputChange = (ev?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value === undefined) return;
        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:MinimumVersionChange' });
        setMinimumBuildVersion(value);
        props.update(value);

        if (!isValidClientVersion(client, value)) {
            appInsightsClient.logEvent({ name: 'FFV2:StartFlight:InvalidMinimumBuild' });
        }
    };

    const getErrorMessage = (value: string): string => {
        if (!value || value === '') {
            return 'Enter a minimum build number';
        }

        if (!isValidClientVersion(props.client, value)) {
            return 'Invalid build version';
        }

        return '';
    };

    return (
        <Stack horizontal tokens={gapStackTokensLarge}>
            <TooltipHost content={getTooltipContent()}>
                <TextField
                    styles={textFieldStyles}
                    id="minimumVersion"
                    placeholder="Minimum build number"
                    value={minimumBuildVersion}
                    onChange={handleMinimumVersionInputChange}
                    componentRef={maskedTextFieldRef}
                    validateOnLoad={false}
                    validateOnFocusOut
                    deferredValidationTime={500}
                    onGetErrorMessage={getErrorMessage}
                />
            </TooltipHost>
        </Stack>
    );

    function getTooltipContent() {
        if (props.client === webRWC) {
            return 'Minimum build version format for "Web - RWC"; client is YYMMDDXXXXX (ex: 23071601274)';
        }
        if (props.client === desktopTCNS) {
            return 'Minimum build version format for "Desktop - TCNS"; client is YYDDD.XXX(X).XXXX.XXXX (ex:23190.500.2225.2952 or 23313.2300.2511.9248)';
        }
    }
};

export default MinimumBuildVersionInput;
