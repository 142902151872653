import { Link } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../../components/Card';
import { ProcessedTrainMetadata } from '../../../../services/models/TrainMetadata';
import { trainMainCardColorMap, trainMainCardStyles } from '../../styles/styles';

type TrainMainCardProps = {
    processedTrainMetadata: ProcessedTrainMetadata;
    onClick: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * Displays the train main card.
 *
 * @param props The props for the component.
 * @returns The train main card.
 */
export const TrainMainCard: React.FC<TrainMainCardProps> = (props) => {
    return (
        <Card styles={trainMainCardStyles} onClick={props.onClick}>
            <CardHeader>
                <Link
                    href={props.processedTrainMetadata.teamsThreadLink}
                    target="_blank"
                    data-toggle="tooltip"
                    title="Open in Teams"
                    rel="noreferrer"
                    data-id="train-main-card go-to-teams"
                    data-train={props.processedTrainMetadata.title}
                    style={{ color: trainMainCardColorMap[props.processedTrainMetadata.status ?? 'Active'], textDecoration: 'underline' }}
                >
                    {props.processedTrainMetadata.title}
                </Link>
                {props.processedTrainMetadata.status}
            </CardHeader>
            <div>
                {props.processedTrainMetadata?.snapBranchMetadata?.branches &&
                    props.processedTrainMetadata.snapBranchMetadata.branches.length > 0 && <span>Snap Branches:</span>}
                {props.processedTrainMetadata?.snapBranchMetadata?.branches &&
                    props.processedTrainMetadata.snapBranchMetadata.branches.map((branch) => {
                        return (
                            <li key={branch.branchName}>
                                <Link href={branch.link} target="_blank" rel="noreferrer" data-toggle="tooltip" title={branch.branchName}>
                                    {branch.displayName}
                                </Link>
                            </li>
                        );
                    })}
            </div>
        </Card>
    );
};
