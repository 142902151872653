import React, { useState, useEffect } from 'react';
import { Stack, SpinnerSize, Spinner } from '@fluentui/react';
import * as retrospectiveService from '../../services/retrospective.service';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import TrainRetrospective from './TrainRetrospective';
import retrospectiveConstants from '../../constants/retrospective.constants';
import { trainProgressHeadersRetrospective } from './TrainProgressionHeaders';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import './Retrospective.css';
import CardWrapper from '../../components/CardWrapper/CardWrapper';

const RetrospectiveTrainDetails = (props) => {
    const [latestTrainNames, setLatestTrainNames] = useState([]);
    const [ringBlockers, setRingBLockers] = useState({});
    const [releaseData, setReleaseData] = useState([]);
    const [noDataFlag, setNoDataFlag] = useState(false);
    const [loaderFlag, setLoaderFlag] = useState(false);

    const ringTokens = { childrenGap: '0.5%' };

    // ===============================================================

    useEffect(() => {
        window.scrollTo(0, 0);

        setLoaderFlag(true);
        setNoDataFlag(false);

        retrospectiveService
            .getTrainNamesForMonth(props.client, props.os, props.envV2, props.viewedYear, props.viewedMonth)
            .then(function (response) {
                if (response === undefined || response == null) {
                    setNoDataFlag(true);
                    setLoaderFlag(false);
                } else {
                    setNoDataFlag(false);
                    setLoaderFlag(false);
                    setLatestTrainNames(response);
                }
            })
            .catch((fail) => {
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing getTrainNamesForMonth in RetrospectiveTrainDetails' }
                );
                setNoDataFlag(true);
                setLoaderFlag(false);
            });

        retrospectiveService
            .getAllRingBlockers(props.client, props.os, props.envV2)
            .then(function (response) {
                setRingBLockers(response);
            })
            .catch((fail) => {
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing getAllRingBlockers in RetrospectiveTrainDetails' }
                );
                setLoaderFlag(false);
            });

        retrospectiveService
            .getScheduledReleasePlan(props.client, props.os, props.env, 'None', props.daysFromNow)
            .then(function (response) {
                if (response.length === 0) {
                    setLoaderFlag(false);
                } else {
                    setLoaderFlag(false);

                    let completeReleaseSchedule = response.map((item) => {
                        let snapDate = new Date(item.snapDate);
                        return { name: item.trainName, ...extractStartEndDate(item.trainSchedule), snapDate: snapDate };
                    });

                    getReleaseSchedule(completeReleaseSchedule);
                }
            })
            .catch((fail) => {
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing getScheduledReleasePlan in RetrospectiveTrainDetails' }
                );
                setLoaderFlag(false);
            });
    }, [props.client, props.os, props.env, props.envV2, props.viewedMonth, props.viewedYear, props.daysFromNow]);

    // ===============================================================
    function getReleaseSchedule(data) {
        setReleaseData(data);
    }

    const extractStartEndDate = (item) => {
        let releaseRing = {};
        item.forEach((schedule) => {
            let key = `${schedule.cloud}_${schedule.ring}`;
            let rollouts = schedule.rolloutSchedule;
            let date, endRollout;

            rollouts.forEach((rollout) => {
                if (rollout.releasePercentage === 100) {
                    let startDate = rollout.startTime;
                    let endDate = rollout.endTime;
                    date = startDate?.trim()?.length > 0 ? new Date(startDate) : '';
                    endRollout = endDate?.trim()?.length > 0 ? new Date(endDate) : '';
                }
            });
            releaseRing[key] = <div>{date}</div>;
            releaseRing[key + retrospectiveConstants.KEY_END_ROLLOUT] = <div>{endRollout}</div>;
        });
        return releaseRing;
    };

    const getRingBlockerForTrain = (trainName) => {
        let trainSuffixes = Object.keys(ringBlockers);
        let train = trainSuffixes.filter((trainSuffix) => trainName.includes(trainSuffix));
        return train?.length === 1 ? ringBlockers[train] : {};
    };

    // ===============================================================

    return (
        <>
            <>
                {noDataFlag && (
                    <CardWrapper>
                        <ErrorNotification
                            msg="Train Progress Data is not Available for current search criteria."
                            resetChoice={() => setNoDataFlag(false)}
                        />
                    </CardWrapper>
                )}
            </>
            <>
                <div tabIndex="0">
                    <>
                        {loaderFlag ? (
                            <CardWrapper>
                                <Spinner size={SpinnerSize.large} label="Fetching records..." />
                            </CardWrapper>
                        ) : (
                            <div>
                                <Stack className="sticky-header" horizontal tokens={ringTokens}>
                                    {trainProgressHeadersRetrospective(props).map((header) => {
                                        return (
                                            <Stack
                                                key={header.id}
                                                className={`${
                                                    props.isExpanded && header.id === 'release'
                                                        ? 'release-sticky-expanded'
                                                        : 'release-sticky'
                                                }`}
                                            >
                                                <div
                                                    className={
                                                        header.id === 'release'
                                                            ? 'card train-release-header-card'
                                                            : 'card train-ring-header-card'
                                                    }
                                                >
                                                    <div className="cardHeader">
                                                        <span className="card-title" id={header.id}>
                                                            {header.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Stack>
                                        );
                                    })}
                                </Stack>

                                {latestTrainNames.map((train) => {
                                    return (
                                        <TrainRetrospective
                                            key={train}
                                            trainName={train}
                                            clientType={props.client}
                                            releaseData={releaseData}
                                            ringBlockers={getRingBlockerForTrain(train)}
                                            headers={trainProgressHeadersRetrospective(props)}
                                            isExpanded={props.isExpanded}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </>
                </div>
            </>
        </>
    );
};

RetrospectiveTrainDetails.propTypes = {};
RetrospectiveTrainDetails.defaultProps = {};

export default RetrospectiveTrainDetails;
