export const customToggleStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        margin: '0 5px 0 0'
    },
    label: {
        marginRight: 8,
        fontSize: '13px'
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    }
};

export const commandBarStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 'auto'
    },
    itemStyles: {
        root: {
            height: '100%'
        }
    }
};
