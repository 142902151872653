import { INavStyles, IPersonaStyles } from '@fluentui/react';
import { TransitionStatus } from 'react-transition-group';

export const appStyles = { height: '100vh' };

/**
 * Styles for the content container.
 *
 * @param state The state of the transition.
 * @returns The styles for the content container.
 */
export const contentContainerStyles = (state: TransitionStatus) => {
    const transitionStyles = {
        entering: { transform: 'translateX(-200px)', transition: 'transform 300ms ease-in-out' },
        entered: {},
        exiting: { transform: 'translateX(0)', transition: 'transform 300ms ease-in-out' },
        exited: {},
        unmounted: {}
    };
    return {
        maxHeight: 'calc(100vh - 48px - 14px)',
        minHeight: 'calc(100vh - 48px - 14px)',
        position: 'relative' as const,
        padding: '7px',
        overflow: 'auto',
        zIndex: 2,
        ...transitionStyles[state]
    };
};

export const headerStyles = {
    root: {
        height: '48px',
        width: '100%',
        padding: '3px',
        zIndex: 1,
        paddingRight: '15px',
        paddingLeft: '15px',
        backgroundColor: '#464775',
        color: '#e5e5e5'
    }
};

export const headerIconStyles = {
    root: { padding: '3px', fontSize: '1.3rem', width: '48px', justifyContent: 'center', display: 'flex', color: 'white' }
};

export const teamsLogoStyles = { fontSize: '1.3rem', padding: '10px' };
export const leftNavStyles: Partial<INavStyles> = {
    root: {},
    link: {
        color: 'white',
        selectors: {
            '.is-selected &': {
                color: '#6264a7'
            }
        }
    },
    chevronButton: {
        color: 'white'
    }
};

export const personaStyles: Partial<IPersonaStyles> = {
    root: {
        cursor: 'pointer'
    },
    primaryText: {
        color: 'white',
        '&:hover': {
            color: 'white'
        }
    }
};
export const headerTitleStyle = { fontSize: '1.3rem', color: 'white', textDecoration: 'auto' };

/**
 * Styles for the left nav container.
 *
 * @param state The state of the transition.
 * @returns The styles for the left nav container.
 */
export const leftNavContainerStyles = (state: TransitionStatus) => {
    const transitionStyles = {
        entering: { transform: 'translateX(0)', transition: 'transform 300ms ease-in-out' },
        entered: {},
        exiting: { transform: 'translateX(-100%)', transition: 'transform 300ms ease-in-out' },
        exited: { display: 'none' },
        unmounted: {}
    };
    return {
        float: 'left' as const,
        width: '200px',
        height: 'calc(100vh - 48px - 6px)',
        zIndex: 1,
        position: 'relative' as const,
        color: 'white',
        backgroundColor: '#33344a',
        padding: '3px',
        display: 'flex',
        flexDirection: 'column' as const,
        overflowY: 'auto' as const,
        ...transitionStyles[state]
    };
};
export const footerStyles = { textAlign: 'center' as const, fontSize: '0.8rem', color: 'grey' };
