import { Pivot, PivotItem, Calendar, DateRangeType, defaultCalendarStrings, addMonths } from '@fluentui/react';
import React, { useState, useEffect, useCallback } from 'react';
import { useConst } from '@fluentui/react-hooks';
import ApiService from '../../services/api.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import RetrospectiveTrainDetails from './RetrospectiveTrainDetails';

const Retrospective = (props) => {
    const apiService = new ApiService();
    const [tabData, setTabs] = useState({ clients: [] });
    const [selectedTab, setSelectedTab] = useState('windows');

    // ===============================================================
    useEffect(() => {
        apiService.getJsonFile('clients.json').then(function (response) {
            setTabs((prevState) => ({ ...prevState, clients: response.clients }));
        });
    }, []);

    // ===============================================================
    const maxDate = useConst(addMonths(new Date(Date.now()), -1));
    const [selectedDate, setSelectedDate] = useState(maxDate);
    const [daysFromNow, setDaysFromNow] = useState(calcDaysFromNow(maxDate));
    const [selectedYear, setSelectedYear] = useState(maxDate.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(maxDate.getMonth() + 1);

    function calcDaysFromNow(date) {
        return -Math.ceil((date - Date.now()) / (24 * 60 * 60 * 1000)) + 31; // add extra days to cover request getByWindowInDays
    }

    const onSelectDate = useCallback((date) => {
        setSelectedDate(date);
        setSelectedYear(date.getFullYear());
        setSelectedMonth(date.getMonth() + 1);
        setDaysFromNow(calcDaysFromNow(date));
    }, []);

    // ===============================================================
    return (
        <div data-testid="Retrospective" className="retrospective-v2">
            <div className="retrospective-header-container">
                <div className="retrospective-header" tabIndex="0">
                    <span className="header-title">Release Retrospective Report</span>
                </div>
                <div className="retrospective-header">
                    <Calendar
                        dateRangeType={DateRangeType.Month}
                        highlightSelectedMonth
                        isDayPickerVisible={false}
                        onSelectDate={onSelectDate}
                        value={selectedDate}
                        // Calendar uses English strings by default. For localized apps, you must override this prop.
                        maxDate={maxDate}
                        today={maxDate}
                        strings={defaultCalendarStrings}
                        showGoToToday={false}
                    />
                </div>
            </div>
            <Pivot onLinkClick={onTabClick} selectedKey={selectedTab} className="client-tabs">
                {tabData.clients.map((client) => (
                    <PivotItem itemKey={client.key} headerText={client.text} key={client.key}>
                        <RetrospectiveTrainDetails
                            client={client.clientType}
                            os={client.os}
                            env={client.environment}
                            envV2={client.environmentV2 ?? client.environment}
                            isTrainDataAvailableForInnerRing={client.isTrainDataAvailableForInnerRing}
                            isAGCloudAvailable={client.isAGCloudAvailable}
                            isGovCloudAvailable={client.isGovCloudAvailable}
                            isExpanded={props.isExpanded}
                            viewedYear={selectedYear}
                            viewedMonth={selectedMonth}
                            daysFromNow={daysFromNow}
                        />
                    </PivotItem>
                ))}
            </Pivot>
        </div>
    );

    // ===============================================================
    function onTabClick(tab) {
        setSelectedTab(tab.props.itemKey);
    }

    // ===============================================================
};

Retrospective.propTypes = {};
Retrospective.defaultProps = {};

export default appInsightsClient.withAITracking(Retrospective, 'Retrospective');
