export const SupportedClients = [
    'Mac (T1)',
    'Windows (T1)',
    'TFW Web (T1)',
    'TFL Web (T1)',
    'Windows (T2/Maglev)',
    'T2 Web Consumer',
    'T2 Desktop Converged Win',
    'T2 Web Enterprise',
    'T2 Desktop Converged Mac'
];

// eslint-disable-next-line no-shadow
export const enum RecentChangeType {
    BitsReleaseUpdate = 'BitsReleaseUpdate',
    BuildRolloutUpdate = 'BuildRolloutUpdate',
    FeatureRolloutUpdate = 'FeatureFlagUpdate'
}
