export const SupportedTabs = [
    'Mac (T1)',
    'Windows (T1)',
    'TFW Web (T1)',
    'TFW MultiWindow',
    'TFL MultiWindow',
    'TFL Web (T1)',
    'Windows (T2/Maglev)',
    'T2 Web Consumer',
    'T2 Desktop Converged Win',
    'T2 Desktop Converged Mac',
    'T2 Web Enterprise',
    'Mobile (Android)',
    'Mobile (IOS)'
];

export const SupportedRepositories = [
    'Teamspace-Web',
    'teams-modular-packages',
    'teams-client-native-shell',
    'SkypeSpaces-Android',
    'Teamspace-iOS'
];
