import { Checkbox, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { audienceNames } from '../configs/defaults';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { errorCheckboxStyles, errorMessageStyle } from '../styles/StartFlightFormStyle';

type AudienceProps = {
    reset: boolean;
    defaultValue?: string[] | string;
    showAllErrors: boolean;
    update?: (audience: string[] | string) => void;
};

/**
 * AudienceCheckbox renders a set of checkboxes for selecting Audience.
 *
 * @param props - The component props.
 * @returns The JSX element representing the ComboBox with audience options.
 */
const AudienceCheckbox: React.FC<AudienceProps> = (props) => {
    // ================= State =================
    const reset = props.reset;
    const showAllErrors = props.showAllErrors;
    const defaultValue = props.defaultValue ?? [];
    const disabled = props.update === undefined;

    const [audience, setAudience] = React.useState<string[] | string>(defaultValue);
    const checkBoxStyles = !audience.length && showAllErrors ? errorCheckboxStyles : {};

    // ========================= Hooks =========================
    useEffect(() => {
        setAudience(defaultValue);
    }, [reset]);

    // ========================= Event handlers =========================
    const handleAudienceCheckboxChange = (value: string, isChecked: boolean) => {
        if (!props.update) return;
        let updateAudiences: string[] = [];
        if (isChecked) {
            updateAudiences = [...audience, value];
            appInsightsClient.logEvent({
                name: 'FFV2:StartFlight:AudienceCheckboxChange',
                properties: { value: value, isChecked: isChecked }
            });
        } else if (Array.isArray(audience)) {
            updateAudiences = audience.filter((aud) => aud !== value);
            appInsightsClient.logEvent({
                name: 'FFV2:StartFlight:AudienceCheckboxChange',
                properties: { value: value, isChecked: isChecked }
            });
        }

        setAudience(updateAudiences);
        props.update(updateAudiences);
    };

    return (
        <Stack tokens={gapStackTokensMedium}>
            <Checkbox
                label="Consumer"
                checked={audience.includes(audienceNames.consumer)}
                onChange={(_, isChecked) => {
                    if (isChecked !== undefined) {
                        handleAudienceCheckboxChange(audienceNames.consumer, isChecked);
                    }
                }}
                styles={checkBoxStyles}
                disabled={disabled}
            />
            <Checkbox
                label="Enterprise"
                checked={audience.includes(audienceNames.enterprise)}
                disabled={disabled}
                onChange={(_, isChecked) => {
                    if (isChecked !== undefined) {
                        handleAudienceCheckboxChange(audienceNames.enterprise, isChecked);
                    }
                }}
                styles={checkBoxStyles}
            />
            {!audience.length && showAllErrors && (
                <div role="alert" style={errorMessageStyle}>
                    Select at least one audience
                </div>
            )}
        </Stack>
    );
};

export default AudienceCheckbox;
