import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { ClientType, Environment, Experience, OS, WorkItem } from 'skynet-client';

import { Card } from '../../../components/Card';
import ErrorNotification from '../../../components/ErrorNotification/ErrorNotification';
import { ProcessedReleaseSchedule } from '../../../services/models/ReleaseSchedule';
import { ProcessedTrainMetadata } from '../../../services/models/TrainMetadata';
import ReleaseTrainService from '../../../services/releaseTrain.service';
import ShiproomV2Service from '../../../services/shiproomV2.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { clientTypes } from '../configs/defaults';
import { trainDetailsStackTokens, trainFilteringCardStyles } from '../styles/styles';
import { trainStatusValues } from '../utils';

import { TrainProgressionRow } from './TrainProgressionRow';
import TrainSorting from './TrainSorting';

import { TrainFiltering, TrainHeaderRow } from '.';

type TrainDetailsProps = {
    clientType: ClientType;
    os: OS;
    environment: Environment;
    supportedRings: Record<string, string[]>;
    releaseSchedules: ProcessedReleaseSchedule[];
};

type TrainDetailsWithTrainName = {
    trainDetails: ProcessedTrainMetadata | undefined;
    trainName: string;
};

/**
 * Displays the train details.
 *
 * @param props The props.
 * @returns The Train Details component.
 */
const TrainDetails: React.FC<TrainDetailsProps> = (props) => {
    const [trainsDetails, setTrainsDetails] = useState<Map<string, ProcessedTrainMetadata | undefined>>(new Map());
    const [sortedTrainsDetails, setSortedTrainsDetails] = useState<Map<string, ProcessedTrainMetadata | undefined>>(new Map());
    const [trainStatusFilter, setTrainStatusFilter] = useState<string[]>(trainStatusValues);
    const [ringBlockers, setRingBlockers] = useState<Record<string, Record<string, WorkItem[]>>>({});

    const sortingFilterOptions = [
        { key: 'StartDate', text: 'Start date' },
        { key: 'CompletionDate', text: 'Completion date' }
    ];

    const defaultOption = props.clientType === clientTypes.MOBILE ? sortingFilterOptions[1].key : sortingFilterOptions[0].key;
    const [selectedOption, setSelectedOption] = useState<string>(localStorage.getItem('selectedOption') || defaultOption);
    const [trainFilteringMethod, setTrainFilteringMethod] = useState<string>(selectedOption);

    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    useEffect(() => {
        setIsLoading();
        setIsDataNotLoaded();
        setTrainStatusFilter(trainStatusValues);
        setTrainsDetails(new Map());

        const trainsDetailsMap = new Map<string, ProcessedTrainMetadata | undefined>();

        const shiproomService = new ShiproomV2Service();
        const releaseTrainService = new ReleaseTrainService();
        shiproomService
            .apiDashboardShiproomReportGetLatestTrainNamesGet({
                clientType: props.clientType,
                os: props.os,
                environment: props.environment
            })
            .then((trainNamesResponse) => {
                Promise.all(
                    trainNamesResponse.map((trainName) =>
                        releaseTrainService
                            .getTrainByTitle({ trainTitle: trainName })
                            .then((trainDetailsResponse) => {
                                trainsDetailsMap.set(trainName, trainDetailsResponse);
                            })
                            .catch((error) => {
                                trainsDetailsMap.set(trainName, undefined);
                                console.error(`Error fetching train details for ${trainName}:`, error);
                            })
                    )
                ).then(() => {
                    setIsDataLoaded();
                    setIsNotLoading();
                    setTrainsDetails(trainsDetailsMap);
                    appInsightsClient.logTrace(
                        { message: `Fetched train metadata from ShiproomService` },
                        { trainNames: trainNamesResponse }
                    );
                });

                appInsightsClient.logTrace(
                    { message: `Fetched latest train names from ShiproomService` },
                    { clientType: props.clientType, os: props.os, environment: props.environment }
                );
            })
            .catch((fail) => {
                setIsDataNotLoaded();
                setIsNotLoading();

                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect in TrainDetails`,
                        clientType: props.clientType,
                        os: props.os,
                        environment: props.environment
                    }
                );
            });

        shiproomService
            .getRingBlockers({ clientType: props.clientType, os: props.os, environment: props.environment, experience: Experience.None })
            .then((response) => {
                setRingBlockers(response);

                appInsightsClient.logTrace(
                    { message: `Fetched ring blockers from ShiproomService` },
                    { clientType: props.clientType, os: props.os, environment: props.environment }
                );
            })
            .catch((fail) => {
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect in TrainDetails`,
                        clientType: props.clientType,
                        os: props.os,
                        environment: props.environment
                    }
                );
            });
    }, [props.clientType, props.os, props.environment, props.releaseSchedules]);

    useEffect(() => {
        setSortedTrainsDetails(sortTrainDetailsByFilteringMethod());
    }, [trainFilteringMethod, trainsDetails]);

    function sortMapByDate(firstDate: Date | undefined, secondDate: Date | undefined) {
        firstDate = firstDate || new Date(0);
        secondDate = secondDate || new Date(0);

        return new Date(secondDate).getTime() - new Date(firstDate).getTime();
    }

    function sortTrainDetailsByFilteringMethod() {
        if (trainFilteringMethod === 'CompletionDate') {
            const sortingTrains = new Map<Date, TrainDetailsWithTrainName>();

            trainsDetails.forEach((trainDetails, trainName) => {
                if (trainsDetails) {
                    const trainStatus = trainDetails?.status;
                    const buildReleaseList = trainDetails?.buildReleaseMetadata?.buildReleaseList;

                    if (trainStatus === 'Active') {
                        // If train is active, filtering is done by expectedDate for final target ring, which should be the maxDate in trainSchedule.
                        const releaseScheduleForCurrentTrain = props.releaseSchedules.find((schedule) => schedule.trainName === trainName);
                        const maxDate = releaseScheduleForCurrentTrain
                            ? Object.values(releaseScheduleForCurrentTrain?.trainSchedule).reduce(
                                  (max, current) => (new Date(current) > new Date(max) ? current : max),
                                  new Date(0)
                              )
                            : new Date(0);

                        sortingTrains.set(maxDate, { trainDetails: trainDetails, trainName: trainName });
                    }

                    if (trainStatus === 'Completed' || trainStatus === 'Abandoned') {
                        // If train is completed or abandoned, filtering is done by completedDate of the highest ring.

                        const allRollouts = buildReleaseList?.flatMap((buildRelease) => buildRelease.rollouts);

                        if (allRollouts && allRollouts.length > 0) {
                            const latestRolloutObj = allRollouts?.reduce((latestRollout, currentRollout) => {
                                const currentRolloutDate = new Date(currentRollout?.completedOn ?? 0);
                                const latestRolloutDate = latestRollout ? new Date(latestRollout?.completedOn ?? 0) : new Date(0);
                                return currentRolloutDate > latestRolloutDate ? currentRollout : latestRollout;
                            });

                            sortingTrains.set(latestRolloutObj?.completedOn || new Date(0), {
                                trainDetails: trainDetails,
                                trainName: trainName
                            });
                        } else if (trainDetails) {
                            sortingTrains.set(new Date(0), { trainDetails: trainDetails, trainName: trainName });
                        }
                    }
                } else {
                    sortingTrains.set(new Date(0), {
                        trainDetails: trainDetails,
                        trainName: trainName
                    });
                }
            });

            const sortingTrainsByDate = [...sortingTrains.entries()].sort((a, b) => {
                const firstDate = a[0];
                const secondDate = b[0];
                return sortMapByDate(firstDate, secondDate);
            });

            const sortedTrains = new Map<string, ProcessedTrainMetadata | undefined>(
                sortingTrainsByDate.map(([_, value]) => [value.trainName, value.trainDetails])
            );
            return sortedTrains;
        }

        if (trainFilteringMethod === 'StartDate') {
            const sortedTrainsDetailsArray = Array.from(trainsDetails.entries()).sort((a, b) => {
                const firstDate = a[1]?.createdOn;
                const secondDate = b[1]?.createdOn;
                return sortMapByDate(firstDate, secondDate);
            });

            return new Map(sortedTrainsDetailsArray);
        }

        return trainsDetails;
    }

    return (
        <>
            {!isLoading && !isDataLoaded && (
                <Card>
                    <ErrorNotification msg="Unable to get train details" />
                </Card>
            )}
            {isLoading && (
                <Card>
                    <Spinner size={SpinnerSize.large} label="Fetching trains..." />
                </Card>
            )}
            {!isLoading && isDataLoaded && (
                <Stack tokens={trainDetailsStackTokens}>
                    <Stack horizontal style={{ gap: '10px' }}>
                        <Card styles={trainFilteringCardStyles}>
                            <TrainFiltering setTrainStatusFilter={setTrainStatusFilter} />
                        </Card>
                        <Card>
                            <TrainSorting
                                setTrainFilteringMethod={setTrainFilteringMethod}
                                sortingFilterOptions={sortingFilterOptions}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                clientType={props.clientType}
                            />
                        </Card>
                    </Stack>
                    <TrainHeaderRow supportedRings={props.supportedRings} />
                    {Array.from(sortedTrainsDetails.entries()).map(([trainName, trainDetails]) => {
                        return (
                            <TrainProgressionRow
                                key={trainName}
                                trainName={trainName}
                                clientType={props.clientType}
                                os={props.os}
                                environment={props.environment}
                                trainDetails={trainDetails}
                                supportedRings={props.supportedRings}
                                ringBlockers={getRingBlockersForTrain(trainName)}
                                schedule={getScheduleForTrain(trainName)}
                                trainStatusFilter={trainStatusFilter}
                            />
                        );
                    })}
                </Stack>
            )}
        </>
    );

    function getRingBlockersForTrain(trainName: string): Record<string, WorkItem[]> {
        const trainSuffixes = Object.keys(ringBlockers);
        const train = trainSuffixes.filter((trainSuffix) => trainName.includes(trainSuffix));
        return train?.length === 1 ? ringBlockers[train[0]] : {};
    }

    function getScheduleForTrain(trainName: string): ProcessedReleaseSchedule | undefined {
        const schedules = props.releaseSchedules.filter((schedule) => schedule.trainName === trainName);
        if (schedules.length === 1) {
            return schedules[0];
        } else {
            return undefined;
        }
    }
};

export { TrainDetails };
