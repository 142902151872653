import { IPersonaStyles, IStackItemStyles, IStackStyles, IStackTokens, IconButton, Persona, PersonaSize, Stack } from '@fluentui/react';
import React, { useState, useCallback, useEffect } from 'react';

import { ONEBOT_URI } from '../../configs/env.config';

import WebChat from './WebChat';

import './styles/arnoldWebChat.css';

/**
 * A component to render the web chat.
 *
 * @returns JSX (The actual view).
 */
const ArnoldWebChat: React.FC = () => {
    const [minimized, setMinimized] = useState(true);
    const [isLoaded, setLoaded] = useState(false);
    const [isShaking, setIsShaking] = useState<boolean>(true);
    const [token, setToken] = useState<string>();

    const stackStyles: IStackStyles = {
        root: {
            backgroundColor: '#464775',
            color: '#e5e5e5',
            borderRadius: '10px 10px 0 0',
            padding: '12px',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            display: 'flex',
            alignItems: 'center'
        }
    };

    const composeBoxHeaderTokens: IStackTokens = {
        childrenGap: 8
    };

    const personaStyles: Partial<IPersonaStyles> = {
        primaryText: {
            fontSize: '18px',
            color: 'white',
            '&:hover': {
                color: 'white'
            }
        },
        secondaryText: {
            fontSize: '12px',
            color: 'white',
            '&:hover': {
                color: 'white'
            }
        }
    };

    const handleFetchToken = useCallback(async () => {
        if (!token) {
            const res = await fetch(`${ONEBOT_URI}/directline/token`, { method: 'POST' });
            const responseJson = await res.json();

            setToken(responseJson.token);
        }
    }, [setToken, token]);

    const handleMaximizeButtonClick = () => {
        setLoaded(true);
        setMinimized(false);
    };

    const handleMinimizeButtonClick = () => {
        setMinimized(true);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsShaking(false);
        }, 8000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className="arnold-web-chat">
            {minimized && <button className={`maximize ${isShaking ? 'shake' : ''}`} onClick={handleMaximizeButtonClick}></button>}
            {isLoaded && (
                <div className={`chat-box ${minimized ? 'hide' : ''}`}>
                    <Stack horizontal styles={stackStyles} tokens={composeBoxHeaderTokens}>
                        <Stack.Item styles={stackItemStyles}>
                            <Persona
                                text="Arnold"
                                secondaryText="(Beta)"
                                imageUrl="/arnold.png"
                                size={PersonaSize.size48}
                                styles={personaStyles}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <IconButton
                                iconProps={{ iconName: 'Cancel' }}
                                title="Close"
                                styles={{ root: { color: 'white' } }}
                                onClick={handleMinimizeButtonClick}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack className="web-chat">
                        <WebChat onFetchToken={handleFetchToken} token={token} />
                    </Stack>
                </div>
            )}
        </div>
    );
};

export default ArnoldWebChat;
