import {
    ComboBox,
    DatePicker,
    DefaultButton,
    IComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    IStackTokens,
    PrimaryButton,
    SelectableOptionMenuItemType,
    Stack,
    defaultDatePickerStrings
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useState } from 'react';

import CloudRingSortedList from '../../../configs/rings.json';
import { ClientRepoMap } from '../../../services/models/ClientRepoMap';
import { Ring } from '../../../services/models/common/ring';
import { RolloutParsedLog, RolloutType } from '../../../services/models/FeatureFlag/History/RolloutParsedLog';

interface IHistoryFilterFormProps {
    clients: ClientRepoMap[];
    logs: RolloutParsedLog[];
    updateFilteredLogs: React.Dispatch<React.SetStateAction<RolloutParsedLog[]>>;
}

/**
 * The rollout history filter component.
 *
 * @param props The rollout history filter component props.
 * @returns The rollout history filter component.
 */
const HistoryFilterForm: React.FC<IHistoryFilterFormProps> = (props) => {
    // ========================= State ========================= //
    const today = useConst(new Date(Date.now()));

    const { clients, logs } = props;
    const { updateFilteredLogs } = props;

    const eventTypes: string[] = [RolloutType.FeatureFlag, RolloutType.Build];
    const rings: string[] = CloudRingSortedList.rings.map((x) => x.key);

    const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>();
    const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();

    const [selectedClients, setSelectedClients] = useState<string[]>([]);
    const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
    const [selectedRings, setSelectedRings] = useState<string[]>([]);

    // ========================= Functions ========================= //
    const onClientsChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
            if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
                option.selected ? setSelectedClients(clients.map((x) => x.client)) : setSelectedClients([]);
                return;
            }

            if (option.selected) {
                setSelectedClients([...selectedClients, option.key as string]);
            } else {
                setSelectedClients(selectedClients.filter((x) => x !== option.key));
            }
        }
    };

    const onEventTypeChange = (_event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
            if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
                option.selected ? setSelectedEventTypes(eventTypes.map((x) => x)) : setSelectedEventTypes([]);
                return;
            }

            if (option.selected) {
                setSelectedEventTypes([...selectedEventTypes, option.key as string]);
            } else {
                setSelectedEventTypes(selectedEventTypes.filter((x) => x !== option.key));
            }
        }
    };

    const onRingChange = (_event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
            if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
                option.selected ? setSelectedRings(rings.map((x) => x)) : setSelectedRings([]);
                return;
            }

            if (option.selected) {
                setSelectedRings([...selectedRings, option.key as string]);
            } else {
                setSelectedRings(selectedRings.filter((x) => x !== option.key));
            }
        }
    };

    const onFilterButtonClicked = () => {
        function compareDate(log: RolloutParsedLog) {
            const date = new Date(log.timestamp);
            date.setHours(0, 0, 0, 0);
            return !selectedStartDate || !selectedEndDate || (date >= selectedStartDate && date <= selectedEndDate);
        }

        const filteredLogs = logs
            .filter(compareDate)
            .filter((log) => !selectedEventTypes.length || selectedEventTypes.includes(log.kind))
            .filter((log) => !selectedRings.length || selectedRings.includes(`${log.cloud}_${log.ring}`.toLowerCase()))
            .filter((log) => !selectedClients.length || selectedClients.includes(log.client));

        updateFilteredLogs(filteredLogs);
    };

    const onResetButtonClicked = () => {
        setSelectedStartDate(undefined);
        setSelectedEndDate(undefined);
        setSelectedClients([]);
        setSelectedEventTypes([]);
        setSelectedRings([]);
    };
    // ========================= Render ========================= //
    return (
        <Stack horizontal wrap tokens={stackTokens} style={{ padding: 8 }}>
            <Stack.Item>
                <DatePicker
                    placeholder="Start date"
                    ariaLabel="Start date"
                    onSelectDate={(date) => setSelectedStartDate(date ?? undefined)}
                    strings={defaultDatePickerStrings}
                    maxDate={today}
                    style={{ minWidth: 200 }}
                />
            </Stack.Item>

            <Stack.Item>
                <DatePicker
                    placeholder="End date"
                    ariaLabel="End date"
                    onSelectDate={(date) => setSelectedEndDate(date ?? undefined)}
                    strings={defaultDatePickerStrings}
                    maxDate={today}
                    style={{ minWidth: 200 }}
                />
            </Stack.Item>

            <ComboBox
                placeholder="Event type"
                multiSelect
                options={getTypeComboBoxOptions(eventTypes)}
                styles={filterOptionsStyles}
                onChange={onEventTypeChange}
            />

            <ComboBox
                placeholder="Ring"
                styles={filterOptionsStyles}
                multiSelect
                options={getRingComboBoxOptions(CloudRingSortedList.rings)}
                calloutProps={{ calloutMaxHeight: 500, doNotLayer: true }}
                onChange={onRingChange}
            />

            <ComboBox
                placeholder="Client"
                multiSelect
                options={getClientComboBoxOptions(clients)}
                styles={filterOptionsStyles}
                onChange={onClientsChange}
            />

            <PrimaryButton text="Apply" iconProps={{ iconName: 'Filter' }} onClick={onFilterButtonClicked} />

            <DefaultButton text="Reset" onClick={onResetButtonClicked} />
        </Stack>
    );
};

// ========================= Styles ========================= //
const stackTokens: IStackTokens = { childrenGap: 10 };

const filterOptionsStyles: Partial<IComboBoxStyles> = { callout: { minWidth: 120 }, root: { minWidth: 120, maxWidth: 150 } };

// ========================= Helper Functions ========================= //
/**
 * Gets the ring combo box options.
 *
 * @param rings The rings.
 * @returns The Ring combo box options.
 */
const getRingComboBoxOptions = (rings: Ring[]): IComboBoxOption[] => {
    const options: IComboBoxOption[] = [
        {
            key: 'selectAll',
            text: 'Select All',
            itemType: SelectableOptionMenuItemType.SelectAll
        }
    ];

    rings.forEach((cloudRing) => {
        if (options.find((x) => x.key === cloudRing.cloud) === undefined) {
            options.push({ key: cloudRing.cloud, text: cloudRing.cloud, itemType: SelectableOptionMenuItemType.Header });
        }
        options.push({ key: cloudRing.key, text: cloudRing.text });
    });

    return options;
};

/**
 * Gets the event type combo box options.
 *
 * @param eventTypes The event types.
 * @returns The event type combo box options.
 */
const getTypeComboBoxOptions = (eventTypes: string[]): IComboBoxOption[] => {
    const options: IComboBoxOption[] = [
        {
            key: 'selectAll',
            text: 'Select All',
            itemType: SelectableOptionMenuItemType.SelectAll
        }
    ];

    eventTypes.forEach((eventType) => {
        options.push({ key: eventType, text: eventType });
    });

    return options;
};

/**
 * Gets the client combo box options.
 *
 * @param clients The clients.
 * @returns The client combo box options.
 */
const getClientComboBoxOptions = (clients: ClientRepoMap[]): IComboBoxOption[] => {
    const options: IComboBoxOption[] = [
        {
            key: 'selectAll',
            text: 'Select All',
            itemType: SelectableOptionMenuItemType.SelectAll
        }
    ];

    clients.forEach((client) => {
        options.push({ key: client.client, text: client.client });
    });

    return options;
};

// ========================= Export ========================= //
export default HistoryFilterForm;
