import { Stack, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../components/Card';
import { JSONChartData } from '../../services/models/RecentChangesReport/JSONChartData';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import { FilterFormData } from './FilterForm/FilterFormData';
import { RecentChangesFiltersForm } from './FilterForm/RecentChangesFilterForm';
import { recentChangesPageStyles } from './styles/styles';
import { RecentChangesView } from './View/RecentChangesView';

const RecentChangesPage: React.FC = () => {
    const [filterFormData, setFilterFormData] = useState<FilterFormData>({
        startDate: new Date(),
        endDate: new Date(),
        clients: [],
        changeTypes: [],
        rings: []
    });
    const [validatedFormData, setValidatedFormData] = useState<FilterFormData>();
    const [jsonChartData, setJsonChartData] = useState<JSONChartData[]>([]);
    const [filteredFeatureFlags, setFilteredFeatureFlags] = useState<string[]>([]);

    useEffect(() => {
        setJsonChartData([]);
    }, []);

    return (
        <Stack tokens={recentChangesPageStyles}>
            <Card>
                <CardHeader>Recent Changes</CardHeader>
                <Text>
                    Review recent release information across supported pipelines. History includes feature flag, build rollout and bits
                    release changes upto 30 days.
                </Text>
            </Card>
            <RecentChangesFiltersForm
                filterFormData={filterFormData}
                setFilterFormData={setFilterFormData}
                onSubmit={onSubmit}
                jsonChartData={jsonChartData}
                filteredFeatureFlags={filteredFeatureFlags}
                setFilteredFeatureFlags={setFilteredFeatureFlags}
            />
            {validatedFormData && (
                <RecentChangesView filterFormData={validatedFormData} onDataLoad={onDataLoad} filteredFeatureFlags={filteredFeatureFlags} />
            )}
        </Stack>
    );

    function onSubmit() {
        setValidatedFormData(filterFormData);
        appInsightsClient.logTrace(
            { message: 'RecentChangesReport: Filter form submitted' },
            {
                filterFormData: filterFormData
            }
        );
    }

    function onDataLoad(chartData: JSONChartData[]) {
        setJsonChartData(chartData);
    }
};

export default appInsightsClient.withAITracking(RecentChangesPage, 'RecentChangesReport');
