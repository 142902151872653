import { Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClientRepoMap } from 'skynet-client';

import { Card, CardHeader } from '../../components/Card';
import clientsJson from '../../configs/clients.base.json';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import { SupportedRings, SupportedTabs } from './configs/defaults';
import ShiproomClientReport from './ShiproomClientReport';
import { shiproomRootStackTokens, shiproomRootPivotCardStyles } from './styles/styles';

/**
 * Displays the shiproom page.
 *
 * @returns The main component for the page.
 */
const ShiproomPage: React.FC = () => {
    const [tabData, setTabData] = useState<ClientRepoMap[]>([]);
    const [selectedTabData, setSelectedTabData] = useState<ClientRepoMap>();

    const [searchParams, setSearchParams] = useSearchParams();

    const client = searchParams.get('client') ?? 'T2 Desktop Converged Win';
    const clients: ClientRepoMap[] = clientsJson as ClientRepoMap[];

    useEffect(() => {
        const supportedTabs = clients.filter((clientMap: ClientRepoMap) => clientMap.client && SupportedTabs.includes(clientMap.client));
        setTabData(supportedTabs);

        if (selectedTabData === undefined && supportedTabs.length > 0) {
            const defaultTab = supportedTabs.find((tab) => tab.client === client) ?? supportedTabs[0];
            setSelectedTabData(defaultTab);
            appInsightsClient.logEvent({ name: 'Shiproom Report Tab View' }, { client: defaultTab.client });
        }
    }, []);

    return (
        <Stack tokens={shiproomRootStackTokens}>
            <Card styles={shiproomRootPivotCardStyles}>
                <CardHeader>Shiproom</CardHeader>
                {selectedTabData && (
                    <Pivot onLinkClick={onTabClick} selectedKey={selectedTabData.client} overflowBehavior="menu">
                        {tabData.map((clientMap: ClientRepoMap) => (
                            <PivotItem
                                itemKey={clientMap.client}
                                headerText={clientMap.client}
                                key={clientMap.client}
                                headerButtonProps={{
                                    'data-id': `shiproom-report-client-pivot`,
                                    'data-value': clientMap.client ?? 'N/A'
                                }}
                            ></PivotItem>
                        ))}
                    </Pivot>
                )}
            </Card>
            {selectedTabData &&
                selectedTabData.clientType &&
                selectedTabData.os &&
                selectedTabData.environment &&
                selectedTabData.experience &&
                selectedTabData.client && (
                    <ShiproomClientReport
                        clientType={selectedTabData.clientType}
                        os={selectedTabData.os}
                        environment={selectedTabData.environment}
                        experience={selectedTabData.experience}
                        supportedRings={SupportedRings[selectedTabData.client]}
                    />
                )}
        </Stack>
    );

    function onTabClick(item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) {
        const key = item?.props.itemKey ?? 'Windows (T1)';
        const selectedTab = tabData.filter((tab) => tab.client === key)[0];
        setSelectedTabData(selectedTab);
        handleDeepLink(key);
        appInsightsClient.logEvent({ name: 'Shiproom Report Tab View' }, { client: selectedTab.client });
    }

    function handleDeepLink(key: string) {
        setSearchParams({ client: key });
    }
};

export default appInsightsClient.withAITracking(ShiproomPage, 'ShiproomReport');
