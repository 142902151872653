import { Label, MessageBar, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import {
    cloudDropdownOptions,
    defaultRingChangeRequestForm,
    idTypeDropdownOptions,
    operationDropdownOptions,
    ringDropdownOptions
} from './configs/defaults';
import AdoWorkItemInputForRM from './RingAssignmentPageComponents/AdoWorkItemInputForRM';
import DropdownInput from './RingAssignmentPageComponents/DropdownInput';
import IDInput from './RingAssignmentPageComponents/IDInput';
import FormButtons from './RingAssignmentPageComponents/RequestFormButtons';
import { ringmasterRequestTableCellStyle, ringmasterRequestTableStyle } from './styles/RingmasterPageStyle';
import { RingChangeRequestFormData } from './types/Types';

/**
 * This component exposes the form to request a ring change for a given ID.
 * It appears on the RingAssignmentPage as a tab.
 *
 * @returns The actual view.
 */
const RequestForm: React.FC = () => {
    // ============================== State ==============================
    const [formData, setFormData] = useState<RingChangeRequestFormData>({ ...defaultRingChangeRequestForm, createdBy: getUserInfo() });

    const [clearFormData, setClearFormData] = useState<boolean>(false);

    // ============================== Functions ==============================

    const updateWorkItemDetails = (workItemId: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            workItemId: workItemId
        }));
    };

    const updateIdentityType = (idType: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            identityType: idType
        }));
    };

    const updateRing = (ring: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ring: ring
        }));
    };

    const updateCloud = (cloud: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            cloud: cloud
        }));
    };

    const updateOperation = (operation: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            operation: operation
        }));
    };

    const updateOperationId = (operationId: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            operationId: operationId
        }));
    };

    return (
        <>
            <Stack style={ringmasterRequestTableStyle}>
                <MessageBar>Please use this form to request a ring change for any test user/tenant</MessageBar>
                <Stack.Item>
                    <table>
                        <tbody>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>ADO Work Item:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <AdoWorkItemInputForRM setWorkItemDetails={updateWorkItemDetails} resetInput={clearFormData} />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Identity Type:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateIdentityType}
                                        dropdownOptions={idTypeDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Ring:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateRing}
                                        dropdownOptions={ringDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Cloud:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateCloud}
                                        dropdownOptions={cloudDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Operation:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <DropdownInput
                                        setDropdownOption={updateOperation}
                                        dropdownOptions={operationDropdownOptions}
                                        resetInput={clearFormData}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Operation Id:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <IDInput setId={updateOperationId} resetInput={clearFormData} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Stack.Item>

                <FormButtons
                    formData={formData}
                    setFormData={setFormData}
                    resetFormDataTrigger={setClearFormData}
                    resetData={clearFormData}
                />
            </Stack>
        </>
    );
};

export default appInsightsClient.withAITracking(RequestForm, 'RequestForm');
