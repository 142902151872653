import { Dropdown, IComboBoxStyles, IComboBoxOption } from '@fluentui/react';
import React, { useState } from 'react';

import CardWrapper from '../../components/CardWrapper/CardWrapper';
import { EnvironmentType } from '../../services/models/Experience/EnvironmentType';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import ExperienceView from './ExperiencesView';

const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { minWidth: 200, maxWidth: 300, maxHeight: 200 },
    callout: { minWidth: 200 }
};

/**
 * Displays the experience report page.
 *
 * @returns The main component for the report.
 */
const ExperienceReport: React.FC = () => {
    const [environment, setEnvironment] = useState<EnvironmentType>('Work');

    const onEnvironmentSelected = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IComboBoxOption,
        _index?: number,
        _value?: string
    ): void => {
        setEnvironment(option?.key as EnvironmentType);
    };

    // ===============================================================================================
    return (
        <>
            <CardWrapper>
                <div className="cardHeader" style={{ fontSize: 18 }}>
                    Experience info Report
                </div>
                <Dropdown
                    label="Environment"
                    selectedKey={environment}
                    options={[
                        { key: 'Work', text: 'Work' },
                        { key: 'Life', text: 'Life' }
                    ]}
                    onChange={onEnvironmentSelected}
                    calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
                    styles={comboBoxStyles}
                />
            </CardWrapper>
            <CardWrapper>
                <ExperienceView environment={environment} />
            </CardWrapper>
        </>
    );
};

export default appInsightsClient.withAITracking(ExperienceReport, 'ExperienceReport');
