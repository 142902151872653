import RingmasterRequestDetailsObject from '../../../services/models/Ringmaster/RingmasterRequestDetailsObject';
import { RingmasterRequestDetailsItem } from '../types/Types';

/**
 * Computes the status date pertaining to a particular request status.
 *
 * @param request An object of type RingmasterRequestsStructure.
 * @returns A string representing the status date.
 */
export function getStatusDate(request: RingmasterRequestDetailsObject): string {
    switch (request.requestStatus) {
        case 'Approved':
            return request.approvedTime;
        case 'Rejected':
            return request.rejectedTime;
        case 'Pending':
            return request.createdTime;
    }

    return '';
}

/**
 * Matches a string to a RingmasterRequestDetailsItem.
 *
 * @param text A string to match.
 * @param item An object of type RingmasterRequestDetailsItem.
 * @returns A boolean indicating whether the string matches the item.
 */
export function matchesRingmasterRequestDetailsItem(text: string, item: RingmasterRequestDetailsItem): boolean {
    return (
        item.userFriendlyRequestId?.toLowerCase().includes(text.toLowerCase()) ||
        item.workItemId?.toLowerCase().includes(text.toLowerCase()) ||
        item.operation?.toLowerCase().includes(text.toLowerCase()) ||
        item.createdTime?.toLowerCase().includes(text.toLowerCase()) ||
        item.identityType?.toLowerCase().includes(text.toLowerCase()) ||
        item.ring?.toLowerCase().includes(text.toLowerCase()) ||
        item.requestStatus?.toLowerCase().includes(text.toLowerCase()) ||
        item.statusDate?.toLowerCase().includes(text.toLowerCase()) ||
        item.createdBy?.principalName?.toLowerCase().includes(text.toLowerCase())
    );
}
