/**
 * Custom error class representing an invalid boarding pass error.
 * Extends the built-in Error class.
 *
 * @class InvalidBoardingPass
 * @param {string} message - The error message describing the reason for the invalid boarding pass.
 */
export class InvalidBoardingPass extends Error {
    /**
     *
     * @param {string} message - The error message describing the reason for the invalid boarding pass.
     */
    constructor(message: string) {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

/**
 * Custom error class representing an invalid ado item state error.
 * Extends the built-in Error class.
 *
 * @class InvalidADOItemState
 * @param {string} message - The error message describing the details.
 */
export class InvalidADOItemState extends Error {
    /**
     *
     * @param {string} message - The error message describing the reason.
     */
    constructor(message = 'Invalid ADO item: state is not set to "Rolling Out". ') {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

/**
 * Custom error class representing an invalid ado item with no feature flags error.
 * Extends the built-in Error class.
 *
 * @class InvalidADOItemNoFeatureFlags
 * @param {string} message - The error message describing the details.
 */
export class InvalidADOItemNoFeatureFlags extends Error {
    /**
     *
     * @param {string} message - The error message describing the reason.
     */
    constructor(message = 'Invalid ADO Item: no feature flags') {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

/**
 * Custom error class representing ADO item does not exist error.
 * Extends the built-in Error class.
 *
 * @class ADOItemNotExist
 * @param {string} message - The error message describing the details.
 */
export class ADOItemNotExist extends Error {
    /**
     *
     * @param {string} message - The error message describing the reason.
     */
    constructor(message = 'ADO item does not exist, or you do not have permissions to read it.') {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

/**
 * Custom error class representing fail to retrieve ADO item error.
 * Extends the built-in Error class.
 *
 * @class RetrieveWorkItemError
 * @param {string} message - The error message describing the details.
 */
export class FailToRetrieveWorkItem extends Error {
    /**
     *
     * @param {string} message - The error message describing the reason.
     */
    constructor(message = 'Error occurred while trying to retrieve work item') {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

/**
 * Represents an error that occurs when an invalid control configuration is encountered.
 */
export class InvalidControlConfigError extends Error {
    /**
     * Creates a new instance of the InvalidControlConfigError class.
     *
     * @param message - The error message describing the reason for the error. Default value is 'Invalid control config'.
     */
    constructor(message = 'Invalid control config') {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}
