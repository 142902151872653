import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import React, { useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { comboBoxStyles } from '../styles/StartFlightFormStyle';

type ExperienceComboBoxProps = {
    isOpen: boolean;
    reset: boolean;
    experienceOptions: string[];
    update: (value?: string) => void;
    defaultValue?: string;
};

/**
 * Renders a combo box to select experience options.
 *
 * @param props - The props containing experienceOptions and isExperienceOptionsEnable.
 * @returns The JSX element representing the experience combo box.
 */
const ExperienceComboBox: React.FC<ExperienceComboBoxProps> = (props) => {
    // ================= State =================
    const { isOpen, reset, experienceOptions } = props;
    const defaultValue = props.defaultValue ?? 'react-web-client';
    const [experience, setExperience] = React.useState<string>(defaultValue);
    const options = experienceOptions.map((key) => ({
        key: key,
        text: key,
        disabled: key !== 'react-web-client'
    }));

    // ========================= Hooks =========================
    useEffect(() => {
        setExperience(defaultValue);
        props.update(defaultValue);
    }, [isOpen, reset]);

    const onChangeInput = (_?: React.FormEvent<IComboBox>, value?: IComboBoxOption) => {
        props.update(value?.key.toString());
        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:ExperienceSelected', properties: { value: value } });
    };

    return <>{isOpen && <ComboBox options={options} selectedKey={experience} styles={comboBoxStyles} onChange={onChangeInput} />}</>;
};

export default ExperienceComboBox;
