const Common = {
    //================================================================================
    //common for TrainRetrospective and TrainProgression

    getTrainNameDetails(teamsThread, trainName) {
        return (
            <b>
                <a
                    href={teamsThread}
                    target="_blank"
                    data-toggle="tooltip"
                    title="Arnold Teams Post"
                    rel="noreferrer"
                    style={{ color: 'darkslategrey' }}
                >
                    {trainName}
                </a>
            </b>
        );
    },

    getTrainStatus(trainStatus, style) {
        return <div className={`${style}`}>[{trainStatus}]</div>;
    },

    getSnapBranches(snapBranches) {
        return (
            <div className="release-content" style={{ fontSize: '12px' }}>
                <br />
                Snap Branches :
                {snapBranches?.map((snap) => (
                    <li key={snap.link}>
                        <a href={snap.link} target="_blank" rel="noreferrer">
                            {snap.title}
                        </a>
                    </li>
                ))}
            </div>
        );
    }

    //================================================================================
};

export default Common;
