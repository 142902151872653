import {
    IconButton,
    Link,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    ProgressIndicator,
    Stack,
    Text,
    TextField
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import ApiResponseError from '../../../services/models/error/ApiResponseError';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { getUserInfo } from '../../../utils/userUtility';
import { formStyles, gapStackTokensMedium, iconButtonStyles, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { cancelIcon } from '../styles/MyFlightsStyle';
import { ApprovalFormData, ApprovalItem, ProcessedFlightRollout } from '../types/Types';

type ApprovalFormProps = {
    onDismiss: () => void;
    updateFlight: (flightId: string) => void;
    action: string | undefined;
    flight: ProcessedFlightRollout | undefined;
};

/**
 *
 * Displays the form to approve or reject a flight.
 *
 * @param props The props for the component.
 * @returns The flight approval form.
 */
const ApprovalForm: React.FC<ApprovalFormProps> = (props) => {
    const { flight, action, updateFlight } = props;
    const user = useConst(() => getUserInfo());
    const [comments, setComments] = useState<string>('');
    const [approvalResult, setApprovalResult] = useState<ApprovalItem | Error | 'loading' | undefined>();

    function onSubmit() {
        if (!action || !flight) return;
        appInsightsClient.logEvent({ name: 'FFV2:MyFlights:ApprovalFormSubmit', properties: { response: 'good' } });
        const data: ApprovalFormData = {
            status: action,
            user: user
        };
        if (comments.length) {
            data.comments = comments;
        }

        new FeatureFlightService()
            .approveRollout(flight.id, data)
            .then((response) => {
                appInsightsClient.logEvent({ name: 'FFV2:MyFlights:ApprovalFormSubmit', properties: { response: response } });
                setApprovalResult({ ...response, ['rolloutId']: flight.id });
                updateFlight(flight.id);
            })
            .catch((fail: ApiResponseError) => {
                if (fail.statusCode === 409) {
                    updateFlight(flight.id);
                }
                const err = new Error(fail.errorDetail);
                setApprovalResult(err);
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing updating rollout approval status in ApprovalForm' }
                );
                console.error(`Caught error approving flight: ${flight.id}, error: ${fail}`);
            });
        onDismiss();
    }

    function onCommentsChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        if (newValue) {
            setComments(newValue);
        }
    }

    function onDismiss() {
        props.onDismiss();
        setComments('');
    }

    return (
        <>
            {approvalResult &&
                approvalResult !== 'loading' &&
                ('message' in approvalResult ? (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        truncated={true}
                        onDismiss={() => setApprovalResult(undefined)}
                    >
                        <Text>Approval action failed: {approvalResult.message}</Text>
                    </MessageBar>
                ) : (
                    <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={() => setApprovalResult(undefined)}>
                        <Text>
                            Rollout {approvalResult.rolloutId} has been {approvalResult.status} by {approvalResult.approvedBy?.displayName}:{' '}
                            <Link href={approvalResult.url} target="_blank">
                                approval details
                            </Link>
                        </Text>
                    </MessageBar>
                ))}
            {approvalResult === 'loading' && <ProgressIndicator label={`Updating flight`} styles={progressIndicatorCustomStyles} />}
            <Modal
                isOpen={!!action && !!flight}
                onDismiss={props.onDismiss}
                onDismissed={() => setComments('')}
                isBlocking={false}
                className={formStyles.container}
            >
                <div className={formStyles.header}>
                    <h3>
                        You are {action ? action.replace(/..$/, 'ing') : ''} rollout {flight?.id}.
                    </h3>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
                </div>
                <div className={formStyles.body}>
                    <Stack tokens={gapStackTokensMedium}>
                        <Text>Please review its feature flags before confirming your action: </Text>
                        <pre>{JSON.stringify(flight?.featureFlags, null, 3)}</pre>
                        <TextField label="Comments" multiline onChange={onCommentsChange} />
                        <Stack horizontal tokens={gapStackTokensMedium}>
                            <PrimaryButton onClick={onSubmit}>Confirm</PrimaryButton>
                        </Stack>
                    </Stack>
                </div>
            </Modal>
        </>
    );
};

export default ApprovalForm;
