import {
    DatePicker,
    FontWeights,
    IconButton,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    Separator,
    Stack,
    getTheme,
    mergeStyleSets
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { Experience, ReleaseSchedule, ReleaseSchedulePayload } from 'skynet-client';

import { ProcessedReleaseSchedule } from '../../services/models/ReleaseSchedule';
import ScheduleService from '../../services/schedule.service';
import { getTrainScheduleDict } from '../../services/utils';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserPrincipalName } from '../../utils/userUtility';
import { getLongDateTimeString } from '../Shiproom/utils';

type ReleaseScheduleFormProps = {
    isOpen: boolean;
    onDismiss: () => void;
    schedule: ProcessedReleaseSchedule;
    rawSchedule: ReleaseSchedule;
    supportedRings: string[];
    setListData: React.Dispatch<React.SetStateAction<ProcessedReleaseSchedule[]>>;
};

/**
 * Displays the release schedule form for the selected train.
 *
 * @param props The props.
 * @returns The Release Schedule Form component.
 */
const ReleaseScheduleForm: React.FC<ReleaseScheduleFormProps> = (props) => {
    const [formState, setFormState] = useState<Record<string, Date>>({});
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [isSubmitEnabled, { setTrue: enableSubmit, setFalse: disableSubmit }] = useBoolean(false);

    const containerStackTokens = { childrenGap: 5 };

    const titleId = useId('title');
    const cancelIcon = { iconName: 'Cancel' };

    useEffect(() => {
        setError(undefined);
        setWarning(undefined);
        setFormState({});
        disableSubmit();
    }, [props.schedule, props.rawSchedule, props.supportedRings]);

    return (
        <Modal
            className={contentStyles.container}
            titleAriaId={titleId}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            isBlocking={false}
        >
            <div className={contentStyles.header}>
                <h3 id={titleId}>Edit schedule: {props.schedule.trainName}</h3>
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
            </div>
            <div className={contentStyles.body}>
                {error && <MessageBar messageBarType={MessageBarType.error}> {error}</MessageBar>}
                {warning && (
                    <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setWarning(undefined)}>
                        {warning}
                    </MessageBar>
                )}
                <form onSubmit={onSubmit}>
                    <Stack tokens={containerStackTokens}>
                        <Separator>
                            <b>Snap Date:</b> {getLongDateTimeString(props.schedule.snapDate)}
                        </Separator>
                        {Object.keys(props.schedule.trainSchedule)
                            .filter((x) => props.supportedRings.includes(x))
                            .map((cloudRing) => {
                                return (
                                    <div key={cloudRing}>
                                        <DatePicker
                                            isMonthPickerVisible={false}
                                            initialPickerDate={new Date(props.schedule.trainSchedule[cloudRing])}
                                            label={cloudRing}
                                            minDate={new Date(props.schedule.snapDate)}
                                            onSelectDate={(date) => {
                                                if (date) {
                                                    onChange(props.schedule, cloudRing, date, props.supportedRings);
                                                }
                                            }}
                                            placeholder={new Date(props.schedule.trainSchedule[cloudRing]).toDateString()}
                                        />
                                    </div>
                                );
                            })}
                        <br />
                    </Stack>
                    <PrimaryButton text="Submit" onClick={onSubmit} allowDisabledFocus disabled={!isSubmitEnabled} />
                </form>
            </div>
        </Modal>
    );

    function onChange(schedule: ProcessedReleaseSchedule, key: string, date: Date, supportedRings: string[]) {
        // set time based on the original time set for the ring
        const originalDate = new Date(schedule.trainSchedule[key]);
        date = new Date(date);
        date.setHours(originalDate.getHours(), originalDate.getMinutes());

        if (date < new Date()) {
            setWarning('The updated date is in the past!');
        } else {
            setWarning(undefined);
        }
        if (date === originalDate) {
            delete formState[key];
        } else {
            formState[key] = date;
        }
        setFormState(formState);
        if (isFormStateValid(schedule, formState, supportedRings)) {
            enableSubmit();
        } else {
            disableSubmit();
        }
    }

    function onSubmit() {
        const payload = getUpdatePayload(props.rawSchedule, formState);

        new ScheduleService()
            .apiReleaseScheduleUpdatePut({ releaseSchedulePayload: payload })
            .then(() => {
                const user = getUserPrincipalName();
                const timestamp = new Date();
                const properties = { user, timestamp, formState };
                appInsightsClient.logEvent({ name: 'releaseScheduleUpdateEvent' }, properties);
                console.log('Release schedule update logged successfully.');
            })
            .catch((fail) => {
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing updateReleaseSchedule in ReleaseScheduleForm' }
                );
            })
            .finally(() => {
                props.onDismiss();
                props.setListData((currSchedules) => {
                    const index = currSchedules.findIndex((x) => x.trainName === props.schedule.trainName);
                    if (payload.trainSchedule !== undefined) {
                        currSchedules[index].trainSchedule = getTrainScheduleDict(payload.trainSchedule);
                    }
                    return currSchedules;
                });
            });
    }

    function isFormStateValid(schedule: ProcessedReleaseSchedule, currFormState: Record<string, Date>, supportedRings: string[]) {
        if (Object.keys(currFormState).length === 0) return false;
        let prevDate = schedule.snapDate;
        let prevCloudRing = 'snapDate';

        const cloudRings = Object.keys(schedule.trainSchedule).filter((x) => supportedRings.includes(x));
        for (const currCloudRing of cloudRings) {
            const currDate =
                currFormState[currCloudRing] === undefined ? schedule.trainSchedule[currCloudRing] : currFormState[currCloudRing];
            if (new Date(prevDate) > new Date(currDate)) {
                setError(
                    `Dates must be in sequential order!\nThe release date for ${prevCloudRing} must come before release date for ${currCloudRing}.`
                );
                return false;
            }
            prevDate = currDate;
            prevCloudRing = currCloudRing;
        }

        setError(undefined);
        return true;
    }
};

function getUpdatePayload(rawSchedule: ReleaseSchedule, validFormState: Record<string, Date>) {
    const updatePayload: ReleaseSchedulePayload = {
        clientType: rawSchedule.clientType,
        os: rawSchedule.os,
        environment: rawSchedule.environment,
        experience: rawSchedule.experience ?? Experience.None,
        trainSnap: rawSchedule.trainSnap,
        month: rawSchedule.month,
        year: rawSchedule.year,
        snapDate: rawSchedule.snapDate,
        trainSchedule: []
    };

    for (const cloudRing in validFormState) {
        const [cloud, ring] = cloudRing.split('_', 2);

        const originalTrainSchedule = rawSchedule.trainSchedule.find((x) => x.cloud === cloud && x.ring === ring);
        if (!originalTrainSchedule) continue;

        const fullRelease = originalTrainSchedule.rolloutSchedule.find((x) => x.releasePercentage === 100);
        if (!fullRelease) continue;

        // get how long a release takes in this cloud/ring
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const releaseDays = (new Date(fullRelease.endTime).getTime() - new Date(fullRelease.startTime).getTime()) / millisecondsPerDay;

        const startTime = new Date(validFormState[cloudRing]);
        const endTime = new Date(validFormState[cloudRing]);
        endTime.setDate(endTime.getDate() + releaseDays);

        const proposedTrainSchedule = { ...originalTrainSchedule };
        proposedTrainSchedule.rolloutSchedule.forEach((stage) => {
            stage.startTime = startTime;
            stage.endTime = endTime;
        });
        if (updatePayload.trainSchedule === undefined) {
            updatePayload.trainSchedule = [];
        }
        updatePayload.trainSchedule.push(proposedTrainSchedule);
    }

    return updatePayload;
}
const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    header: [
        theme.fonts.large,
        {
            borderBottom: `0.07rem solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '4px 12px 4px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        minWidth: '600px',
        maxWidth: '600px',
        overflowY: 'hidden'
    }
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};
export default ReleaseScheduleForm;
