import { Link } from '@fluentui/react';
import React from 'react';
import { WorkItem } from 'skynet-client';

import { Card, CardHeader } from '../../../../components/Card';
import { ringBlockersTableStyles, ringBlockersTableHeaderStyles, ringBlockersTableCellStyles } from '../../styles/styles';

type RingBlockersTableProps = {
    ringBlockers: WorkItem[];
    showRing: boolean;
};

/**
 * Displays the ring blockers table.
 *
 * @param props The props for the component.
 * @returns The ring blockers table.
 */
export const RingBlockersTable: React.FC<RingBlockersTableProps> = (props) => {
    const { ringBlockers } = props;

    return (
        <Card>
            <CardHeader>
                <span>Ring Blockers</span>
            </CardHeader>
            <table style={ringBlockersTableStyles}>
                <thead>
                    <tr>
                        <th style={ringBlockersTableHeaderStyles}>Id</th>
                        <th style={ringBlockersTableHeaderStyles}>Title</th>
                        <th style={ringBlockersTableHeaderStyles}>Area Path</th>
                        {props.showRing && <th style={ringBlockersTableHeaderStyles}>Ring</th>}
                        <th style={ringBlockersTableHeaderStyles}>GEM Owner</th>
                        <th style={ringBlockersTableHeaderStyles}> Status Tweet</th>
                    </tr>
                </thead>
                <tbody>
                    {ringBlockers.map(
                        (workItem) =>
                            workItem.fields && (
                                <tr key={workItem.id}>
                                    <td style={ringBlockersTableCellStyles}>
                                        <Link href={workItem.links?.html?.href} target="_blank" rel="noopener noreferrer">
                                            {workItem.id}
                                        </Link>
                                    </td>
                                    <td style={ringBlockersTableCellStyles}>{workItem.fields['System.Title']}</td>
                                    <td style={ringBlockersTableCellStyles}>{workItem.fields['System.AreaPath']}</td>
                                    {props.showRing && (
                                        <td style={ringBlockersTableCellStyles}>{workItem.fields['MicrosoftTeamsCMMI.RingBlocker']}</td>
                                    )}
                                    <td style={ringBlockersTableCellStyles}>
                                        {workItem.fields['MicrosoftTeamsCMMI.GEMOwner']?.displayName}
                                    </td>
                                    <td style={ringBlockersTableCellStyles}>{workItem.fields['MicrosoftTeamsCMMI.StatusTweet']}</td>
                                </tr>
                            )
                    )}
                </tbody>
            </table>
        </Card>
    );
};
