import { ActionButton, IButtonProps, TeachingBubble } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import React from 'react';

import { platformsMap } from '../configs/defaults';
import { cellStyles, platformCellStyles, tableStyles } from '../styles/StartFlightFormStyle';

/**
 * Renders a teaching bubble for platforms.
 *
 * @returns The JSX element representing the mapping of platforms to the audience.
 */
const PlatformsTeachingBubble: React.FC = () => {
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const buttonId = useId('platformInfoButton');
    const primaryButtonProps: IButtonProps = {
        children: 'Close',
        onClick: toggleTeachingBubbleVisible
    };

    return (
        <>
            <ActionButton id={buttonId} iconProps={{ iconName: 'Info' }} onClick={toggleTeachingBubbleVisible} />

            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${buttonId}`}
                    primaryButtonProps={primaryButtonProps}
                    hasSmallHeadline={true}
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="Platform-to-audience mapping"
                    closeButtonAriaLabel="Close"
                >
                    <table style={tableStyles}>
                        <thead>
                            <tr>
                                <th style={platformCellStyles}>Platform </th>
                                <th style={cellStyles}>Consumer </th>
                                <th style={cellStyles}>Enterprise </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={platformCellStyles}>{platformsMap.webT2Platform}</td>
                                <td style={cellStyles}>Web</td>
                                <td style={cellStyles}>Web</td>
                            </tr>
                            <tr>
                                <td style={platformCellStyles}>{platformsMap.desktopT20Platform}</td>
                                <td style={cellStyles}>T2 Client (Maglev)</td>
                                <td style={cellStyles}>N/A</td>
                            </tr>
                            <tr>
                                <td style={platformCellStyles}>{platformsMap.desktopT21Platform}</td>
                                <td style={cellStyles}>T2.2 Converged Client (Maglev)</td>
                                <td style={cellStyles}>T2.1 Client (Maglev)</td>
                            </tr>
                            <tr>
                                <td style={platformCellStyles}>{platformsMap.desktopMacT21Platform}</td>
                                <td style={cellStyles}>T2.2 Converged Mac Client (Maglev)</td>
                                <td style={cellStyles}>T2.1 Mac Client (Maglev)</td>
                            </tr>
                        </tbody>
                    </table>
                </TeachingBubble>
            )}
        </>
    );
};

export default PlatformsTeachingBubble;
