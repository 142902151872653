import { Stack, SearchBox, DatePicker, IconButton, addYears, ComboBox } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React from 'react';

import { audienceNames, inProgressStatus } from '../configs/defaults';
import { gapStackTokensLarge, iconButtonStyles } from '../styles/FFv2Style';
import { filterStackStyles, searchStyles, filterStyles, cancelIcon } from '../styles/MyFlightsStyle';
import { FlightFilters, FlightStatusFilter, ProcessedFlightRollout, UserInfo } from '../types/Types';
import { capitalizeFirstLetter } from '../utilities/FFv2Utils';

import FlightCreatorFilterComboBox from './FlightCreatorFilterComboBox';
import FlightStageFilterComboBox from './FlightStageFilterComboBox';

type FlightFiltersBarProps = {
    flightFilters: FlightFilters;
    flightStages: string[];
    flightStatusFilter: FlightStatusFilter;
    handleFilterChange: (key: string, value: string | string[] | Date | undefined | boolean) => void;
    clearFilters: () => void;
    selectedFlight?: ProcessedFlightRollout;
    teamMembers?: UserInfo[];
    otherUsers?: UserInfo[];
};

/**
 * Flight Filters Bar component.
 *
 * @param {FlightFiltersBarProps} props - The props for the FlightFiltersBar component.
 * @returns {React.ReactElement} The rendered FlightFiltersBar component.
 */
const FlightFiltersBar: React.FC<FlightFiltersBarProps> = (props) => {
    const { flightStages, flightFilters, teamMembers, otherUsers, handleFilterChange, clearFilters } = props;
    const today = useConst(new Date(Date.now()));
    const minDate = useConst(addYears(today, -1));

    return (
        <Stack verticalAlign="start" styles={filterStackStyles} tokens={gapStackTokensLarge}>
            <Stack horizontal horizontalAlign="space-between" wrap>
                <Stack horizontal tokens={gapStackTokensLarge} wrap>
                    <Stack.Item shrink={false} grow={4}>
                        <SearchBox
                            placeholder="Search by flight, flag name or any ID"
                            onSearch={(value) => handleFilterChange('searchKey', value)}
                            underlined
                            styles={searchStyles}
                        />
                    </Stack.Item>
                    {['inProgress', 'pendingApproval', 'pendingRequest'].includes(props.flightStatusFilter.key) && (
                        <Stack.Item styles={filterStyles}>
                            <ComboBox
                                selectedKey={flightFilters.status}
                                onChange={(_, option) =>
                                    handleFilterChange(
                                        'status',
                                        option?.selected
                                            ? [...flightFilters.status, option?.key as string]
                                            : flightFilters.status.filter((k) => k !== option?.key)
                                    )
                                }
                                placeholder="Status"
                                multiSelect
                                options={inProgressStatus
                                    .filter((status) => status !== 'Pending Approval')
                                    .map((stage) => ({ key: stage, text: stage }))}
                                allowFreeInput
                                autoComplete="on"
                            />
                        </Stack.Item>
                    )}
                    <Stack.Item styles={filterStyles}>
                        <FlightCreatorFilterComboBox
                            selectedKeys={flightFilters.creatorIds}
                            setSelectedKeys={(value) => handleFilterChange('creatorIds', value)}
                            teamMembers={teamMembers}
                            others={otherUsers}
                        />
                    </Stack.Item>
                    <Stack.Item styles={filterStyles}>
                        <FlightStageFilterComboBox
                            selectedKeys={flightFilters.stages}
                            setSelectedKeys={(value) => handleFilterChange('stages', value)}
                            flightStages={flightStages}
                        />
                    </Stack.Item>
                    <Stack.Item styles={filterStyles}>
                        <ComboBox
                            selectedKey={flightFilters.audiences}
                            onChange={(_, option) =>
                                handleFilterChange(
                                    'audiences',
                                    option?.selected
                                        ? [...flightFilters.audiences, option?.key as string]
                                        : flightFilters.audiences.filter((k) => k !== option?.key)
                                )
                            }
                            placeholder="Audience"
                            multiSelect
                            options={Object.keys(audienceNames).map((audience) => ({
                                key: audience,
                                text: capitalizeFirstLetter(audience)
                            }))}
                            allowFreeInput
                            autoComplete="on"
                        />
                    </Stack.Item>

                    <Stack.Item styles={filterStyles}>
                        <DatePicker
                            placeholder="Start date"
                            ariaLabel="Start date"
                            allowTextInput
                            value={flightFilters.startDate}
                            onSelectDate={(date) => handleFilterChange('startDate', date ?? undefined)}
                            minDate={minDate}
                            maxDate={flightFilters.endDate ?? today}
                        />
                    </Stack.Item>
                    <Stack.Item styles={filterStyles}>
                        <DatePicker
                            placeholder="End date"
                            ariaLabel="End date"
                            allowTextInput
                            value={flightFilters.endDate}
                            onSelectDate={(date) => handleFilterChange('endDate', date ?? undefined)}
                            minDate={flightFilters.startDate ?? minDate}
                            maxDate={today}
                        />
                    </Stack.Item>
                </Stack>
                <Stack.Item align="center">
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        title="Clear and dismiss filters"
                        ariaLabel="Clear and dismiss filters"
                        onClick={clearFilters}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );
};

export default FlightFiltersBar;
