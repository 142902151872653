import { FontIcon, Link, Separator, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import CommitTrackingService from '../../services/commitTracking.service';
import { ClientRepoMap } from '../../services/models/ClientRepoMap';
import { CommitProgressionDetails, CommitRolloutHistory, DeploymentDetail } from '../../services/models/CommitProgressionDetails';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import './styles/CommitStatusReport.css';

type ICommitProgressionProps = {
    commitIdentifier: string;
    clientInfo: ClientRepoMap;
};

type GroupedDeploymentDetail = {
    [key: string]: DeploymentDetail[];
};

/**
 * Displays the commit progression.
 *
 * @param props The props for the component.
 * @returns The component for the commit progression.
 */
const CommitProgression: React.FC<ICommitProgressionProps> = (props) => {
    const [noDataFlag, setNoDataFlag] = useState<boolean>(false);
    const [loaderFlag, setLoaderFlag] = useState<boolean>(true);

    const [groupedDeploymentDetails, setGroupedDeploymentDetails] = useState<GroupedDeploymentDetail>();

    // ========================= Functions ========================= //

    /**
     * Groups the rollout data based on cloud.
     *
     * @param response The rollout data list.
     */
    const getGroupedProgressionDetailBasedOnCloud = (response: CommitProgressionDetails) => {
        const deploymentDetails = response.deploymentDetails;

        const groupedList = deploymentDetails.reduce((acc: GroupedDeploymentDetail, deploymentDetail) => {
            if (!acc[deploymentDetail.cloud]) {
                acc[deploymentDetail.cloud] = [];
            }

            acc[deploymentDetail.cloud].push(deploymentDetail);
            return acc;
        }, {});

        setGroupedDeploymentDetails(groupedList);
    };

    // ========================= Hooks ========================= //
    useEffect(() => {
        setLoaderFlag(true);
        setNoDataFlag(false);

        new CommitTrackingService()
            .getCommitProgressionForIdentifier(
                props.commitIdentifier,
                props.clientInfo.clientType,
                props.clientInfo.os,
                props.clientInfo.environment,
                props.clientInfo.experience
            )
            .then((response) => {
                getGroupedProgressionDetailBasedOnCloud(response);
                setLoaderFlag(false);
                setNoDataFlag(false);

                appInsightsClient.logTrace(
                    { message: 'Fetched client progression for commit' },
                    { commitIdentifier: props.commitIdentifier, clientInfo: props.clientInfo }
                );
            })
            .catch((error) => {
                console.error('Error in fetching data', error);
                setLoaderFlag(false);
                setNoDataFlag(true);

                appInsightsClient.logException(
                    { exception: error },
                    {
                        message: 'Caught error in while fetching client progression for commit',
                        commitIdentifier: props.commitIdentifier,
                        clientInfo: props.clientInfo
                    }
                );
            });
    }, [props.commitIdentifier, props.clientInfo]);

    // ========================= Render ========================= //

    return (
        <>
            {noDataFlag && <ErrorNotification msg="Commit Progression Data is not available." resetChoice={() => setNoDataFlag(false)} />}

            {loaderFlag && <Spinner size={SpinnerSize.large} label="Fetching progression details..." />}

            {!noDataFlag && !loaderFlag && groupedDeploymentDetails && renderCommitProgression(groupedDeploymentDetails, props.clientInfo)}

            <Text variant="medium">Note(s): </Text>
            <Text variant="small">
                <ul>
                    <li>In case of Desktop client (S) and (E) in version depicts Stable and Experimental version respectively.</li>
                    <li>
                        <FontIcon iconName="SyncStatusSolid" style={{ color: '#0078d4' }} /> - Rollout in progress.
                    </li>
                    <li>
                        <FontIcon iconName="SkypeCircleCheck" style={{ color: 'green' }} /> - Rollout completed.
                    </li>
                    <li>
                        <FontIcon iconName="StatusErrorFull" style={{ color: 'red' }} /> - Rollout not started.
                    </li>
                </ul>
            </Text>
        </>
    );
};

// ========================= Helpers =========================

/**
 * Fetches latest commit rollout event from history.
 *
 * @param clientType The client type.
 * @param deploymentDetail The deployment detail.
 * @returns The list of snapshots.
 */
const fetchCommitRolloutHistory = (clientType: string, deploymentDetail: DeploymentDetail) => {
    let percentage = deploymentDetail.latestBuildRolloutAllocationPercentage;

    const snapshotList: CommitRolloutHistory[] = [];

    const releaseMetadata = deploymentDetail.commitRolloutHistory.find((x) => x.allocationPercentage === percentage);

    if (releaseMetadata !== undefined) {
        snapshotList.push(releaseMetadata);
        return snapshotList;
    }

    if (clientType === 'Desktop' && snapshotList.length === 0) {
        percentage = percentage / 2;
        deploymentDetail.commitRolloutHistory.filter((x) => x.allocationPercentage === percentage).forEach((x) => snapshotList.push(x));
    }

    return snapshotList;
};

// ========================= Render Functions =========================
/**
 * Renders the commit progression for given selected tab.
 *
 * @param groupedDeploymentDetails The cloud wise grouped deployment details.
 * @param clientInfo The client info.
 * @returns The commit progression.
 */
const renderCommitProgression = (groupedDeploymentDetails: GroupedDeploymentDetail, clientInfo: ClientRepoMap) => {
    const clouds = Object.keys(groupedDeploymentDetails ?? {});

    return (
        <>
            {clouds?.map((cloud) => {
                const deploymentDetailsForCloud = groupedDeploymentDetails[cloud];

                return (
                    <div key={cloud}>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <Stack.Item>{renderFirstColumn(cloud)}</Stack.Item>
                            {deploymentDetailsForCloud?.map((deploymentDetail) => {
                                return (
                                    <>
                                        <Separator vertical />
                                        <Stack.Item key={`${deploymentDetail.cloud}_${deploymentDetail.ring}`}>
                                            {renderRingColumnCard(deploymentDetail, clientInfo.clientType)}
                                        </Stack.Item>
                                    </>
                                );
                            })}
                        </Stack>
                        <Separator />
                    </div>
                );
            })}
        </>
    );
};

/**
 * Renders the first default column for each cloud.
 *
 * @param cloud The cloud.
 * @returns The first column.
 */
const renderFirstColumn = (cloud: string) => {
    return (
        <div className="grid-column">
            <div>{cloud.toUpperCase()}</div>
            <div>Status</div>
            <div>Train Info</div>
            <div>Version(s)</div>
        </div>
    );
};

/**
 * Renders the ring detail column for each cloud.
 *
 * @param deploymentDetail The deployment detail.
 * @param clientType The client type.
 * @returns The ring detail column.
 */
const renderRingColumnCard = (deploymentDetail: DeploymentDetail, clientType: string) => {
    const percentage = deploymentDetail.latestBuildRolloutAllocationPercentage;

    return (
        <div className="grid-column">
            <div>{deploymentDetail.ring.replace('ring', 'ring ').toUpperCase()}</div>
            <div>{renderReleasePercentage(percentage)}</div>
            <div>{renderTrainDetails(clientType, deploymentDetail)}</div>
            <div>{renderVersionDetails(clientType, deploymentDetail)}</div>
        </div>
    );
};

/**
 * Renders the percentage row for each ring detail card.
 *
 * @param percentage The percentage.
 * @returns The percentage row for Ring detail card.
 */
const renderReleasePercentage = (percentage: number) => {
    const { image, color } =
        percentage === 100
            ? { image: 'SkypeCircleCheck', color: 'green' }
            : percentage === 0
              ? { image: 'StatusErrorFull', color: 'red' }
              : { image: 'SyncStatusSolid', color: '#0078d4' };

    return (
        <div>
            <Text>
                {percentage}%
                <FontIcon iconName={image} style={{ color: color, display: 'inherit', marginLeft: 5 }} />
            </Text>
        </div>
    );
};

/**
 * Renders the version details for the given cloud ring.
 *
 * @param clientType The client type.
 * @param deploymentDetail The ring details.
 * @returns The version details row for Ring detail card.
 */
const renderVersionDetails = (clientType: string, deploymentDetail: DeploymentDetail) => {
    const percentage = deploymentDetail.latestBuildRolloutAllocationPercentage;
    if (percentage === undefined || percentage === null || percentage === 0) {
        return <div>-</div>;
    }

    const versionList = fetchCommitRolloutHistory(clientType, deploymentDetail);
    if (versionList.length === 0) {
        return <div>-</div>;
    }

    if (versionList.length === 1) {
        const versionDetails = versionList[0];
        return (
            <div>
                <Text variant="medium">
                    <Link href={versionDetails.buildUrl} target="_blank" rel="noreferrer" title="Build version">
                        {versionDetails.buildVersion}
                    </Link>
                </Text>
            </div>
        );
    }

    if (versionList.length === 2) {
        const stableVersionDetails = versionList.find((version) => version.buildFlavor === 'Stable');
        const experimentalVersionDetails = versionList.find((version) => version.buildFlavor === 'Experimental');

        return (
            <div>
                <Text variant="medium">
                    (S):{' '}
                    <Link href={stableVersionDetails?.buildUrl} target="_blank" rel="noreferrer" title="Stable build version">
                        {stableVersionDetails?.buildVersion}
                    </Link>
                </Text>
                <br />
                <Text variant="medium">
                    (E):{' '}
                    <Link href={experimentalVersionDetails?.buildUrl} target="_blank" rel="noreferrer" title="Experimental build version">
                        {experimentalVersionDetails?.buildVersion}
                    </Link>
                </Text>
            </div>
        );
    }
    return <div>{versionList.length}</div>;
};

/**
 * Renders the train details for the given cloud ring.
 *
 * @param clientType The client type.
 * @param deploymentDetail The deployment details.
 * @returns The train details row for Ring detail card.
 */
const renderTrainDetails = (clientType: string, deploymentDetail: DeploymentDetail) => {
    const percentage = deploymentDetail.latestBuildRolloutAllocationPercentage;
    if (percentage === undefined || percentage === null || percentage === 0) {
        return <div>-</div>;
    }

    const details = fetchCommitRolloutHistory(clientType, deploymentDetail);
    if (details.length === 0) {
        return <div>-</div>;
    }

    const trainDetail = details[0].trainMetadata;
    // const snapDetail = trainDetail.snapBranchMetadata;

    return (
        <div>
            <Text>
                <Link href={trainDetail.teamsThread} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Arnold Teams Post">
                    {trainDetail.title}
                </Link>
            </Text>
        </div>
    );
};

// ========================= Export =========================
export default CommitProgression;
