import {
    Dropdown,
    IconButton,
    IDropdownOption,
    Label,
    Modal,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    MessageBar,
    Link,
    MessageBarType,
    ProgressIndicator,
    Shimmer,
    ShimmerElementType
} from '@fluentui/react';
import { useBoolean, useConst, useId } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';

import { Card } from '../../../components/Card';
import ErrorNotification from '../../../components/ErrorNotification/ErrorNotification';
import FeatureFlagTrackingService from '../../../services/featureFlagTracking.service';
import FeatureFlightService from '../../../services/featureFlight.service';
import { FeatureItem } from '../../../services/models/FeatureFlag/FeatureItem';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { getUserInfo } from '../../../utils/userUtility';
import { FeatureFlightRings } from '../configs/defaults';
import { formStyles, gapStackTokensSmall, iconButtonStyles, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { cancelIcon } from '../styles/MyFlightsStyle';
import { ADOWorkItem, BlockFlightFormData, ProcessedFlightRollout } from '../types/Types';
import { getCurrentIterationPath } from '../utilities/FFv2Utils';

type BlockFlightFormProps = {
    isOpen: boolean;
    onDismiss: () => void;
    flight: ProcessedFlightRollout | undefined;
    getBlockers: (flightIds: number[]) => void;
};

/**
 * Displays the form to fill to block a flight.
 *
 * @param props The props for the component.
 * @returns The block flight form.
 */
const BlockFlightForm: React.FC<BlockFlightFormProps> = (props) => {
    const { isOpen, flight } = props;
    const user = useConst(() => getUserInfo());
    const shimmerElementLine = useConst([{ type: ShimmerElementType.line, height: 32 }]);

    const [loadError, setLoadError] = useState<Error | undefined>();
    const [boardingPassInfo, setBoardingPassInfo] = useState<FeatureItem>();
    const [formState, setFormState] = useState<BlockFlightFormData>(getDefaultFormState());
    const [ringSelection, setRingSelection] = useState<string>();
    const [blockResult, setBlockResult] = useState<ADOWorkItem | Error | 'loading' | undefined>();

    const [isSubmitEnabled, { setTrue: enableSubmit, setFalse: disableSubmit }] = useBoolean(false);

    const titleId = useId('title');

    useEffect(() => {
        if (!isOpen || !flight) return;
        reset();
        new FeatureFlagTrackingService()
            .getDetailsByFeatureId(flight.boardingPassId.toString())
            .then((response) => {
                setBoardingPassInfo(response[0]);
                const defaultFormState = getDefaultFormState();
                setFormState({ ...defaultFormState, areaPath: response[0].areaPath });

                appInsightsClient.logTrace(
                    { message: `Successfully fetched boarding pass info.` },
                    { boardingPassId: flight?.boardingPassId }
                );
            })
            .catch((fail) => {
                setLoadError(fail);

                console.error(`Caught error getting ADO feature: ${flight?.boardingPassId}, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getDetailsByFeatureId in BlockFlightForm`,
                        boardingPassId: flight?.boardingPassId
                    }
                );
            });
    }, [flight, isOpen]);

    return (
        <>
            {blockResult &&
                blockResult !== 'loading' &&
                ('message' in blockResult ? (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        truncated={true}
                        onDismiss={() => setBlockResult(undefined)}
                    >
                        <Text>Create blocker failed: {blockResult.message}</Text>
                    </MessageBar>
                ) : (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        truncated={true}
                        onDismiss={() => setBlockResult(undefined)}
                    >
                        <Text>
                            <Link href={blockResult.url} target="_blank">
                                {blockResult.id} - {blockResult.title}
                            </Link>
                        </Text>
                    </MessageBar>
                ))}
            {blockResult === 'loading' && <ProgressIndicator label={`Blocking flight`} styles={progressIndicatorCustomStyles} />}
            <Modal
                className={formStyles.container}
                titleAriaId={titleId}
                isOpen={isOpen && !!flight}
                onDismiss={props.onDismiss}
                onDismissed={reset}
                isBlocking={false}
            >
                <div className={formStyles.header}>
                    <h3 id={titleId}>Create flight blocking bug: {flight?.rolloutName}</h3>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
                </div>
                <div className={formStyles.body}>
                    {loadError && (
                        <Card>
                            <ErrorNotification
                                msg={`Unable to fetch feature work item ${flight?.boardingPassId}. Error: ${loadError.message}`}
                            />
                        </Card>
                    )}
                    {!loadError && (
                        <form>
                            <Stack tokens={gapStackTokensSmall}>
                                <Label htmlFor="bugTitle" required>
                                    Bug title
                                </Label>
                                <TextField
                                    prefix="[Flight Blocker]"
                                    id="bugTitle"
                                    defaultValue={formState.title}
                                    onChange={onChange}
                                    validateOnLoad={false}
                                    deferredValidationTime={500}
                                    onGetErrorMessage={getTitleErrorMessage}
                                />
                                <Label htmlFor="bugDescription" required>
                                    Bug description
                                </Label>
                                <TextField
                                    id="bugDescription"
                                    defaultValue={formState.description}
                                    multiline
                                    rows={3}
                                    onChange={onChange}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    onGetErrorMessage={getDescriptionErrorMessage}
                                />
                                <Label htmlFor="assignedTo" required>
                                    Assigned to
                                </Label>
                                <TextField
                                    id="assignedTo"
                                    defaultValue={`${formState.assignedTo.displayName} (${formState.assignedTo.principalName})`}
                                    onChange={onChange}
                                    disabled
                                />
                                <Label htmlFor="areaPath" required>
                                    Area path
                                </Label>
                                <Shimmer isDataLoaded={boardingPassInfo !== undefined} shimmerElements={shimmerElementLine}>
                                    <TextField
                                        id="areaPath"
                                        value={boardingPassInfo?.areaPath}
                                        placeholder="MSTeams\..."
                                        onChange={onChange}
                                        disabled
                                    />
                                </Shimmer>
                                <Label htmlFor="iterationPath" required>
                                    Iteration path
                                </Label>
                                <TextField id="iterationPath" defaultValue={getCurrentIterationPath()} onChange={onChange} disabled />
                                <Label htmlFor="ring">Ring</Label>
                                <Dropdown
                                    id="ring"
                                    placeholder="Select a ring"
                                    onChange={onDropdownChange}
                                    notifyOnReselect
                                    selectedKey={ringSelection}
                                    options={FeatureFlightRings.filter((ring) => ring.ring !== 'ring0').map((ring) => ({
                                        key: ring.key,
                                        text: ring.text
                                    }))}
                                />
                            </Stack>
                            <br />
                            {boardingPassInfo ? (
                                <PrimaryButton text="Submit" onClick={onSubmit} allowDisabledFocus disabled={!isSubmitEnabled} />
                            ) : (
                                <Spinner size={SpinnerSize.medium} label="Loading feature work item..." labelPosition="right" />
                            )}
                        </form>
                    )}
                </div>
            </Modal>
        </>
    );

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) {
        if (!option || (ringSelection && ringSelection === option?.key.toString())) {
            setRingSelection('');
            delete formState.ring;
            setFormState(formState);
        } else {
            setRingSelection(option.key.toString());
            setFormState({ ...formState, ring: FeatureFlightRings.find((ring) => ring.key === option.key)?.ring });
        }
    }

    function onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
        const target = event.target as HTMLInputElement;

        switch (target.id) {
            case 'bugTitle':
                setFormState({ ...formState, title: newValue ? newValue : '' });
                break;
            case 'bugDescription':
                setFormState({ ...formState, description: newValue ? newValue : '' });
                break;
            case 'assignedTo':
                setFormState({ ...formState, assignedTo: { ...formState.assignedTo, principalName: newValue ? newValue : '' } });
                break;
            case 'areaPath':
                setFormState({ ...formState, areaPath: newValue ? newValue : '' });
                break;
            case 'iterationPath':
                setFormState({ ...formState, iterationPath: newValue ? newValue : '' });
                break;
            default:
                break;
        }

        checkFormState(target.id, newValue ? newValue : '');
    }

    function getTitleErrorMessage(value: string): string {
        return isTitleError(value) ? 'Title must be at least 10 characters and no more than 240 characters.' : '';
    }

    function isTitleError(value: string): boolean {
        return value.length < 10 || value.length > 240 - '[Flight Blocker]'.length;
    }

    function isDescriptionError(value: string) {
        return value.length < 10;
    }

    function getDescriptionErrorMessage(value: string) {
        return isDescriptionError(value) ? `Description must be at least 10 characters.` : '';
    }

    function checkFormState(updatedField: string, updatedValue: string) {
        const title = updatedField === 'title' ? updatedValue : formState.title;
        const description = updatedField === 'description' ? updatedValue : formState.description;
        if (
            updatedValue &&
            !isTitleError(title) &&
            !isDescriptionError(description) &&
            (formState.assignedTo || updatedField === 'assignedTo') &&
            (formState.areaPath || updatedField === 'areaPath') &&
            (formState.iterationPath || updatedField === 'iterationPath') &&
            (formState.requestedBy || updatedField === 'requestedBy')
        ) {
            enableSubmit();
        } else if (isSubmitEnabled) {
            disableSubmit();
        }
    }

    function onSubmit() {
        if (!flight) return;
        appInsightsClient.logEvent({ name: 'BlockFlightFormSubmit', properties: { response: 'good' } });
        setBlockResult('loading');
        props.onDismiss();

        new FeatureFlightService()
            .blockRollout(flight.id, { ...formState, title: `[Flight Blocker] ${formState.title}` })
            .then((response) => {
                setBlockResult(response);
                props.getBlockers([flight.rolloutId]);
                appInsightsClient.logEvent({ name: 'BlockFlightFormSubmit', properties: { response: response } });
            })
            .catch((fail) => {
                setBlockResult(fail);
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing block rollout in BlockFlightForm' }
                );
            })
            .finally(() => {
                reset();
            });
    }

    function reset(): void {
        setRingSelection(undefined);
        setFormState(getDefaultFormState());
        disableSubmit();
        setBoardingPassInfo(undefined);
        setLoadError(undefined);
    }

    function getDefaultFormState(): BlockFlightFormData {
        return {
            title: `${flight?.rolloutName}`,
            description: ``,
            assignedTo: user,
            areaPath: boardingPassInfo?.areaPath || '',
            iterationPath: getCurrentIterationPath(),
            requestedBy: user
        };
    }
};

export default BlockFlightForm;
