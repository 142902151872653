import { Icon, Pivot, PivotItem, Link, Stack, Text, MessageBar, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../components/Card';
import { displayName, teamsClientRelease, teamsEcsContributors } from '../../services/configs/msGraph.defaults';
import FeatureFlightService from '../../services/featureFlight.service';
import MsGraphService from '../../services/msGraph.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserPrincipalName, getUserDisplayName } from '../../utils/userUtility';

import AdminFlights from './AdminFlights';
import { featureBlockerWorkflowWikiLink, safeChangeManagementWikiLink } from './configs/defaults';
import FeedbackForm from './FeedbackForm';
import StartFlightForm from './StartFlightForm';
import { gapStackTokensMedium, mediumIconClass } from './styles/FFv2Style';
import UserFlights from './UserFlights';

/**
 * This component is to provide implementation for FFv2.
 *
 * @returns The actual view.
 */
const FFv2: React.FC = () => {
    const userPrincipalName = getUserPrincipalName();

    const [activeTab, setActiveTab] = useState('flights');
    const [isTeamsEcsContributor, { setTrue: setIsTeamsEcsContributor, setFalse: setIsNotTeamsEcsContributor }] = useBoolean(false);
    const [isTeamsClientRelease, { setTrue: setIsTeamsClientRelease, setFalse: setIsNotTeamsClientRelease }] = useBoolean(false);
    const [freezeState, setFreezeState] = useState('');
    const msGraphService = new MsGraphService();

    const handleTabChange = (item?: PivotItem) => {
        if (item) {
            setActiveTab(item.props.itemKey || '');
        }
    };

    useEffect(() => {
        msGraphService
            .getTransitiveMemberOf(`${displayName} eq '${teamsEcsContributors}'`, 'id, displayName')
            .then((groups) => {
                groups && groups.length > 0 ? setIsTeamsEcsContributor() : setIsNotTeamsEcsContributor();
            })
            .catch((fail) => {
                console.error(
                    `Caught error getting membership details for user: ${getUserDisplayName()} - ${userPrincipalName} and group: ${teamsEcsContributors}, error: ${fail}`
                );
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTransitiveMemberOf in Feature Flight page`,
                        user: userPrincipalName,
                        group: teamsEcsContributors
                    }
                );
            });

        msGraphService
            .getTransitiveMemberOf(`${displayName} eq '${teamsClientRelease}'`, 'id, displayName')
            .then((groups) => {
                groups && groups.length > 0 ? setIsTeamsClientRelease() : setIsNotTeamsClientRelease();
            })
            .catch((fail) => {
                console.error(
                    `Caught error getting membership details for user: ${getUserDisplayName()} - ${userPrincipalName} and group: ${teamsClientRelease}, error: ${fail}`
                );
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTransitiveMemberOf in Feature Flight page`,
                        user: userPrincipalName,
                        group: teamsClientRelease
                    }
                );
            });

        new FeatureFlightService()
            .getFreeze()
            .then((response) => {
                setFreezeState(response.state);
            })
            .catch((fail) => {
                console.error(`Caught error getting freeze state, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getFreeze in Feature Flight page`,
                        user: userPrincipalName,
                        group: teamsClientRelease
                    }
                );
            });
    }, []);

    return (
        <Stack tokens={gapStackTokensMedium}>
            <Card>
                <CardHeader>
                    Feature Flight Center <Text variant="small">(MVP)</Text>
                </CardHeader>
            </Card>

            <Card>
                <CardHeader>Note(s) :</CardHeader>
                <Text variant="medium">Feature Flight V2 empowers you to:</Text>
                <ul>
                    <li>
                        <Text variant="medium">
                            Start automated rollout (flight) of feature flags in a compliant manner, following all
                            <Link href={safeChangeManagementWikiLink} target="_blank" rel="noreferrer">
                                {' '}
                                safe change management policies
                            </Link>{' '}
                            and integrated with experimentation scorecards.
                        </Text>
                    </li>
                    <li>
                        <Text variant="medium">Track and manage progress of all flights owned by you and your direct reports.</Text>
                    </li>
                    <li>
                        <Text variant="medium">
                            Have full control over progressing flights by raising
                            <Link href={featureBlockerWorkflowWikiLink} target="_blank" rel="noreferrer">
                                {' '}
                                Flight Blockers
                            </Link>{' '}
                            , ADO bugs that halt automated flights.
                        </Text>
                    </li>
                </ul>
                <Text variant="medium">
                    Check{' '}
                    <Link
                        href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/44863/Feature-Flighting-v2"
                        target="_blank"
                    >
                        Feature Flight V2 wiki
                    </Link>{' '}
                    for user manual, Q&A and more. Any suggestion or bug report? <FeedbackForm /> or post in{' '}
                    <Link
                        href="https://teams.microsoft.com/l/channel/19%3Askypespaces_60599f608e5542939a1fe4932dea4326%40thread.skype/Build%2C%20Quality%2C%20Testing%20and%20Release%20%5BBQTR%5D?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47"
                        target="_blank"
                    >
                        our Teams channel
                    </Link>
                    !
                </Text>
            </Card>
            {freezeState && freezeState !== 'Operational' && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    FFv2 is in the freeze state of {freezeState}. You may still approve or reject flights, but they will not proceed until
                    freeze ends.
                </MessageBar>
            )}
            <Card>
                <Pivot onLinkClick={handleTabChange} selectedKey={activeTab} overflowBehavior="menu">
                    <PivotItem headerText="Flights" itemKey="flights" />
                    <PivotItem headerText="Start Flight" itemKey="start-flight" />
                    {isTeamsClientRelease && <PivotItem headerText="Admin Flights" itemKey="admin-flights" />}
                </Pivot>
            </Card>
            {activeTab && activeTab === 'flights' && <UserFlights />}
            {activeTab && activeTab === 'start-flight' && (
                <Card>
                    {isTeamsEcsContributor ? (
                        <StartFlightForm />
                    ) : (
                        <Stack verticalAlign="center" styles={{ root: { minHeight: '200px' } }} tokens={gapStackTokensMedium}>
                            <Stack.Item align="center">
                                <Icon iconName="UserWarning" className={mediumIconClass} />
                            </Stack.Item>
                            <Stack.Item align="center">
                                <Text variant="large">You do not have access to start flights</Text>
                            </Stack.Item>
                            <Stack.Item align="center" styles={{ root: { maxWidth: '750px' } }}>
                                <Text>
                                    You currently do not have appropriate permissions to flight a feature in Teams. These permissions
                                    require you be a member of the Teams organization in Teams Ring 0 or a partner sponsored to become a
                                    member of Teams Ring 0. Please work with your manager or Teams sponsor to gain the correct access.
                                </Text>
                            </Stack.Item>
                        </Stack>
                    )}
                </Card>
            )}
            {activeTab && activeTab === 'admin-flights' && <AdminFlights />}
        </Stack>
    );
};

export default FFv2;
