import { SKYNET_BASE_URL } from '../configs/env.config';
import ApiService from './api.service';

class RingInsightsService {
    constructor() {
        this.apiService = new ApiService();
        this.shiproomBaseUri = `${SKYNET_BASE_URL}/api/dashboard/shiproomReport`;
    }

    getRingInsights = (client, os, environment, experience, cloud, ring = 'Ring0') => {
        return this.apiService.getApiData(
            `${this.shiproomBaseUri}/getLatestRingInsightsForCloudRing?clientType=${client}&os=${os}&environment=${environment}&experience=${experience}&cloud=${cloud}&ring=${ring}`
        );
    };
}

export default RingInsightsService;
