import {
    FFV2_AZURE_FUNCTION_URL,
    FFV2_SCOPE,
    REDLINE_BASE_URL,
    REDLINE_SCOPE,
    RINGMASTER_BASE_URL,
    RINGMASTER_SCOPE,
    SKYNET_BASE_URL,
    SKYNET_SCOPE
} from '../../configs/env.config';

// eslint-disable-next-line no-shadow
export enum Service {
    SKYNET = 'Skynet',
    RINGMASTER = 'Ringmaster',
    FFV2 = 'FFV2',
    REDLINE = 'Redline'
}

export interface ApiServiceConfig {
    baseURL: string;
    scopes: string[];
    timeout?: number;
    headers?: Record<string, string>;
}

export const apiServiceConfigs: Record<Service, ApiServiceConfig> = {
    [Service.SKYNET]: {
        baseURL: SKYNET_BASE_URL,
        scopes: SKYNET_SCOPE
    },
    [Service.RINGMASTER]: {
        baseURL: RINGMASTER_BASE_URL,
        scopes: RINGMASTER_SCOPE
    },
    [Service.FFV2]: {
        baseURL: FFV2_AZURE_FUNCTION_URL,
        scopes: FFV2_SCOPE
    },
    [Service.REDLINE]: {
        baseURL: REDLINE_BASE_URL,
        scopes: REDLINE_SCOPE
    }
};
