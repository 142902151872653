import React from 'react';

type CardWrapperProps = {
    children: JSX.Element | JSX.Element[];
};

/**
 * This component is to provide implementation of Card.
 *
 * @param props The props.
 * @returns JSX (The actual view).
 */
const CardWrapper: React.FC<CardWrapperProps> = (props) => {
    let cardHeaderData;
    let cardBody;

    if (Array.isArray(props.children)) {
        cardHeaderData = props.children?.filter((child) => child.props?.className === 'cardHeader');
        cardBody = props.children?.filter((child) => child.props?.className !== 'cardHeader');
    }

    return (
        <div className="card">
            <div className="cardHeader">
                <span className="card-title">{cardHeaderData}</span>
            </div>
            <div className="cardBody" role="group">
                {cardBody ?? props.children}
            </div>
        </div>
    );
};

export default CardWrapper;
