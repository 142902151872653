import { IColumn } from '@fluentui/react';

import { FetchIDDetailsResponse } from '../../../services/models/Ringmaster/RingmasterResponse';
import { FindIdFormData, RMDropdownOptionType, RingChangeRequestFormData, UserInfo } from '../types/Types';

export const defaultRingmasterLookbackDays = 30;

export const defaultRingChangeRequestForm: RingChangeRequestFormData = {
    workItemId: '',
    identityType: '',
    ring: '',
    cloud: '',
    operation: '',
    operationId: '',
    createdBy: {} as UserInfo
};

export const defaultFindIdForm: FindIdFormData = {
    operationId: '',
    requestedBy: {} as UserInfo
};

export const defaultFindIdFormResponse: FetchIDDetailsResponse = {
    operationId: '',
    ring: '',
    cloud: '',
    identityType: '',
    notes: '',
    isStrategicTenant: false,
    error: ''
};

export const idTypeDropdownOptions: RMDropdownOptionType[] = [
    { key: 'IndividualOId', text: 'Enterprise User (AAD OID)' },
    { key: 'TenantId', text: 'Enterprise Tenant (AAD Tenant ID)' }
];

export const ringDropdownOptions: RMDropdownOptionType[] = [
    { key: 'Ring0_s', text: 'Ring0_s' },
    { key: 'Ring0', text: 'Ring0' },
    { key: 'Ring1', text: 'Ring1' },
    { key: 'Ring1_5', text: 'Ring1_5' },
    { key: 'Ring1_6', text: 'Ring1_6' },
    { key: 'Ring2', text: 'Ring2' },
    { key: 'Ring3', text: 'Ring3' },
    { key: 'Ring3_9', text: 'Ring3_9' },
    { key: 'General', text: 'General' }
];

export const cloudDropdownOptions: RMDropdownOptionType[] = [
    { key: 'Dev', text: 'Dev' },
    { key: 'Prod', text: 'Prod' },
    { key: 'GCC', text: 'GCC' },
    { key: 'GCCH', text: 'GCCH' },
    { key: 'DoD', text: 'DoD' },
    { key: 'AG08', text: 'AG08' },
    { key: 'AG09', text: 'AG09' },
    { key: 'BlackForest', text: 'BlackForest' },
    { key: 'Gallatin', text: 'Gallatin' }
];

export const operationDropdownOptions: RMDropdownOptionType[] = [
    { key: 'Add', text: 'Add' },
    { key: 'Remove', text: 'Remove' },
    { key: 'Update', text: 'Update' }
];

export const ringmasterRequestDetailsColumns: IColumn[] = [
    {
        key: 'userFriendlyRequestId',
        name: 'Request Id',
        fieldName: 'userFriendlyRequestId',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
    },
    {
        key: 'operationId',
        name: 'Operation Id',
        fieldName: 'operationId',
        minWidth: 100,
        maxWidth: 250,
        isResizable: true
    },
    {
        key: 'workItemId',
        name: 'ADO Item',
        fieldName: 'workItemId',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'operation',
        name: 'Request Type',
        fieldName: 'operation',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'createdTime',
        name: 'Created Time',
        fieldName: 'createdTime',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        showSortIconWhenUnsorted: true
    },
    {
        key: 'identityType',
        name: 'Identity Type',
        fieldName: 'identityType',
        minWidth: 70,
        maxWidth: 140,
        isResizable: true
    },
    {
        key: 'ring',
        name: 'Ring',
        fieldName: 'ring',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'requestStatus',
        name: 'Request Status',
        fieldName: 'requestStatus',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'statusDate',
        name: 'Status Date',
        fieldName: 'statusDate',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
    },
    {
        key: 'createdBy',
        name: 'Created By',
        fieldName: 'createdBy',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
    }
];
