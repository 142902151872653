import React from 'react';

interface ICardFooterProps {
    children: React.ReactNode;
}

/**
 *  Card Footer component.
 *
 * @param props The props.
 * @returns The Card Footer component.
 */
export const CardFooter: React.FC<ICardFooterProps> = (props) => {
    return <>{props.children}</>;
};
