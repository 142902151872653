import { IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ContextualMenu, IContextualMenuItem, IconButton, Persona, PersonaInitialsColor, PersonaSize, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useRef } from 'react';

import { useAppSelector } from '../redux/hooks/hooks';

import { headerIconStyles, headerStyles, headerTitleStyle, personaStyles } from './styles/styles';

type HeaderProps = {
    toggleNav: () => void;
};

/**
 * The header of the application.
 *
 * @param props The props for the component.
 * @returns The header of the application.
 */
const Header: React.FC<HeaderProps> = (props) => {
    const personaRef = useRef(null);
    const [isMenuOpen, { setTrue: openMenu, setFalse: dismissMenu }] = useBoolean(false);

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const currentUserName = useAppSelector((state) => state.userData.name);
    const currentUserPhoto = useAppSelector((state) => state.userData.photo);

    const menuItems: IContextualMenuItem[] = [{ key: 'signOut', text: 'Sign out', onClick: () => handleLogout(instance) }];

    return (
        <>
            <Stack horizontal verticalAlign="center" styles={headerStyles} tokens={{ padding: 10, childrenGap: 10 }}>
                <IconButton
                    iconProps={{ iconName: 'GlobalNavButton' }}
                    styles={headerIconStyles}
                    onClick={() => {
                        props.toggleNav();
                    }}
                />
                <Stack.Item grow>
                    <a href="/" style={headerTitleStyle}>
                        Teams Engineering System Release Reporting Portal
                    </a>
                </Stack.Item>
                {isAuthenticated && (
                    <Persona
                        ref={personaRef}
                        text={currentUserName}
                        imageUrl={currentUserPhoto}
                        size={PersonaSize.size32}
                        initialsColor={PersonaInitialsColor.cyan}
                        onClick={isMenuOpen ? dismissMenu : openMenu}
                        styles={personaStyles}
                    />
                )}
            </Stack>
            {isAuthenticated && isMenuOpen && (
                <ContextualMenu items={menuItems} target={personaRef} onDismiss={dismissMenu} subMenuHoverDelay={250} />
            )}
        </>
    );
};

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect();
}

export { Header };
