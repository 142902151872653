import { ICardStyles } from '../Card';

export const defaultCardStyles: ICardStyles = {
    root: {
        display: 'block',
        position: 'relative',
        padding: '0.7rem',
        borderRadius: '0.4rem',
        boxShadow: '0 0.1rem 0.2rem rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white'
    },
    header: {
        fontWeight: 'bold'
    },
    body: {},
    footer: {
        position: 'absolute',
        bottom: '0.2rem',
        paddingBottom: '0.4rem'
    }
};
