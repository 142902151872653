import { Separator } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { TransformedChartData } from '../../../services/models/RecentChangesReport/TransformedChartData';

import { ClientChangesChart } from './ClientChangesChart';

type RingRecentChangesProps = {
    cloudRing: string;
    startDate: Date;
    endDate: Date;
    selectedChangeTypes: string[];
    chartDataDict: Record<string, TransformedChartData[]>;
    filteredFeatureFlags: string[];
};

/**
 * Displays the ClientChangesChart for each client in the given ring.
 *
 * @param props The props for the component.
 * @returns The JSX for the component.
 */
export const RingRecentChanges: React.FC<RingRecentChangesProps> = (props) => {
    const { cloudRing } = props;
    const splitString = cloudRing.split('_');
    const cloud = splitString[0];
    const ring = splitString[1] + (splitString.length === 3 ? '_' + splitString[2] : '');

    return (
        <Card>
            <CardHeader>
                {cloud.toUpperCase()} {ring.toUpperCase()}
            </CardHeader>
            <div>
                {props.chartDataDict &&
                    Object.keys(props.chartDataDict)
                        .sort()
                        .map((client, index) => {
                            if (client) {
                                if (props.chartDataDict[client].length > 0) {
                                    return (
                                        <div key={client}>
                                            <ClientChangesChart
                                                client={client}
                                                startDate={props.startDate}
                                                endDate={props.endDate}
                                                cloudRing={props.cloudRing}
                                                selectedChangeTypes={props.selectedChangeTypes}
                                                chartData={props.chartDataDict[client]}
                                                showLegend={index === 0}
                                                filteredFeatureFlags={props.filteredFeatureFlags}
                                            />
                                            {index !== Object.keys(props.chartDataDict).length - 1 && <Separator />}
                                        </div>
                                    );
                                } else {
                                    return <>No data available for the given parameters :(</>;
                                }
                            }
                        })}
                {(props.chartDataDict === undefined || Object.keys(props.chartDataDict).length === 0) && (
                    <>No data available here for the selected parameters ☹️</>
                )}
            </div>
        </Card>
    );
};
