import { SelectionMode, DetailsRow, IDetailsRowProps, DetailsList, IColumn } from '@fluentui/react';
import React from 'react';

import './ExperiencesDetailsList.css';
import { ExperiencesDataItemType } from './models/ExperiencesDataItemType';

type DataAndColumns = {
    dataTable: ExperiencesDataItemType[];
    columns: IColumn[];
};

/**
 * Displays data in DetailsList view. It has fixed first column.
 * You can scroll left and right to see long list of the data.
 *
 * @param props The props.
 * @returns The component for the data table view.
 */
const ExperiencesDetailsList: React.FC<DataAndColumns> = (props) => {
    const { dataTable, columns } = props;
    const onRenderRow = (rowProps?: IDetailsRowProps) => {
        if (!rowProps) {
            return null;
        }
        const customStyles = {
            root: {
                backgroundColor: rowProps.itemIndex % 2 === 0 ? '#f3f3f3' : 'white'
            }
        };

        return <DetailsRow {...rowProps} styles={customStyles} />;
    };

    return (
        <>
            <DetailsList
                className="experience-datalist"
                items={dataTable}
                columns={columns}
                setKey="set"
                selectionMode={SelectionMode.none}
                onRenderRow={onRenderRow}
            />
        </>
    );
};

export default ExperiencesDetailsList;
