/* eslint-disable no-shadow */
export type RolloutParsedLog = {
    cloud: string;
    ring: string;
    id: string;
    url: string;
    allocationPercentage: number;
    timestamp: Date;
    kind: RolloutType;
    client: string;
};

export enum RolloutType {
    Build = 'Build',
    FeatureFlag = 'Feature Flag'
}
