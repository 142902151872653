import {
    Panel,
    PanelType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    DefaultButton,
    TooltipHost,
    Text,
    Link
} from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { existingRolloutAlertStyle, formButtonStyles, stackItemStyles } from '../styles/StartFlightFormStyle';
import { ExistingRolloutsData } from '../types/Types';

type ExistingRolloutAlertPanel = {
    isOpen: boolean;
    onProceed: () => void;
    onStop: () => void;
    existingRolloutsData: ExistingRolloutsData | undefined;
};

/**
 * A component that alerts the user about existing rollouts and prompts for action.
 * It enables the user to choose whether to continue with the current rollout process.
 *
 * @param props - The properties passed to the component.
 * @returns {React.Component} - A rendered panel for handling rollout decisions.
 */
const ExistingRolloutAlertPanel: React.FC<ExistingRolloutAlertPanel> = (props) => {
    const renderWarningTitle = () => (
        <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={true} styles={existingRolloutAlertStyle}>
            <strong>Warning!</strong> One of the flags you are attempting to flight is included in a previous feature flight with identical
            filters. Do you want to proceed with this flight?
        </MessageBar>
    );

    type RolloutItem = {
        id: string;
        rolloutName: string;
        rolloutId: string;
        releaseId: string;
        featureFlags: string;
        filters: string;
        ADOLink: string;
        ECSLink: string;
        isScorecardEligible: string;
        createdBy: string;
        [key: string]: string;
    };

    const propertiesToKeep = [
        'id',
        'rolloutName',
        'rolloutId',
        'releaseId',
        'featureFlags',
        'filters',
        'ADOLink',
        'ECSLink',
        'isScorecardEligible',
        'createdBy'
    ];

    const originalData = props.existingRolloutsData?.existingRollouts as RolloutItem[];

    const dataToDisplay =
        originalData?.map((item) => {
            const newItem: Partial<RolloutItem> = {};
            for (const prop of propertiesToKeep) {
                if (prop in item) {
                    newItem[prop] = item[prop];
                }
            }
            return newItem;
        }) ?? [];

    return (
        <Panel isOpen={props.isOpen} isLightDismiss={false} type={PanelType.medium} hasCloseButton={false} headerText={`Flight Alert!`}>
            {props.existingRolloutsData && renderWarningTitle()}

            <Stack horizontal tokens={gapStackTokensMedium}>
                <TooltipHost content="Proceeding with this flight will override the existing rollout">
                    <Stack.Item styles={stackItemStyles}>
                        <DefaultButton text="Proceed" onClick={props.onProceed} styles={formButtonStyles} />
                    </Stack.Item>
                </TooltipHost>

                <TooltipHost content="Cancel to return to editing this flight.">
                    <Stack.Item styles={stackItemStyles}>
                        <PrimaryButton text="Cancel" onClick={props.onStop} styles={formButtonStyles} />
                    </Stack.Item>
                </TooltipHost>
            </Stack>

            {dataToDisplay.map((rollout, index) => (
                <Card key={rollout.id || index} styles={{ root: { width: '100%', marginBottom: '10px' } }}>
                    <CardHeader>Existing flight: {rollout.rolloutName}</CardHeader>
                    <table cellSpacing={15}>
                        <tbody>
                            <tr>
                                <td>
                                    <Text>Flight ID: </Text>
                                </td>
                                <td>
                                    <Text>{rollout.id}</Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>Flight Name: </Text>
                                </td>
                                <td>
                                    <Text>{rollout.rolloutName}</Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>Feature Flags: </Text>
                                </td>
                                <td>
                                    <tbody>
                                        {Object.entries(rollout.featureFlags || {}).map(([key, value]) => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>Filters: </Text>
                                </td>
                                <td>
                                    <table>
                                        <tbody>
                                            {Object.entries(rollout.filters || {}).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td>{key}:</td>
                                                    <td>{value}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>ADO Link: </Text>
                                </td>
                                <td>
                                    <Link href={rollout.ADOLink ?? ''} target="_blank">
                                        {rollout.releaseId}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>ECS Link: </Text>
                                </td>
                                <td>
                                    <Link href={rollout.ECSLink ?? ''} target="_blank">
                                        {rollout.rolloutId}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text style={{ paddingRight: '50px' }}>Scorecard Eligible: </Text>
                                </td>
                                <td>
                                    <Text>{rollout.isScorecardEligible ? 'Yes' : 'No'}</Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text>Created By: </Text>
                                </td>
                                <td>
                                    <table>
                                        <tbody>
                                            {Object.entries(rollout.createdBy || {}).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td>{key}:</td>
                                                    <td>{value}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            ))}
        </Panel>
    );
};

export { ExistingRolloutAlertPanel };
