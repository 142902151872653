import React from 'react';

interface ICardHeaderProps {
    children: React.ReactNode;
}

/**
 * Card Header component.
 *
 * @param props The props.
 * @returns The Card Header component.
 */
export const CardHeader: React.FC<ICardHeaderProps> = (props) => {
    return <>{props.children}</>;
};
