import { SKYNET_BASE_URL } from '../configs/env.config';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import ApiResponseError from './models/error/ApiResponseError';
import { RedRiderTestPassPercentageResponse } from './models/RedRiderTestPassPercentageResponse';

/**
 * Service for fetching RedRider test results from Skynet.
 */
class SkynetRedRiderService {
    private apiService: ApiService;
    private skynetRedRiderBaseUri: string;

    /**
     * Initializes an instance of SkynetRedRiderService.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.skynetRedRiderBaseUri = `${SKYNET_BASE_URL}/api/redrider`;
    }

    /**
     * Gets the RedRider test pass results for the given parameters.
     *
     * @param clientType The client type.
     * @param os The OS.
     * @param environment The environment.
     * @param experience The experience.
     * @param cloud The cloud.
     * @param ring The ring.
     * @param buildId The build ID.
     * @returns The RedRider test pass results.
     */
    public async getTestPassPercentage(
        clientType: string,
        os: string,
        environment: string,
        experience: string,
        cloud: string,
        ring: string,
        buildId: string
    ) {
        const params = new URLSearchParams({
            clientType,
            os,
            environment,
            experience,
            cloud,
            ring,
            buildId
        });
        const url = `${this.skynetRedRiderBaseUri}/testPassResults` + `?${params.toString()}`;

        try {
            const response = await this.apiService.get<RedRiderTestPassPercentageResponse>(url);
            return response.passRate;
        } catch (error) {
            let errorMessage = `Failed to get the RedRider test pass results.`;
            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }
            throw new Error(errorMessage);
        }
    }
}

export default SkynetRedRiderService;
