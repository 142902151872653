import {
    ApiReleaseTrainByTitleTrainTitleGetRequest,
    InitOverrideFunction,
    ReleaseTrainApi,
    ResponseError,
    TrainMetadataV2
} from 'skynet-client';

import { skynetConfiguration } from './configs/skynet.defaults';
import { ProcessedTrainMetadata } from './models/TrainMetadata';

/**
 * Service for release train API.
 */
class ReleaseTrainService extends ReleaseTrainApi {
    /**
     * Initializes an instance of the ReleaseTrainService class.
     */
    constructor() {
        super(skynetConfiguration);
    }

    /**
     * Get the processed train metadata by title.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The processed train metadata.
     */
    public async getTrainByTitle(
        requestParameters: ApiReleaseTrainByTitleTrainTitleGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<ProcessedTrainMetadata> {
        try {
            const trainMetadata = await super.apiReleaseTrainByTitleTrainTitleGet(requestParameters, initOverrides);
            const processedMetadata = this.transformTrainMetadata(trainMetadata);
            if (processedMetadata === undefined) {
                throw new Error('Failed to get processed train metadata');
            }
            return processedMetadata;
        } catch (error) {
            let errorMessage = `Failed to get train metadata for ${requestParameters.trainTitle}`;
            if (error instanceof ResponseError) {
                if (error.response.status === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.message) {
                    errorMessage = error.message;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Processes the train metadata.
     *
     * @param trainMetadata The train metadata.
     * @returns The processed train metadata.
     */
    private transformTrainMetadata(trainMetadata: TrainMetadataV2) {
        const processedTrainMetadata: ProcessedTrainMetadata = { rolloutDict: {}, ...trainMetadata };
        if (trainMetadata.buildReleaseMetadata?.buildReleaseList !== undefined) {
            trainMetadata.buildReleaseMetadata.buildReleaseList.forEach((buildRelease) => {
                buildRelease.rollouts?.forEach((rollout) => {
                    const rolloutKey = `${rollout.cloud}_${rollout.ring}`;
                    if (!processedTrainMetadata.rolloutDict[rolloutKey]) {
                        processedTrainMetadata.rolloutDict[rolloutKey] = [];
                    }
                    processedTrainMetadata.rolloutDict[rolloutKey].push(rollout);
                });
            });
            return processedTrainMetadata;
        }
    }
}

export default ReleaseTrainService;
