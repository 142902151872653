import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';

/**
 * Returns the selectable options from the given options.
 *
 * @param options The options to filter.
 * @returns The selectable options.
 */
export function getSelectableOptions(options: IComboBoxOption[]): IComboBoxOption[] {
    return options.filter(
        (option) => (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled
    );
}

/**
 * Trims the given string to the given max length. Adds ellipsis if trimmed.
 *
 * @param str The string to trim.
 * @param maxLength The max length of the string.
 * @returns The trimmed string.
 */
export function trimString(str: string, maxLength: number): string {
    return str.length > maxLength ? `${str.substring(0, maxLength - 3)}...` : str;
}

/**
 * Creates a temporary download link for the given blob, and clicks it.
 *
 * @param blob The blob to download.
 * @param fileName The name of the file.
 */
export function createTempDownloadLink(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
}
