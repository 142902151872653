import { Service, apiServiceConfigs } from '../configs/apiServiceConfig';

import ApiService from './api.service';

/**
 * API manager.
 */
class ApiManager {
    private static apiServiceInstance: ApiService;
    private static apiServiceInstances: Record<Service, ApiService> = {} as Record<Service, ApiService>;

    /**
     * Constructor.
     */
    private constructor() {
        // Private constructor to prevent external instantiation
    }

    /**
     * Get API service.
     *
     * @param serviceName Service name.
     * @returns API service.
     */
    public static getApiService(serviceName?: Service): ApiService {
        // Use default service name if not provided
        serviceName = serviceName || Service.SKYNET;

        const config = apiServiceConfigs[serviceName];

        if (!ApiManager.apiServiceInstances[serviceName]) {
            // Create a new instance of ApiService with configuration if it doesn't exist for the given service
            ApiManager.apiServiceInstances[serviceName] = new ApiService(config);
        }

        return ApiManager.apiServiceInstances[serviceName];
    }
}

export default ApiManager;
