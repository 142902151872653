import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    throw new Error('Unable to find the root element with id `root` in the document.');
}
