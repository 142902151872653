import { Stack, Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import ApiService from '../../services/api.service';
import ClientSpecificInsights from './ClientSpecificInsights';
import './Ring0Insights.css';

const Ring0Insights = (props) => {
    const apiService = new ApiService();
    const [tabData, setTabs] = useState({ clients: [] });
    const [selectedTab, setSelectedTab] = useState('windows');

    // ===============================================================
    useEffect(() => {
        apiService.getJsonFile('ring0Insights.json').then(function (response) {
            setTabs((prevState) => ({ ...prevState, clients: response.clients }));
        });
    }, []);

    // ===============================================================
    return (
        <div data-testid="Ring0Insights" className="ring0">
            <Stack className="ring0-insights-top">
                <Stack>
                    <span className="header-title">Ring 0 Insights </span>
                </Stack>
            </Stack>
            <Pivot onLinkClick={onTabClick} selectedKey={selectedTab} className="client-tabs">
                {tabData.clients.map((client) => (
                    <PivotItem itemKey={client.key} headerText={client.text} key={client.key}>
                        <div>
                            <ClientSpecificInsights client={client.clientType} os={client.os} env={client.environment} />
                        </div>
                    </PivotItem>
                ))}
            </Pivot>
        </div>
    );

    // ===============================================================
    function onTabClick(tab) {
        setSelectedTab(tab.props.itemKey);
    }

    // ===============================================================
};

Ring0Insights.propTypes = {};
Ring0Insights.defaultProps = {};

export default Ring0Insights;
