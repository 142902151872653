import { Text } from '@fluentui/react';
import {
    Chart as ChartJS,
    LinearScale,
    TimeScale,
    PointElement,
    Tooltip,
    Legend,
    ChartOptions,
    Point,
    ChartData,
    TooltipModel,
    ActiveElement,
    ChartEvent,
    ChartDataset
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import { TransformedChartData } from '../../../services/models/RecentChangesReport/TransformedChartData';
import { RecentChangeType } from '../configs/defaults';

ChartJS.register(LinearScale, TimeScale, PointElement, Tooltip, Legend);

type ClientChangesChartProps = {
    cloudRing: string;
    client: string;
    startDate: Date;
    endDate: Date;
    selectedChangeTypes: string[];
    chartData: TransformedChartData[];
    showLegend: boolean;
    filteredFeatureFlags: string[];
};

/**
 * Displays the custom scatter plot chart for the client changes.
 *
 * @param props The props for the component.
 * @returns The JSX for the component.
 */
const ClientChangesChart: React.FC<ClientChangesChartProps> = (props) => {
    const externalTooltipHandler = (context: { chart: ChartJS<'scatter', Point[]>; tooltip: TooltipModel<'scatter'> }) => {
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.xAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        // Set Text
        if (tooltip.body) {
            const dataPoints = tooltip.dataPoints;

            const tableBody = document.createElement('tbody');
            tableBody.style.maxHeight = 'inherit';
            tableBody.style.maxWidth = 'inherit';
            tableBody.style.overflowY = 'scroll';
            dataPoints.forEach((dataPoint, i) => {
                const colors = tooltip.labelColors[i];
                const item = dataPoint.raw as TransformedChartData;

                const link = document.createElement('a');
                link.href = item.metadata.link as string;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.style.color = 'lightblue';
                if (item.metadata.configurationGroupName && item.metadata.configurationName) {
                    link.textContent = `${item.metadata.configurationName.toString()} (${item.metadata.allocationPercentage}%)`;
                } else if (item.metadata.buildVersion && item.metadata.allocationPercentage !== undefined) {
                    link.textContent = `${item.metadata.buildVersion} (${item.metadata.allocationPercentage}%)`;
                } else if (item.metadata.buildVersion && item.metadata.stageName) {
                    link.textContent = `${item.metadata.buildVersion} (${item.metadata.stageName})`;
                }

                const linkTd = document.createElement('td');
                linkTd.style.borderWidth = '0';
                linkTd.appendChild(link);

                const bodyTr = document.createElement('tr');
                bodyTr.appendChild(linkTd);

                const labelSpan = document.createElement('span');
                labelSpan.style.background = colors.backgroundColor.toString();
                labelSpan.style.borderColor = colors.borderColor.toString();
                labelSpan.style.borderWidth = '2px';
                labelSpan.style.marginRight = '10px';
                labelSpan.style.height = '10px';
                labelSpan.style.width = '10px';
                labelSpan.style.display = 'inline-block';

                const timestampTr = document.createElement('tr');
                timestampTr.style.backgroundColor = 'inherit';
                timestampTr.style.overflowY = 'auto';
                timestampTr.style.maxWidth = 'inherit';
                timestampTr.style.maxHeight = 'inherit';
                timestampTr.style.borderWidth = '0';

                const timestampTd = document.createElement('td');
                timestampTd.style.borderWidth = '0';

                const timestamp = item.x.toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZoneName: 'short'
                });
                const timestampText = document.createTextNode(timestamp);

                timestampTd.appendChild(labelSpan);
                timestampTd.appendChild(timestampText);
                timestampTr.appendChild(timestampTd);
                tableBody.appendChild(timestampTr);
                tableBody.appendChild(bodyTr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot?.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot?.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.fontSize = '0.8rem';
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    const [chartOptions, setChartOptions] = useState<ChartOptions<'scatter'>>({
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 1,
                max: 3,
                grid: {
                    display: false
                },
                offset: true,
                ticks: {
                    stepSize: 1,
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: props.showLegend,
                labels: {
                    usePointStyle: true
                },
                onClick: () => {
                    // Do nothing
                }
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler
            }
        },
        onClick: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS<'scatter', Point[]>) => {
            if (elements.length === 0) {
                const tooltipEl = getOrCreateTooltip(chart);
                tooltipEl.style.opacity = '0';
                tooltipEl.style.pointerEvents = 'none';
            }
        }
    });

    const [chartData, setChartData] = useState<ChartData<'scatter', TransformedChartData[]>>({
        datasets: []
    });

    useEffect(() => {
        const chartDatasets: ChartDataset<'scatter', TransformedChartData[]>[] = [];
        let featureRolloutUpdates = props.chartData.filter((d) => d.y === 1) ?? [];
        if (props.filteredFeatureFlags !== undefined && props.filteredFeatureFlags.length > 0) {
            const filteredFeatureFlagUpdates: TransformedChartData[] = [];

            props.filteredFeatureFlags.forEach((input) => {
                featureRolloutUpdates.forEach((data) => {
                    if (
                        Array.isArray(data.metadata.featureFlags) &&
                        data.metadata.featureFlags.some(
                            (meta) => typeof meta === 'string' && meta.toLowerCase().includes(input.toLowerCase())
                        )
                    ) {
                        if (!filteredFeatureFlagUpdates.includes(data)) {
                            filteredFeatureFlagUpdates.push(data);
                        }
                    }
                });
            });

            featureRolloutUpdates = filteredFeatureFlagUpdates;
        }

        const buildRolloutUpdates = props.chartData.filter((d) => d.y === 2) ?? [];
        const bitsReleaseUpdates = props.chartData.filter((d) => d.y === 3) ?? [];

        if (props.selectedChangeTypes.includes(RecentChangeType.BitsReleaseUpdate)) {
            chartDatasets.push({
                label: 'Bits Release Updates',
                data: bitsReleaseUpdates,
                pointStyle: 'rectRot',
                pointRadius: 7,
                backgroundColor: '#F3CBBD'
            });
        }
        if (props.selectedChangeTypes.includes(RecentChangeType.BuildRolloutUpdate)) {
            chartDatasets.push({
                label: 'Build Rollout Updates',
                data: buildRolloutUpdates,
                pointStyle: 'triangle',
                pointRadius: 7,
                backgroundColor: '#90CDC3'
            });
        }
        if (props.selectedChangeTypes.includes(RecentChangeType.FeatureRolloutUpdate)) {
            chartDatasets.push({
                label: 'Feature Flag Updates',
                data: featureRolloutUpdates,
                pointStyle: 'circle',
                pointRadius: 5,
                backgroundColor: '#F9968B'
            });
        }
        setChartData({
            datasets: chartDatasets
        });
    }, [props.chartData, props.selectedChangeTypes, props.filteredFeatureFlags]);

    useEffect(() => {
        setChartOptions((prevOptions) => {
            return {
                ...prevOptions,
                scales: {
                    ...prevOptions.scales,
                    x: {
                        type: 'time',
                        time: {
                            minUnit: 'hour'
                        },
                        min: props.startDate.toISOString(),
                        max: props.endDate.toISOString(),
                        ticks: {
                            major: {
                                enabled: true
                            },
                            font: (context) => {
                                if (context.tick && context.tick.major) {
                                    return {
                                        weight: 'bold'
                                    };
                                }
                            }
                        }
                    }
                }
            };
        });
    }, [props.startDate, props.endDate]);

    return (
        <>
            <Text variant="medium" styles={{ root: { fontWeight: '500' } }}>
                {props.client}
            </Text>
            <div className="chart-container" style={{ position: 'relative', minHeight: '200px', maxHeight: '300px', width: '100%' }}>
                <Scatter options={chartOptions} data={chartData} />
            </div>
        </>
    );
};

const getOrCreateTooltip = (chart: ChartJS<'scatter', Point[]>) => {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(0%, -100%)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode?.appendChild(tooltipEl);
    }
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'all';

    return tooltipEl;
};

export { ClientChangesChart };
