import { DefaultButton, IStackStyles, MessageBar, MessageBarType, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { useConst } from '@fluentui/react-hooks';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Card } from '../../../components/Card';
import { JSONChartData } from '../../../services/models/RecentChangesReport/JSONChartData';
import { createTempDownloadLink } from '../utils';

import { ChangeTypeComboBox } from './ChangeTypeComboBox';
import { ClientComboBox } from './ClientComboBox';
import { DateAndTimePicker } from './DateAndTimePicker';
import { FilterFormData } from './FilterFormData';
import { RingComboBox } from './RingComboBox';

const stackStyles: Partial<IStackStyles> = { root: { minWidth: 200, width: '100%' } };
const stackTokens = { childrenGap: 5 };
const filterIcon: IIconProps = { iconName: 'Filter' };

type RecentChangesFiltersFormProps = {
    filterFormData: FilterFormData;
    setFilterFormData: React.Dispatch<React.SetStateAction<FilterFormData>>;
    onSubmit: () => void;
    jsonChartData: JSONChartData[];
    filteredFeatureFlags: string[];
    setFilteredFeatureFlags: React.Dispatch<React.SetStateAction<string[]>>;
};

/**
 * Form for filtering recent changes.
 *
 * @param props The props for the component.
 * @returns The component to display the form.
 */
const RecentChangesFiltersForm: React.FC<RecentChangesFiltersFormProps> = (props) => {
    const { filterFormData, setFilterFormData } = props;

    const [error, setError] = useState<string>();

    const today = useConst(new Date(new Date(Date.now()).setSeconds(0, 0)));
    const threeDaysBeforeToday = useConst(addDays(today, -3));
    const thirtyDaysBeforeToday = useConst(addDays(today, -30));

    useEffect(() => {
        setError(undefined);
        setFilterFormData((prevFormData) => {
            return {
                ...prevFormData,
                startDate: threeDaysBeforeToday,
                endDate: today,
                isValid: false
            };
        });
    }, []);

    return (
        <Card>
            <Stack>
                {error && (
                    <div style={{ paddingBottom: '0.5rem' }}>
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                    </div>
                )}
                <Stack horizontal wrap tokens={stackTokens} styles={stackStyles}>
                    <Stack.Item>
                        <DateAndTimePicker
                            defaultDate={threeDaysBeforeToday}
                            datePickerProps={{
                                isRequired: true,
                                placeholder: 'Start date',
                                ariaLabel: 'Start date',
                                minDate: thirtyDaysBeforeToday,
                                maxDate: today,
                                isMonthPickerVisible: false,
                                style: { minWidth: 160 }
                            }}
                            timePickerProps={{
                                placeholder: 'Start time',
                                ariaLabel: 'Start time',
                                increments: 120
                            }}
                            onChange={(date: Date) => onStartDateTimeChange(date)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DateAndTimePicker
                            defaultDate={today}
                            datePickerProps={{
                                isRequired: true,
                                placeholder: 'End date',
                                ariaLabel: 'End date',
                                minDate: thirtyDaysBeforeToday,
                                maxDate: today,
                                isMonthPickerVisible: false,
                                style: { minWidth: 160 }
                            }}
                            timePickerProps={{
                                placeholder: 'Start time',
                                ariaLabel: 'Start time',
                                increments: 120
                            }}
                            onChange={(date: Date) => onEndDateTimeChange(date)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <ClientComboBox setFilterFormData={setFilterFormData} />
                    </Stack.Item>
                    <Stack.Item>
                        <ChangeTypeComboBox setFilterFormData={setFilterFormData} />
                    </Stack.Item>
                    <Stack.Item>
                        <RingComboBox setFilterFormData={setFilterFormData} />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton text="Search" onClick={onSearchButtonClick} />
                    </Stack.Item>
                </Stack>
                {props.jsonChartData && props.jsonChartData.length > 0 && (
                    <Stack
                        horizontal
                        wrap
                        tokens={stackTokens}
                        styles={{
                            root: {
                                marginTop: '10px'
                            }
                        }}
                    >
                        <Stack.Item shrink={false} grow={1}>
                            <SearchBox
                                placeholder="Filter feature flags (comma-separated, for feature flag updates only)"
                                iconProps={filterIcon}
                                onSearch={(input) => {
                                    const splitText = input
                                        .split(',')
                                        .map((flag: string) => flag.trim())
                                        .filter((flag: string) => flag.length > 0);
                                    props.setFilteredFeatureFlags(splitText);
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item
                            styles={{
                                root: {
                                    marginLeft: 'auto'
                                }
                            }}
                        >
                            <DefaultButton
                                iconProps={{ iconName: 'download' }}
                                text="Download as JSON"
                                onClick={() => {
                                    let blob = new Blob([JSON.stringify(props.jsonChartData)], { type: 'application/json' });
                                    if (props.filteredFeatureFlags !== undefined && props.filteredFeatureFlags.length > 0) {
                                        const filteredFeatureFlagUpdates: JSONChartData[] = [];
                                        props.filteredFeatureFlags?.forEach((input) => {
                                            props.jsonChartData.forEach((data) => {
                                                if (
                                                    Array.isArray(data.metadata.featureFlags) &&
                                                    data.metadata.featureFlags.some(
                                                        (meta) =>
                                                            typeof meta === 'string' && meta.toLowerCase().includes(input.toLowerCase())
                                                    )
                                                ) {
                                                    if (!filteredFeatureFlagUpdates.includes(data)) {
                                                        filteredFeatureFlagUpdates.push(data);
                                                    }
                                                }
                                            });
                                        });

                                        props.jsonChartData.forEach((data) => {
                                            if (data.changeType !== 'FeatureFlagUpdate') {
                                                filteredFeatureFlagUpdates.push(data);
                                            }
                                        });
                                        blob = new Blob([JSON.stringify(filteredFeatureFlagUpdates)], { type: 'application/json' });
                                    }

                                    const fileName = `recentChanges_${new Date().toISOString()}.json`;
                                    createTempDownloadLink(blob, fileName);
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        </Card>
    );

    function onStartDateTimeChange(date: Date) {
        setFilterFormData((prevFormData) => {
            return {
                ...prevFormData,
                startDate: date
            };
        });
    }

    function onEndDateTimeChange(date: Date) {
        setFilterFormData((prevFormData) => {
            return {
                ...prevFormData,
                endDate: date
            };
        });
    }

    function onSearchButtonClick() {
        if (filterFormData.startDate > filterFormData.endDate) {
            setError('Start date must be before end date!');
            return;
        }
        if (filterFormData.clients.length === 0) {
            setError('At least one client must be selected!');
            return;
        }
        if (filterFormData.changeTypes.length === 0) {
            setError('At least one change type must be selected!');
            return;
        }
        if (filterFormData.rings.length === 0) {
            setError('At least one ring must be selected!');
            return;
        }
        setError(undefined);
        props.onSubmit();
    }
};

export { RecentChangesFiltersForm };
