import { DetailsList, IColumn, Link, SelectionMode, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClientType, Environment, OS, ReleaseStatus } from 'skynet-client';

import { Card, CardHeader } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import ShiproomV2Service from '../../services/shiproomV2.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import { ringStatusCardStyles, ringStatusDetailsListStyles } from './styles/styles';
import { getFormattedCloudRingPairs } from './utils';

type RingStatusDetailsProps = {
    clientType: ClientType;
    os: OS;
    environment: Environment;
    supportedRings: Record<string, string[]>;
    isDataCollapsed: boolean;
};

/**
 * Displays the ring status details.
 *
 * @param props The props.
 * @returns The Ring Status Details component.
 */
const RingStatusDetails: React.FC<RingStatusDetailsProps> = (props) => {
    const [data, setData] = useState<ReleaseStatus[]>([]);
    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setIsLoading();
        setIsDataNotLoaded();

        const shiproomService = new ShiproomV2Service();
        shiproomService
            .apiDashboardShiproomReportGetCurrentReleaseSnapshotGet({
                clientType: props.clientType,
                os: props.os,
                environment: props.environment
            })
            .then((response) => {
                const cloudRingPairs = getFormattedCloudRingPairs(props.supportedRings);
                const filteredData = response.filter((item) => cloudRingPairs.includes(`${item.cloud}_${item.ring}`));
                filteredData.sort(
                    (a, b) => cloudRingPairs.indexOf(`${a.cloud}_${a.ring}`) - cloudRingPairs.indexOf(`${b.cloud}_${b.ring}`)
                );

                setData(filteredData);
                setIsDataLoaded();
                setIsNotLoading();

                appInsightsClient.logTrace(
                    { message: `Fetched current release snapshot from ShiproomService` },
                    { clientType: props.clientType, os: props.os, environment: props.environment }
                );
            })
            .catch((fail) => {
                setIsDataNotLoaded();
                setIsNotLoading();

                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect in RingStatusDetails`,
                        clientType: props.clientType,
                        os: props.os,
                        environment: props.environment
                    }
                );
            });
    }, [props.clientType, props.os, props.environment]);

    const columns: IColumn[] = [
        {
            key: 'cloud_ring',
            name: 'Ring',
            fieldName: 'cloud_ring',
            minWidth: 50,
            isMultiline: true,
            onRender: (item: ReleaseStatus) => {
                if (item.cloud === 'Prod') {
                    return <span>{item.ring}</span>;
                } else {
                    return (
                        <span>
                            {item.cloud} {item.ring}
                        </span>
                    );
                }
            }
        },
        {
            key: 'trainName',
            name: 'Train',
            fieldName: 'trainName',
            minWidth: 120,
            onRender: (item: ReleaseStatus) => {
                return (
                    <Link
                        title="Go to Train"
                        data-id="ring-status go-to-train"
                        data-train={item.trainName}
                        onClick={() => handleDeepLink(item.trainName ?? '')}
                    >
                        {item.trainName}
                    </Link>
                );
            }
        },
        {
            key: 'percent',
            name: 'Percent',
            fieldName: 'percent',
            minWidth: 50,
            onRender: (item: ReleaseStatus) => {
                return <span>{item.percent}%</span>;
            }
        }
    ];

    return (
        <div style={{ display: props.isDataCollapsed ? 'none' : 'block' }}>
            <Card styles={ringStatusCardStyles}>
                <CardHeader>Ring Status</CardHeader>
                {!isLoading && !isDataLoaded && <ErrorNotification msg="Unable to get ring status details" />}
                {isLoading && <Spinner size={SpinnerSize.large} label="Fetching ring status details ..." />}
                {!isLoading && isDataLoaded && (
                    <DetailsList
                        className="ring-status-details-list"
                        items={data}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        styles={ringStatusDetailsListStyles}
                    />
                )}
            </Card>
        </div>
    );

    function handleDeepLink(trainName: string) {
        searchParams.set('trainName', trainName);
        setSearchParams(searchParams);
    }
};

export default RingStatusDetails;
