import { IStackTokens, Icon, Stack, Text } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { Card, CardHeader } from '../../../components/Card';
import { RolloutHistoryCachedResponse } from '../../../services/models/FeatureFlag/History/RolloutHistoryCachedResponse';
import { RolloutParsedLog, RolloutType } from '../../../services/models/FeatureFlag/History/RolloutParsedLog';

import HistoryFilterForm from './HistoryFilterForm';
import RolloutLogListView from './RolloutLogListView';

/**
 * The rollout history log component.
 *
 * @returns The rollout history log component.
 */
const RolloutHistoryLog: React.FC = () => {
    // ========================= State ========================= //
    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
    const [data, setData] = useState<RolloutHistoryCachedResponse>();

    const [rolloutLogs, setRolloutLogs] = useState<RolloutParsedLog[]>([]);
    const [filteredRolloutLogs, setFilteredRolloutLogs] = useState<RolloutParsedLog[]>([]);

    // ========================= Hooks ========================= //
    useEffect(() => {
        const cachedData = getCachedData();

        if (!cachedData) {
            setShouldRedirect(true);
            return;
        }

        const parsedLogs = getParsedRolloutLogsFromRolloutHistory(cachedData);

        if (parsedLogs.length > 0) {
            setData(cachedData);
            setRolloutLogs(parsedLogs);
            setFilteredRolloutLogs(parsedLogs);
        }
    }, []);

    // ========================= Render ========================= //
    return (
        <>
            {shouldRedirect && <Navigate to="/featureTracking" />}

            {data && (
                <Stack tokens={stackTokens}>
                    <Card>
                        <CardHeader>
                            <Icon iconName="History" />
                            <Text variant="xLarge"> Rollout History Log</Text>
                        </CardHeader>

                        <Text block variant="mediumPlus" style={{ marginLeft: 5 }}>
                            Review recent release information across all supported pipelines. History includes version releases and feature
                            flag changes.
                        </Text>

                        <HistoryFilterForm clients={data.clients} logs={rolloutLogs} updateFilteredLogs={setFilteredRolloutLogs} />

                        <div style={{ marginLeft: 5 }}>
                            <Text block variant="medium">
                                <u>Feature Flag name:</u> {data.response.name}
                            </Text>
                            <Text block variant="medium">
                                <u>Repository name:</u> {data.repoName}
                            </Text>
                            <Text block variant="medium">
                                <u>Commit Id:</u> {data.commitId}
                            </Text>
                        </div>
                    </Card>

                    {filteredRolloutLogs && (
                        <Card>
                            <RolloutLogListView logs={filteredRolloutLogs} />
                        </Card>
                    )}
                </Stack>
            )}
        </>
    );
};

// ========================= Styles ========================= //
const stackTokens: IStackTokens = { childrenGap: 10 };

// ========================= Helper Functions ========================= //
/**
 * Parses the cached response data and invalidate if older than 30 mins.
 *
 * @returns The parsed logs and cloud rings.
 */
const getCachedData = (): RolloutHistoryCachedResponse | null => {
    const cachedObjectData = JSON.parse(localStorage.getItem('featureTrackingResponse') || 'null');
    if (!cachedObjectData) {
        return null;
    }

    const cachedData: RolloutHistoryCachedResponse = cachedObjectData;

    const diffInMinutes = Math.round((Date.now() - cachedData.timestamp) / 1000 / 60);
    if (diffInMinutes >= 30) {
        // Clear the cache if it is older than 30 minutes
        localStorage.removeItem('featureTrackingResponse');
        return null;
    }

    return cachedData;
};

/**
 * Parses the rollout history data and returns the parsed logs.
 *
 * @param rolloutCachedData The rollout history data.
 * @returns The parsed logs.
 */
const getParsedRolloutLogsFromRolloutHistory = (rolloutCachedData: RolloutHistoryCachedResponse): RolloutParsedLog[] => {
    const parsedData = rolloutCachedData.response.featureFlagTrackingDetails;

    const parsedLogs: RolloutParsedLog[] = [];

    parsedData?.forEach((featureDetailsForClient) => {
        const clientName = featureDetailsForClient.client;
        const releaseHistoryArray = featureDetailsForClient.releaseHistory;

        releaseHistoryArray.forEach((releaseHistory) => {
            const buildRolloutHistory = releaseHistory.buildRolloutHistory;
            const featureRolloutHistory = releaseHistory.featureFlagRolloutHistory;

            buildRolloutHistory.forEach((buildRollout) => {
                const log: RolloutParsedLog = {
                    kind: RolloutType.Build,
                    id: buildRollout.buildId,
                    url: buildRollout.buildUrl,
                    allocationPercentage: buildRollout.allocationPercentage,
                    timestamp: new Date(buildRollout.rolloutCompletedOn),
                    cloud: releaseHistory.cloud,
                    ring: releaseHistory.ring,
                    client: clientName
                };

                parsedLogs.push(log);
            });

            featureRolloutHistory.forEach((featureRollout) => {
                const log: RolloutParsedLog = {
                    kind: RolloutType.FeatureFlag,
                    id: featureRollout.rolloutId?.toString() ?? '',
                    url: featureRollout.rolloutUrl ?? '',
                    allocationPercentage: featureRollout.allocationPercentage,
                    timestamp: new Date(featureRollout.iterationUpdateTime ?? ''),
                    cloud: releaseHistory.cloud,
                    ring: releaseHistory.ring,
                    client: clientName
                };

                parsedLogs.push(log);
            });
        });
    });

    return parsedLogs;
};

// ========================= Export ========================= //
export default RolloutHistoryLog;
