import { MessageBarType, MessageBar } from '@fluentui/react';
import React from 'react';

type ErrorNotificationProps = {
    msg: string | JSX.Element;
    success?: boolean;
    resetChoice?: () => void;
};
/**
 * This component is to provide implementation of Error Handling for Reports.
 *
 * @param props The props.
 * @returns JSX (The actual view).
 */
const ErrorNotification: React.FC<ErrorNotificationProps> = (props) => (
    <MessageBar
        messageBarType={props?.success ? MessageBarType.success : MessageBarType.error}
        isMultiline={true}
        onDismiss={props.resetChoice}
        dismissButtonAriaLabel="Close"
    >
        {props.msg}
    </MessageBar>
);

ErrorNotification.propTypes = {};
ErrorNotification.defaultProps = {};
export default ErrorNotification;
