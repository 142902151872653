import { Configuration } from 'skynet-client';

import { getBearerTokenHeader } from '../../auth/authService';
import { SKYNET_BASE_URL, SKYNET_SCOPE } from '../../configs/env.config';

export const skynetConfiguration: Configuration = new Configuration({
    basePath: SKYNET_BASE_URL,
    apiKey: () => getBearerTokenHeader(undefined, SKYNET_SCOPE),
    headers: {
        'Content-Type': 'application/json',
        'X-Client-Id': 'ReportingPortal'
    }
});
