import { Spinner, SpinnerSize, DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react';
import { useEffect, useState } from 'react';
import './Ring0Insights.css';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import RingInsightsService from '../../services/ringInsights.service';
import CommonUtils from './CommonUtils';

const ClientSpecificInsights = (props) => {
    const ringInsights = new RingInsightsService();

    const [loaderFlag, setLoaderFlag] = useState(true);
    const [noDataFlag, setNoDataFlag] = useState(false);
    const [data, setData] = useState({
        items: [],
        columns: []
    });

    const [clientType, setClientType] = useState(props.client);
    // ===============================================================

    const populateRows = function () {
        let rowArray = [
            {
                key: '1',
                fieldName: 'completedOn',
                clientType: 'all',
                parameter: 'Update Date/Time',
                value: 'N/A'
            },
            {
                key: '2',
                fieldName: 'buildNumber',
                clientType: 'all',
                parameter: 'Build Number',
                value: 'N/A'
            },
            {
                key: '3',
                fieldName: 'latestCommit',
                clientType: 'all',
                parameter: 'Latest Commit',
                value: 'N/A'
            },
            {
                key: '4',
                fieldName: 'ringblockers',
                clientType: 'all',
                parameter: 'Current Active R0 Ringblockers',
                value: 'None'
            },
            {
                key: '5',
                fieldName: 'teamsVersionStable',
                clientType: 'desktop',
                parameter: 'Teams Version (S)',
                value: 'N/A'
            },
            {
                key: '6',
                fieldName: 'teamsVersionExperimental',
                clientType: 'desktop',
                parameter: 'Teams Version (E)',
                value: 'N/A'
            },
            {
                key: '7',
                fieldName: 'electronVersionStable',
                clientType: 'desktop',
                parameter: 'Electron (S)',
                value: 'N/A'
            },
            {
                key: '8',
                fieldName: 'electronVersionExperimental',
                clientType: 'desktop',
                parameter: 'Electron (E)',
                value: 'N/A'
            },
            {
                key: '9',
                fieldName: 'slimcoreVersionStable',
                clientType: 'desktop',
                parameter: 'Slimcore (S)',
                value: 'N/A'
            },
            {
                key: '10',
                fieldName: 'slimcoreVersionExperimental',
                clientType: 'desktop',
                parameter: 'Slimcore (E)',
                value: 'N/A'
            },
            {
                key: '11',
                fieldName: 'tsCallingVersion',
                clientType: 'desktop',
                parameter: 'TSCalling',
                value: 'N/A'
            },
            {
                key: '12',
                fieldName: 'teamsVersion',
                clientType: 't2-web',
                parameter: 'Teams Version',
                value: 'N/A'
            },
            {
                key: '13',
                fieldName: 'orbitalFilesVersion',
                clientType: 'web',
                parameter: 'Orbital Files: Version',
                value: 'N/A'
            },
            {
                key: '14',
                fieldName: 'orbitalFilesCommit',
                clientType: 'web',
                parameter: 'Orbital Files: Last Commit',
                value: 'N/A'
            },
            {
                key: '15',
                fieldName: 'orbitalMultiWindowVersion',
                clientType: 'web',
                parameter: 'Orbital Multi-Window: Version',
                value: 'N/A'
            },
            {
                key: '16',
                fieldName: 'orbitalMultiWindowCommit',
                clientType: 'web',
                parameter: 'Orbital Multi-Window: Last Commit',
                value: 'N/A'
            }
        ];

        if (props.client === 'Desktop') {
            return rowArray.filter(function (item) {
                return !item.clientType.endsWith('web');
            });
        } else if (props.client === 'WebClient') {
            return rowArray.filter(function (item) {
                return item.clientType !== 'desktop';
            });
        } else {
            return rowArray.filter(function (item) {
                return !(item.clientType.startsWith('web') || item.clientType.startsWith('desktop'));
            });
        }
    };

    const populateColumns = () => {
        let columnArray = [
            {
                key: '1',
                name: '',
                fieldName: 'parameter',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '2',
                fieldName: 'Prod',
                name: 'Prod',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '3',
                fieldName: 'GCC',
                name: 'GCC',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '4',
                fieldName: 'GCCH',
                name: 'GCCH',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '5',
                fieldName: 'DoD',
                name: 'DoD',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '6',
                fieldName: 'Gallatin',
                name: 'Gallatin',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '7',
                fieldName: 'AG08',
                name: 'AG08',
                minWidth: 250,
                maxWidth: 400
            },
            {
                key: '8',
                fieldName: 'AG09',
                name: 'AG09',
                minWidth: 250,
                maxWidth: 400
            }
        ];
        if (props.os === 'Mac') {
            columnArray = columnArray.filter(function (item) {
                return !item.name.startsWith('AG');
            });
        } 
        if (props.client === 'ReactWebClient' || props.client === 'Maglev') {
            columnArray = columnArray.filter(function (item) {
                return !item.name.endsWith('GCC');
            });
        }
        if (props.env === 'Life') {
            return columnArray.splice(0, 2);
        } else return columnArray;
    };

    // ===============================================================
    const gridStyles = (height) => {
        return {
            root: {},
            headerWrapper: {
                flex: '0 0 auto',
                '.ms-DetailsHeader': {
                    backgroundColor: 'lightgrey',
                    height: '27px',
                    lineHeight: '27px',
                    paddingTop: 0,
                    marginTop: 0
                }
            },
            contentWrapper: {
                flex: '1 1 auto',
                overflowY: 'auto',
                overflowX: 'hidden',
                '.ms-List-cell': {
                    minHeight: '20px'
                }
            }
        };
    };

    const formatDate = (data) => {
        let trimmedData = data?.trim();
        if (trimmedData?.length > 0) {
            return CommonUtils.getFormattedDateTime(trimmedData);
        }

        return '';
    };

    useEffect(() => {
        setLoaderFlag(true);
        setNoDataFlag(false);
        setClientType(props.client);
        setData((prevState) => ({ ...prevState, items: populateRows(), columns: populateColumns() }));

        let items = populateRows();
        let columns = populateColumns().filter((field) => field.fieldName !== 'parameter');
        columns.forEach((item) => {
            ringInsights.getRingInsights(props.client, props.os, props.env, 'None', item.fieldName).then(
                function (res) {
                    if (res === undefined || res == null || res.length === 0) {
                        items.forEach((entry) => {
                            entry[item.fieldName] = 'N/A';
                        });
                        setData((prevState) => ({ ...prevState, items: items, columns: populateColumns() }));
                        setLoaderFlag(false);
                    } else {
                        setLoaderFlag(false);
                        items[0][item.fieldName] = formatDate(res.completedOn);
                        items[1][item.fieldName] = (
                            <a href={res.buildUrl} target="_blank" rel="noreferrer">
                                {res.buildNumber}
                            </a>
                        );
                        items[2][item.fieldName] = (
                            <a href={res.changesUrl} target="_blank" rel="noreferrer">
                                {res.latestCommit}
                            </a>
                        );
                        items[3][item.fieldName] = res.ringBlockers ? (
                            <ul style={{ margin: 0, padding: 0 }}>
                                {res.ringBlockers.map((ringBlocker) => (
                                    <li key={ringBlocker.id}>
                                        <a href={ringBlocker.url} target="_blank" rel="noopener noreferrer">
                                            {ringBlocker.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            'None'
                        );
                        if (clientType === 'Desktop') {
                            items[4][item.fieldName] = res.desktopData.teamsVersion.stable;
                            items[5][item.fieldName] = res.desktopData.teamsVersion.experimental;
                            items[6][item.fieldName] = res.desktopData.electronVersion.stable;
                            items[7][item.fieldName] = res.desktopData.electronVersion.experimental;
                            items[8][item.fieldName] = res.desktopData.slimcoreVersion.stable;
                            items[9][item.fieldName] = res.desktopData.slimcoreVersion.experimental;
                            items[10][item.fieldName] = res.desktopData.tsCallingVersion;
                        } else if (clientType === 'WebClient') {
                            items[4][item.fieldName] = res.teamsVersion;
                            items[5][item.fieldName] = (
                                <a href={res.webClientData.orbitalFiles.buildUrl} target="_blank" rel="noreferrer">
                                    {res.webClientData.orbitalFiles.version}
                                </a>
                            );
                            items[6][item.fieldName] = (
                                <a href={res.webClientData.orbitalFiles.changesUrl} target="_blank" rel="noreferrer">
                                    {res.webClientData.orbitalFiles.commit}
                                </a>
                            );
                            items[7][item.fieldName] = (
                                <a href={res.webClientData.orbitalMultiWindow.buildUrl} target="_blank" rel="noreferrer">
                                    {res.webClientData.orbitalMultiWindow.version}
                                </a>
                            );
                            items[8][item.fieldName] = (
                                <a href={res.webClientData.orbitalMultiWindow.changesUrl} target="_blank" rel="noreferrer">
                                    {res.webClientData.orbitalMultiWindow.commit}
                                </a>
                            );
                        } else {
                            items[4][item.fieldName] = res.teamsVersion;
                        }
                        setData((prevState) => ({ ...prevState, items: items, columns: populateColumns() }));
                    }
                },
                (fail) => {
                    appInsightsClient.logException(
                        { exception: fail },
                        { message: 'catch error in processing getRingInsights in ClientSpecificinsights' }
                    );
                    setLoaderFlag(false);
                    setNoDataFlag(true);
                }
            );
        });
    }, [props.client, props.os, props.env]);

    // ===============================================================
    return (
        <>
            {loaderFlag && <Spinner size={SpinnerSize.large} label="Fetching records..." />}
            {!loaderFlag && !noDataFlag ? (
                <>
                    <DetailsList
                        items={data.items}
                        columns={data.columns}
                        setKey="set"
                        styles={gridStyles('400px')}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => false}
                    />
                </>
            ) : (
                ''
            )}
            {noDataFlag && (
                <ErrorNotification
                    msg="No data available."
                    resetChoice={() => {
                        setNoDataFlag(false);
                    }}
                />
            )}
        </>
    );
};

export default ClientSpecificInsights;
