import { useState, useEffect } from 'react';
import * as service from '../../services/retrospective.service';
import { getSnapDetailsV2, extractReleaseInfo } from './tools';
import { appInsightsClient } from '../../utils/appInsightsUtility';

export default function useTrainDetail(trainName) {
    const [noDataFlag, setNoDataFlag] = useState(false);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [data, setData] = useState({
        trainName: null,
        snapInfo: null,
        trainStatus: null,
        teamsThread: null,
        releaseInfo: {}
    });

    useEffect(() => {
        setLoaderFlag(true);
        setNoDataFlag(false);

        service
            .getTrainDetails(trainName)
            .then((response) => {
                if (response.length === 0) {
                    setNoDataFlag(true);
                    setLoaderFlag(false);
                } else {
                    setNoDataFlag(false);
                    setLoaderFlag(false);

                    var buildMetadatas = response.buildMetadataV2.reduce((accum, item) => {
                        if (!accum.hasOwnProperty(item.id)) {
                            accum[item.id] = item;
                        }
                        return accum;
                    }, {});

                    const releaseInfo = extractReleaseInfo(response.rollouts, buildMetadatas);
                    const snapInfo = getSnapDetailsV2(response.snapBranchMetadataV2);
                    
                    setData((prev) => ({
                        trainName: response.name,
                        snapInfo: snapInfo,
                        trainStatus: response.trainStatus,
                        teamsThread: response.teamsThread,
                        releaseInfo: releaseInfo
                    }));
                }
            })
            .catch((fail) => {
                appInsightsClient.logException({ exception: fail }, { message: 'catch error in processing getTrainDetails' });
                setNoDataFlag(true);
                setLoaderFlag(false);
            });
    }, [trainName]);

    return { noDataFlag, loaderFlag, data };
}
