import { Dropdown, IDropdownOption } from '@fluentui/react';
import React, { useEffect } from 'react';

import { dropdownStyles } from '../styles/RingmasterPageStyle';
import { RMDropdownOptionType } from '../types/Types';

type DropdownInputProps = {
    setDropdownOption: (val: string) => void;
    dropdownOptions: IDropdownOption<RMDropdownOptionType>[];
    resetInput: boolean;
};

/**
 * Renders a dropdown field.
 * A user can select the option from the dropdown.
 *
 * @param props A set of props for the component.
 * @returns A dropdown field for the user to select the option.
 */
const DropdownInput: React.FC<DropdownInputProps> = (props) => {
    // ========================= State =========================
    const [selectedOption, setSelectedOption] = React.useState<IDropdownOption>();

    // ========================= Hooks =========================
    useEffect(() => {
        setSelectedOption({ key: '', text: '' });
    }, [props.resetInput]);

    // ========================= Event handlers =========================
    const onChangeOfSelectedOption = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedOption(item);
        props.setDropdownOption(item ? item.key.toString() : '');
    };

    // ========================= Render =========================
    return (
        <Dropdown
            selectedKey={selectedOption ? selectedOption.key : undefined}
            onChange={onChangeOfSelectedOption}
            placeholder="Select an option"
            options={props.dropdownOptions}
            styles={dropdownStyles}
        />
    );
};

export default DropdownInput;
