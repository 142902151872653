import { configureStore } from '@reduxjs/toolkit';

import { userProfileReducer } from '../slices/userProfileSlice';

const store = configureStore({
    reducer: {
        userData: userProfileReducer
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
