import { Icon, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { ClientType, Environment, Experience, OS } from 'skynet-client';

import { Card, CardHeader } from '../../components/Card';
import { ProcessedReleaseSchedule } from '../../services/models/ReleaseSchedule';

import ReleaseNotesDetails from './ReleaseNotesDetails';
import ReleaseScheduleDetails from './ReleaseScheduleDetails';
import RingStatusDetails from './RingStatusDetails';
import {
    hoverableCollapseIconStyles,
    ringStatusReleaseScheduleStyles,
    shiproomClientReportStackTokens,
    collapseIconStyles
} from './styles/styles';
import { TrainDetails } from './TrainDetails';

type ShiproomClientReportProps = {
    clientType: ClientType;
    os: OS;
    environment: Environment;
    experience: Experience;
    supportedRings: Record<string, string[]>;
};

/**
 * Displays the shiproom report for the selected client.
 *
 * @param props The props for the component.
 * @returns The main component for each client of the report.
 */
const ShiproomClientReport: React.FC<ShiproomClientReportProps> = (props) => {
    const [releaseSchedules, setReleaseSchedules] = useState<ProcessedReleaseSchedule[]>([]);

    const [isDataCollapsed, { setTrue: collapse, setFalse: expand }] = useBoolean(true);

    useEffect(() => {
        collapse();
    }, [props]);

    return (
        <Stack tokens={shiproomClientReportStackTokens}>
            <Stack>
                <Card>
                    <CardHeader>Release Manager notes</CardHeader>
                    <ReleaseNotesDetails
                        clientType={props.clientType}
                        os={props.os}
                        environment={props.environment}
                        experience={props.experience}
                    />
                </Card>
            </Stack>

            <Card styles={ringStatusReleaseScheduleStyles}>
                <CardHeader>
                    <div
                        onClick={() => {
                            isDataCollapsed ? expand() : collapse();
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Icon
                                iconName={
                                    isDataCollapsed ? collapseIconStyles['collapsed'].iconName : collapseIconStyles['expanded'].iconName
                                }
                                styles={hoverableCollapseIconStyles}
                            />
                            <span> Ring Status and Release Schedule details</span>
                        </Stack>
                    </div>
                </CardHeader>
                <Stack horizontal>
                    <Stack.Item>
                        <RingStatusDetails
                            clientType={props.clientType}
                            os={props.os}
                            environment={props.environment}
                            supportedRings={props.supportedRings}
                            isDataCollapsed={isDataCollapsed}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <ReleaseScheduleDetails
                            clientType={props.clientType}
                            os={props.os}
                            environment={props.environment}
                            supportedRings={props.supportedRings}
                            setSchedules={setReleaseSchedules}
                            isDataCollapsed={isDataCollapsed}
                        />
                    </Stack.Item>
                </Stack>
            </Card>
            <Stack.Item style={{ marginTop: '1.4rem', minWidth: 'max-content' }}>
                <TrainDetails
                    clientType={props.clientType}
                    os={props.os}
                    environment={props.environment}
                    supportedRings={props.supportedRings}
                    releaseSchedules={releaseSchedules}
                />
            </Stack.Item>
        </Stack>
    );
};

export default ShiproomClientReport;
