import { Link, Text } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { getADOWorkItemURL } from '../../../utils/adoUtility';
import {
    desktopTCNSDisplayName,
    desktopVersion,
    experienceBuild,
    platformNames,
    skypeAClientPlatform,
    webRWCDisplayName
} from '../configs/defaults';
import { ProcessedFlightRollout } from '../types/Types';
import { capitalizeFirstLetter, getClient } from '../utilities/FFv2Utils';

type FlightDetailsCardProps = {
    flight: ProcessedFlightRollout;
};

/**
 * Displays a card with essential flight information.
 *
 * @param props The props for the component.
 * @returns The flight info card.
 */
const FlightDetailsCard: React.FC<FlightDetailsCardProps> = (props) => {
    const client: string = props.flight.experience ? webRWCDisplayName : desktopTCNSDisplayName;
    // handle legacy data
    const platformFilter = props.flight.filters[skypeAClientPlatform] as unknown;
    const platforms: string = (typeof platformFilter === 'string' ? platformFilter.split(',') : (platformFilter as Array<string>))
        .map((id) => {
            return `${platformNames[parseInt(id) as keyof typeof platformNames]} (${id})`;
        })
        .join(', ');

    return (
        <Card styles={{ root: { width: '40%' } }}>
            <CardHeader>Flight details</CardHeader>
            <table cellSpacing={8}>
                <tbody>
                    <tr>
                        <td>
                            <Text>Flight ID</Text>
                        </td>
                        <td>
                            <Text>
                                <Link href={props.flight.ECSLink} target="_blank">
                                    {props.flight.id}
                                </Link>
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Flight type</Text>
                        </td>
                        <td>
                            <Text>{capitalizeFirstLetter(props.flight.flightType)}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Boarding pass ID</Text>
                        </td>
                        <td>
                            <Text>
                                <Link href={getADOWorkItemURL(props.flight.boardingPassId)} target="_blank">
                                    {props.flight.boardingPassId}
                                </Link>
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>ADO release ID</Text>
                        </td>
                        <td>
                            <Text>
                                <Link href={props.flight.ADOLink} target="_blank">
                                    {props.flight.releaseId}
                                </Link>
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Audience</Text>
                        </td>
                        <td>
                            <Text>
                                {capitalizeFirstLetter(
                                    Array.isArray(props.flight.audience) ? props.flight.audience[0] : props.flight.audience
                                )}
                            </Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Client</Text>
                        </td>
                        <td>
                            <Text>{client}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Platform(s)</Text>
                        </td>
                        <td>
                            <Text>{platforms}</Text>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Text>Minimum Build Version</Text>
                        </td>
                        <td>
                            <Text>{props.flight.minimumBuildVersion}</Text>
                        </td>
                    </tr>

                    {Object.entries(props.flight.filters)
                        .filter(
                            (filter) =>
                                filter[0] !== skypeAClientPlatform &&
                                filter[0] !== experienceBuild &&
                                (!getClient(props.flight.filters) || filter[0] !== desktopVersion)
                        )
                        .map(([key, value]) => (
                            <tr key={key}>
                                <td>
                                    <Text>{key}</Text>
                                </td>
                                <td>
                                    <Text>{value?.toString()}</Text>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Card>
    );
};

export default FlightDetailsCard;
