import { SKYNET_BASE_URL } from '../configs/env.config';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { ClientRepoMap } from './models/ClientRepoMap';
import ApiResponseError from './models/error/ApiResponseError';
import { FeatureFlagTrackingResponse } from './models/FeatureFlag/FeatureFlagTrackingResponse';
import { FeatureItem } from './models/FeatureFlag/FeatureItem';

/**
 * FeatureFlagTrackingService.
 */
class FeatureFlagTrackingService {
    private apiService: ApiService;
    private featureTrackingBaseUrl: string;

    /**
     * Constructor.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.featureTrackingBaseUrl = `${SKYNET_BASE_URL}/api/featureflag`;
    }

    /**
     * Get feature flag tracking details by feature id.
     *
     * @param featureId Feature id.
     * @returns FeatureItem[].
     */
    async getDetailsByFeatureId(featureId: string): Promise<FeatureItem[]> {
        const url = `${this.featureTrackingBaseUrl}/featureItemDetails?featureIdentifier=${featureId}`;

        try {
            const featureItems = await this.apiService.get<FeatureItem[]>(url);
            return featureItems;
        } catch (error) {
            let errorMessage = 'Failed to get feature item';

            if (error instanceof ApiResponseError) {
                // Handle specific error codes or response properties
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.statusCode === 500) {
                    errorMessage = 'Internal server error. Please try again later. ' + error.errorDetail;
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }

            throw new Error(errorMessage);
        }
    }

    /**
     * Get feature flag tracking details by feature name.
     *
     * @param repoName Repository name.
     * @param commitId Commit id.
     * @param flagName Flag name.
     * @param clientRepoMapList Client repo map list.
     * @returns FeatureFlagTrackingResponse.
     */
    async getFlagRolloutHistory(
        repoName: string,
        commitId: string,
        flagName: string,
        clientRepoMapList: ClientRepoMap[]
    ): Promise<FeatureFlagTrackingResponse> {
        const url = `${this.featureTrackingBaseUrl}/featureTrackingReport`;

        try {
            const response = await this.apiService.post<FeatureFlagTrackingResponse>(url, {
                repoName,
                commitId,
                flagName,
                clientRepoMap: clientRepoMapList
            });

            return response;
        } catch (error) {
            let errorMessage = 'Failed to get feature item';

            if (error instanceof ApiResponseError) {
                // Handle specific error codes or response properties
                if (error.statusCode === 403) {
                    // Just an example
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.statusCode === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }

            throw new Error(errorMessage);
        }
    }
}

export default FeatureFlagTrackingService;
