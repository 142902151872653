import { Link, Stack, Text, Icon } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { getADOWorkItemURL } from '../../../utils/adoUtility';
import { gapStackTokensSmall } from '../styles/FFv2Style';
import { ProcessedFlightRollout } from '../types/Types';

type FlightLinksCardProps = {
    flight: ProcessedFlightRollout;
};

/**
 * Displays a card with essential flight links.
 *
 * @param props The props for the component.
 * @returns The flight links card.
 */
const FlightLinksCard: React.FC<FlightLinksCardProps> = (props) => {
    const ecsExperimentLink: string = props.flight.experimentId
        ? props.flight.experimentLink
            ? props.flight.experimentLink
            : props.flight.ECSLink.slice(0, props.flight.ECSLink.lastIndexOf('/')) + '/' + props.flight.experimentId
        : '';

    return (
        <Card>
            <CardHeader>Flight links</CardHeader>
            <Stack tokens={gapStackTokensSmall} styles={{ root: { padding: '5px' } }}>
                <Text>
                    <Link href={props.flight.ECSLink} target="_blank">
                        ECS Rollout - {props.flight.rolloutId}
                    </Link>
                </Text>
                {props.flight.experimentId ? (
                    <Text>
                        <Link href={ecsExperimentLink} target="_blank">
                            ECS Experiment - {props.flight.experimentId}
                        </Link>
                    </Text>
                ) : null}
                <Text>
                    <Link href={getADOWorkItemURL(props.flight.boardingPassId)} target="_blank">
                        ADO Feature work item - {props.flight.boardingPassId}
                    </Link>
                </Text>
                <Text>
                    <Link href={props.flight.ADOLink} target="_blank">
                        ADO Release Pipeline - {props.flight.releaseId}
                    </Link>
                </Text>
                {props.flight.testBuilds.length > 0 && (
                    <Text>
                        Tests builds -{' '}
                        {props.flight.testBuilds.map((build) => (
                            <Text key={`build-${build.id}`}>
                                {build.ring}:{' '}
                                <Link
                                    href={`https://domoreexp.visualstudio.com/Teamspace/_build/results?buildId=${build.id}&view=results`}
                                    target="_blank"
                                >
                                    {build.id}
                                </Link>{' '}
                            </Text>
                        ))}
                    </Text>
                )}
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon iconName="AnalyticsReport" /> &nbsp;
                    <Text>
                        <Link href={`/featureFlagReport?rolloutId=${props.flight.id}`}>Feature flag report</Link>
                    </Text>
                </span>
            </Stack>
        </Card>
    );
};

export default FlightLinksCard;
