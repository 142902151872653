import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import rings from '../../../configs/rings.json';
import { Ring } from '../../../services/models/common/ring';
import { getSelectableOptions } from '../utils';

import { FilterFormData } from './FilterFormData';

type RingComboBoxProps = {
    setFilterFormData: React.Dispatch<React.SetStateAction<FilterFormData>>;
};

/**
 * Component for the ring combo box.
 *
 * @param props The props for the component.
 * @returns The component to display the ring combo box.
 */
export const RingComboBox: React.FC<RingComboBoxProps> = (props) => {
    const { setFilterFormData } = props;

    const [allRings, setAllRings] = useState<Ring[]>([]);

    const [ringComboBoxOptions, setRingComboBoxOptions] = useState<IComboBoxOption[]>([]);
    const [selectedRingComboBoxKeys, setSelectedRingComboBoxKeys] = useState<string[]>([]);

    useEffect(() => {
        let cloudRings = rings.rings;
        cloudRings = cloudRings.filter((ring) => !ring.key.includes('ring0'));
        setRingComboBoxOptions(createRingComboBoxOptions(cloudRings));
        setAllRings(cloudRings);
    }, []);

    return (
        <ComboBox
            placeholder="Select Ring(s)"
            multiSelect
            options={ringComboBoxOptions}
            onChange={onRingComboBoxChange}
            calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
        />
    );

    function onRingComboBoxChange(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, _index?: number, _value?: string) {
        if (!option) {
            return;
        }

        const selected = option.selected;
        const currentSelectedOptionKeys = selectedRingComboBoxKeys.filter((key) => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === getSelectableOptions(ringComboBoxOptions).length;

        if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
            if (selectAllState) {
                setSelectedRingComboBoxKeys([]);
                setFilterFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        rings: []
                    };
                });
            } else {
                setSelectedRingComboBoxKeys(['selectAll', ...getSelectableOptions(ringComboBoxOptions).map((o) => o.key as string)]);
                setFilterFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        rings: [...getSelectableOptions(ringComboBoxOptions).map((o) => o.key as string)]
                    };
                });
            }
        } else {
            const updatedKeys = selected
                ? [...currentSelectedOptionKeys, option.key as string]
                : currentSelectedOptionKeys.filter((k) => k !== option.key);
            if (updatedKeys.length === getSelectableOptions(ringComboBoxOptions).length) {
                updatedKeys.push('selectAll');
            }
            setSelectedRingComboBoxKeys(updatedKeys);
            setFilterFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    rings: updatedKeys
                };
            });
        }
    }

    function createRingComboBoxOptions(ringList: string[] | Ring[]): IComboBoxOption[] {
        const options: IComboBoxOption[] = [
            {
                key: 'selectAll',
                text: 'Select All',
                itemType: SelectableOptionMenuItemType.SelectAll
            }
        ];
        if (typeof ringList[0] === 'string') {
            const keys = ringList as string[];
            keys.forEach((cloudRing) => {
                const splitString = cloudRing.split('_');
                const cloud = splitString[0];
                const ring = splitString[1] + (splitString.length === 3 ? '_' + splitString[2] : '');

                if (options.find((x) => x.key === cloud) === undefined) {
                    options.push({ key: cloud, text: cloud.toLocaleUpperCase(), itemType: SelectableOptionMenuItemType.Header });
                }
                options.push({ key: cloudRing, text: allRings.find((x) => x.key === cloudRing)?.text ?? ring });
            });
            return options;
        } else {
            (ringList as Ring[]).forEach((ring) => {
                if (options.find((x) => x.key === ring.cloud) === undefined) {
                    options.push({ key: ring.cloud, text: ring.cloud.toLocaleUpperCase(), itemType: SelectableOptionMenuItemType.Header });
                }
                options.push({ key: ring.key, text: ring.text });
            });
            return options;
        }
    }
};
