import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { SupportedClients } from '../configs/defaults';
import { getSelectableOptions } from '../utils';

import { FilterFormData } from './FilterFormData';

type ClientComboBoxProps = {
    setFilterFormData: React.Dispatch<React.SetStateAction<FilterFormData>>;
};

/**
 * Component for the client combo box.
 *
 * @param props The props for the component.
 * @returns The component to display the client combo box.
 */
export const ClientComboBox: React.FC<ClientComboBoxProps> = (props) => {
    const { setFilterFormData } = props;

    const [clientComboBoxOptions, setClientComboBoxOptions] = useState<IComboBoxOption[]>([]);
    const [selectedClientComboBoxKeys, setSelectedClientComboBoxKeys] = useState<string[]>([]);

    useEffect(() => {
        const clientOptions = [
            {
                key: 'selectAll',
                text: 'Select All',
                itemType: SelectableOptionMenuItemType.SelectAll
            }
        ];
        SupportedClients.sort().forEach((x) => {
            clientOptions.push({ key: x, text: x, itemType: SelectableOptionMenuItemType.Normal });
        });
        setClientComboBoxOptions(clientOptions);
    }, []);

    return (
        <ComboBox
            placeholder="Select Client(s)"
            multiSelect
            options={clientComboBoxOptions}
            onChange={onClientComboBoxChange}
            calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
        />
    );

    function onClientComboBoxChange(_event: React.FormEvent<IComboBox>, option?: IComboBoxOption, _index?: number, _value?: string) {
        if (!option) {
            return;
        }

        const selected = option.selected;
        const currentSelectedOptionKeys = selectedClientComboBoxKeys.filter((key) => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === getSelectableOptions(clientComboBoxOptions).length;

        if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
            if (selectAllState) {
                setSelectedClientComboBoxKeys([]);
                setFilterFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        clients: []
                    };
                });
            } else {
                setSelectedClientComboBoxKeys(['selectAll', ...getSelectableOptions(clientComboBoxOptions).map((o) => o.key as string)]);
                setFilterFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        clients: [...getSelectableOptions(clientComboBoxOptions).map((o) => o.key as string)]
                    };
                });
            }
        } else {
            const updatedKeys = selected
                ? [...currentSelectedOptionKeys, option.key as string]
                : currentSelectedOptionKeys.filter((k) => k !== option.key);
            if (updatedKeys.length === getSelectableOptions(clientComboBoxOptions).length) {
                updatedKeys.push('selectAll');
            }
            setSelectedClientComboBoxKeys(updatedKeys);
            setFilterFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    clients: updatedKeys
                };
            });
        }
    }
};
