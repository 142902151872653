import { MessageBar, MessageBarType, Stack, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

import { gapStackTokensLarge, gapStackTokensMedium } from '../styles/FFv2Style';

/**
 * ValidationStatusTracker Component.
 *
 * This component displays the status of various stages in a validation process. Each status includes
 * a message and an indicator showing whether the stage is in progress (loading), completed successfully (success),
 * or failed (error). The statuses are grouped by categories such as "BoardingPass" or "Rollouts" to provide
 * clear feedback on each specific part of the process.
 *
 * @param {object} root0 - The props object.
 * @param {ValidationStatusMap} root0.statusMap - A map containing validation statuses.
 * @returns {JSX.Element} - The component that displays the validation statuses.
 *
 * @example
 * const statusMap = {
 *   BoardingPass: { group: 'BoardingPass', message: 'Validating boarding pass...', status: 'loading' },
 *   Rollouts: { group: 'Rollouts', message: 'Checking for duplicate rollouts...', status: 'loading' },
 * };
 *
 * return <ValidationStatusTracker statusMap={statusMap} />;
 */
const ValidationStatusTracker: React.FC<{ statusMap: ValidationStatusMap }> = ({ statusMap }) => {
    return (
        <Stack tokens={gapStackTokensLarge}>
            {Object.entries(statusMap).map(([group, status]) => (
                <Stack horizontal key={group} verticalAlign="center" tokens={gapStackTokensMedium}>
                    <MessageBar
                        messageBarType={
                            status?.status === 'success'
                                ? MessageBarType.success
                                : status?.status === 'error'
                                  ? MessageBarType.error
                                  : MessageBarType.info
                        }
                        isMultiline={false}
                    >
                        <Stack horizontal>
                            {status?.status === 'loading' && <Spinner size={SpinnerSize.small} style={{ marginRight: 8 }} />}
                            {status?.message}
                        </Stack>
                    </MessageBar>
                </Stack>
            ))}
        </Stack>
    );
};

/**
 * Validation Groups.
 *
 * These constants define the different groups of validation stages that the component tracks.
 */
export const ValidationGroups = {
    BOARDING_PASS: 'BoardingPass',
    ROLLOUTS: 'Rollouts'
} as const;

/**
 * Status Types.
 *
 * These constants define the possible status values for each validation stage.
 */
export const StatusTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading'
} as const;

export type ValidationGroup = (typeof ValidationGroups)[keyof typeof ValidationGroups];
export type StatusType = (typeof StatusTypes)[keyof typeof StatusTypes];

/**
 * ValidationStatusProps Type.
 *
 * This type represents the structure of a validation status object.
 * It includes the group name, status message, status type, and a timestamp to track when the status was updated.
 */
export type ValidationStatusProps = {
    group: ValidationGroup;
    message: string;
    status: StatusType;
};

export type ValidationStatusMap = {
    [key in ValidationGroup]?: ValidationStatusProps;
};

export default ValidationStatusTracker;
