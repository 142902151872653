import { ActionButton, IButtonProps, TeachingBubble } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import React from 'react';

type BasicTeachingBubbleProps = {
    idName: string;
    headline: string;
};

/**
 * Renders a teaching bubble with content.
 *
 * @param props The props for the component.
 * @returns The JSX element representing a teaching bubble.
 */
const BasicTeachingBubble: React.FC<BasicTeachingBubbleProps> = (props) => {
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const buttonId = useId(props.idName);
    const primaryButtonProps: IButtonProps = {
        children: 'Close',
        onClick: toggleTeachingBubbleVisible
    };

    return (
        <>
            <ActionButton id={buttonId} iconProps={{ iconName: 'Info' }} onClick={toggleTeachingBubbleVisible} />

            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${buttonId}`}
                    primaryButtonProps={primaryButtonProps}
                    hasSmallHeadline={true}
                    onDismiss={toggleTeachingBubbleVisible}
                    headline={props.headline}
                    closeButtonAriaLabel="Close"
                ></TeachingBubble>
            )}
        </>
    );
};

export default BasicTeachingBubble;
