import { Modal, IconButton, Stack, MessageBar, Label, TextField, PrimaryButton, MessageBarType, Link } from '@fluentui/react';
import React, { useState } from 'react';

import RingmasterService from '../../../services/ringmaster.service';
import { getADOWorkItemURL } from '../../../utils/adoUtility';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { getUserInfo } from '../../../utils/userUtility';
import {
    cancelIcon,
    formButtonStyles,
    iconButtonStyles,
    modalStyles,
    ringmasterRequestTableCellStyle,
    ringmasterRequestTableStyle
} from '../styles/RingmasterPageStyle';
import { RingmasterRequestDetailsItem, RingmasterRequestStatus } from '../types/Types';

type RequestDetailsModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    updateRefreshKey: () => void;
    selectedItem: RingmasterRequestDetailsItem;
    isRingmasterRequestApprovalGroupMember: boolean;
};

/**
 * Renders the modal for showing details of a ringmaster request.
 *
 * @param props The props for the component.
 * @returns The component to display the modal.
 */
export const RequestDetailsModal: React.FC<RequestDetailsModalProps> = (props) => {
    const ringmasterService = new RingmasterService();

    const [submitErrorMsg, setSubmitErrorMsg] = useState<string>('');
    const [submitNonErrorMsg, setSubmitNonErrorMsg] = useState<string>('');

    function handleDecision(requestStatus: RingmasterRequestStatus) {
        ringmasterService
            .resolveRequest(props.selectedItem.id, requestStatus, getUserInfo())
            .then((response) => {
                appInsightsClient.logEvent({
                    name: 'Ringmaster:RequestStatusDetails:RequestDetailsModal:resolveRequest',
                    properties: { response: response }
                });

                // Handle no error case
                if (!response.error) {
                    setSubmitNonErrorMsg(`Ring change request has been ${response.requestStatus}`);
                } else {
                    setSubmitErrorMsg('An error occurred during form submission. ' + response.error);
                }
            })
            .catch((error) => {
                appInsightsClient.logException(
                    { exception: new Error('Ringmaster:RequestStatusDetails:RequestDetailsModal:resolveRequest') },
                    { message: 'Ring change request resolution (approve/reject) error' }
                );
                setSubmitErrorMsg('An error occurred during form submission. ' + error);
            })
            .finally(() => {
                props.updateRefreshKey();
                props.onDismiss();
            });
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            titleAriaId={props.selectedItem?.id}
            containerClassName={modalStyles.container}
            isBlocking={false}
        >
            <div className={modalStyles.header}>
                <h2>Request Details</h2>
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
            </div>
            <div className={modalStyles.body}>
                <Stack style={ringmasterRequestTableStyle}>
                    <MessageBar>Please review the information for the request</MessageBar>
                    <table>
                        <tbody>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Request ID:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.selectedItem?.userFriendlyRequestId}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Request Type:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.selectedItem?.operation}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>ADO Id:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Link href={getADOWorkItemURL(props.selectedItem?.workItemId)} target="_blank">
                                        {props.selectedItem?.workItemId}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Identity Type:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.selectedItem?.identityType}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Ring:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.selectedItem?.ring}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Request Status:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.selectedItem?.requestStatus}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>id:</Label>
                                </td>
                                <td>
                                    <TextField value={props.selectedItem?.operationId} disabled />
                                </td>
                            </tr>
                            {props.isRingmasterRequestApprovalGroupMember && (
                                <tr>
                                    <td style={ringmasterRequestTableCellStyle}>
                                        <PrimaryButton
                                            text="Approve"
                                            iconProps={{ iconName: 'Checkmark' }}
                                            onClick={() => handleDecision(RingmasterRequestStatus.Approved)}
                                            styles={formButtonStyles}
                                            allowDisabledFocus
                                            disabled={!(props.selectedItem?.requestStatus === 'Pending')}
                                        />
                                    </td>
                                    <td>
                                        <PrimaryButton
                                            text="Reject"
                                            iconProps={{ iconName: '' }}
                                            onClick={() => handleDecision(RingmasterRequestStatus.Rejected)}
                                            styles={formButtonStyles}
                                            allowDisabledFocus
                                            disabled={!(props.selectedItem?.requestStatus === 'Pending')}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Stack>
                {submitErrorMsg && (
                    <MessageBar delayedRender={false} messageBarType={MessageBarType.error} onDismiss={() => setSubmitErrorMsg('')}>
                        {submitErrorMsg}
                    </MessageBar>
                )}

                {submitNonErrorMsg && (
                    <MessageBar delayedRender={false} messageBarType={MessageBarType.info} onDismiss={() => setSubmitNonErrorMsg('')}>
                        {submitNonErrorMsg}
                    </MessageBar>
                )}
            </div>
        </Modal>
    );
};
