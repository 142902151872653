import { REDLINE_BASE_URL } from '../configs/env.config';
import { appInsightsClient } from '../utils/appInsightsUtility';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { Service } from './configs/apiServiceConfig';
import ApiResponseError from './models/error/ApiResponseError';
import { RedlinePenetrationResponse } from './models/RedlinePenetrationResponse';
import { RedlineScenarioReliabilityResponse } from './models/RedlineScenarioReliabilityResponse';

/**
 * Service for Redline.
 */
class RedlineService {
    private apiService: ApiService;
    private redlinePenetrationUri: string;
    private redlineScenarioReliabilityUri: string;

    /**
     * Initializes an instance of RedlineService.
     */
    constructor() {
        this.apiService = ApiManager.getApiService(Service.REDLINE);
        this.redlinePenetrationUri = `${REDLINE_BASE_URL}/api/v2/penetration/cached`;
        this.redlineScenarioReliabilityUri = `${REDLINE_BASE_URL}/api/v2/scenarioReliability/cached`;
    }

    /**
     * Gets the penetration results for the given parameters.
     *
     * @param ring The ring.
     * @param version The build version.
     * @param clientType The client type.
     * @param os The OS.
     * @param environment The environment.
     * @param releasePercentage The release percentage.
     * @returns The penetration results.
     */
    public async getPenetrationResults(
        ring: string,
        version: string,
        clientType: string,
        os: string,
        environment: string,
        releasePercentage: number
    ) {
        const params = new URLSearchParams({
            ring,
            version,
            clientType,
            os,
            environment,
            releasePercentage: releasePercentage.toString()
        });
        const url = `${this.redlinePenetrationUri}?${params.toString()}`;
        try {
            const response = await this.apiService.get<RedlinePenetrationResponse>(url);

            const isPassed = response.isPassed;
            const penetration = isPassed
                ? response.successes[0].evaluatedResult[0].penetration
                : response.failures[0].evaluatedResult[0].penetration;
            const threshold = isPassed
                ? response.successes[0].evaluatedResult[0].threshold
                : response.failures[0].evaluatedResult[0].threshold;

            appInsightsClient.logTrace(
                {
                    message: `Fetched penetration data from RedlineService for url: ${url}`
                },
                {
                    isPassed: isPassed,
                    penetration: penetration,
                    threshold: threshold
                }
            );

            return {
                isPassed: isPassed,
                penetration: penetration,
                threshold: threshold
            };
        } catch (error) {
            throw this.handleApiError(url, error, 'Failed to get penetration results');
        }
    }

    /**
     * Gets the scenario reliability results for the given parameters.
     *
     * @param ring The ring.
     * @param version The build version.
     * @param clientType The client type.
     * @param os The OS.
     * @param environment The environment.
     * @param releasePercentage The release percentage.
     * @returns The scenario reliability results.
     */
    public async getScenarioReliabilityResults(
        ring: string,
        version: string,
        clientType: string,
        os: string,
        environment: string,
        releasePercentage: number
    ) {
        const params = new URLSearchParams({
            ring,
            version,
            clientType,
            os,
            environment,
            releasePercentage: releasePercentage.toString()
        });
        const url = `${this.redlineScenarioReliabilityUri}?${params.toString()}`;
        try {
            const response = await this.apiService.get<RedlineScenarioReliabilityResponse>(url);

            const isPassed = response.scenarioReliability.every((scenario) => scenario.result.isPassed) || false;
            const scenariosPassed = response.scenarioReliability
                .filter((scenario) => scenario.result.isPassed)
                .map((scenario) => scenario.scenarioGroup);
            const scenariosFailed = response.scenarioReliability
                .filter((scenario) => !scenario.result.isPassed)
                .map((scenario) => scenario.scenarioGroup);

            appInsightsClient.logTrace(
                {
                    message: `Fetched scenario reliability data from RedlineService for url: ${url}`
                },
                {
                    isPassed: isPassed,
                    scenariosPassed: scenariosPassed,
                    scenariosFailed: scenariosFailed
                }
            );

            return {
                isPassed: isPassed,
                scenariosPassed: scenariosPassed,
                scenariosFailed: scenariosFailed
            };
        } catch (error: unknown) {
            throw this.handleApiError(url, error, 'Failed to get scenario reliability results');
        }
    }

    /**
     *
     * @param url The url.
     * @param error The error.
     * @param defaultMessage The default message.
     */
    private handleApiError(url: string, error: unknown, defaultMessage: string) {
        let errorMessage = defaultMessage;
        if (error instanceof ApiResponseError) {
            if (error.statusCode === 403) {
                errorMessage = 'Unable to connect to the server. Please try again later.';
            } else if (error.statusCode === 400) {
                errorMessage = `Bad Request: ${url}`;
            } else if (error.errorDetail) {
                errorMessage = error.errorDetail;
            }
        }
        throw new Error(errorMessage);
    }
}

export default RedlineService;
