import { IStackTokens, Link, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, CardHeader } from '../../components/Card';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import CommitPivotView from './CommitPivotView';

/**
 * Displays the commit status report.
 *
 * @returns The main component for the report.
 */
const CommitStatusReport: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const defaultSearchValue = searchParams.get('identifier') ?? '';
    const defaultSelectedTab = searchParams.get('client') ?? 'Windows (T1)';

    const [searchField, setSearchField] = useState<string>(defaultSearchValue);
    const [commitIdentifier, setCommitIdentifier] = useState<string>('');

    const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);

    const [showCommitPivotView, setShowCommitPivotView] = useState<boolean>(false);

    // ========================= Hooks ========================= //
    useEffect(() => {
        setIsSearchDisabled(searchField === '' || (searchField.length !== 40 && (!Number.isInteger(+searchField) || +searchField < 0)));
    }, [searchField]);

    // ========================= Functions ========================= //

    /**
     * Handles the search button click event.
     */
    const onSearchButtonClicked = () => {
        if (isSearchDisabled) return;
        setCommitIdentifier(searchField);
        setShowCommitPivotView(true);
    };

    // ========================= Render ========================= //
    return (
        <Stack tokens={stackTokens}>
            <Card>
                <CardHeader>Commit Status Report</CardHeader>

                <div style={{ paddingBottom: 7 }}>
                    <Text variant="medium">
                        The report is designed to provide users with valuable insights into the progression of their code across various
                        Clouds and Rings.{' '}
                        <i>
                            <Link href="https://aka.ms/CommitTrackingWiki" target="_blank" rel="noreferrer" title="Help page">
                                Help link
                            </Link>
                        </i>
                    </Text>
                </div>

                <Stack horizontal tokens={stackTokens}>
                    <Stack.Item styles={{ root: { width: 400 } }}>
                        <TextField
                            placeholder="Enter commit hash or pull request ID"
                            defaultValue={searchField}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    onSearchButtonClicked();
                                }
                            }}
                            onChange={(_ev, newValue) => setSearchField(newValue ?? '')}
                            onGetErrorMessage={getErrorMessage}
                            validateOnLoad={false}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton text="Search" onClick={onSearchButtonClicked} disabled={isSearchDisabled} />
                    </Stack.Item>
                </Stack>
            </Card>

            {showCommitPivotView && <CommitPivotView commitIdentifier={commitIdentifier} defaultClientTab={defaultSelectedTab} />}
        </Stack>
    );
};

// ========================= Styles ========================= //
const stackTokens: IStackTokens = { childrenGap: 10 };

// ========================= Helper Functions ========================= //

/**
 * Returns the error message for the search field.
 *
 * @param value The value of the search field.
 * @returns The error message.
 */
const getErrorMessage = (value: string): string => {
    return value.length === 40 || (Number.isInteger(+value) && +value > 0)
        ? ''
        : `Invalid commit or pull request ID! Commit SHA should be of 40 characters length or pull request ID should be a number.`;
};

// ========================= Export ========================= //
export default appInsightsClient.withAITracking(CommitStatusReport, 'CommitStatusReport');
