/**
 * API response error.
 */
class ApiResponseError extends Error {
    public statusCode: number;
    public errorDetail: string;

    /**
     * Constructor.
     *
     * @param statusCode Status code.
     * @param errorDetail Error Detail.
     * @returns ApiResponseError.
     */
    constructor(statusCode: number, errorDetail: string) {
        super(errorDetail);

        this.statusCode = statusCode;
        this.errorDetail = errorDetail;
    }
}

export default ApiResponseError;
