import { FontIcon, TooltipHost, assign } from '@fluentui/react';
import React from 'react';
import { WorkItem } from 'skynet-client';

import { Card, CardFooter, CardHeader, ICardStyles } from '../../../../components/Card';
import IconWithBadge from '../../../../components/IconWithBadge/IconWithBadge';
import { trainRingCardStyleMap, trainScheduleCardStyles } from '../../styles/styles';
import { getLongDateTimeString, getShortDateTimeString } from '../../utils';

type TrainScheduleCardProps = {
    trainStatus: string;
    ring: string;
    dueDate: Date;
    ringBlockers: WorkItem[];
    onClick: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * Displays the train schedule card.
 *
 * @param props The props for the component.
 * @returns The train schedule card.
 */
const TrainScheduleCard: React.FC<TrainScheduleCardProps> = (props) => {
    let status = 'Default';
    if (props.trainStatus === 'Abandoned') {
        status = 'Abandoned';
    } else if (props.ringBlockers.length > 0) {
        status = 'Blocked';
    }
    const mergedStyles: ICardStyles = {
        root: assign({}, trainScheduleCardStyles.root, trainRingCardStyleMap[status].root),
        body: assign({}, trainScheduleCardStyles.body, trainRingCardStyleMap[status].body),
        header: assign({}, trainScheduleCardStyles.header, trainRingCardStyleMap[status].header),
        footer: assign({}, trainScheduleCardStyles.footer, trainRingCardStyleMap[status].footer)
    };

    return (
        <Card styles={mergedStyles} onClick={props.onClick}>
            <CardHeader>
                <span style={{ flexGrow: 1 }}></span>
                {status === 'Blocked' && (
                    <TooltipHost content={<span>{props.ringBlockers.length} Ring Blocker(s) </span>}>
                        <IconWithBadge iconName="BugSolid" badgeText={props.ringBlockers.length.toString()} />
                    </TooltipHost>
                )}
            </CardHeader>
            <FontIcon iconName="Calendar" style={{ fontSize: '40px', color: 'lightgrey' }}></FontIcon>
            <CardFooter>
                <span>Expected Arrival: </span>
                <TooltipHost content={getLongDateTimeString(props.dueDate)}>
                    <span style={{ fontWeight: 'bold' }}>{getShortDateTimeString(props.dueDate)}</span>
                </TooltipHost>
            </CardFooter>
        </Card>
    );
};

export { TrainScheduleCard };
