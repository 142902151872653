import { Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import { trainStatusValues } from '../utils';

type TrainFilteringProps = {
    setTrainStatusFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

/**
 * Displays the train filters form.
 *
 * @param props The props for the component.
 * @returns The train filtering (by train status) component.
 */
const TrainFiltering: React.FC<TrainFilteringProps> = (props) => {
    const [selectedTab, setSelectedTab] = useState<string>('All');

    // ===============================================================================================================

    return (
        <Stack horizontal>
            <Pivot onLinkClick={onTabClick} selectedKey={selectedTab} className="train-status-tabs" overflowBehavior="menu">
                <PivotItem
                    itemKey={'All'}
                    headerText={'All'}
                    key={'All'}
                    headerButtonProps={{
                        'data-id': `train-status-pivot`,
                        'data-value': 'All'
                    }}
                ></PivotItem>
                {trainStatusValues.map((tab) => (
                    <PivotItem
                        itemKey={tab}
                        headerText={tab}
                        key={tab}
                        headerButtonProps={{
                            'data-id': `train-status-pivot`,
                            'data-value': tab
                        }}
                    ></PivotItem>
                ))}
            </Pivot>
        </Stack>
    );
    function onTabClick(item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) {
        const value = item?.props.itemKey ?? 'All';
        if (value === 'All') {
            props.setTrainStatusFilter(trainStatusValues);
        } else {
            props.setTrainStatusFilter([value]);
        }
        setSelectedTab(value);
    }
};

export { TrainFiltering };
