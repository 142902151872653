import { Pivot, PivotItem } from '@fluentui/react';
import React, { useState } from 'react';

import { Card } from '../../../components/Card';
import { defaultTab, flightStatusValues } from '../configs/defaults';
import { FlightStatusFilterTabsCardStyles } from '../styles/MyFlightsStyle';
import { FlightStatusFilter, FlightsFiltered } from '../types/Types';

type FlightStatusFilterTabsProps = {
    switchFlightStatusFilter: (filter: FlightStatusFilter) => void;
    flights: FlightsFiltered | undefined;
};

/**

 * Displays the flight filters tabs.
 *
 * @param props The props for the component.
 * @returns The flight filters tabs.
 */
const FlightStatusFilterTabs: React.FC<FlightStatusFilterTabsProps> = (props) => {
    const [selectedTab, setSelectedTab] = useState<string>('all');

    return (
        <Card styles={FlightStatusFilterTabsCardStyles}>
            <Pivot onLinkClick={onTabClick} selectedKey={selectedTab} className="flight-status-tabs" overflowBehavior="menu">
                {flightStatusValues.map((tab) => (
                    <PivotItem
                        itemKey={tab.key}
                        headerText={`${tab.title}${
                            !tab.disabled && props.flights?.[tab.key].length !== undefined ? ` (${props.flights?.[tab.key].length})` : ''
                        }`}
                        key={tab.key}
                        headerButtonProps={{
                            disabled: tab.disabled,
                            style: tab.disabled ? { color: 'grey' } : undefined,
                            'data-id': `flight-status-pivot`,
                            'data-value': tab.title
                        }}
                    />
                ))}
            </Pivot>
        </Card>
    );

    function onTabClick(item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) {
        const value = item?.props.itemKey ?? 'all';
        const selectedFilter = flightStatusValues.find((v) => v.key === value);
        if (selectedFilter) {
            props.switchFlightStatusFilter(selectedFilter);
        } else {
            props.switchFlightStatusFilter(defaultTab);
        }
        setSelectedTab(value);
    }
};

export default FlightStatusFilterTabs;
