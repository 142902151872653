import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../../components/Card';
import { trainHeaderRowStackTokens, trainHeaderRowStackStyles, trainHeaderCardStyles, trainHeaderMainCardStyles } from '../styles/styles';
import { getFormattedCloudRingPairs } from '../utils';

type TrainHeaderRowProps = {
    supportedRings: Record<string, string[]>;
};

/**
 * Displays the train header row.
 *
 * @param props The props for the component.
 * @returns The train header row.
 */
const TrainHeaderRow: React.FC<TrainHeaderRowProps> = (props) => {
    const [columns, setColumns] = useState<string[]>([]);

    useEffect(() => {
        const uniqueValues: string[] = getFormattedCloudRingPairs(props.supportedRings, false, ' ');
        setColumns(columns.concat(uniqueValues));
    }, [props.supportedRings]);

    return (
        <Stack horizontal tokens={trainHeaderRowStackTokens} styles={trainHeaderRowStackStyles}>
            <Card styles={trainHeaderMainCardStyles}>
                <CardHeader>Train</CardHeader>
            </Card>
            {columns.map((columnName) => {
                return (
                    <Card key={columnName} styles={trainHeaderCardStyles}>
                        <CardHeader>{columnName}</CardHeader>
                    </Card>
                );
            })}
        </Stack>
    );
};

export { TrainHeaderRow };
