// ========================= SGs =========================
export const teamsFFv2SpecialUsersAccess = 'Teams FFv2 Special Users Access';
export const teamsEcsContributors = 'TEAMS ECS CONTRIBUTORS';
export const ringmasterRequestAssistanceGroup = 'TEAMS-RINGMASTER-REQUEST-ASSISTANCE-GROUP';
export const ringmasterRequestApprovalGroup = 'TEAMS-RINGMASTER-REQUEST-APPROVAL-GROUP';
export const teamsClientRelease = 'Teams Client Release';

// ========================= Filters =========================

export const displayName = 'displayName';
