class CommonUtils {
    getFormattedDateTime = (date) => {
        if (!date) return '';
        return new Date(date).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short'
        });
    };

    getFormattedDate = (date) => {
        if (!date) return '';
        return new Date(date).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    };

    getFormattedTime = (time) => {
        if (!time) return '';
        return new Date(time).toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZoneName: 'long'
        });
    };
}

export default new CommonUtils();
