import { msalInstance } from '../App';
import { UserInfo } from '../pages/FFv2/types/Types';

/**
 * Gets the username of the currently logged-in user.
 *
 * @returns The username.
 */
export function getUserPrincipalName(): string {
    return msalInstance.getAllAccounts()[0].username;
}

/**
 * Gets the display name of the currently logged-in user.
 *
 * @returns The full name.
 */
export function getUserDisplayName(): string {
    return msalInstance.getAllAccounts()[0].name || '';
}

/**
 * Gets the id of the currently logged-in user.
 *
 * @returns The id.
 */
export function getUserId(): string {
    return msalInstance.getAllAccounts()[0].idTokenClaims?.oid || '';
}

/**
 * Gets the user info of the currently logged-in user.
 *
 * @returns The user info.
 */
export function getUserInfo(): UserInfo {
    return { id: getUserId(), displayName: getUserDisplayName(), principalName: getUserPrincipalName() };
}
