import { getAccessToken } from '../auth/authService';
import { SKYNET_SCOPE } from '../configs/env.config';

class ApiService {
    /**
     *  Headers for all APIs
     */
    getHeadersForApi = async () => {
        const token = await getAccessToken(SKYNET_SCOPE);
        return {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        };
    };

    /**
     *  Headers for json file
     */
    getHeadersForFile = () => {
        return {
            'content-type': 'application/json',
            Accept: 'application/json'
        };
    };

    /**
     *  Function to call json file
     */
    getJsonFile = (fileName) => {
        return fetch(`assets/${fileName}`, {
            headers: this.getHeadersForFile()
        }).then(function (response) {
            return response?.json();
        });
    };

    /**
     *  Function to call APIs for all reports
     */
    getApiData = async (apiUrl) => {
        return fetch(`${apiUrl}`, {
            headers: await this.getHeadersForApi()
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error(`Error in Api call: ${apiUrl}`, response?.json());
                    return;
                }
            })
            .catch((error) => {
                console.error(`Error in Api call: ${apiUrl}`, error);
            });
    };

    /**
     * Function to call APIs for POST
     * @param {*} apiUrl API URL
     * @param {*} data Data to be posted
     * @returns Response
     */
    postDataApi = async (apiUrl, data) => {
        return fetch(`${apiUrl}`, {
            headers: await this.getHeadersForApi(),
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error(`Error in POST Api call: ${apiUrl}`, response);
                    return;
                }
            })
            .catch((error) => {
                console.error(`Error in POST Api call: ${apiUrl}`, error);
            });
    };

    /**
     * Function to call APIs for PUT
     */
    putDataApi = async (apiUrl, data) => {
        return fetch(`${apiUrl}`, {
            headers: await this.getHeadersForApi(),
            method: 'PUT',
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error(`Error in Api call: ${apiUrl}`, response);
                    return;
                }
            })
            .catch((error) => {
                console.error(`Error in Api call: ${apiUrl}`, error);
            });
    };
}

export default ApiService;
