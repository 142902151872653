import { SKYNET_BASE_URL } from '../configs/env.config';
import { transformClientDisplayName } from '../utils/clientUtility';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import ApiResponseError from './models/error/ApiResponseError';
import { BitsReleaseChangesResponse } from './models/RecentChangesReport/BitsReleaseChangesResponse';
import { BuildRolloutChangesResponse } from './models/RecentChangesReport/BuildRolloutChangesResponse';
import { FeatureFlagUpdate } from './models/RecentChangesReport/FeatureFlagUpdate';
import { TransformedChartData } from './models/RecentChangesReport/TransformedChartData';

/**
 * Service for recent changes.
 */
class RecentChangesService {
    private apiService: ApiService;
    private recentChangesBaseUri: string;

    /**
     * Initializes an instance of RecentChangesService.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.recentChangesBaseUri = `${SKYNET_BASE_URL}/api/dashboard/recentChangesReport`;
    }

    /**
     * Gets the bits release changes for the given parameters.
     *
     * @param clientType The client type.
     * @param os The OS.
     * @param environment The environment.
     * @param experience The experience.
     * @param startDate The start date.
     * @param endDate The end date.
     * @returns The bits release changes.
     */
    public async getBitsReleaseChanges(
        clientType: string,
        os: string,
        environment: string,
        experience: string,
        startDate: Date,
        endDate: Date
    ) {
        const startDateString = startDate.toISOString().slice(0, 19);
        const endDateString = endDate.toISOString().slice(0, 19);
        if (clientType === 'Desktop') {
            environment = 'Work';
        }

        const url =
            `${this.recentChangesBaseUri}/getBitsReleaseChanges` +
            `?clientType=${clientType}&os=${os}&environment=${environment}&experience=${experience}&startTime=${startDateString}&endTime=${endDateString}`;

        try {
            const response = await this.apiService.get<BitsReleaseChangesResponse[]>(url);
            return this.transformBitsReleaseUpdates(response);
        } catch (error) {
            let errorMessage = `Failed to get recent bits released changes`;
            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Gets the build rollout changes for the given parameters.
     *
     * @param startDate The start date.
     * @param endDate The end date.
     * @returns The build rollout changes.
     */
    public async getBuildRolloutChanges(startDate: Date, endDate: Date) {
        const startDateString = startDate.toISOString().slice(0, 19);
        const endDateString = endDate.toISOString().slice(0, 19);

        const url = `${this.recentChangesBaseUri}/getBuildRolloutChanges` + `?startTime=${startDateString}&endTime=${endDateString}`;

        try {
            const response = await this.apiService.get<BuildRolloutChangesResponse[]>(url);
            return this.transformBuildRolloutChanges(response);
        } catch (error) {
            let errorMessage = `Failed to get recent build rollout changes`;
            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Gets the feature flag changes for the given parameters.
     *
     * @param clientType The client type.
     * @param os The OS.
     * @param environment The environment.
     * @param experience The experience.
     * @param startDate The start date.
     * @param endDate The end date.
     * @returns The feature flag changes.
     */
    public async getFeatureFlagChanges(
        clientType: string,
        os: string,
        environment: string,
        experience: string,
        startDate: Date,
        endDate: Date
    ) {
        const startDateString = startDate.toISOString().slice(0, 19);
        const endDateString = endDate.toISOString().slice(0, 19);
        if (clientType === 'Desktop') {
            environment = 'Work';
        }

        const url =
            `${this.recentChangesBaseUri}/getFeatureFlagChanges` +
            `?clientType=${clientType}&os=${os}&environment=${environment}&experience=${experience}&startTime=${startDateString}&endTime=${endDateString}`;

        try {
            const response = await this.apiService.get<FeatureFlagUpdate[]>(url);
            return this.transformFeatureFlagUpdates(response);
        } catch (error) {
            let errorMessage = `Failed to get recent feature flag changes`;
            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Transforms the bits release updates into a dictionary. The dictionary is keyed by cloud_ring, and then at the next level by client.
     *
     * @param updates The bits release updates.
     * @returns The transformed bits release updates.
     */
    private transformBitsReleaseUpdates(updates: BitsReleaseChangesResponse[]) {
        const dataDict: Record<string, Record<string, TransformedChartData[]>> = {};

        for (const item of updates) {
            const cloudRing = `${item.cloud}_${item.ring}`.toLowerCase();
            const itemClient = transformClientDisplayName(item.client);
            if (dataDict[cloudRing] === undefined) {
                dataDict[cloudRing] = {};
            }
            if (dataDict[cloudRing][itemClient] === undefined) {
                dataDict[cloudRing][itemClient] = [];
            }
            dataDict[cloudRing][itemClient].push({
                x: new Date(item.releaseTime),
                y: 3,
                metadata: {
                    buildVersion: item.buildVersion,
                    stageName: item.stageName,
                    link: item.buildLink
                }
            });
        }
        return dataDict;
    }

    /**
     * Transforms the build rollout changes into a dictionary. The dictionary is keyed by cloud_ring, and then at the next level by client.
     *
     * @param updates The build rollout changes.
     * @returns The transformed build rollout changes.
     */
    private transformBuildRolloutChanges(updates: BuildRolloutChangesResponse[]) {
        const dataDict: Record<string, Record<string, TransformedChartData[]>> = {};

        for (const item of updates) {
            const cloudRing = `${item.cloud}_${item.ring}`.toLowerCase();
            const itemClient = transformClientDisplayName(item.client);
            if (dataDict[cloudRing] === undefined) {
                dataDict[cloudRing] = {};
            }
            if (dataDict[cloudRing][itemClient] === undefined) {
                dataDict[cloudRing][itemClient] = [];
            }
            dataDict[cloudRing][itemClient].push({
                x: new Date(item.iterationCreateTime),
                y: 2,
                metadata: {
                    buildVersion: item.buildVersion,
                    allocationPercentage: item.allocationPercentage,
                    link: item.link
                }
            });
        }
        return dataDict;
    }

    /**
     * Transforms the feature flag updates into a dictionary. The dictionary is keyed by cloud_ring, and then at the next level by client.
     *
     * @param featureFlagUpdates The feature flag updates.
     * @returns The transformed feature flag updates.
     */
    private transformFeatureFlagUpdates(featureFlagUpdates: FeatureFlagUpdate[]) {
        const dataDict: Record<string, Record<string, TransformedChartData[]>> = {};

        for (const item of featureFlagUpdates) {
            const cloudRing = `${item.cloud}_${item.ring}`.toLowerCase();
            const itemClient = transformClientDisplayName(item.client);
            if (dataDict[cloudRing] === undefined) {
                dataDict[cloudRing] = {};
            }
            if (dataDict[cloudRing][itemClient] === undefined) {
                dataDict[cloudRing][itemClient] = [];
            }
            dataDict[cloudRing][itemClient].push({
                x: new Date(item.iterationCreateTime),
                y: 1,
                metadata: {
                    configurationGroupName: item.configurationGroupName,
                    configurationName: item.configurationName,
                    allocationPercentage: item.allocationPercentage,
                    featureFlags: item.featureFlags,
                    link: item.link
                }
            });
        }
        return dataDict;
    }
}

export default RecentChangesService;
