import { Ring } from '../../../services/models/common/ring';
import { FlightStatusFilter, InitiateFlightState, StartFlightFormData, UserInfo } from '../types/Types';

export const platformsMap = {
    webT2Platform: 1415,
    desktopT20Platform: 48,
    desktopT21Platform: 49,
    desktopMacT21Platform: 50
};

export const platformNames = {
    1415: 'Web',
    48: 'Maglev',
    49: 'Maglev',
    50: 'Maglev Mac'
};

export const audienceNames = {
    consumer: 'consumer',
    enterprise: 'enterprise'
};

export const audienceShortNames = {
    [audienceNames.enterprise]: 'Ent',
    [audienceNames.consumer]: 'Cons'
};

export const audiencePlatformsMap = {
    [audienceNames.consumer]: [1415, 48],
    [audienceNames.enterprise]: [1415, 49, 50]
};

export const skypeAClientPlatform = 'Skype.A.ClientPlatform';
export const osPlatform = 'OsPlatform';
export const desktopVersion = 'DesktopVersion';
export const experience = 'Experience';
export const experienceBuild = 'ExperienceBuild';
export const desktopTCNS = 'desktop-tcns';
export const webRWC = 'web-rwc';
export const desktopTCNSDisplayName = 'Desktop - TCNS';
export const webRWCDisplayName = 'Web - RWC';

export const constantFilters = [skypeAClientPlatform, experience, osPlatform];
export const arrayTypeFilters = [osPlatform, 'UserID', 'TenantID', 'Virtualization', 'BuildType', 'SkuID'];
export const GUIDTypeFilters = ['UserID', 'TenantID', 'SkuID'];

export const safeChangeManagementWikiLink = 'https://aka.ms/teams/goslow';
export const featureBlockerWorkflowWikiLink =
    'https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/41987/Feature-Blocker-Workflow';
export const teamsChannelLink =
    'https://teams.microsoft.com/l/channel/19%3Askypespaces_60599f608e5542939a1fe4932dea4326%40thread.skype/Build%2C%20Quality%2C%20Testing%20and%20Release%20%5BBQTR%5D?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47';

export const defaultStartFlightFormData: StartFlightFormData = {
    rolloutName: '',
    featureFlags: {},
    controlConfigs: {},
    audience: '',
    flightType: 'standard',
    boardingPassId: 0,
    filters: { [skypeAClientPlatform]: [] },
    createdBy: {} as UserInfo,
    client: desktopTCNS
};

export const flightStatusValues: FlightStatusFilter[] = [
    {
        title: 'In Progress',
        key: 'inProgress',
        disabled: false
    },
    {
        title: 'Pending Approval',
        key: 'pendingApproval',
        disabled: false
    },
    {
        title: 'Pending Request',
        key: 'pendingRequest',
        disabled: false
    },
    {
        title: 'Ready To Burn In',
        key: 'readyToBurnIn',
        disabled: false
    },
    {
        title: 'Completed in Last 90 Days',
        key: 'recentlyCompleted',
        disabled: false
    },
    {
        title: 'Retirement Ready',
        key: 'retirementReady',
        disabled: true
    },
    {
        title: 'Cancelled',
        key: 'cancelled',
        disabled: false
    }
];

// Default tab = In Progress
export const defaultTab = flightStatusValues[0];

export const FeatureFlightRings: Ring[] = [
    {
        key: 'prod_ring0',
        text: 'Ring 0',
        cloud: 'Prod',
        ring: 'ring0'
    },
    {
        key: 'prod_ring1',
        text: 'Ring 1',
        cloud: 'Prod',
        ring: 'ring1'
    },
    {
        key: 'prod_ring2',
        text: 'Ring 2',
        cloud: 'Prod',
        ring: 'ring2'
    },
    {
        key: 'prod_ring3',
        text: 'Ring 3',
        cloud: 'Prod',
        ring: 'ring3'
    },
    {
        key: 'prod_ring4',
        text: 'Ring 4',
        cloud: 'Prod',
        ring: 'ring4'
    }
];

export const feedbackOptions = [
    { key: 'Bug', text: 'Report a bug' },
    { key: 'Requirement', text: 'Suggest enhancements or new features' }
];

export const defaultInitiateFlightState: InitiateFlightState = {
    audience: '',
    loading: false,
    results: [],
    error: null
};

export const tutorialStepsByGroup = {
    myFlights: [0, 4],
    startFlightForm: [0, 2]
};

export const defaultEmptyFilters = {
    status: [],
    creatorIds: [],
    stages: [],
    audiences: [],
    searchKey: '',
    showOnlyBlocked: false,
    showOnlyMyFlights: true,
    startDate: undefined,
    endDate: undefined
};

export const inProgressStatus = ['Queued', 'Running', 'Paused', 'Pending Approval'];
