import { SKYNET_BASE_URL } from '../configs/env.config';
import { transformClientDisplayName } from '../utils/clientUtility';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { ClientRepoMap } from './models/ClientRepoMap';
import { CommitProgressionDetails } from './models/CommitProgressionDetails';
import ApiResponseError from './models/error/ApiResponseError';

/**
 * Service for commit tracking report.
 */
class CommitTrackingService {
    private apiService: ApiService;
    private commitBaseUrl: string;

    /**
     * Initializes an instance of the CommitTrackingService class.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.commitBaseUrl = `${SKYNET_BASE_URL}/api/commit`;
    }

    /**
     * Get commit progression details for a given commitId, clientType, os, environment and experience.
     *
     * @param commitId The commitId.
     * @param clientType The clientType.
     * @param os The os.
     * @param environment The environment.
     * @param experience The experience.
     * @returns CommitProgressionDetails.
     */
    async getCommitProgression(
        commitId: string,
        clientType: string,
        os: string,
        environment: string,
        experience: string
    ): Promise<CommitProgressionDetails> {
        const url = `${this.commitBaseUrl}/currentStatus?commitId=${commitId}&clientType=${clientType}&os=${os}&environment=${environment}&experience=${experience}&includeDetails=true`;

        try {
            const details = await this.apiService.get<CommitProgressionDetails>(url);
            return details;
        } catch (error) {
            let errorMessage = 'Failed to get commit progression details for given commitId';

            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }

            throw new Error(errorMessage);
        }
    }

    /**
     * Get commit progression details for a given commit or pull request identifier, clientType, os, environment and experience.
     *
     * @param identifier The commit or pull request identifier.
     * @param clientType The clientType.
     * @param os The os.
     * @param environment The environment.
     * @param experience The experience.
     * @returns CommitProgressionDetails.
     */
    async getCommitProgressionForIdentifier(
        identifier: string,
        clientType: string,
        os: string,
        environment: string,
        experience: string
    ): Promise<CommitProgressionDetails> {
        const url = `${this.commitBaseUrl}/currentStatusForIdentifier?identifier=${identifier}&clientType=${clientType}&os=${os}&environment=${environment}&experience=${experience}&includeDetails=true`;

        try {
            const details = await this.apiService.get<CommitProgressionDetails>(url);
            return details;
        } catch (error) {
            let errorMessage = 'Failed to get commit progression details for given commit/pull request identifier.';

            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }

            throw new Error(errorMessage);
        }
    }

    /**
     * Get available client options for a given commitId.
     *
     * @param identifier The commit or pull request identifier.
     * @returns List of ClientRepoMap.
     */
    async getAvailableClientOptions(identifier: string): Promise<ClientRepoMap[]> {
        const url = `${this.commitBaseUrl}/clientOptions/${identifier}`;

        try {
            const clientRepoMapList = await this.apiService.get<ClientRepoMap[]>(url);
            const transformedClientRepoMapList = clientRepoMapList.map((item) => ({
                ...item,
                client: transformClientDisplayName(item.client)
            }));
            return transformedClientRepoMapList;
        } catch (error) {
            let errorMessage = 'Failed to get commit progression details for given commitId';

            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }

            throw new Error(errorMessage);
        }
    }
}

export default CommitTrackingService;
