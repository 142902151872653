import { FontIcon, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../../../components/Card';
import redlineConfig from '../../../../configs/RedlineScorecard/redline.json';
import { ProcessedTrainMetadata } from '../../../../services/models/TrainMetadata';
import RedlineService from '../../../../services/redline.service';
import { appInsightsClient } from '../../../../utils/appInsightsUtility';
import { badgeLoadingStyles, errorBadgeStyles, successBadgeStyles } from '../../styles/styles';
import { LatestProdTrainRingProps } from '../../types/LatestProdTrainRingProps';

interface RedlineScorecardProps {
    trainData: ProcessedTrainMetadata;
    ring: string;
    latestProdRingPropsForTrain?: LatestProdTrainRingProps;
}

/**
 * This component displays the Redline Scorecard.
 *
 * @param props The props for the component.
 * @returns The Redline Scorecard.
 */
const RedlineScorecard: React.FC<RedlineScorecardProps> = (props) => {
    const [isRedlineEnabled, setIsRedlineEnabled] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [penetrationValue, setPenetrationValue] = useState<number>();
    const [thresholdValue, setThresholdValue] = useState<number>();
    const [penetrationPassed, setPenetrationPassed] = useState<boolean>();

    const [scenariosPassed, setScenariosPassed] = useState<string[]>();
    const [scenariosFailed, setScenariosFailed] = useState<string[]>();
    const [allScenariosPassed, setAllScenariosPassed] = useState<boolean>();

    const fetchRedlinePenetrationData = () => {
        const redlineService = new RedlineService();
        let environment = props.trainData.environment;
        if (props.trainData.clientType === 'Desktop') {
            environment = 'Work';
        }
        if (
            props.latestProdRingPropsForTrain &&
            props.latestProdRingPropsForTrain.buildVersion &&
            props.latestProdRingPropsForTrain.percentage &&
            props.trainData.clientType &&
            props.trainData.os &&
            environment
        ) {
            redlineService
                .getPenetrationResults(
                    props.ring.split('_')[1].toLocaleLowerCase(),
                    props.latestProdRingPropsForTrain?.buildVersion,
                    props.trainData.clientType,
                    props.trainData.os,
                    environment,
                    props.latestProdRingPropsForTrain.percentage
                )
                .then((response) => {
                    setLoadingError(false);
                    setPenetrationValue(response.penetration);
                    setThresholdValue(response.threshold);
                    setPenetrationPassed(response.isPassed);
                })
                .catch((error) => {
                    appInsightsClient.logException(
                        { exception: error },
                        { message: 'catch error in processing fetchRedlinePenetrationData' }
                    );
                    setLoadingError(true);
                });
        }
    };

    const fetchRedlineScenarioReliabilityData = () => {
        const redlineService = new RedlineService();
        let environment = props.trainData.environment;
        if (props.trainData.clientType === 'Desktop') {
            environment = 'Work';
        }
        if (
            props.latestProdRingPropsForTrain &&
            props.latestProdRingPropsForTrain.buildVersion &&
            props.latestProdRingPropsForTrain.percentage &&
            props.trainData.clientType &&
            props.trainData.os &&
            environment
        ) {
            redlineService
                .getScenarioReliabilityResults(
                    props.ring.split('_')[1].toLocaleLowerCase(),
                    props.latestProdRingPropsForTrain.buildVersion,
                    props.trainData.clientType,
                    props.trainData.os,
                    environment,
                    props.latestProdRingPropsForTrain.percentage
                )
                .then((response) => {
                    setLoadingError(false);
                    setScenariosPassed(response.scenariosPassed);
                    setScenariosFailed(response.scenariosFailed);
                    setAllScenariosPassed(response.isPassed);
                })
                .catch((error) => {
                    appInsightsClient.logException(
                        { exception: error },
                        { message: 'catch error in processing fetchRedlineScenarioReliabilityData' }
                    );
                    setLoadingError(true);
                });
        }
    };

    useEffect(() => {
        if (penetrationPassed && scenariosPassed === undefined) {
            fetchRedlineScenarioReliabilityData();
        }
    }, [penetrationPassed]);

    useEffect(() => {
        if (isRedlineEnabled) {
            fetchRedlinePenetrationData();
        }
    }, [isRedlineEnabled]);

    useEffect(() => {
        if (
            props.latestProdRingPropsForTrain &&
            props.ring === props.latestProdRingPropsForTrain.ring &&
            props.trainData.clientType &&
            props.trainData.os &&
            props.trainData.environment &&
            hasRedlineEnabled(props.trainData.clientType, props.trainData.environment, props.trainData.os, props.ring) &&
            props.trainData.status === 'Active'
        ) {
            setIsRedlineEnabled(true);
        }
    }, []);

    if (isRedlineEnabled) {
        return (
            <Card>
                <CardHeader>Redline Scorecard</CardHeader>
                <Separator />
                <div>
                    {penetrationPassed !== undefined ? (
                        <div style={penetrationPassed ? successBadgeStyles : errorBadgeStyles}>
                            <FontIcon iconName="FinancialSolid" style={{ paddingRight: '4px' }} /> {penetrationValue?.toFixed(4)}/
                            {thresholdValue?.toFixed(4)}
                        </div>
                    ) : loadingError ? (
                        <div style={errorBadgeStyles}>
                            <span>Error while fetching data</span>
                        </div>
                    ) : (
                        <div style={badgeLoadingStyles}>
                            <Spinner size={SpinnerSize.xSmall} style={{ marginRight: '5px' }} />
                            <span> Fetching penetration data... </span>
                        </div>
                    )}
                    {penetrationPassed !== undefined &&
                        penetrationPassed &&
                        (allScenariosPassed !== undefined ? (
                            <div style={scenariosFailed && scenariosFailed.length > 0 ? errorBadgeStyles : successBadgeStyles}>
                                <FontIcon iconName="TestExploreSolid" style={{ paddingRight: '6px', verticalAlign: 'middle' }} />
                                {scenariosPassed?.length} &#x2714;/{scenariosFailed?.length} &#10006;
                            </div>
                        ) : loadingError ? (
                            <div style={errorBadgeStyles}>
                                <span>Error while fetching data</span>
                            </div>
                        ) : (
                            <div style={badgeLoadingStyles}>
                                <Spinner size={SpinnerSize.xSmall} style={{ marginRight: '5px' }} />
                                <span>Fetching scenarios data...</span>
                            </div>
                        ))}
                    {penetrationPassed !== undefined && (
                        <div>
                            <table style={{ minWidth: '700px', maxWidth: '700px', padding: '0.3rem', verticalAlign: 'top' }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '0.3rem' }}>Penetration</th>
                                        <th style={{ padding: '0.3rem' }}>Threshold</th>
                                        {scenariosPassed && (
                                            <th style={{ padding: '0.3rem' }}>Passed Scenarios ({scenariosPassed.length})</th>
                                        )}
                                        {scenariosFailed && (
                                            <th style={{ padding: '0.3rem' }}>Failed Scenarios ({scenariosFailed.length})</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top', padding: '0.3rem' }}>{penetrationValue?.toFixed(4)}</td>
                                        <td style={{ verticalAlign: 'top', padding: '0.3rem' }}>{thresholdValue?.toFixed(4)}</td>
                                        {scenariosPassed && (
                                            <td style={{ verticalAlign: 'top', padding: '0.3rem' }}>
                                                {scenariosPassed && scenariosPassed.join(', ')}
                                            </td>
                                        )}
                                        {scenariosFailed && (
                                            <td style={{ verticalAlign: 'top', padding: '0.3rem' }}>
                                                {scenariosFailed && scenariosFailed.join(', ')}
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </Card>
        );
    } else {
        return null;
    }
};

function hasRedlineEnabled(clientType: string, environment: string, os: string, cloudRing: string): boolean {
    const matchingConfigEntry = redlineConfig.find(
        (entry) => entry.clientType === clientType && entry.environment === environment && entry.os === os
    );

    const [cloud, ring] = cloudRing.split('_');
    const matchingMetadataEntry = matchingConfigEntry?.redlineStageMetadata.find((entry) => entry.cloud === cloud && entry.ring === ring);

    return matchingMetadataEntry !== undefined;
}

export { RedlineScorecard };
