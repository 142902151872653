import {
    IconButton,
    Dropdown,
    IDropdownOption,
    Modal,
    PrimaryButton,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    TextField,
    Stack
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { gapStackTokensMedium, gapStackTokensSmall, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { formStyles, iconButtonStyles } from '../styles/MyFlightsStyle';
import { ADOWorkItem, ProcessedFlightRollout, GeneralExceptionFormData } from '../types/Types';

type GeneralExceptionFormProps = {
    isOpen: boolean;
    onDismiss: () => void;
    flight: ProcessedFlightRollout | undefined;
    updateFlight: (flightId: string) => void;
};

const exceptionTypeOptions: IDropdownOption[] = [
    { key: 'Accessibility', text: 'Accessibility' },
    { key: 'Custom Feature Rollout %', text: 'Custom Feature Rollout %' },
    { key: 'Experimentation Opt Out', text: 'Experimentation Opt Out' },
    { key: 'Privacy', text: 'Privacy' },
    { key: 'Security', text: 'Security' },
    { key: 'Temporary Special Clouds Opt Out', text: 'Temporary Special Clouds Opt Out' },
    { key: 'Permanent Special Clouds Opt Out', text: 'Permanent Special Clouds Opt Out' }
];

/**
 * Displays the form to fill for a general exception.
 *
 * @param props The props for the component.
 * @returns The general exception form.
 */
const GeneralExceptionForm: React.FC<GeneralExceptionFormProps> = (props) => {
    const { flight: flight, isOpen } = props;
    const [formData, setFormData] = useState(getDefaultFormData());
    const [exceptionResult, setExceptionResult] = useState<ADOWorkItem | Error | 'loading' | undefined>();

    const titleId = useId('title');
    const cancelIcon = { iconName: 'Cancel' };

    const isSubmitEnabled = formData.title && formData.description && formData.exceptionType;

    const handleDismiss = () => {
        setFormData(getDefaultFormData());
        props.onDismiss();
    };

    return (
        <>
            {exceptionResult &&
                exceptionResult !== 'loading' &&
                ('message' in exceptionResult ? (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        onDismiss={() => {
                            setExceptionResult(undefined);
                            setFormData({ ...formData, title: '', description: '', exceptionType: '' });
                        }}
                    >
                        Exception request failed: {exceptionResult.message}
                    </MessageBar>
                ) : (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        onDismiss={() => {
                            setExceptionResult(undefined);
                            setFormData({ ...formData, title: '', description: '', exceptionType: '' });
                        }}
                    >
                        Exception request for flight {exceptionResult.id} created successfully.
                    </MessageBar>
                ))}
            {exceptionResult === 'loading' && <ProgressIndicator label="Submitting request..." styles={progressIndicatorCustomStyles} />}
            <Modal titleAriaId={titleId} isOpen={isOpen} onDismiss={handleDismiss} isBlocking={false}>
                <div className={formStyles.container}>
                    <div className={formStyles.header}>
                        <span id={titleId}>New Exception Request</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={handleDismiss}
                        />
                    </div>
                    <div className={formStyles.body}>
                        <Stack tokens={gapStackTokensMedium}>
                            <form>
                                <Stack tokens={gapStackTokensSmall}>
                                    <Stack.Item>
                                        <TextField
                                            label="Title"
                                            prefix={`Flight ${flight?.id} `}
                                            value={formData.title}
                                            onChange={(e, newValue) => {
                                                setFormData({ ...formData, title: newValue || '' });
                                            }}
                                            required
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Description"
                                            value={formData.description}
                                            onChange={(e, newValue) => {
                                                setFormData({ ...formData, description: newValue || '' });
                                            }}
                                            required
                                            multiline
                                            rows={8}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Dropdown
                                            label="Exception Type"
                                            selectedKey={formData.exceptionType}
                                            onChange={(e, option) => {
                                                setFormData({ ...formData, exceptionType: option?.key as string });
                                            }}
                                            options={exceptionTypeOptions}
                                            required
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <br />
                                        <PrimaryButton text="Submit" onClick={onSubmit} allowDisabledFocus disabled={!isSubmitEnabled}>
                                            Submit
                                        </PrimaryButton>
                                    </Stack.Item>
                                </Stack>
                            </form>
                        </Stack>
                    </div>
                </div>
            </Modal>
        </>
    );

    function onSubmit() {
        if (!flight) return;

        appInsightsClient.logEvent({ name: 'GenericExceptionFormSubmit', properties: { response: 'good' } });
        setExceptionResult('loading');
        props.onDismiss();

        const adoTaskData = {
            title: formData.title,
            description: formData.description,
            exceptionType: formData.exceptionType
        };

        new FeatureFlightService()
            .createAdoExceptionTask(flight.id, adoTaskData)
            .then((response) => {
                setExceptionResult(response);
                appInsightsClient.logEvent({ name: 'GenericExceptionFormSubmit', properties: { response: response } });
                props.updateFlight(flight.id);
            })
            .catch((fail) => {
                setExceptionResult(fail);
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'caught error in processing ADO exception task in GenericExceptionForm' }
                );
            });
        setFormData(getDefaultFormData());
    }

    function getDefaultFormData(): GeneralExceptionFormData {
        return {
            title: '',
            description: '',
            exceptionType: ''
        };
    }
};

export default GeneralExceptionForm;
