import { Spinner, SpinnerSize, IColumn } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import ExperienceReportService from '../../services/experience.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import ExperienceMetadataDtoTransformer from './ExperienceMetadataDtoTransformer';
import ExperiencesDetailsList from './ExperiencesDetailsList';
import { IEnvironmentProps } from './models/EnvironmentProps';
import { ExperiencesDataItemType } from './models/ExperiencesDataItemType';

/**
 * Displays experiences view.
 *
 * @param props The props for the component.
 * @returns The component for the experience data table view.
 */
const ExperienceView: React.FC<IEnvironmentProps> = (props) => {
    const [noDataFlag, setNoDataFlag] = useState<boolean>(false);
    const [loaderFlag, setLoaderFlag] = useState<boolean>(false);
    const [experiencesMetadata, setExperiencesMetadata] = useState<ExperiencesDataItemType[]>();
    const [columns, setColumns] = useState<IColumn[]>();

    // ===============================================================================================

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoaderFlag(true);
        setNoDataFlag(false);

        new ExperienceReportService()
            .getExperiencesMetadata(props.environment)
            .then((response) => {
                appInsightsClient.logTrace({ message: 'getExperiencesMetadata response: ' + JSON.stringify(response) });
                if (!Array.isArray(response)) {
                    console.error('Error in fetching data');
                    setLoaderFlag(false);
                    setNoDataFlag(true);
                    throw new Error('Error in fetching data. Expect array, but receive: ' + typeof response);
                } else {
                    const { columnData, contentData } = ExperienceMetadataDtoTransformer(response);
                    setColumns(columnData);
                    setExperiencesMetadata(contentData);
                    setLoaderFlag(false);
                    setNoDataFlag(false);
                }
            })
            .catch((error) => {
                appInsightsClient.logException({ exception: error }, { message: 'catch error in processing getExperiencesMetadata' });
                setLoaderFlag(false);
                setNoDataFlag(true);
            });
        return () => {
            setExperiencesMetadata([]);
            setColumns([]);
        };
    }, [props.environment]);

    // ===============================================================================================

    return (
        <>
            {noDataFlag && <ErrorNotification msg="Failed to get Experience metadata. Please ensure that the server works properly." />}
            {loaderFlag && <Spinner size={SpinnerSize.large} label="Fetching Experiences metadata data..." />}

            {!noDataFlag && !loaderFlag && !!experiencesMetadata && !!columns && (
                <ExperiencesDetailsList dataTable={experiencesMetadata} columns={columns} />
            )}
        </>
    );
};

export default ExperienceView;
