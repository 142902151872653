import { Icon, Label } from '@fluentui/react';
import React from 'react';

type IconWithBadgeProps = {
    iconName: string;
    badgeText: string;
};

/**
 * Component to display an icon with a badge.
 *
 * @param props The props.
 * @returns The icon with badge component.
 */
const IconWithBadge: React.FC<IconWithBadgeProps> = (props) => {
    return (
        <div style={{ display: 'inline-block', position: 'relative' }}>
            <Icon iconName={props.iconName} styles={{ root: { fontSize: '1rem' } }} />
            <Label
                style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-8px',
                    minWidth: '16px',
                    height: '16px',
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '10px',
                    fontWeight: 'bold'
                }}
            >
                {props.badgeText}
            </Label>
        </div>
    );
};

export default IconWithBadge;
