import { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { DirectLineActivity } from 'botframework-webchat-core';

import { AppDispatch } from '../../../redux/store/configureStore';

/**
 * Middleware to handle incoming activities from the bot.
 *
 * @param _mainStoreDispatch The dispatch function of the main store.
 * @returns The middleware function.
 */
export const createWebChatFrameworkMiddleware = (_mainStoreDispatch: AppDispatch) => {
    const midd: Middleware = () => (next) => (action) => {
        const directLineAction = action as PayloadAction<DirectLineActivity>;

        // TODO :: Add the logic to send activity to Bot UI inteface after connecting to the bot
        // if (directLineAction.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        //     dispatch({
        //         type: 'WEB_CHAT/SET_SEND_BOX',
        //         payload: { text: 'Hi' }
        //     });
        // }

        // TODO :: Add the logic to handle incoming activities from the bot and send them to the main store
        if (directLineAction.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            console.log('Incoming activity', directLineAction.payload);

            if (directLineAction.payload.activity.from.role === 'bot') {
                console.log('Content from bot', directLineAction.payload.activity);

                // mainStoreDispatch(setName(directLineAction.payload.activity.text));
                // Send Activities from bot to the main store to take action
            }
        }

        return next(action);
    };

    return midd;
};
