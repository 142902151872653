import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React, { useMemo, useState } from 'react';

type FlightStageFilterComboBoxProps = {
    selectedKeys: string[];
    setSelectedKeys: (creatorIds: string[]) => void;
    flightStages: string[];
};

/**

 * Displays the flight filter by filter combobox.
 *
 * @param props The props for the component.
 * @returns The flight filter by filter combobox.
 */
const FlightStageFilterComboBox: React.FC<FlightStageFilterComboBoxProps> = (props) => {
    const { selectedKeys, setSelectedKeys } = props;

    const options = useMemo<IComboBoxOption[]>(
        () => [
            { key: 'selectAll', text: 'Select All', itemType: SelectableOptionMenuItemType.SelectAll },
            ...props.flightStages.map((stage) => ({ key: stage, text: stage, itemType: SelectableOptionMenuItemType.Normal }))
        ],
        [props.flightStages]
    );

    const selectableOptions = useMemo(
        () =>
            options.filter(
                (option) => (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled
            ),
        [props]
    );
    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeys.filter((key) => key !== 'selectAll');

        if (option) {
            if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
                setSelectedKeys(selectedKeys.filter((key) => key !== 'selectAll'));
                if (selectedAll) {
                    setSelectedKeys([]);
                    setSelectedAll(false);
                } else {
                    setSelectedKeys(['selectAll', ...selectableOptions.map((o) => o.key as string)]);
                    setSelectedAll(true);
                }
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option?.key as string]
                    : currentSelectedOptionKeys.filter((k) => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                setSelectedKeys(updatedKeys);
            }
        }
    };

    return (
        <ComboBox
            placeholder="Stage"
            multiSelect
            options={options}
            selectedKey={selectedKeys}
            onChange={onChange}
            allowFreeInput
            autoComplete="on"
        />
    );
};

export default FlightStageFilterComboBox;
