import { FontWeights, IButtonStyles, ITextFieldStyles, getTheme, mergeStyleSets } from '@fluentui/react';

import { ICardStyles } from '../../../components/Card';

const theme = getTheme();

export const filterComboboxStyles = { root: { maxWidth: 150 } };

export const searchStyles: Partial<ITextFieldStyles> = {
    root: { minWidth: '270px', paddingLeft: '0px' }
};

export const cancelIcon = { iconName: 'Cancel' };

export const filterStyles = { root: { width: '110px' } };

export const FlightStatusFilterTabsCardStyles: Partial<ICardStyles> = {
    root: {
        paddingTop: '0.01rem',
        paddingBottom: '0.01rem'
    }
};

export const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: '4px',
        marginRight: '4px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

export const formStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    header: [
        theme.fonts.large,
        {
            borderBottom: `0.07rem solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            justifyContent: 'space-between',
            padding: '4px 12px 4px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '5px 24px 24px 24px',
        overflowY: 'hidden',
        maxWidth: '600px'
    }
});

export const filterStackStyles = { root: { paddingLeft: 24, paddingRight: 14 } };
