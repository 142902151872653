import { DetailsList, DetailsListLayoutMode, SelectionMode, Text, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { SKYNET_BASE_URL } from '../../configs/env.config';

import { appInsightsClient } from '../../utils/appInsightsUtility';
import { useId } from '@fluentui/react-hooks';
import homeConstant from '../../constants/home.constants';
import CardWrapper from '../../components/CardWrapper/CardWrapper';
import ApiService from '../../services/api.service';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';

/**
 * This component is to provide implementation for Home Page
 * This is the landing page of the application
 * @returns JSX (The actual view)
 */
const Home = (props) => {
    const apiService = new ApiService();

    const tooltipProps =  {
        calloutProps: {
            styles: {
                beak: { background: 'black' },
                beakCurtain: { background: 'black' },
                calloutMain: { background: 'black' }
            }
        },
        styles: {
            content: { color: 'white' }
        }
    };

    /**
     * State Variable to store the actual data of Home Page Table Data
     */
    const [data, setData] = useState({ items: [], columns: [] });
    const [noDataFlag, setNoDataFlag] = useState(false);
    const [loaderFlag, setLoaderFlag] = useState(true);

    const tooltipId = useId('tooltip');
    /**
     *  To fetch data for Home Page table.
     */
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoaderFlag(true);
        setNoDataFlag(false);

        apiService.getJsonFile('home.json').then(function (myJson) {
            setData((prevState) => ({ ...prevState, columns: myJson.columns }));
            apiService.getApiData(`${SKYNET_BASE_URL}/api/release/generalRingReleases`).then(
                function (res) {

                    if (res === undefined || res == null) {
                        setNoDataFlag(true);
                        setLoaderFlag(false);
                        return;
                    }

                    let items = [...myJson.items];
                    items.forEach((item, idx) => {
                        Object.entries(res).forEach(([key, value]) => {
                            let percent = value[idx]?.rolloutMetadata?.percent;
                            let trainName = value[idx]?.trainMetadata?.title;
                            let version = value[idx]?.buildVersion;

                            if (trainName !== null && version !== null) {
                                items[idx][key] = `${trainName} \n\n${version}${percent ? ` (${percent}%)` : ''}`;
                            }
                        });
                    });

                    setData((prevState) => ({ ...prevState, items: items }));
                    setLoaderFlag(false);
                },
                (fail) => {
                    appInsightsClient.logException(
                        { exception: fail },
                        { message: 'catch error in processing generalRingReleases in Home/index.js' }
                    );
                    setNoDataFlag(true);
                    setLoaderFlag(false);
                }
            );
        });
    }, [props]);

    const _renderItemColumn = (item, index, column) => {
        let value = item[column.fieldName];
        let hoverTitleText = '';
        if (item.name === homeConstant.ROLLING_OUT && value === homeConstant.ROLLING_OUT) hoverTitleText = homeConstant.ROLLING_OUT_DESC;
        else if (item.name === homeConstant.LATEST_STABLE && value === homeConstant.LATEST_STABLE)
            hoverTitleText = homeConstant.LATEST_STABLE_DESC;
        else if (item.name === homeConstant.MINIMUM && value === homeConstant.MINIMUM) hoverTitleText = homeConstant.MINIMUM_DESC;
        else if (item.name === homeConstant.CRITICAL && value === homeConstant.CRITICAL) hoverTitleText = homeConstant.CRITICAL_DESC;
        else hoverTitleText = value;

        return (
            <TooltipHost
                content={hoverTitleText}
                id={tooltipId}
                calloutProps={{ gapSpace: 0 }}
                tooltipProps={ tooltipProps }
            >
                <Text style={{ fontSize: '12px' }} aria-describedby={tooltipId}>
                    {value}
                </Text>
            </TooltipHost>
        );
    }

    return (
        <div className="home" data-testid="Home">
            <CardWrapper>
                <div className="cardHeader">Home</div>
                <div className="cardBody">
                    <Text>
                        Use this Teams Engineering System DevOps Portal to discover information on the latest releases and what’s contained
                        in them. This portal will be updated to show additional information and provide additional capabilities over time,
                        but if you see something is missing or experience bugs please provide feedback through the links in the side bar.
                    </Text>
                </div>
            </CardWrapper>

            <CardWrapper>
                <div className="cardHeader">R4 (General) Client Versions</div>
                <DetailsList
                    items={data.items}
                    columns={data.columns}
                    compact={true}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={_renderItemColumn}
                    arialLabelForSelectionColumn="Toggle selection"
                    arialLabelForSelectAllCheckbox="Togge selection for all items"
                    checkButtonArialLabel="Select row"
                    onRenderDetailsHeader={(headerProps, defaultRender) => {
                        return defaultRender({
                            ...headerProps,
                            styles: {
                                root: {
                                    selectors: {
                                        '.ms-DetailsHeader-cell': {
                                            whiteSpace: 'normal',
                                            textOverflow: 'ellipsis',
                                            lineHeight: 'normal'
                                        },
                                        '.ms-DetailsHeader-cellTitle': {
                                            height: '100%',
                                            alignItems: 'center'
                                        }
                                    }
                                }
                            }
                        });
                    }}
                />

                {loaderFlag && <Spinner size={SpinnerSize.large} label="Fetching records..." />}

                {noDataFlag && <ErrorNotification msg="No Data Available." resetChoice={() => setNoDataFlag(false)} />}
            </CardWrapper>

            <CardWrapper>
                <div className="cardHeader">Helpful References</div>
                <div style={{ fontSize: 14, fontWeight: 'bold' }}> - Health Scorecard</div>
                <ul>
                    <li>
                        <a target="_blank" href={homeConstant.ENGSYS_HEALTH_SCORECARD} rel="noreferrer">
                            Teams Engineering Health Scorecard
                        </a>
                    </li>
                </ul>

                <div style={{ fontSize: 14, fontWeight: 'bold' }}> - Daily Client Release Status</div>
                <ul>
                    <li>
                        <a title="press 1 to access link" target="_blank" href={homeConstant.RELEASE_BLOCKERS_LINK} rel="noreferrer">
                            Release Blockers
                        </a>
                    </li>
                    <li>
                        <a title="press 2 to access link" target="_blank" href={homeConstant.RELEASE_BLOCKERS_TEAMS_LINK} rel="noreferrer">
                            Release Blockers Teams 2.0
                        </a>
                    </li>
                    <li>
                        <a
                            title="press 3 to access link"
                            target="_blank"
                            href={homeConstant.WEB_DESKTOP_SHIPROOM_CHANNEL_LINK}
                            rel="noreferrer"
                        >
                            Web and Desktop Shiproom Channel
                        </a>
                    </li>
                    <li>
                        <a title="press 9 to access link" target="_blank" href={homeConstant.DESKTOP_SHIPROOM_LINK} rel="noreferrer">
                            Desktop Ship Room
                        </a>
                    </li>
                </ul>

                <div style={{ fontSize: 14, fontWeight: 'bold' }}> - Release Collateral</div>
                <ul>
                    <li>
                        <a
                            title="press 4 to access link"
                            target="_blank"
                            href={homeConstant.CHERRY_PICK_BUG_BAR_BY_RING_LINK}
                            rel="noreferrer"
                        >
                            Cherry-Pick Bug Bar by Ring
                        </a>
                    </li>
                    <li>
                        <a
                            title="press 5 to access link"
                            target="_blank"
                            href={homeConstant.CENTRAL_TRIAGE_APPROVAL_PROCESS_LINK}
                            rel="noreferrer"
                        >
                            Central Triage Approval Process
                        </a>
                    </li>
                    <li>
                        <a
                            title="press 6 to access link"
                            target="_blank"
                            href={homeConstant.RING_BLOCKER_BUG_DASHBOARD_LINK}
                            rel="noreferrer"
                        >
                            Ring Blocker Bug Dashboard
                        </a>
                    </li>
                    <li>
                        <a
                            title="press 7 to access link"
                            target="_blank"
                            href={homeConstant.RING_BLOCKER_METRICES_DASHBOARD_LINK}
                            rel="noreferrer"
                        >
                            Ring Blocker Metrices Dashboard
                        </a>
                    </li>
                    <li>
                        <a title="press 8 to access link" target="_blank" href={homeConstant.RELEASE_POLICY_CADENCE_LINK} rel="noreferrer">
                            Release Policy & Cadence
                        </a>
                    </li>
                </ul>
            </CardWrapper>
        </div>
    );
};

Home.propTypes = {};
Home.defaultProps = {};

export default appInsightsClient.withAITracking(Home, 'Home');
