import { FontIcon, TooltipHost, assign } from '@fluentui/react';
import React, { Fragment } from 'react';
import { Rollout, WorkItem } from 'skynet-client';

import { Card, CardFooter, CardHeader, ICardStyles } from '../../../../components/Card';
import IconWithBadge from '../../../../components/IconWithBadge/IconWithBadge';
import { trainRingCardStyles, trainRingCardStyleMap } from '../../styles/styles';
import { getLongDateTimeString, getShortDateTimeString } from '../../utils';

type TrainRingCardProps = {
    trainId: string;
    trainStatus: string;
    ring: string;
    firstRollout: Rollout;
    lastRollouts: Rollout[];
    ringBlockers: WorkItem[];
    onClick: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * Displays the train ring card.
 *
 * @param props The props for the component.
 * @returns The train ring card.
 */
const TrainRingCard: React.FC<TrainRingCardProps> = (props) => {
    let lastRolloutPercent = props.lastRollouts[0].percent ?? 0;
    if (props.lastRollouts.length > 1) {
        lastRolloutPercent = 0;
        props.lastRollouts.forEach((rollout) => {
            lastRolloutPercent += rollout.percent ?? 0;
        });
        lastRolloutPercent = Math.min(100, lastRolloutPercent);
    }

    let status = 'Active';
    if (props.trainStatus === 'Abandoned') {
        status = 'Abandoned';
    } else if (props.ringBlockers.length > 0) {
        status = 'Blocked';
    } else if (lastRolloutPercent === 100) {
        status = 'Completed';
    }
    const mergedStyles: ICardStyles = {
        root: assign({}, trainRingCardStyles.root, trainRingCardStyleMap[status].root),
        body: assign({}, trainRingCardStyles.body, trainRingCardStyleMap[status].body),
        header: assign({}, trainRingCardStyles.header, trainRingCardStyleMap[status].header),
        footer: assign({}, trainRingCardStyles.footer, trainRingCardStyleMap[status].footer)
    };

    return (
        <Card styles={mergedStyles} onClick={props.onClick}>
            <CardHeader>
                <div style={{ flexGrow: 1 }}>
                    <span style={{ paddingRight: '0.15rem', fontSize: '0.8rem' }}>
                        {lastRolloutPercent === 100 ? <FontIcon iconName="SkypeCircleCheck" style={{ color: 'green' }} /> : <></>}
                    </span>
                    <span>{lastRolloutPercent}%</span>
                </div>
                <div>
                    {status === 'Blocked' && (
                        <TooltipHost content={<span>{props.ringBlockers.length} Ring Blocker(s) </span>}>
                            <IconWithBadge iconName="BugSolid" badgeText={props.ringBlockers.length.toString()} />
                        </TooltipHost>
                    )}
                </div>
            </CardHeader>
            {props.lastRollouts.length > 1 ? (
                props.lastRollouts
                    .sort((a, b) => {
                        if (a.buildFlavor && b.buildFlavor) {
                            return b.buildFlavor.localeCompare(a.buildFlavor);
                        } else {
                            return 0;
                        }
                    })
                    .map((rollout) => {
                        return (
                            <Fragment key={rollout.buildFlavor}>
                                <span key={rollout.buildFlavor}>
                                    Version {rollout.buildFlavor === 'Stable' ? '(S)' : '(E)'}: {rollout.buildVersion}
                                </span>
                                <br />
                            </Fragment>
                        );
                    })
            ) : (
                <span>Version: {props.lastRollouts[0].buildVersion}</span>
            )}
            <CardFooter>
                <table cellSpacing={0} cellPadding={0} style={{ width: '100%' }}>
                    <tbody>
                        {props.firstRollout.completedOn && (
                            <tr>
                                <td>Started:</td>
                                <td style={{ textAlign: 'right' }}>
                                    <TooltipHost content={getLongDateTimeString(props.firstRollout.completedOn)}>
                                        <span>{getShortDateTimeString(props.firstRollout.completedOn)}</span>
                                    </TooltipHost>
                                </td>
                            </tr>
                        )}
                        {props.lastRollouts[0].completedOn && (
                            <tr>
                                <td>Completed:</td>
                                <td style={{ textAlign: 'right' }}>
                                    <TooltipHost content={getLongDateTimeString(props.lastRollouts[0].completedOn)}>
                                        <span>{getShortDateTimeString(props.lastRollouts[0].completedOn)}</span>
                                    </TooltipHost>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </CardFooter>
        </Card>
    );
};

export { TrainRingCard };
