import { Checkbox, DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import React, { FormEvent, useEffect } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { ProcessedFlightRollout } from '../types/Types';

type CancelFlightDialogProps = {
    isOpen: boolean;
    selectedFlight: ProcessedFlightRollout | undefined;
    onDismiss: () => void;
    updateFlight: (flightId: string) => void;
};

/**
 * Renders a dialog component for canceling a flight.
 *
 * @param {CancelFlightDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const CancelFlightDialog: React.FC<CancelFlightDialogProps> = (props) => {
    const { isOpen, selectedFlight: flight, onDismiss, updateFlight } = props;
    const [checked, setChecked] = React.useState(false);

    const dialogContentProps = {
        type: DialogType.normal,
        title: `Cancel Flight ${flight?.id}`,
        closeButtonAriaLabel: 'Close',
        subText:
            'Canceling the flight will IRREVERSIBLY stop the ECS rollout and abandon the ADO release. Are you sure you want to cancel this flight?'
    };

    useEffect(() => {
        setChecked(false);
    }, [isOpen]);

    const handleConfirm = async () => {
        if (!flight) return;
        await new FeatureFlightService().updateFlightStatus(flight.id, 'Cancelled').then((_) => {
            updateFlight(flight.id);
            onDismiss();
        });
    };

    const handleCheckboxChange = (_?: FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        if (isChecked !== undefined) setChecked(isChecked);
    };

    return (
        <Dialog hidden={!isOpen || !flight} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            <Checkbox label="I understand that the cancellation cannot be undone." checked={checked} onChange={handleCheckboxChange} />

            <DialogFooter>
                <PrimaryButton onClick={handleConfirm} text="Confirm" disabled={!checked} />
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default CancelFlightDialog;
