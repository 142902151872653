const flightCrewWhitelist = [
    'afatu',
    'andreasecman',
    'anprabhu',
    'apatwardhan',
    'calebbishop',
    'fgogota',
    'gusaini',
    'jiayanghu',
    'jimmul',
    'lgiubega',
    'mphalswal',
    'obalashov',
    'praneta',
    'ptripathi',
    'rapuvvad',
    'ravsodhi',
    'rikashya',
    'slavale',
    'samroth',
    'yaalshal',
    'sanjayg',
    'dballesteros',
    'roahs',
    'agarwalsh'
];

export { flightCrewWhitelist };
