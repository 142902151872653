/**
 * Transforms the client display name returned in the response to the latest convention.
 *
 * @param client The client.
 * @returns The transformed client display name.
 */
export function transformClientDisplayName(client: string) {
    switch (client) {
        case 'TFL Web (T2/RWC)':
            return 'T2 Web Consumer';
        case 'Windows (T2.1/Maglev)':
            return 'T2 Desktop Converged Win';
        case 'TFW Web (T2.1/RWC)':
            return 'T2 Web Enterprise';
        case 'Mac (T2.1/Maglev)':
            return 'T2 Desktop Converged Mac';
        default:
            return client;
    }
}
