import { createSlice } from '@reduxjs/toolkit';

import { UserProfileState } from '../types/user-profile';

const initialState: UserProfileState = {
    name: '',
    email: '',
    photo: ''
};

const userProfileSlice = createSlice({
    name: 'user-profile',
    initialState,
    reducers: {
        setName: (state, action) => {
            console.log('Action: ', action);
            const name = action.payload;
            state.name = name;
        },
        setEmail: (state, action) => {
            console.log('Action: ', action);
            const email = action.payload;
            state.email = email;
        },
        setPhoto: (state, action) => {
            console.log('Action: ', action);
            const photo = action.payload;
            state.photo = photo;
        }
    }
});

export const userProfileReducer = userProfileSlice.reducer;
export const { setName, setEmail, setPhoto } = userProfileSlice.actions;
