import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import React from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { ProcessedFlightRollout } from '../types/Types';

type CompleteFlightDialogProps = {
    isOpen: boolean;
    selectedFlight: ProcessedFlightRollout | undefined;
    onDismiss: () => void;
    updateFlight: (flightId: string) => void;
};

/**
 * Renders a dialog component for completing a flight.
 *
 * @param {CompleteFlightDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const CompleteFlightDialog: React.FC<CompleteFlightDialogProps> = (props) => {
    const { isOpen, selectedFlight: flight, onDismiss, updateFlight } = props;

    const dialogContentProps = {
        type: DialogType.normal,
        title: `Complete Flight ${flight?.id}`,
        closeButtonAriaLabel: 'Close',
        subText: 'Completing the flight will stop the ECS rollout. Are you sure you have burnt feature flags into TMP base config?'
    };

    const handleConfirm = async () => {
        if (!flight) return;
        await new FeatureFlightService().updateFlightStatus(flight.id, 'Completed').then((_) => {
            updateFlight(flight.id);
            onDismiss();
        });
    };

    return (
        <Dialog hidden={!isOpen || !flight} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton onClick={handleConfirm} text="Confirm" />
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default CompleteFlightDialog;
