import { Link, Separator, Stack } from '@fluentui/react';
import React from 'react';
import { BuildReleasePair, ClientType, CommitMetadataV2, Environment, SnapBranchMetadataV2, WorkItem } from 'skynet-client';

import { Card, CardHeader } from '../../../../components/Card';
import { ProcessedTrainMetadata } from '../../../../services/models/TrainMetadata';
import { getShortDateTimeString } from '../../utils';

import { ActiveCherryPicksDetails } from './ActiveCherryPicksDetails';

import { BuildDetails, RingBlockersTable, RolloutProgressionTable } from '.';

type TrainModalDetailsProps = {
    trainData: ProcessedTrainMetadata;
    ringBlockers: WorkItem[];
};

/**
 * Displays the train modal details.
 *
 * @param props The props.
 * @returns The Train Modal Details component.
 */
const TrainModalDetails: React.FC<TrainModalDetailsProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: '1%' }}>
            <h3>Train Details</h3>
            {props.ringBlockers !== undefined && props.ringBlockers.length > 0 && (
                <RingBlockersTable ringBlockers={props.ringBlockers} showRing={true} />
            )}
            {props.trainData.buildReleaseMetadata?.buildReleaseList && props.trainData.snapBranchMetadata?.branches && (
                <ActiveCherryPicksDetails
                    buildReleaseList={props.trainData.buildReleaseMetadata.buildReleaseList}
                    snapBranches={props.trainData.snapBranchMetadata.branches}
                />
            )}
            {props.trainData.buildReleaseMetadata?.buildReleaseList?.map((buildRelease) => {
                if (buildRelease.releaseMetadata && props.trainData.clientType !== undefined && props.trainData.environment !== undefined) {
                    return (
                        <BuildReleaseDetails
                            key={buildRelease.releaseMetadata.id}
                            clientType={props.trainData.clientType}
                            environment={props.trainData.environment}
                            buildReleasePair={buildRelease}
                        />
                    );
                }
            })}
            {props.trainData.snapBranchMetadata?.branches && (
                <LastCodeTimestamp
                    branches={props.trainData.snapBranchMetadata}
                    key={props.trainData.snapBranchMetadata.branches[0].branchName}
                />
            )}
        </Stack>
    );
};

type BuildReleaseDetailsProps = {
    clientType: ClientType;
    environment: Environment;
    buildReleasePair: BuildReleasePair;
};

const BuildReleaseDetails: React.FC<BuildReleaseDetailsProps> = (props) => {
    const { buildReleasePair } = props;
    const { buildMetadata, releaseMetadata, cherryPicks, rollouts } = buildReleasePair;
    return (
        buildMetadata &&
        releaseMetadata &&
        cherryPicks && (
            <Card>
                <CardHeader>
                    <span style={{ fontSize: '1.1rem', paddingRight: '0.2rem' }}>
                        Build (
                        <Link href={buildMetadata.link} target="_blank" rel="noreferrer">
                            {buildMetadata.buildNumber}
                        </Link>
                        )
                    </span>
                    <span style={{ fontSize: '1.1rem', paddingLeft: '0.2rem' }}>
                        Release (
                        <Link href={releaseMetadata.link} target="_blank" rel="noreferrer">
                            {releaseMetadata.id}
                        </Link>
                        )
                    </span>
                </CardHeader>
                <Stack tokens={{ childrenGap: '0.5%' }}>
                    <Stack horizontal>
                        <BuildDetails clientType={props.clientType} environment={props.environment} build={buildMetadata} />
                        {cherryPicks && cherryPicks.length > 0 && <Separator vertical />}
                        <CherryPickDetails cherryPicks={cherryPicks} />
                    </Stack>
                    {rollouts && rollouts.length > 0 && (
                        <>
                            <Separator />
                            <RolloutProgressionTable rollouts={rollouts} />
                        </>
                    )}
                </Stack>
            </Card>
        )
    );
};

type CherryPickDetailsProps = {
    cherryPicks: CommitMetadataV2[];
};

const CherryPickDetails: React.FC<CherryPickDetailsProps> = (props) => {
    const { cherryPicks } = props;

    return (
        <div style={{ marginBottom: '0.1rem' }}>
            {cherryPicks.length > 0 && <span style={{ fontWeight: 'bold', margin: '0.2rem', marginBottom: '0rem' }}> Cherry Picks</span>}
            <ul>
                {cherryPicks.map((cherryPick) => {
                    return (
                        <li key={cherryPick.id}>
                            <Link href={cherryPick.link} target="_blank" rel="noreferrer">
                                {cherryPick.message}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

type LastCodeTimestampProps = {
    branches: SnapBranchMetadataV2;
};

const LastCodeTimestamp: React.FC<LastCodeTimestampProps> = (props) => {
    return (
        <Card>
            {props.branches.branches?.map(
                (snap) =>
                    snap.commitHeadDate && (
                        <div key={snap.branchName} style={{ marginBottom: '0.1rem' }}>
                            Last code timestamp to have made the build at the time of the Snap of {snap.displayName} is{' '}
                            {getShortDateTimeString(snap.commitHeadDate)}
                        </div>
                    )
            )}
        </Card>
    );
};

export { TrainModalDetails };
