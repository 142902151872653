import { TooltipHost } from '@fluentui/react';
import React from 'react';
import { Rollout } from 'skynet-client';

import { getLongDateTimeString, getShortDateTimeString } from '../../utils';

type RolloutProgressionTableProps = {
    rollouts: Rollout[];
};

/**
 * Displays the ring progression table.
 *
 * @param props The props for the component.
 * @returns The ring progression table.
 */
export const RolloutProgressionTable: React.FC<RolloutProgressionTableProps> = (props) => {
    const { rollouts } = props;
    return (
        <table style={{ minWidth: '700px' }}>
            <thead>
                <tr>
                    <th style={{ padding: '0.3rem' }}>Stage</th>
                    <th style={{ padding: '0.3rem' }}>Build Flavor</th>
                    <th style={{ padding: '0.3rem' }}>Version</th>
                    <th style={{ padding: '0.3rem' }}>Completed On</th>
                </tr>
            </thead>
            <tbody>
                {rollouts.map(
                    (rollout) =>
                        rollout &&
                        rollout.completedOn && (
                            <tr key={`${rollout.completedOn}_${rollout.buildFlavor}`}>
                                <td style={{ padding: '0.3rem' }}>{rollout.releaseEnvironmentName}</td>
                                <td style={{ padding: '0.3rem' }}>{rollout.buildFlavor}</td>
                                <td style={{ padding: '0.3rem' }}>{rollout.buildVersion}</td>
                                <td style={{ padding: '0.3rem' }}>
                                    {
                                        <TooltipHost content={getLongDateTimeString(rollout.completedOn)}>
                                            {getShortDateTimeString(rollout.completedOn)}
                                        </TooltipHost>
                                    }
                                </td>
                            </tr>
                        )
                )}
            </tbody>
        </table>
    );
};
