import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { desktopTCNS, desktopTCNSDisplayName, webRWC, webRWCDisplayName } from '../configs/defaults';

type ClientOptionsProps = {
    reset: boolean;
    update?: (client: string) => void;
    defaultValue?: string;
};

/**
 * Renders client options as a choice group and updates experience options accordingly.
 *
 * @param props - The props containing experienceOptions, setExperienceOptions, setIsExperienceOptionsEnable, and setFormData.
 * @returns The JSX element representing the client options choice group.
 */
const ClientOptions: React.FC<ClientOptionsProps> = (props) => {
    // ================= State =================
    const defaultValue = props.defaultValue ?? desktopTCNS;
    const disabled = props.update === undefined;

    const clientOptions: IChoiceGroupOption[] = useConst([
        { key: desktopTCNS, text: desktopTCNSDisplayName },
        { key: webRWC, text: webRWCDisplayName }
    ]);

    const [client, setClient] = React.useState<string>(defaultValue);

    // ========================= Hooks =========================
    useEffect(() => {
        setClient(defaultValue);
    }, [props.reset]);

    const onClientRadioChange = (clientOption: IChoiceGroupOption) => {
        if (!props.update) return;
        if (clientOption.key) {
            setClient(clientOption.key.toString());
            props.update(clientOption.key.toString());
            appInsightsClient.logEvent({ name: 'FFV2:StartFlight:ClientRadioChange', properties: { clientOption: clientOption.key } });
        }
    };

    return (
        <ChoiceGroup
            options={clientOptions}
            required
            selectedKey={client}
            onChange={(_, value) => {
                if (value !== undefined) {
                    onClientRadioChange(value);
                }
            }}
            disabled={disabled}
        />
    );
};

export default ClientOptions;
