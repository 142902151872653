import {
    ActionButton,
    DetailsList,
    IColumn,
    IDetailsListStyles,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { ClientType, Environment, Experience, Month, OS, ReleaseSchedule } from 'skynet-client';

import { Card } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import { ProcessedReleaseSchedule } from '../../services/models/ReleaseSchedule';
import ScheduleService from '../../services/schedule.service';
import { transformSchedules } from '../../services/utils';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserPrincipalName } from '../../utils/userUtility';
import { getLongDateTimeString, getShortDateTimeString, getFormattedCloudRingPairs } from '../Shiproom/utils';

import ReleaseScheduleForm from './ReleaseScheduleForm';
import { isUserReleaseManager } from './utils';

type ReleaseScheduleTableProps = {
    clientType: ClientType;
    os: OS;
    environment: Environment;
    experience: Experience;
    supportedRings: Record<string, string[]>;
};

/**
 * Displays the release schedule table for the selected client.
 *
 * @param props The props for the component.
 * @returns The main component for each client of the report.
 */
const ReleaseScheduleTable: React.FC<ReleaseScheduleTableProps> = (props) => {
    const [rawData, setRawData] = useState<ReleaseSchedule[]>([]);
    const [data, setData] = useState<ProcessedReleaseSchedule[]>([]);
    const [filteredData, setFilteredData] = useState<ProcessedReleaseSchedule[]>([]);

    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    const [tableColumns, setTableColumns] = useState<IColumn[]>([]);

    const [selectedRawItem, setSelectedRawItem] = useState<ReleaseSchedule>();
    const [selectedItem, setSelectedItem] = useState<ProcessedReleaseSchedule>();

    const [isEditButtonDisabled, { setTrue: disableEditButton, setFalse: enableEditButton }] = useBoolean(true);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const selection = new Selection({
        onSelectionChanged: () => {
            const selectedRow = selection.getSelection()[0] as ProcessedReleaseSchedule;
            if (selectedRow && isUserReleaseManager(getUserPrincipalName())) {
                setSelectedItem(selectedRow);
                setSelectedRawItem(rawData.find((item) => item.trainName === selectedRow.trainName));
                enableEditButton();
            } else {
                setSelectedItem(undefined);
                setSelectedRawItem(undefined);
                disableEditButton();
            }
        }
    });
    const monthsAgo = 2;
    const monthsForward = 12;

    // ===============================================================

    useEffect(() => {
        setIsLoading();
        setIsDataNotLoaded();

        const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format;
        const now = new Date();
        const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

        const from = new Date(new Date(startOfCurrentMonth).setMonth(startOfCurrentMonth.getMonth() - monthsAgo));
        const fromYear = from.getFullYear();
        const fromMonthName = monthName(from) as Month;

        const to = new Date(new Date(startOfCurrentMonth).setMonth(startOfCurrentMonth.getMonth() + monthsForward));
        const toYear = to.getFullYear();
        const toMonthName = monthName(to) as Month;

        new ScheduleService()
            .apiReleaseScheduleGetByTimeRangeGet({
                clientType: props.clientType,
                os: props.os,
                environment: props.environment,
                experience: props.experience,
                fromMonth: fromMonthName,
                toMonth: toMonthName,
                fromYear: fromYear,
                toYear: toYear
            })
            .then((response) => {
                setRawData(response);

                const processedSchedules = transformSchedules(response);
                setData(processedSchedules);
                setFilteredData(processedSchedules);
                setTableColumns(prepareColumns(props.supportedRings));

                setIsNotLoading();
                setIsDataLoaded();

                appInsightsClient.logTrace(
                    {
                        message: `Fetched scheduled release plan from ScheduleService`
                    },
                    { clientType: props.clientType, os: props.os, environment: props.environment }
                );
            })
            .catch((fail) => {
                setIsDataNotLoaded();
                setIsNotLoading();

                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect in ReleaseScheduleTable`,
                        clientType: props.clientType,
                        os: props.os,
                        environment: props.environment
                    }
                );
            });
    }, [props.clientType, props.os, props.environment, props.experience]);

    if (!isLoading && !isDataLoaded) {
        return (
            <Card>
                <ErrorNotification msg="Unable to fetch release schedules" />
            </Card>
        );
    }
    if (isLoading) {
        return (
            <Card>
                <Spinner size={SpinnerSize.large} label="Fetching schedules..." />
            </Card>
        );
    }
    if (!isLoading && isDataLoaded) {
        return (
            <Card styles={{ root: { minWidth: 'max-content' } }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <TextField
                        label="Search: "
                        underlined
                        placeholder="Search train names"
                        onChange={onFilter}
                        styles={{ root: { minWidth: '25%' } }}
                    />
                    <ActionButton iconProps={{ iconName: 'Edit' }} onClick={showModal} allowDisabledFocus disabled={isEditButtonDisabled}>
                        Edit
                    </ActionButton>
                </Stack>
                <div style={{ maxWidth: 'calc(100% - 7px)' }}>
                    <DetailsList
                        className="schedule"
                        items={filteredData}
                        columns={tableColumns}
                        styles={gridStyles}
                        selection={selection}
                        selectionMode={SelectionMode.single}
                    />
                </div>
                {selectedItem && selectedRawItem && isUserReleaseManager(getUserPrincipalName()) && (
                    <ReleaseScheduleForm
                        isOpen={isModalOpen}
                        onDismiss={hideModal}
                        schedule={selectedItem}
                        rawSchedule={selectedRawItem}
                        setListData={setFilteredData}
                        supportedRings={getFormattedCloudRingPairs(props.supportedRings)}
                    />
                )}
            </Card>
        );
    }

    return <></>;
    function onFilter(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
        setFilteredData(newValue ? data.filter((x) => x.trainName.toLowerCase().includes(newValue.toLocaleLowerCase())) : data);
    }
};

function prepareColumns(supportedRings: Record<string, string[]>): IColumn[] {
    const columns: IColumn[] = [
        {
            key: 'trainName',
            name: 'Train',
            fieldName: 'trainName',
            minWidth: 130,
            maxWidth: 400,
            onRender: (item: ProcessedReleaseSchedule) => {
                return <span>{item.trainName}</span>;
            }
        },
        {
            key: 'codeCutoffDate',
            name: 'Code Cutoff',
            fieldName: 'codeCutoffDate',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ProcessedReleaseSchedule) => {
                const codeCutOffDate = new Date(item.snapDate);
                codeCutOffDate.setDate(codeCutOffDate.getDate() - 3);
                codeCutOffDate.setHours(codeCutOffDate.getHours() - 3);
                codeCutOffDate.setMinutes(codeCutOffDate.getMinutes() - 30);
                return <TooltipHost content={getLongDateTimeString(codeCutOffDate)}>{getShortDateTimeString(codeCutOffDate)}</TooltipHost>;
            }
        },
        {
            key: 'snapDate',
            name: 'Snap Date',
            fieldName: 'snapDate',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ProcessedReleaseSchedule) => {
                return <TooltipHost content={getLongDateTimeString(item.snapDate)}>{getShortDateTimeString(item.snapDate)}</TooltipHost>;
            }
        }
    ];
    const cloudRingPairs = getFormattedCloudRingPairs(supportedRings);
    const cleanCloudRingPairs = getFormattedCloudRingPairs(supportedRings, false, ' ');
    cloudRingPairs.forEach((ring, index) => {
        columns.push({
            key: ring,
            name: cleanCloudRingPairs[index],
            fieldName: ring,
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ProcessedReleaseSchedule) => {
                if (item.trainSchedule[ring] !== undefined) {
                    return (
                        <TooltipHost content={getLongDateTimeString(item.trainSchedule[ring])}>
                            {getShortDateTimeString(item.trainSchedule[ring])}
                        </TooltipHost>
                    );
                }
                return <></>;
            }
        });
    });

    return columns;
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        width: 'fit-content'
    },
    headerWrapper: {
        flex: '0 0 auto',
        '.ms-DetailsHeader': {
            backgroundColor: 'lightgrey',
            height: '40px',
            lineHeight: '40px',
            paddingTop: 0,
            marginTop: 0
        }
    }
};
export default ReleaseScheduleTable;
