import {
    ComboBox,
    Dropdown,
    IComboBoxOption,
    IconButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    SelectableOptionMenuItemType,
    Stack,
    TextField
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { arrayTypeFilters, desktopTCNS, desktopVersion, experienceBuild, webRWC, GUIDTypeFilters, osPlatform } from '../configs/defaults';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { operatorInputStyle, pairInputStyle, removeIcon } from '../styles/StartFlightFormStyle';
import { FilterData, FilterOperation, FilterSchema } from '../types/Types';
import { convertFiltersListToDict, isValidClientVersion, validateListOfGuids } from '../utilities/FFv2Utils';

type FiltersInputProps = {
    client: string;
    filterKeys: FilterSchema[];
    reset: boolean;
    addFilters: (filters: { [key: string]: string | number | string[] | number[] | boolean }) => void;
    deleteFilter: (filterName: string) => void;
    defaultFilters?: FilterData[];
};

/**
 *
 * Renders the component for Filters Input.
 *
 * @param props - The props needed to update and delete filters in the form state, and reset input.
 * @returns The JSX element representing the filters input component.
 */
const FiltersInput: React.FC<FiltersInputProps> = (props) => {
    // ========================= State =========================
    const { client, reset } = props;

    const defaultFilters = props.defaultFilters?.length
        ? props.defaultFilters
        : [{ name: '', value: '', operation: FilterOperation.Equal }];
    const [filtersOnPage, setFiltersOnPage] = useState<FilterData[]>(defaultFilters);

    const options = (() => {
        const filters = props.filterKeys.filter((item) => item.key !== (client === webRWC ? experienceBuild : desktopVersion));

        const mapFiltersToOptions = (f: FilterSchema[]) => {
            return f.map((item) => {
                return {
                    key: item.key,
                    text: item.key,
                    disabled: filtersOnPage.map((row) => row.name).includes(item.key)
                };
            });
        };

        const priorityFilters = mapFiltersToOptions(filters.filter((item) => item.priorityFilter));
        const splitHeader = [
            { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
            { key: 'Header', text: 'More filters', itemType: SelectableOptionMenuItemType.Header }
        ];
        const secondaryFilters = mapFiltersToOptions(filters.filter((item) => !item.priorityFilter));

        // check if secondaryFilters is empty
        if (secondaryFilters.length === 0) return [...priorityFilters];

        return [...priorityFilters, ...splitHeader, ...secondaryFilters];
    })();

    const operationOptions = () => {
        let opOptions = Object.values(FilterOperation).map(
            (operator) => ({ key: operator, text: operator, disabled: false }) as IComboBoxOption
        );

        /*
        const type = getFilterType(filterName);

        // if filter type is not number only allow = and != operators, this includes multi-select filters for numbers
        // if filter is experienceBuild or desktopVersion, allow all operators
        if (type !== 'number' && filterName !== experienceBuild && filterName !== desktopVersion) {
            // disable all operators in option except = and !=
            opOptions = opOptions.map((op) => {
                return { ...op, disabled: op.key !== FilterOperation.Equal && op.key !== FilterOperation.NotEqual };
            });
        }

        // if filter is a multi-select filter, only allow = operators for now
        if (arrayTypeFilters.includes(filterName)) {
            opOptions = opOptions.map((op) => {
                return { ...op, disabled: op.key !== FilterOperation.Equal };
            });
        }
        */

        // only allow Equal operator for now
        opOptions = opOptions.map((op) => {
            return { ...op, disabled: op.key !== FilterOperation.Equal };
        });

        return opOptions;
    };

    // ========================= Hooks =========================
    useEffect(() => {
        setFiltersOnPage(defaultFilters);
    }, [reset]);

    useEffect(() => {
        let updatedFilters = filtersOnPage.filter((filter) => filter.name !== (client === webRWC ? experienceBuild : desktopVersion));
        if (updatedFilters.length === 0) {
            updatedFilters = [{ name: '', value: '', operation: FilterOperation.Equal }];
        }
        setFiltersOnPage(updatedFilters);
    }, [client]);

    // ========================= Event handlers =========================
    const handleFilterOperationChange = (index: number, name: string, operation: string) => {
        const updatedFiltersOnPage = [...filtersOnPage];
        const prevFilter = updatedFiltersOnPage[index];
        updatedFiltersOnPage[index] = { name: name, value: prevFilter.value, operation: operation as FilterOperation };
        setFiltersOnPage(updatedFiltersOnPage);

        const updatedFiltersToSubmit = updatedFiltersOnPage.filter((filter) => filter.name !== '' && filter.value !== '');

        props.addFilters(convertFiltersListToDict(updatedFiltersToSubmit));
    };

    const handleFilterInputChange = (index: number, name: string, value: string, multiSelect?: boolean) => {
        const updatedFiltersOnPage = [...filtersOnPage];
        const prevFilter = updatedFiltersOnPage[index];
        updatedFiltersOnPage[index] = { name: name, value: value, operation: prevFilter.operation };
        setFiltersOnPage(updatedFiltersOnPage);

        const updatedFiltersToSubmit = updatedFiltersOnPage.filter((filter) => filter.name !== '' && filter.value !== '');

        if (multiSelect && !value) {
            // if the value is empty, remove the filter from formData
            props.deleteFilter(name);
        } else {
            props.addFilters(convertFiltersListToDict(updatedFiltersToSubmit));
        }
    };

    const handleAddFilter = () => {
        setFiltersOnPage([...filtersOnPage, { name: '', value: '', operation: FilterOperation.Equal }]);
        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:AddFilterEvent' });
    };

    const handleRemoveFilter = (name: string) => {
        const updatedFiltersOnPage = filtersOnPage.filter((filter) => filter.name !== name);

        setFiltersOnPage(
            updatedFiltersOnPage.length === 0 ? [{ name: '', value: '', operation: FilterOperation.Equal }] : updatedFiltersOnPage
        );
        props.deleteFilter(name);

        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:RemoveFilterEvent', properties: { filterName: name } });
    };

    // ========================= Helper Functions =========================
    const getErrorMessage = (filterName: string, value: string): string | undefined => {
        if (filterName === 'DesktopVersion' && (!value || !isValidClientVersion(desktopTCNS, value))) {
            appInsightsClient.logEvent({ name: 'FFV2:StartFlight:InvalidDesktopVersionError' });
            return 'Invalid desktop version, the format is 11111.111.1111.1111';
        }

        if (arrayTypeFilters.includes(filterName) && GUIDTypeFilters.includes(filterName) && (!value || !validateListOfGuids(value))) {
            appInsightsClient.logEvent({ name: `FFV2:StartFlight:Invalid${filterName}` });
            return `${filterName} filter must be a list of valid GUID(s) separated by ','`;
        }

        if (filterName.length > 0 && !value) return 'Fill in a filter value';

        return '';
    };

    const getFilterType = (filterName: string): string => {
        const target = props.filterKeys.filter((item) => item.key === filterName);
        if (target.length > 0 && target[0] !== undefined) {
            return target[0].type;
        }
        return '';
    };

    const getFilterOptions = (filterName: string): unknown[] | undefined => {
        const target = props.filterKeys.filter((item) => item.key === filterName);
        if (target.length > 0 && target[0] !== undefined) {
            return target[0].options;
        }
        return undefined as unknown[] | undefined;
    };

    const FilterDataInput = (filter: FilterData, index: number) => {
        const type = getFilterType(filter.name);
        const targetOptions = getFilterOptions(filter.name);

        switch (true) {
            case type === 'boolean': {
                return (
                    <ComboBox
                        selectedKey={filter.value}
                        styles={pairInputStyle}
                        placeholder="Select boolean"
                        options={[
                            { key: 'true', text: 'true' },
                            { key: 'false', text: 'false' }
                        ]}
                        onChange={(event, option, optionIndex, value) => {
                            if (value !== undefined) {
                                handleFilterInputChange(index, filter.name, value);
                            }
                        }}
                        errorMessage={getErrorMessage(filter.name, filter.value)}
                    />
                );
            }
            case type === 'array' && !!targetOptions: {
                const comboBoxOptions = targetOptions
                    ? targetOptions.map((option) => ({ key: option, text: option }) as IComboBoxOption)
                    : [];
                if (filter.name === osPlatform) {
                    comboBoxOptions.map((option) => {
                        if (option.key === 'windows') {
                            option.disabled = false;
                        } else if (option.key === 'mac') {
                            option.disabled = true;
                        } else {
                            option.disabled = true;
                        }
                    });
                }
                return (
                    <ComboBox
                        selectedKey={filter.value.split(',').map((value) => value.trim())}
                        styles={pairInputStyle}
                        placeholder="Select at least one"
                        multiSelect
                        options={comboBoxOptions}
                        onChange={(event, option) => {
                            if (option) {
                                const selected = option?.selected;
                                const prevSelected = filter.value ? filter.value.split(',').map((value) => value.trim()) : [];
                                const filterValue = (
                                    selected ? [...prevSelected, option.key as string] : prevSelected.filter((k) => k !== option.key)
                                ).join(',');
                                handleFilterInputChange(index, filter.name, filterValue, true);
                            }
                        }}
                        errorMessage={getErrorMessage(filter.name, filter.value)}
                    />
                );
            }
            default: {
                return (
                    <TextField
                        value={filter.value}
                        placeholder="Filter value"
                        styles={pairInputStyle}
                        validateOnLoad
                        validateOnFocusOut
                        errorMessage={getErrorMessage(filter.name, filter.value)}
                        onChange={(event, newValue) => {
                            if (newValue !== undefined) {
                                handleFilterInputChange(index, filter.name, newValue);
                            }
                        }}
                    />
                );
            }
        }
    };

    // ========================= Render =========================
    return (
        <Stack tokens={gapStackTokensMedium}>
            <MessageBar delayedRender={false} messageBarType={MessageBarType.info}>
                Currently, only equal (=) to operation is supported for filters. More operators will be supported in the near future.
            </MessageBar>
            <Stack tokens={gapStackTokensMedium}>
                {filtersOnPage.map((filter, index) => (
                    <Stack horizontal key={index} tokens={gapStackTokensMedium}>
                        <ComboBox
                            selectedKey={filter.name}
                            placeholder="Select filter"
                            styles={pairInputStyle}
                            options={options}
                            onChange={(event, option, optionIndex, name) => {
                                if (name !== undefined) {
                                    handleFilterInputChange(index, name, filter.value);
                                }
                            }}
                        />
                        <Dropdown
                            id={`${filter.name}`}
                            selectedKey={filter.operation}
                            styles={operatorInputStyle}
                            options={operationOptions()}
                            onChange={(event, operation) => {
                                if (operation !== undefined) {
                                    handleFilterOperationChange(index, filter.name, operation.text);
                                }
                            }}
                        />
                        {FilterDataInput(filter, index)}
                        {(filtersOnPage.length > 1 || filtersOnPage[0].name !== '' || filtersOnPage[0].value !== '') && (
                            <IconButton iconProps={removeIcon} onClick={() => handleRemoveFilter(filter.name)} />
                        )}
                    </Stack>
                ))}
                <PrimaryButton styles={pairInputStyle} onClick={handleAddFilter}>
                    + Add Filter
                </PrimaryButton>
            </Stack>
        </Stack>
    );
};

export default FiltersInput;
