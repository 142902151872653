import { IconButton, Modal } from '@fluentui/react';
import React from 'react';
import { WorkItem } from 'skynet-client';

import { ProcessedReleaseSchedule } from '../../../../services/models/ReleaseSchedule';
import { ProcessedTrainMetadata } from '../../../../services/models/TrainMetadata';
import { trainModalContentStyles, trainModalIconButtonStyles, trainModalCancelIcon } from '../../styles/styles';
import { LatestProdTrainRingProps } from '../../types/LatestProdTrainRingProps';

import { RingModalDetails, TrainModalDetails } from '.';

type TrainModalProps = {
    schedule?: ProcessedReleaseSchedule;
    isOpen: boolean;
    trainData: ProcessedTrainMetadata;
    ringBlockers: Record<string, WorkItem[]>;
    view: string;
    latestProdRingPropsForTrain?: LatestProdTrainRingProps;
    onDismiss: () => void;
};

/**
 * Displays the train details modal.
 *
 * @param props The props for the component.
 * @returns The train details modal.
 */
export const TrainModal: React.FC<TrainModalProps> = (props) => {
    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} className={trainModalContentStyles.container}>
            <div className={trainModalContentStyles.header}>
                <h2 id={props.trainData.id}>
                    {props.trainData.title} | {props.trainData.status}
                </h2>
                <IconButton
                    styles={trainModalIconButtonStyles}
                    iconProps={trainModalCancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={props.onDismiss}
                />
            </div>
            <div className={trainModalContentStyles.body}>
                {props.view === 'main' ? (
                    <TrainModalDetails trainData={props.trainData} ringBlockers={Object.values(props.ringBlockers).flat()} />
                ) : (
                    <RingModalDetails
                        ring={props.view}
                        trainData={props.trainData}
                        ringBlockers={props.ringBlockers}
                        latestProdRingPropsForTrain={props.latestProdRingPropsForTrain}
                    />
                )}
            </div>
        </Modal>
    );
};
