/* eslint-disable @typescript-eslint/ban-ts-comment */
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { msalConfig } from './auth/authConfiguration';
import ArnoldWebChat from './bot/ArnoldWebChat';
import { DEFAULT_SCOPE } from './configs/env.config';
import { flightCrewWhitelist } from './configs/flightCrewWhitelist';
import ErrorPage from './ErrorPage';
import { AppLayout } from './layout/AppLayout';
import CommitStatusReport from './pages/CommitStatusReport';
import ExperienceReport from './pages/ExperienceReport';
import FeatureFlagReportPage from './pages/FeatureFlagReport';
import RolloutHistoryLog from './pages/FeatureFlagTracking/RolloutHistoryLog';
import FFv2 from './pages/FFv2';
import { FlightInfo } from './pages/FFv2/FlightInfo';
// @ts-ignore
import Home from './pages/Home';
import RecentChangesReport from './pages/RecentChangesReport';
// @ts-ignore
import Retrospective from './pages/Retrospective';
// @ts-ignore
import Ring0Insights from './pages/Ring0Insights';
import RingAssignmentPage from './pages/RingAssignmentPage';
import SchedulePage from './pages/Schedule';
import ShiproomPage from './pages/Shiproom';
import './App.css';
import { setEmail, setName, setPhoto } from './redux/slices/userProfileSlice';
import store from './redux/store/configureStore';
import MsGraphService from './services/msGraph.service';

initializeIcons();
const routes = [
    {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/home',
                element: <Home />
            },
            {
                path: '/shiproom',
                element: <ShiproomPage />
            },
            {
                path: '/retrospective',
                element: <Retrospective />
            },
            {
                path: '/schedule',
                element: <SchedulePage />
            },
            {
                path: '/featureTracking',
                element: <FeatureFlagReportPage />
            },
            {
                path: '/rollout-history',
                element: <RolloutHistoryLog />
            },
            {
                path: '/commit-tracking',
                element: <CommitStatusReport />
            },
            {
                path: '/ring0-insights',
                element: <Ring0Insights />
            },
            {
                path: '/featureFlagConfig',
                element: <FeatureFlagReportPage />
            },
            {
                path: '/featureFlagReport',
                element: <FeatureFlagReportPage />
            },
            {
                path: '/experiences',
                element: <ExperienceReport />
            },
            {
                path: '/featureFlight',
                element: <FFv2 />
            },
            {
                path: '/featureFlight/:flightId',
                element: <FlightInfo />
            },
            {
                path: '/recentChanges',
                element: <RecentChangesReport />
            },
            {
                path: '/ring-assignment',
                element: <RingAssignmentPage />
            },
            {
                path: '*',
                element: <Home />
            }
        ]
    }
];
const router = createBrowserRouter(routes);

// MSAL Instance to be used throughout the app
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * The main component which includes the complete user interface containing Header, Side Nav, Body and Footer.
 *
 * @returns JSX (The actual view).
 */
const App: React.FC = () => {
    return (
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={{ scopes: DEFAULT_SCOPE }}
                    errorComponent={(error) => <div>Authentication failed {JSON.stringify(error)}</div>}
                >
                    <AppWithBot />
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </Provider>
    );
};

const AppWithBot: React.FC = () => {
    const { accounts } = useMsal();
    const [isBotEnabled, setIsBotEnabled] = useState<boolean>(false);

    // TODO:: Update this useEffect to trigger only when MSAL log in completed.
    useEffect(() => {
        const userDetails = accounts[0];

        if (userDetails) {
            setIsBotEnabled(flightCrewWhitelist.includes(userDetails.username.split('@')[0]));
        }

        store.dispatch(setName(userDetails.name));
        store.dispatch(setEmail(userDetails.username));

        new MsGraphService()
            .getPhoto()
            .then((response) => {
                store.dispatch(setPhoto(response));
            })
            .catch(() => {
                // Unable to fetch user photo
                console.log('Unable to fetch user photo');
            });
    }, []);

    return (
        <>
            {isBotEnabled && <ArnoldWebChat />}
            <RouterProvider router={router} />
        </>
    );
};

export default App;
export { msalInstance };
