import { INavLinkGroup, Link, Nav, Separator } from '@fluentui/react';
import React from 'react';
import { Transition } from 'react-transition-group';

import { navLinkGroups } from './configs/defaults';
import { leftNavStyles, leftNavContainerStyles, footerStyles } from './styles/styles';

type LeftNavProps = {
    isNavOpen: boolean;
};

/**
 * The navigation bar of the application.
 *
 * @param props The properties of the navigation bar.
 * @returns The navigation bar of the application.
 */
const LeftNav: React.FC<LeftNavProps> = (props) => {
    return (
        <Transition in={props.isNavOpen} timeout={300}>
            {(state) => (
                <div style={leftNavContainerStyles(state)}>
                    <Nav styles={leftNavStyles} groups={navLinkGroups} />
                    <div style={{ marginTop: 'auto' }}>
                        <Separator />
                        <Nav styles={leftNavStyles} groups={footerNavLinkGroups} />
                        <div style={footerStyles}>
                            <Link
                                target="_blank"
                                style={{ textDecoration: 'auto', color: 'grey' }}
                                href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/35973/Skynet-Arnold-Reporting-Portal"
                                rel="noreferrer"
                            >
                                Powered by Skynet
                            </Link>
                            <br />
                            <span>Microsoft {new Date().getFullYear()}</span>
                        </div>
                    </div>
                </div>
            )}
        </Transition>
    );
};

const footerNavLinkGroups: INavLinkGroup[] = [
    {
        expandAriaLabel: 'Show Default Links',
        links: [
            {
                name: 'Report a Bug',
                url: 'https://aka.ms/cre_reportingbug',
                key: 'reportABug',
                icon: 'bug',
                target: '_blank'
            },
            {
                name: 'Send Feedback',
                url: 'https://aka.ms/cre_reportingfeedback',
                key: 'sendFeedback',
                icon: 'Feedback',
                target: '_blank'
            }
        ]
    }
];
export { LeftNav };
