import { AUTH_AAD_AUTHORITY, AUTH_AAD_CLIENT_ID, AUTH_AAD_REDIRECT_URI } from '../configs/env.config';

export const msalConfig = {
    auth: {
        clientId: AUTH_AAD_CLIENT_ID,
        authority: AUTH_AAD_AUTHORITY,
        redirectUri: AUTH_AAD_REDIRECT_URI
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};
