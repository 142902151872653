import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useMemo, useState } from 'react';

import { getUserInfo } from '../../../utils/userUtility';
import { UserInfo } from '../types/Types';

type FlightCreatorFilterComboBoxProps = {
    selectedKeys: string[];
    setSelectedKeys: (creatorIds: string[]) => void;
    teamMembers?: UserInfo[];
    others?: UserInfo[];
};

/**

 * Displays the flight filter by creator combobox.
 *
 * @param props The props for the component.
 * @returns The flight filter by creator combobox.
 */
const FlightCreatorFilterComboBox: React.FC<FlightCreatorFilterComboBoxProps> = (props) => {
    const { teamMembers, others, selectedKeys, setSelectedKeys } = props;
    const me = useConst(getUserInfo());
    const options = useMemo<IComboBoxOption[]>(() => {
        let optionList: IComboBoxOption[] = [
            { key: 'selectAll', text: 'Select All', itemType: SelectableOptionMenuItemType.SelectAll },
            { key: 'me', text: 'Me', itemType: SelectableOptionMenuItemType.Header },
            { key: me.id, text: me.displayName }
        ];
        if (teamMembers) {
            optionList = optionList
                .concat([
                    { key: 'divider_1', text: '-', itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'teamMembers', text: 'Team members', itemType: SelectableOptionMenuItemType.Header }
                ])
                .concat(
                    teamMembers.map((user) => ({
                        key: user.id,
                        text: user.displayName
                    }))
                );
        }
        if (others) {
            optionList = optionList
                .concat([
                    { key: 'divider_2', text: '--', itemType: SelectableOptionMenuItemType.Divider },
                    { key: 'others', text: 'Others', itemType: SelectableOptionMenuItemType.Header }
                ])
                .concat(
                    others
                        ? others.map((user) => ({
                              key: user.id,
                              text: user.displayName
                          }))
                        : []
                );
        }
        const textCount = new Map<string, number>();
        for (const option of optionList) {
            textCount.set(option.text, (textCount.get(option.text) || 0) + 1);
        }
        for (const option of optionList) {
            if ((textCount.get(option.text) || 0) > 1) {
                option.text += ` (${option.key})`;
            }
        }
        return optionList;
    }, [teamMembers, others]);

    const selectableOptions = useMemo(
        () =>
            options.filter(
                (option) => (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled
            ),
        [props]
    );
    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeys.filter((key) => key !== 'selectAll');

        if (option) {
            if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
                setSelectedKeys(selectedKeys.filter((key) => key !== 'selectAll'));
                if (selectedAll) {
                    setSelectedKeys([]);
                    setSelectedAll(false);
                } else {
                    setSelectedKeys(['selectAll', ...selectableOptions.map((o) => o.key as string)]);
                    setSelectedAll(true);
                }
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option?.key as string]
                    : currentSelectedOptionKeys.filter((k) => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                setSelectedKeys(updatedKeys);
            }
        }
    };

    return (
        <ComboBox
            placeholder="Creators"
            multiSelect
            options={options}
            selectedKey={selectedKeys}
            onChange={onChange}
            allowFreeInput
            autoComplete="on"
        />
    );
};

export default FlightCreatorFilterComboBox;
