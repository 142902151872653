import { ITextField, TextField } from '@fluentui/react';
import React, { createRef, useEffect } from 'react';

import { textFieldStyles } from '../styles/RingmasterPageStyle';

type IDInputProps = {
    setId: (val: string) => void;
    resetInput: boolean;
};

/**
 * Renders a text field which takes an id as input.
 *
 * @param props A set of props for the component.
 * @returns A textual field for the user.
 */
const IDInput: React.FC<IDInputProps> = (props) => {
    // ========================= State =========================
    const [textValue, setTextValue] = React.useState<string>('');

    const textFieldRef = createRef<ITextField>();
    // ========================= Hooks =========================
    useEffect(() => {
        setTextValue('');
    }, [props.resetInput]);

    // ========================= Event handlers =========================
    const onChangeOfTextValue = (value: string): void => {
        setTextValue(value);
        if (isValidRingmasterGuid(value)) {
            props.setId(value);
        } else {
            props.setId('');
        }
    };

    function isValidRingmasterGuid(guid: string): boolean {
        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return guidRegex.test(guid);
    }

    function getIDInputErrorMessage(value: string): string {
        if (!value) {
            return 'Input the GUID of a User/Tenant for Ringmaster ring change request';
        }

        if (value && isValidRingmasterGuid(value)) {
            return '';
        }

        return 'Invalid GUID';
    }

    // ========================= Render =========================
    return (
        <TextField
            styles={textFieldStyles}
            placeholder="00000000-0000-0000-0000-000000000000"
            value={textValue}
            componentRef={textFieldRef}
            validateOnLoad={false}
            validateOnFocusIn
            validateOnFocusOut
            deferredValidationTime={500}
            onGetErrorMessage={getIDInputErrorMessage}
            onChange={(event, value) => {
                if (value !== undefined) {
                    onChangeOfTextValue(value);
                }
            }}
        />
    );
};

export default IDInput;
