import { SKYNET_BASE_URL } from '../configs/env.config';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { ActiveCherryPicksResponse } from './models/activeCherryPicksResponse';
import ApiResponseError from './models/error/ApiResponseError';

/**
 * Service for fetching active cherry picks from Skynet.
 */
class ActiveCherryPicksService {
    private apiService: ApiService;
    private activeCherryPicksBaseUri: string;

    /**
     * Initializes an instance of ActiveCherryPicksService.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.activeCherryPicksBaseUri = `${SKYNET_BASE_URL}/api/ado/`;
    }

    /**
     * Gets all the pull request status for the given parameters.
     *
     * @param projectName The project name.
     * @param repositoryName The repository name.
     * @param branchName The branch name.
     * @returns The pull requests status.
     */
    public async getAllPullRequestStatus(projectName: string, repositoryName: string, branchName: string) {
        const params = new URLSearchParams({
            projectName,
            repositoryName,
            branchName
        });
        const url = `${this.activeCherryPicksBaseUri}/pullrequest/status` + `?${params.toString()}`;

        try {
            const activeCherryPicksResponse = await this.apiService.get<ActiveCherryPicksResponse>(url);
            if (!activeCherryPicksResponse) {
                throw new Error('Active cherry picks response is null.');
            }
            if (!activeCherryPicksResponse.activePullRequests || !activeCherryPicksResponse.completedPullRequests) {
                throw new Error('Active cherry picks response required field(s) is/are null.');
            }

            return {
                activePullRequests: activeCherryPicksResponse.activePullRequests,
                completedPullRequests: activeCherryPicksResponse.completedPullRequests
            };
        } catch (error) {
            let errorMessage = `Failed to get the pull requests status.`;
            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }
            throw new Error(errorMessage);
        }
    }
}

export default ActiveCherryPicksService;
