import { FontIcon, Link, Spinner, SpinnerSize } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { BuildMetadataV2, ClientType, Environment, Experience, OS, Rollout } from 'skynet-client';

import SkynetRedRiderService from '../../../../services/skynetRedRider.service';
import { appInsightsClient } from '../../../../utils/appInsightsUtility';
import { TeamsForLifeUrl, TeamsForWorkUrl, clientTypes } from '../../configs/defaults';
import {
    getStableAndExperimentalVersionForDesktop,
    getElectronVersion,
    getSlimcoreVersion,
    getWebClientRelatedDetails,
    getTsCallingVersion
} from '../../utils';

type BuildDetailsProps = {
    clientType: ClientType;
    os?: OS;
    environment: Environment;
    experience?: Experience;
    ring?: string;
    build: BuildMetadataV2;
    rollout?: Rollout;
};

const badgeStyles = {
    border: '1px solid',
    borderRadius: '3px',
    fontSize: '12px',
    padding: '3px 5px',
    margin: '5px 10px 5px 5px',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    borderColor: '#605e5c',
    backgroundColor: '#f3f2f1',
    color: '#605e5c',
    display: 'inline-flex'
};

/**
 * Displays the build details.
 *
 * @param props The props.
 * @returns The Build Details component.
 */
const BuildDetails: React.FC<BuildDetailsProps> = (props) => {
    const { build } = props;
    const buildId = build.id?.toString() ?? '';
    const [cifxPassPercentage, setCifxPassPercentage] = useState<number>();

    useEffect(() => {
        if (props.os && props.experience && props.ring) {
            const skynetRedRiderService = new SkynetRedRiderService();
            skynetRedRiderService
                .getTestPassPercentage(
                    props.clientType,
                    props.os,
                    props.environment,
                    props.experience,
                    props.ring.split('_')[0].toLocaleLowerCase(),
                    props.ring.split('_')[1].toLocaleLowerCase(),
                    buildId
                )
                .then((passPercentage) => {
                    setCifxPassPercentage(passPercentage * 100);
                })
                .catch((error) => {
                    appInsightsClient.logException(
                        { exception: error },
                        { message: 'catch error in processing getRedRiderTestPassPercentage' }
                    );
                });
        }
    }, []);

    return (
        <div style={{ marginBottom: '0.1rem' }}>
            <table style={{ width: '100%' }}>
                <tr>
                    <td>
                        <span style={{ margin: '0.2rem' }}>[{build.definitionName}]:</span>
                        <Link href={build.link} target="_blank" rel="noreferrer">
                            {build.buildNumber}
                        </Link>
                        {props.clientType !== clientTypes.MOBILE && !props.ring && (
                            <>
                                <br />
                                <Link
                                    href={getRedRiderHyperlink(props.clientType, build.id?.toString() ?? '')}
                                    target="_blank"
                                    style={{ margin: '0.2rem' }}
                                >
                                    CiFx Test Results
                                </Link>
                                <br />
                            </>
                        )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                        {props.clientType !== clientTypes.MOBILE && props.ring && (
                            <>
                                {cifxPassPercentage === undefined ? (
                                    <div style={badgeStyles}>
                                        <Spinner size={SpinnerSize.xSmall} style={{ marginRight: '3px' }} />
                                        <span>Fetching CiFx Results...</span>
                                    </div>
                                ) : (
                                    <Link
                                        href={getRedRiderHyperlink(props.clientType, build.id?.toString() ?? '')}
                                        target="_blank"
                                        style={badgeStyles}
                                    >
                                        <FontIcon iconName="TestCase" style={{ marginRight: '3px' }} /> CiFx Passed Tests:{' '}
                                        {cifxPassPercentage?.toFixed(1)}%
                                    </Link>
                                )}
                            </>
                        )}
                    </td>
                </tr>
            </table>
            {shouldShowContainerBuildLink(props) && props.rollout?.ring && props.rollout.buildVersion ? (
                getContainerBuildLink(props.clientType, props.environment, props.rollout.ring, props.rollout.buildVersion)
            ) : (
                <></>
            )}
            {props.clientType === ClientType.Desktop ? getDesktopRelatedDetails(build.tags) : <></>}
            {props.clientType === ClientType.Maglev ? getMaglevRelatedDetails(build.tags) : <></>}
            {props.clientType === ClientType.WebClient ? getWebRelatedDetails(build.tags) : <></>}
            {props.clientType === ClientType.ReactWebClient ? getRWCRelatedDetails(build.tags) : <></>}
        </div>
    );
};

function shouldShowContainerBuildLink(props: BuildDetailsProps) {
    return (props.clientType === clientTypes.WEB || props.clientType === clientTypes.RWC) && props.rollout?.ring !== 'General';
}

function getRedRiderHyperlink(clientType: string, id: string) {
    const teamsVersion = clientType === clientTypes.MAGLEV || clientType === clientTypes.RWC ? 2 : 1;
    const redRiderLink = `https://mango-hill-010a30e1e.2.azurestaticapps.net/products/${teamsVersion}/trainbuilds/${id}`;
    return redRiderLink;
}

function getContainerBuildLink(clientType: string, environment: string, ring: string, buildVersion: string) {
    let containerBuildLinkUrl = environment === 'Life' ? TeamsForLifeUrl : TeamsForWorkUrl;
    if (clientType === clientTypes.RWC) {
        containerBuildLinkUrl += '/v2';
    }
    containerBuildLinkUrl += `?ring=${ring.toLocaleLowerCase()}&version=${buildVersion}`;
    return (
        <Link href={containerBuildLinkUrl} target="_blank" style={{ margin: '0.2rem' }}>
            Container Build Link
        </Link>
    );
}

/**
 * Gets the desktop related details.
 *
 * @param tags The tags.
 * @returns The desktop related details.
 */
function getDesktopRelatedDetails(tags?: string[]) {
    if (tags === undefined) return <></>;
    const desktopVersions = getStableAndExperimentalVersionForDesktop(tags);
    return (
        <table style={{ padding: '0.3rem', minWidth: '20rem' }}>
            <tbody>
                <tr>
                    <td>Version (S)</td>
                    <td>{desktopVersions && desktopVersions[1]}</td>
                </tr>
                <tr>
                    <td>Version (E)</td>
                    <td>{desktopVersions && desktopVersions[0]}</td>
                </tr>
                <tr>
                    <td>Electron (S)</td>
                    <td>{getElectronVersion(tags, 'stable')}</td>
                </tr>
                <tr>
                    <td>Electron (E)</td>
                    <td>{getElectronVersion(tags, 'experimental')}</td>
                </tr>
                <tr>
                    <td>Slimcore (S)</td>
                    <td>{getSlimcoreVersion(tags, clientTypes.DESKTOP, 'stable')}</td>
                </tr>
                <tr>
                    <td>Slimcore (E)</td>
                    <td>{getSlimcoreVersion(tags, clientTypes.DESKTOP, 'experimental')}</td>
                </tr>
                <tr>
                    <td>TsCalling</td>
                    <td>{getTsCallingVersion(tags)}</td>
                </tr>
            </tbody>
        </table>
    );
}

/**
 * Gets the maglev related details.
 *
 * @param tags The tags.
 * @returns The desktop related details.
 */
function getMaglevRelatedDetails(tags?: string[]) {
    if (tags === undefined) return <></>;

    return (
        <table style={{ padding: '0.3rem', minWidth: '20rem' }}>
            <tbody>
                <tr>
                    <td>Slimcore (S)</td>
                    <td>{getSlimcoreVersion(tags, clientTypes.MAGLEV, undefined)}</td>
                </tr>
                <tr>
                    <td>TsCalling</td>
                    <td>{getTsCallingVersion(tags)}</td>
                </tr>
            </tbody>
        </table>
    );
}

/**
 * Gets the web related details.
 *
 * @param tags The tags.
 * @returns The web related details.
 */
function getWebRelatedDetails(tags?: string[]) {
    if (tags === undefined) return <></>;

    return (
        <table style={{ padding: '0.3rem', minWidth: '20rem' }}>
            <tr>
                <td>Orbital - multi-window</td>
                <td>{getWebClientRelatedDetails(tags, 'multi-window')}</td>
            </tr>
            <tr>
                <td>Orbital - files</td>
                <td>{getWebClientRelatedDetails(tags, 'files')}</td>
            </tr>
            <tr>
                <td>TsCalling</td>
                <td>{getTsCallingVersion(tags)}</td>
            </tr>
        </table>
    );
}

/**
 * Gets the RWC related details.
 *
 * @param tags The tags.
 * @returns The web related details.
 */
function getRWCRelatedDetails(tags?: string[]) {
    if (tags === undefined) return <></>;

    return (
        <table style={{ padding: '0.3rem', minWidth: '20rem' }}>
            <tr>
                <td>TsCalling</td>
                <td>{getTsCallingVersion(tags)}</td>
            </tr>
        </table>
    );
}

export { BuildDetails };
