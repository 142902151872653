import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WorkItem } from 'skynet-client';

import { Card } from '../../../../components/Card';
import ErrorNotification from '../../../../components/ErrorNotification/ErrorNotification';
import { ProcessedReleaseSchedule } from '../../../../services/models/ReleaseSchedule';
import { ProcessedTrainMetadata } from '../../../../services/models/TrainMetadata';
import { appInsightsClient } from '../../../../utils/appInsightsUtility';
import { trainProgressionRowStackTokens } from '../../styles/styles';
import { getFormattedCloudRingPairs } from '../../utils';
import { TrainModal } from '../TrainModal';

import { TrainMainCard, TrainRingCard, TrainScheduleCard } from '.';

type TrainProgressionRowProps = {
    trainName: string;
    clientType: string;
    os: string;
    environment: string;
    trainDetails: ProcessedTrainMetadata | undefined;
    supportedRings: Record<string, string[]>;
    ringBlockers: Record<string, WorkItem[]>;
    schedule?: ProcessedReleaseSchedule;
    trainStatusFilter: string[];
};

/**
 * Displays the train progression row.
 *
 * @param props The props for the component.
 * @returns The train progression row.
 */
const TrainProgressionRow: React.FC<TrainProgressionRowProps> = (props) => {
    const data = props.trainDetails;

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [modalView, setModalView] = useState<string>('main');

    const location = useLocation();

    useEffect(() => {
        if (props.trainDetails !== undefined) {
            const queryParams = new URLSearchParams(location.search);
            const train = queryParams.get('trainName');
            if (train && train === props.trainName) {
                const element = document.getElementById(train);
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
        }
    }, [location.search, props.trainDetails]);

    if (data?.status !== undefined && !props.trainStatusFilter.includes(data.status)) {
        return <></>;
    } else {
        return (
            <div id={props.trainName}>
                {data === undefined && (
                    <Card>
                        <ErrorNotification msg={`Unable to fetch train progression data for ${props.trainName}`} />
                    </Card>
                )}
                {data?.status !== undefined && props.trainStatusFilter.includes(data.status) && (
                    <Stack horizontal tokens={trainProgressionRowStackTokens} styles={{ root: { minWidth: '100%', minHeight: '7rem' } }}>
                        <TrainMainCard
                            processedTrainMetadata={data}
                            onClick={() => {
                                setModalView('main');
                                showModal();
                                appInsightsClient.logEvent(
                                    { name: 'train-main-card-clicked' },
                                    {
                                        train: props.trainName,
                                        trainStatus: data.status
                                    }
                                );
                            }}
                        />
                        {getFormattedCloudRingPairs(props.supportedRings).map((ring) => {
                            if (data.rolloutDict[ring] !== undefined) {
                                const cardProps = getTrainRingCardProps(data, props.ringBlockers[ring.toLocaleLowerCase()] ?? [], ring);
                                return (
                                    <TrainRingCard
                                        key={ring}
                                        {...cardProps}
                                        onClick={() => {
                                            setModalView(ring);
                                            showModal();
                                            appInsightsClient.logEvent(
                                                { name: 'train-ring-card-clicked' },
                                                {
                                                    train: props.trainName,
                                                    trainStatus: data.status,
                                                    ring: ring
                                                }
                                            );
                                        }}
                                    />
                                );
                            } else if (props.schedule !== undefined && props.schedule.trainSchedule[ring] !== undefined && data.status) {
                                return (
                                    <TrainScheduleCard
                                        key={ring}
                                        ring={ring}
                                        dueDate={props.schedule.trainSchedule[ring]}
                                        ringBlockers={props.ringBlockers[ring.toLocaleLowerCase()] ?? []}
                                        trainStatus={data.status}
                                        onClick={() => {
                                            setModalView(ring);
                                            showModal();
                                            appInsightsClient.logEvent(
                                                { name: 'train-schedule-card-clicked' },
                                                {
                                                    trainName: props.trainName,
                                                    trainStatus: data.status,
                                                    ring: ring
                                                }
                                            );
                                        }}
                                    />
                                );
                            } else {
                                return <div key={ring}></div>;
                            }
                        })}
                        <TrainModal
                            isOpen={isModalOpen}
                            onDismiss={hideModal}
                            trainData={data}
                            ringBlockers={props.ringBlockers}
                            schedule={props.schedule}
                            view={modalView}
                            latestProdRingPropsForTrain={getLatestProdRingPropsForTrain(props.supportedRings, data)}
                        />
                    </Stack>
                )}
            </div>
        );
    }
};

function getLatestRollout(data: ProcessedTrainMetadata, ring: string) {
    const latestRollout = data.rolloutDict[ring].reduce((previous, current) => {
        if (current.completedOn === undefined || previous.completedOn === undefined) {
            return current;
        }
        if (current.completedOn < previous.completedOn) {
            return previous;
        } else {
            return current;
        }
    });
    const lastRollouts = [latestRollout];
    // Check if the rollout needs to be paired with another rollout (in case of desktop)
    const possiblePairs = data.rolloutDict[ring].filter((x) => {
        if (x.completedOn !== undefined && latestRollout.completedOn !== undefined) {
            const timeDifference = new Date(latestRollout.completedOn).getTime() - new Date(x.completedOn).getTime();
            // If the time difference is less than 2.5 hours, and only the build flavor is different then pair the rollouts
            return (
                x.percent === latestRollout.percent &&
                x.environment === latestRollout.environment &&
                Math.abs(timeDifference) < 2.5 * 60 * 60 * 1000 &&
                x.buildFlavor !== latestRollout.buildFlavor
            );
        }
    });
    if (possiblePairs.length > 0) {
        lastRollouts.push(possiblePairs[possiblePairs.length - 1]);
    }

    return lastRollouts;
}

function getTrainRingCardProps(data: ProcessedTrainMetadata, ringBlockers: WorkItem[], ring: string) {
    const firstRollout = data.rolloutDict[ring].reduce((previous, current) => {
        if (current.completedOn === undefined || previous.completedOn === undefined) {
            return current;
        }
        if (current.completedOn > previous.completedOn) {
            return previous;
        } else {
            return current;
        }
    });
    const lastRollouts = getLatestRollout(data, ring);
    return {
        trainId: data.id ?? 'N/A',
        trainStatus: data.status ?? 'Active',
        ring: ring,
        firstRollout: firstRollout,
        lastRollouts: lastRollouts,
        ringBlockers: ringBlockers
    };
}

function getLatestProdRingPropsForTrain(supportedRings: Record<string, string[]>, data: ProcessedTrainMetadata) {
    const supportedRingsLatestRollouts = getFormattedCloudRingPairs(supportedRings)
        .map((ring) => {
            if (data?.rolloutDict[ring] !== undefined) {
                const latestRollout = getLatestRollout(data, ring);
                return { ring, lastRollout: latestRollout };
            }
            return null;
        })
        .filter((item) => item !== null);

    const latestProdRingRollout = supportedRingsLatestRollouts.reverse().find((item) => item?.ring.startsWith('Prod'));

    if (latestProdRingRollout === null || latestProdRingRollout === undefined) {
        return undefined;
    }

    const lastRollouts = latestProdRingRollout.lastRollout;

    let lastRolloutPercent = lastRollouts[0].percent ?? 0;
    let buildVersion = lastRollouts[0].buildVersion;
    if (lastRollouts.length > 1) {
        lastRolloutPercent = 0;
        lastRollouts.forEach((rollout) => {
            lastRolloutPercent += rollout.percent ?? 0;
        });
        lastRolloutPercent = Math.min(100, lastRolloutPercent);

        buildVersion = lastRollouts.find((item) => item.buildFlavor === 'Stable')?.buildVersion || '';
    }

    return {
        ring: latestProdRingRollout?.ring,
        buildVersion: buildVersion ?? 'N/A',
        percentage: lastRolloutPercent
    };
}

export { TrainProgressionRow };
