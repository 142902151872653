export const AUTH_AAD_CLIENT_ID = import.meta.env.VITE_APP_CLIENT_ID ?? 'Not Defined';
export const AUTH_AAD_AUTHORITY = import.meta.env.VITE_APP_AUTHORITY ?? 'Not Defined';
export const AUTH_AAD_REDIRECT_URI = import.meta.env.VITE_APP_REDIRECT_URI ?? 'Not Defined';

export const SKYNET_BASE_URL = import.meta.env.VITE_APP_SKYNET_BASE_URI ?? 'Not Defined';
export const SKYNET_SCOPE = import.meta.env.VITE_APP_SKYNET_SCOPE?.split(',') ?? [];

export const FFV2_AZURE_FUNCTION_URL = import.meta.env.VITE_APP_FFV2_AZURE_FUNCTION_URL ?? 'Not Defined';
export const FFV2_SCOPE = import.meta.env.VITE_APP_FFV2_SCOPE?.split(',') ?? [];

export const RINGMASTER_BASE_URL = import.meta.env.VITE_APP_RINGMASTER_BASE_URI ?? 'Not Defined';
export const RINGMASTER_SCOPE = import.meta.env.VITE_APP_RINGMASTER_SCOPE?.split(',') ?? [];

export const REDLINE_BASE_URL = import.meta.env.VITE_APP_REDLINE_BASE_URI ?? 'Not Defined';
export const REDLINE_SCOPE = import.meta.env.VITE_APP_REDLINE_SCOPE?.split(',') ?? [];

export const ONEBOT_URI = import.meta.env.VITE_APP_ONEBOT_URI ?? 'Not Defined';
export const ONEBOT_SCOPE = import.meta.env.VITE_APP_ONEBOT_SCOPE?.split(',') ?? [];

export const DEFAULT_SCOPE = import.meta.env.VITE_APP_MS_GRAPH_SCOPES?.split(',') ?? [];

export const APPINSIGHT_CONN_STRING = import.meta.env.VITE_APP_APPLICATIONINSIGHTS_CONNECTION_STRING ?? 'InstrumentationKey=0';
