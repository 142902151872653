import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { withAITracking as _withAITracking, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
    ApplicationInsights,
    ICustomProperties,
    IEventTelemetry,
    IExceptionTelemetry,
    IPageViewTelemetry,
    ITraceTelemetry
} from '@microsoft/applicationinsights-web';

import { APPINSIGHT_CONN_STRING } from '../configs/env.config';

class AppInsightsClient {
    private client: ApplicationInsights;
    private reactPlugin: ReactPlugin;
    private authenticatedUserID: string;

    /**
     * Initializes the application insights client.
     *
     * @param connectionString The connection string for the application insights instance.
     * @param uniqueId The unique id for the user.
     */
    constructor(connectionString: string, uniqueId = 'unknown') {
        this.reactPlugin = new ReactPlugin();
        this.authenticatedUserID = uniqueId;

        const clickPluginInstance = new ClickAnalyticsPlugin();
        // Click Analytics configuration
        const clickPluginConfig = {
            autoCapture: true,
            dataTags: {
                useDefaultContentNameOrId: true
            }
        };

        // set user context in application insights object
        // Application insights configuration
        this.client = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                extensions: [this.reactPlugin, clickPluginInstance],
                enableCorsCorrelation: true,
                enableAutoRouteTracking: false,
                extensionConfig: {
                    [clickPluginInstance.identifier]: clickPluginConfig
                },
                samplingPercentage: 100
            }
        });
        this.client.loadAppInsights();
    }

    /**
     * Wraps the component with the application insights tracking.
     *
     * @param properties - The properties to be logged.
     * @returns Custom properties with unique id.
     */
    private getEnrichedProperties(properties: ICustomProperties | undefined): ICustomProperties | undefined {
        if (this.authenticatedUserID) {
            properties = {
                ...properties,
                authenticatedUserID: this.authenticatedUserID
            };
        }
        return properties;
    }

    /**
     * Set the unique id for the user to be used in custom properties.
     *
     * @param uniqueId The unique id for the user.
     */
    setAuthenticatedUserContext(uniqueId: string) {
        this.authenticatedUserID = uniqueId;
        if (this.authenticatedUserID?.length > 0 && this.authenticatedUserID !== 'unknown') {
            this.client.setAuthenticatedUserContext(uniqueId, undefined, true);
        }
    }

    /**
     * Logs a trace message.
     *
     * @param trace The trace message.
     * @param properties The custom properties.
     */
    logTrace(trace: ITraceTelemetry, properties: ICustomProperties | undefined = undefined) {
        this.client.trackTrace(trace, this.getEnrichedProperties(properties));
    }

    /**
     * Logs an event.
     *
     * @param event The event.
     * @param properties The custom properties.
     */
    logEvent(event: IEventTelemetry, properties: ICustomProperties | undefined = undefined) {
        this.client.trackEvent(event, this.getEnrichedProperties(properties));
    }

    /**
     * Logs an exception.
     *
     * @param exception The exception.
     * @param properties The custom properties.
     */
    logException(exception: IExceptionTelemetry, properties?: ICustomProperties) {
        this.client.trackException(exception, this.getEnrichedProperties(properties));
    }
    /**
     * Tracks a page view.
     *
     * @param pageView The page view.
     */
    trackPageView(pageView?: IPageViewTelemetry) {
        if (pageView) {
            pageView.properties = this.getEnrichedProperties(pageView.properties);
        }
        this.client.trackPageView(pageView);
    }

    /**
     * Wraps a component with the application insights tracking.
     *
     * @param component The component to wrap.
     * @param componentName The name of the component.
     * @returns The wrapped component.
     */
    withAITracking<P>(
        component: React.ComponentType<P>,
        componentName?: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): React.ComponentClass<P, any> {
        return _withAITracking(this.reactPlugin, component, componentName);
    }
}

export const appInsightsClient = new AppInsightsClient(APPINSIGHT_CONN_STRING);
