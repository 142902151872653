import { ICardStyles } from '../../../components/Card';

export const flightStatusCardStyles: Partial<ICardStyles> = {
    root: {
        borderRadius: '0.3rem',
        borderLeft: '0.3rem solid'
    },
    body: {
        fontSize: '0.9rem',
        padding: '0.3rem'
    },
    footer: {
        fontSize: '0.8rem',
        color: 'grey',
        width: '90%'
    }
};

export const flightStatusCardStyleMap: Record<string, Partial<ICardStyles>> = {
    Blocked: {
        root: {
            borderLeftColor: 'rgba(218,10,0,1)'
        }
    },
    Active: {
        root: {
            borderLeftColor: 'rgba(0,120,212,1)'
        }
    },
    Completed: {
        root: {
            borderLeftColor: 'rgba(16,124,16,1)'
        }
    },
    Abandoned: {
        root: {
            background: 'linear-gradient(to bottom right, white 50% 51%, rgba(0, 0, 0, 0.25) 51% 52%, white 52%)',
            opacity: 0.6
        }
    },
    Default: {
        root: {
            borderLeftColor: 'rgba(102,102,102,1)'
        }
    }
};
