// Temporarily hardcoding the category names until JSON validation completes
export const categoryNames = [
    'activity',
    'activityNotification',
    'ageConsent',
    'apiService',
    'artifactsPlatform',
    'augloop',
    'auth',
    'autosuggest',
    'biometricEnrollment',
    'brb',
    'breakoutRoom',
    'broadcast',
    'byod',
    'calendar',
    'calling',
    'cdl',
    'cdlWorker',
    'channels',
    'chat',
    'chatList',
    'chatService',
    'cmdMeetingArtifactsService',
    'coach',
    'communities',
    'compose',
    'consumerGroups',
    'conversationsReminder',
    'copilot',
    'core',
    'cortana',
    'crossDeviceAttach',
    'csaService',
    'default',
    'diagnostics',
    'discoverSurface',
    'distributionLists',
    'dynamicsMarketing',
    'edu',
    'embedClient',
    'esp',
    'extensibility',
    'externalAccounts',
    'federated',
    'feedback',
    'files',
    'fluidThread',
    'flyout',
    'fre',
    'freemium',
    'gallery',
    'giphy',
    'growth',
    'guardians',
    'help',
    'homepage',
    'host',
    'hostCapabilities',
    'hotdesking',
    'imageCache',
    'immersive',
    'inviteLinks',
    'lightMeetings',
    'liveStateService',
    'lmsIntegration',
    'localization',
    'localMigrated',
    'marketplace',
    'meet',
    'meetingArtifacts',
    'meetingBot',
    'meetingCollaboration',
    'meetingConversation',
    'meetingExtensibility',
    'meetingNotes',
    'meetingOptions',
    'meetingRecap',
    'meetingRoom',
    'meetingSchedulingService',
    'meetingStream',
    'meetingTemplates',
    'meetup',
    'mercuryDesign',
    'messaging',
    'metadata',
    'mobile',
    'notifications',
    'notificationsEngine',
    'onboarding',
    'organization',
    'outlook',
    'people',
    'peopleTargeting',
    'phonelinkSms',
    'places',
    'platform',
    'premium',
    'presence',
    'proximityAdvertiser',
    'proximityScanner',
    'pulsar',
    'queues',
    'room',
    'sbs',
    'sdk',
    'search',
    'searchCommon',
    'security',
    'sensitivityLabel',
    'sensitivityUpdate',
    'serp',
    'shortcuts',
    'sidecars',
    'simpleCollab',
    'smb',
    'streaming',
    'surveyForm',
    'sync',
    'teamsAndChannels',
    'teamsBuilds',
    'teamsCopilotAggregator',
    'teamsCopilotService',
    'teamsInsider',
    'teamsPushService',
    'telemetry',
    'tenant',
    'test',
    'transcriptAttribution',
    'trouterService',
    'ufd',
    'userResources',
    'virtualAppointments',
    'virtualConsumerEvents',
    'virtualEvents',
    'webinarRegistration',
    'webrtcVDI',
    'whatsNew',
    'wiki',
    'windowsSignatureExperiences'
];
