import { DetailsList, IColumn, Link, SelectionMode, Spinner, SpinnerSize, Text, TooltipHost } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { ClientType, Environment, OS } from 'skynet-client';

import { Card, CardHeader } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import { ProcessedReleaseSchedule } from '../../services/models/ReleaseSchedule';
import ScheduleService from '../../services/schedule.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import { scheduleCardStyles, scheduleDetailsListStyles } from './styles/styles';
import { getLongDateTimeString, getShortDateTimeString, getFormattedCloudRingPairs } from './utils';

type ReleaseScheduleDetailsProps = {
    clientType: ClientType;
    os: OS;
    environment: Environment;
    supportedRings: Record<string, string[]>;
    setSchedules: React.Dispatch<React.SetStateAction<ProcessedReleaseSchedule[]>>;
    isDataCollapsed: boolean;
};

/**
 * Displays the release schedule details.
 *
 * @param props The props.
 * @returns The Release Schedule Details component.
 */
const ReleaseScheduleDetails: React.FC<ReleaseScheduleDetailsProps> = (props) => {
    const [data, setData] = useState<ProcessedReleaseSchedule[]>([]);
    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    const [tableColumns, setTableColumns] = useState<IColumn[]>([]);

    useEffect(() => {
        setIsLoading();
        setIsDataNotLoaded();

        const scheduleService = new ScheduleService();
        scheduleService
            .getScheduledReleasePlan({
                clientType: props.clientType,
                os: props.os,
                environment: props.environment,
                experience: 'None',
                days: 90
            })
            .then((response) => {
                props.setSchedules(response);

                const filteredData = response
                    .filter((item) => new Date(item.snapDate) >= new Date(new Date().setDate(new Date().getDate() - 7)))
                    .slice(0, 10);
                setData(filteredData);
                setIsDataLoaded();
                setIsNotLoading();

                setTableColumns(prepareColumns(props.supportedRings));

                appInsightsClient.logTrace(
                    {
                        message: `Fetched scheduled release plan from ShiproomService`
                    },
                    { clientType: props.clientType, os: props.os, environment: props.environment }
                );
            })
            .catch((fail) => {
                setIsDataNotLoaded();
                setIsNotLoading();

                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect in ReleaseScheduleDetails`,
                        clientType: props.clientType,
                        os: props.os,
                        environment: props.environment
                    }
                );
            });
    }, [props.clientType, props.os, props.environment]);

    return (
        <div style={{ display: props.isDataCollapsed ? 'none' : 'block' }}>
            <Card styles={scheduleCardStyles}>
                <CardHeader>
                    <Link href="/schedule" target="_blank" styles={{ root: { color: 'black' } }}>
                        Release Schedule
                    </Link>
                    <Text variant="small" styles={{ root: { color: 'red', marginLeft: '8px' } }}>
                        (Note: Code Cutoff for all trains is Thursday by 5:00 PM PST)
                    </Text>
                </CardHeader>
                {!isLoading && !isDataLoaded && <ErrorNotification msg="Unable to fetch release schedules" />}
                {isLoading && <Spinner size={SpinnerSize.large} label="Fetching schedules..." />}
                {!isLoading && isDataLoaded && (
                    <DetailsList
                        className="release-schedule-details-list"
                        items={data}
                        columns={tableColumns}
                        selectionMode={SelectionMode.none}
                        styles={scheduleDetailsListStyles}
                    />
                )}
            </Card>
        </div>
    );
};

function prepareColumns(supportedRings: Record<string, string[]>): IColumn[] {
    const columns: IColumn[] = [
        {
            key: 'trainName',
            name: 'Train',
            fieldName: 'trainName',
            minWidth: 130,
            maxWidth: 130,
            onRender: (item: ProcessedReleaseSchedule) => {
                return <span>{item.trainName}</span>;
            }
        },
        {
            key: 'codeCutoffDate',
            name: 'Code Cutoff',
            fieldName: 'codeCutoffDate',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ProcessedReleaseSchedule) => {
                const codeCutOffDate = new Date(item.snapDate);
                codeCutOffDate.setDate(codeCutOffDate.getDate() - 3);
                codeCutOffDate.setHours(codeCutOffDate.getHours() - 3);
                codeCutOffDate.setMinutes(codeCutOffDate.getMinutes() - 30);
                return <TooltipHost content={getLongDateTimeString(codeCutOffDate)}>{getShortDateTimeString(codeCutOffDate)}</TooltipHost>;
            }
        },
        {
            key: 'snapDate',
            name: 'Snap',
            fieldName: 'snapDate',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ProcessedReleaseSchedule) => {
                const snapDate = new Date(item.snapDate);
                return <TooltipHost content={getLongDateTimeString(snapDate)}>{getShortDateTimeString(snapDate)}</TooltipHost>;
            }
        }
    ];
    const cloudRingPairs = getFormattedCloudRingPairs(supportedRings);
    const cleanCloudRingPairs = getFormattedCloudRingPairs(supportedRings, false, ' ');
    cloudRingPairs.forEach((ring, index) => {
        columns.push({
            key: ring,
            name: cleanCloudRingPairs[index],
            fieldName: ring,
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ProcessedReleaseSchedule) => {
                if (item.trainSchedule[ring] !== undefined) {
                    return (
                        <TooltipHost content={getLongDateTimeString(item.trainSchedule[ring])}>
                            {getShortDateTimeString(item.trainSchedule[ring])}
                        </TooltipHost>
                    );
                }
                return <></>;
            }
        });
    });

    return columns;
}

export default ReleaseScheduleDetails;
