import { releaseManagers } from './configs/defaults';

/**
 * Checks if the currently logged in user is a release manager.
 *
 * @param userEmail The email of the currently logged in user.
 * @returns True if the user is a release manager, false otherwise.
 */
export function isUserReleaseManager(userEmail: string) {
    const user = userEmail.split('@', 1)[0];
    return releaseManagers.includes(user);
}
