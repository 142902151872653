import { Icon, Link, Stack, Text, TooltipHost, assign } from '@fluentui/react';
import React from 'react';

import { Card, CardFooter, CardHeader, ICardStyles } from '../../../components/Card';
import IconWithBadge from '../../../components/IconWithBadge/IconWithBadge';
import { getADOWorkItemURL } from '../../../utils/adoUtility';
import { gapStackTokensSmall } from '../styles/FFv2Style';
import { flightStatusCardStyleMap, flightStatusCardStyles } from '../styles/FlightInfoStyle';
import { Blocker, ProcessedFlightRollout } from '../types/Types';

type FlightStatusCardProps = {
    flight: ProcessedFlightRollout;
    blockers: Blocker[];
};

/**
 * Displays a card with essential flight information.
 *
 * @param props The props for the component.
 * @returns The flight info card.
 */
const FlightStatusCard: React.FC<FlightStatusCardProps> = (props) => {
    const status = props.blockers.length > 0 ? 'Blocked' : props.flight.state.stageName === 'R4 100%' ? 'Completed' : 'Active';
    const mergedStyles: ICardStyles = {
        root: assign({ width: '25%' }, flightStatusCardStyles.root, flightStatusCardStyleMap[status].root),
        body: assign(flightStatusCardStyles.body, flightStatusCardStyleMap[status].body),
        footer: flightStatusCardStyles.footer
    };

    return (
        <Card styles={mergedStyles}>
            <CardHeader>
                <Stack horizontal horizontalAlign="space-between">
                    Flight status
                    {props.blockers && props.blockers.length > 0 && (
                        <TooltipHost
                            content={
                                <Stack tokens={gapStackTokensSmall}>
                                    <Text>{props.blockers.length} flight blocker(s):</Text>
                                    {props.blockers.map((blocker) => {
                                        return (
                                            <Text key={blocker.id}>
                                                <Link href={getADOWorkItemURL(blocker.id)} target="_blank">
                                                    {blocker.id}
                                                </Link>
                                            </Text>
                                        );
                                    })}
                                </Stack>
                            }
                        >
                            <IconWithBadge iconName="BugSolid" badgeText={props.blockers.length.toString()} />
                        </TooltipHost>
                    )}
                </Stack>
            </CardHeader>
            <Text variant="mediumPlus">
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {status === 'Completed' && <Icon iconName="SkypeCircleCheck" style={{ color: 'green', fontSize: 14 }} />} &nbsp;
                    {props.flight.state.stageName}
                </span>
            </Text>
            {props.flight.state.failingGates && props.flight.state.failingGates.length > 0 && (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    &nbsp;
                    <Icon iconName="Error" style={{ fontSize: 14 }} />
                    &nbsp;
                    <Text variant="small">{props.flight.state.failingGates.map((gate) => gate).join(', ')}</Text>
                </span>
            )}
            <CardFooter>
                {status === 'Completed' ? 'Completed' : 'Last updated'}: {new Date(props.flight.state.timestamp).toLocaleString()}
            </CardFooter>
        </Card>
    );
};

export default FlightStatusCard;
