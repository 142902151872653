import { Label, MessageBar, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState } from 'react';

import { FetchIDDetailsResponse } from '../../services/models/Ringmaster/RingmasterResponse';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import { defaultFindIdForm, defaultFindIdFormResponse } from './configs/defaults';
import FormButtons from './RingAssignmentPageComponents/FindIdFormButtons';
import IDInput from './RingAssignmentPageComponents/IDInput';
import RingmasterFindIdResult from './RingAssignmentPageComponents/RingmasterFindIdResult';
import { ringmasterRequestTableCellStyle, ringmasterRequestTableStyle } from './styles/RingmasterPageStyle';
import { FindIdFormData } from './types/Types';

/**
 * This component exposes the form to fetch ring details for a given ID.
 * It appears on the RingAssignmentPage as a tab.
 *
 * @returns The actual view.
 */
const FindIdForm: React.FC = () => {
    // ============================== State ==============================
    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    const [formData, setFormData] = useState<FindIdFormData>({ ...defaultFindIdForm, requestedBy: getUserInfo() });

    const [isResultCardVisible, setIsResultCardVisible] = useState<boolean>(false);

    const [formResponse, setFormResponse] = useState<FetchIDDetailsResponse>({ ...defaultFindIdFormResponse });

    const [clearFormData, setClearFormData] = useState<boolean>(false);

    // ============================== Functions ==============================

    const updateOperationId = (operationId: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            operationId: operationId
        }));
    };

    return (
        <>
            <Stack style={ringmasterRequestTableStyle}>
                <MessageBar>Provide the ID (in GUID format) you are searching for.</MessageBar>
                <Stack.Item>
                    <table>
                        <tbody>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Operation Id:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <IDInput setId={updateOperationId} resetInput={clearFormData} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Stack.Item>

                <FormButtons
                    formData={formData}
                    setFormData={setFormData}
                    setResultCardData={setFormResponse}
                    setResultCardVisibility={setIsResultCardVisible}
                    resetFormDataTrigger={setClearFormData}
                    resetData={clearFormData}
                    setResultCardIsDataLoaded={setIsDataLoaded}
                    setResultCardIsLoading={setIsLoading}
                    setResultCardIsDataNotLoaded={setIsDataNotLoaded}
                    setResultCardIsNotLoading={setIsNotLoading}
                />
            </Stack>
            <RingmasterFindIdResult
                isVisible={isResultCardVisible}
                isDataLoaded={isDataLoaded}
                isLoading={isLoading}
                ringmasterFindIdInfoResponse={formResponse}
            />
        </>
    );
};

export default appInsightsClient.withAITracking(FindIdForm, 'FindIdForm');
