export default Object.freeze({
    INVALID_TRAIN_NAME: 'Invalid train name',
    MISSING_RELEASE_SCHEDULE: 'Missing release schedule',
    MISSING_ROLLOUT_DATA: 'Missing rollout data',
    TRAIN_ON_TIME: 'Train is on time',
    TRAIN_DELAYED: 'Train delayed',
    TRAIN_ARRIVING: 'Train arriving',
    TRAIN_PASSED: ' Train passed',
    TRAIN_IN_PROGRESS: 'Train in progress',
    TRAIN_STATUS_ABANDONED: 'Abandoned',
    ROLLING_OUT: 'Rolling Out',
    LATEST_STABLE: 'Latest Stable',
    MINIMUM: 'Minimum',
    CRITICAL: 'Critical',
    ROLLING_OUT_DESC: 'The current version that is being rolled out in General ring',
    LATEST_STABLE_DESC: 'The most recent build version that is rolled out to 100% in General ring',
    MINIMUM_DESC: 'The minimum version number currently fully supported in general',
    CRITICAL_DESC:
        'The most recent out-of-support version. Clients at or below this version must be updated in order to continue using Teams.',
    ENGSYS_HEALTH_SCORECARD: 'https://aka.ms/teamsenghealth',
    RELEASE_BLOCKERS_LINK:
        'https://teams.microsoft.com/l/channel/19%3a4d0c38af8a0c4461976498b65c73444d%40thread.skype/Release%2520Blockers?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47',
    RELEASE_BLOCKERS_TEAMS_LINK:
        'https://teams.microsoft.com/l/channel/19%3ab338e87f4c654857b08d76a1813d501c%40thread.skype/Release%2520Blockers%2520Teams%25202.0?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47',
    WEB_DESKTOP_SHIPROOM_CHANNEL_LINK:
        'https://teams.microsoft.com/l/channel/19%3askypespaces_30de03bb9691421ab0b0dd33a5fb6e9e%40thread.skype/Shiproom?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47',
    CHERRY_PICK_BUG_BAR_BY_RING_LINK:
        'https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/2931/Bug-Bar-and-Priority-Definitions',
    CENTRAL_TRIAGE_APPROVAL_PROCESS_LINK:
        'https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/10542/Teams-1.0-2.0-Central-Triage-Approval-Process',
    RING_BLOCKER_BUG_DASHBOARD_LINK:
        'https://msit.powerbi.com/groups/16d407ba-b8ac-4370-91fd-1323dd51fb3b/reports/7c54bb07-2873-409e-b838-c0e2613e36cf/ReportSection3112f06398cd8ec04fb8',
    RING_BLOCKER_METRICES_DASHBOARD_LINK:
        'https://msit.powerbi.com/groups/16d407ba-b8ac-4370-91fd-1323dd51fb3b/reports/7c54bb07-2873-409e-b838-c0e2613e36cf/ReportSection269cf34eb0a5139eb775',
    RELEASE_POLICY_CADENCE_LINK: 'https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/10207/Teams-Release-Cadence',
    DESKTOP_SHIPROOM_LINK:
        'https://microsoft.sharepoint.com/teams/skypespacesteamnew/_layouts/15/Doc.aspx?sourcedoc={593fffb4-ecf4-4ea8-9a41-bbe044f040f8}&action=edit&wd=target%28Daily%20Release%20Status.one%7C3572a78a-ea94-446a-b744-2cdc77b9e567%2FHelpful%20Release%20Links%7Cbdd5f642-11d0-42db-b5ad-3170a7636ff5%2F%29&wdorigin=NavigationUrl'
});
