export const editFlightTableCellStyles = {
    padding: '5px'
};

export const editFlightFormStyles = {
    root: {
        borderRadius: '1em',
        paddingTop: '8px',
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '0px'
    }
};
