import { FontWeights, IStackTokens, getTheme, mergeStyleSets, mergeStyles } from '@fluentui/react';

const theme = getTheme();

export const gapStackTokensSmall: IStackTokens = {
    childrenGap: 5
};

export const gapStackTokensMedium: IStackTokens = {
    childrenGap: 10
};

export const gapStackTokensLarge: IStackTokens = {
    childrenGap: 20
};

export const gapStackTokensXLarge: IStackTokens = {
    childrenGap: 30
};

export const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginRight: '4px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

export const formStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    header: [
        theme.fonts.large,
        {
            borderBottom: `0.07rem solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '4px 12px 4px 24px'
        }
    ],
    body: {
        padding: '12px 18px 12px 18px',
        overflowY: 'hidden'
    }
});

export const mediumIconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 25px'
});

export const smallIconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20
});

export const extraSmallIconClass = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12
});

export const progressIndicatorCustomStyles: { itemProgress: { width: string } } = {
    itemProgress: {
        width: '100%'
    }
};

export const boldText = {
    root: {
        fontWeight: 'bold'
    }
};

export const hoverText = {
    root: {
        color: '#0078d4',
        selectors: {
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        }
    }
};

export const stackTokens: IStackTokens = { childrenGap: 10 };
