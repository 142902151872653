export function getSnapDetailsV2(snapMetadata) {
    let snapBranches = [];

    snapMetadata?.branches.forEach((el) => {
        snapBranches.push({ title: el.displayName, link: el.link });
    });
    // NOTE snapMetadata could not be empty this is bug
    return snapBranches;
}

export function extractReleaseInfo(rollouts, buildMetadatas) {
    var releaseInfo = {};
    releaseInfo = rollouts
        .filter((item) => item.completedOn !== '0001-01-01T00:00:00')
        .reduce((accum, item) => {
            var name = item.cloud + '_' + item.ring;
            if (name in accum) {
                accum[name].push(item);
            } else {
                accum[name] = [item];
            }
            return accum;
        }, {});
    var res = Object.entries(releaseInfo).reduce((accum, [key, value]) => {
        value.sort((a, b) => {
            return Date.parse(a.completedOn) - Date.parse(b.completedOn);
        });

        // calculate number of different builds
        var metaDatas = value.reduce((accum, item) => {
            if (0 === accum.filter((el) => el.id === item.buildId).length) {
                accum.push(buildMetadatas[item.buildId]);
            }
            return accum;
        }, []);

        // first stable is "first release started"
        // last stable is released finish
        // percentage sum  of last 2 element if one of them exp and other stable
        var lastTwo = value.slice(-2).reverse();

        var lastStable = lastTwo.find((el) => el.buildFlavor === 'Stable');
        var lastExp = lastTwo.find((el) => el.buildFlavor !== 'Stable');

        var percent = lastStable.percent + (lastExp?.percent ?? 0);
        accum[key] = [
            {
                percent: percent,
                rolloutEarlyStartDate: value[0].completedOn,
                releaseDate: lastStable.completedOn,
                buildFlavor: lastStable.buildFlavor,
                buildMetaData: metaDatas
            }
        ];
        return accum;
    }, {});
    return res;
}
