import { ReleaseSchedule, TrainSchedule } from 'skynet-client';

import { ProcessedReleaseSchedule } from './models/ReleaseSchedule';

/**
 * Returns the ring mapping for the given ring.
 * This is used to map the ring names from ADO to the ring names used in the Skynet.
 *
 * @param ring The ring to map.
 * @returns The mapped ring name.
 */
function getRingMapping(ring: string) {
    switch (ring) {
        case '0 - Canary':
        case '0s - SlimCore':
        case 'Ring 0':
            return 'prod_ring0';
        case '1 - Microsoft Teams':
        case 'Ring 1':
            return 'prod_ring1';
        case '2 - Microsoft':
        case 'Ring 2':
            return 'prod_ring2';
        case '3 - TAP':
        case 'Ring 3':
            return 'prod_ring3';
        case '4 - World':
        case 'Ring 4':
            return 'prod_general';
        case '4 - GCCH':
        case 'GCCH':
            return 'gcch_general';
        case 'GCC':
            return 'gcc_general';
        case 'DoD':
            return 'dod_general';
        default:
            return ring;
    }
}

/**
 * Processes the list of train schedules to return a dict with the 100% rollout date.
 *
 * @param trainSchedule The train schedule.
 * @returns The dictionary.
 */
function getTrainScheduleDict(trainSchedule: TrainSchedule[]): Record<string, Date> {
    const scheduleDict: Record<string, Date> = {};
    for (let index = 0; index < trainSchedule.length; index++) {
        const schedule = trainSchedule[index];
        const key = `${schedule.cloud}_${schedule.ring}`;
        const completeRollout = schedule.rolloutSchedule.filter((item) => item.releasePercentage === 100);
        if (completeRollout.length === 0) {
            continue;
        }
        const completeRolloutDate = completeRollout[0].startTime;
        scheduleDict[key] = completeRolloutDate;
    }
    return scheduleDict;
}
/**
 * Processes the list of schedules.
 *
 * @param schedules The release schedules.
 * @returns The dictionary.
 */
function transformSchedules(schedules: ReleaseSchedule[]): ProcessedReleaseSchedule[] {
    return schedules.map((schedule) => {
        return {
            trainName: schedule.trainName,
            snapDate: schedule.snapDate,
            trainSchedule: getTrainScheduleDict(schedule.trainSchedule)
        };
    });
}

export { getRingMapping, transformSchedules, getTrainScheduleDict };
