export function trainProgressSimShips() {
    /**
     * It is a dictionary that shows a link between rollout from trainProgressHeaders and the list of rollouts that are treated as simShip.
     * The key is the id from `trainProgressHeaders`
     *  and the value is an array of dependent rollout objects with the same structure as in `trainProgressHeaders`: id, cloud, ring, name.
     */
    let simShips = {
        Prod_General: [
            {
                id: 'Gallatin_General',
                cloud: 'gallatin',
                ring: 'general',    
                name: 'Gallatin General'
            }
        ]
    };
    return simShips;
}

export function trainProgressHeaders({ isTrainDataAvailableForInnerRing, isGovCloudAvailable, isAGCloudAvailable }) {
    let trainHeaders = [
        {
            id: 'release',
            name: 'Release'
        },
        {
            id: 'Prod_Ring1',
            cloud: 'prod',
            ring: 'ring1',
            name: 'Ring 1'
        },
        {
            id: 'Prod_Ring2',
            cloud: 'prod',
            ring: 'ring2',
            name: 'Ring 2'
        },
        {
            id: 'Prod_Ring3',
            cloud: 'prod',
            ring: 'ring3',
            name: 'Ring 3'
        },
        {
            id: 'Prod_General',
            cloud: 'prod',
            ring: 'general',
            name: 'General'
        }
    ];

    if (!isTrainDataAvailableForInnerRing) {
        trainHeaders = trainHeaders.slice(0, 1).concat(trainHeaders.slice(2));
    }

    if (isGovCloudAvailable) {
        var govClouds = [
            {
                id: 'GCC_General',
                cloud: 'gcc',
                ring: 'general',
                name: 'GCC General'
            },
            {
                id: 'GCCH_General',
                cloud: 'gcch',
                ring: 'general',
                name: 'GCCH General'
            },
            {
                id: 'DoD_General',
                cloud: 'dod',
                ring: 'general',
                name: 'DoD General'
            }
        ];
        trainHeaders = [...trainHeaders, ...govClouds];
    }

    if (isAGCloudAvailable) {
        var agClouds = [
            {
                id: 'AG08_General',
                cloud: 'ag08',
                ring: 'general',
                name: 'AG08 General'
            },
            {
                id: 'AG09_General',
                cloud: 'ag09',
                ring: 'general',
                name: 'AG09 General'
            }
        ];
        trainHeaders = [...trainHeaders, ...agClouds];
    }
    return trainHeaders;
}

export function populateColumns({ isGovCloudAvailable, isAGCloudAvailable }) {
    let key = 2;
    let colArray = trainProgressHeaders({ isTrainDataAvailableForInnerRing: true, isGovCloudAvailable, isAGCloudAvailable })
        .slice(1)
        .map((item) => ({
            key: `${key++}`,
            fieldName: item.id,
            cloud: item.cloud,
            ring: item.ring,
            name: item.name,
            minWidth: 155
        }));
    let res = [
        {
            key: '0',
            name: 'Train Name',
            fieldName: 'name',
            minWidth: 130
        },
        {
            key: '1',
            fieldName: 'snapDate',
            name: 'Snap Date',
            minWidth: 155
        },
        ...colArray
    ];
    return res;
}

export function trainProgressHeadersRetrospective({ isGovCloudAvailable, isTrainDataAvailableForInnerRing, isAGCloudAvailable }) {
    return trainProgressHeaders({ isGovCloudAvailable, isTrainDataAvailableForInnerRing, isAGCloudAvailable });
}
