import { ITextField, Stack, TextField } from '@fluentui/react';
import React, { createRef, useEffect, useState } from 'react';

import { isValidWorkItemId } from '../../../utils/adoUtility';
import { gapStackTokensMedium, textFieldStyles } from '../styles/RingmasterPageStyle';

type AdoWorkItemInputForRMProps = {
    setWorkItemDetails: (workItemId: string) => void;
    resetInput: boolean;
};

/**
 * Renders an input section for entering and searching ADO work items related to Ringmaster ring change requests.
 *
 * @param props - The props containing the callback to set the work item details, the reset input flag, and the flag to show all errors.
 * @returns The JSX element representing the ADO work item input section.
 */
const AdoWorkItemInputForRM: React.FC<AdoWorkItemInputForRMProps> = (props) => {
    // ========================= State =========================
    const [workItemId, setWorkItemId] = useState<string>('');

    const textFieldRef = createRef<ITextField>();

    // ========================= Hooks =========================
    useEffect(() => {
        setWorkItemId('');
    }, [props.resetInput]);

    // ========================= Event handlers =========================
    const onChangeInput = (value: string) => {
        setWorkItemId(value);
        if (isValidWorkItemId(value)) {
            props.setWorkItemDetails(value);
        } else {
            props.setWorkItemDetails('');
        }
    };

    function getWorkItemIdErrorMessage(value: string): string {
        if (!value) {
            return 'Input the ID of an ADO Work item for Ringmaster ring change request';
        }

        if (value && isValidWorkItemId(value)) {
            return '';
        }

        return 'Invalid work item ID';
    }

    // ========================= Render =========================
    return (
        <Stack horizontal tokens={gapStackTokensMedium}>
            <Stack.Item>
                <TextField
                    styles={textFieldStyles}
                    placeholder="ADO work item ID"
                    value={workItemId}
                    componentRef={textFieldRef}
                    validateOnLoad={false}
                    validateOnFocusIn
                    validateOnFocusOut
                    deferredValidationTime={500}
                    onGetErrorMessage={getWorkItemIdErrorMessage}
                    onChange={(event, value) => {
                        if (value !== undefined) {
                            onChangeInput(value);
                        }
                    }}
                />
            </Stack.Item>
        </Stack>
    );
};

export default AdoWorkItemInputForRM;
