import { SupportedFilters } from '../../../services/models/FeatureFlight/SupportedFilters';
import { skypeAClientPlatform } from '../configs/defaults';

export type FilterData = {
    name: string;
    value: string;
    operation: FilterOperation;
};

// eslint-disable-next-line no-shadow
export enum FilterOperation {
    Equal = '=',
    NotEqual = '!=',
    GreaterThan = '>',
    GreaterThanOrEqual = '>=',
    LessThan = '<',
    LessThanOrEqual = '<='
}

export type FlagPair = {
    name: string;
    value?: string;
    control?: string;
};

export type FilterSchema = {
    key: string;
    type: string;
    options?: unknown[];
    // give priority to filters hardcoded in backend
    description?: string;
    priorityFilter: boolean;
};

export type Filters = {
    [skypeAClientPlatform]: number[];
    [key: string]: string | number | string[] | number[] | boolean;
};

export type StartFlightFormData = {
    rolloutName: string;
    featureFlags: FeatureFlags;
    audience: string;
    flightType: string;
    boardingPassId: number;
    filters: Filters;
    createdBy: UserInfo;
    client: string;
    controlConfigs: FeatureFlags;
    isScorecardEligible?: boolean;
};

export type FeatureFlags = {
    [key: string]: unknown;
};

// eslint-disable-next-line no-shadow
export enum FlightUpdateType {
    checkRolloutBlockers = 'checkRolloutBlockers',
    updateRolloutState = 'updateRolloutState',
    allocationUpdate = 'allocationUpdate',
    updateExperimentState = 'updateExperimentState',
    createConfigReminderWorkItem = 'createConfigReminderWorkItem',
    createRolloutBlocker = 'createRolloutBlocker',
    updateReleaseApproval = 'updateReleaseApproval',
    notification = 'notification',
    editRollout = 'updateRollout',
    queueBuild = 'queueBuild'
}

export type FlightUpdate = {
    updateType: FlightUpdateType;
    updateValue: {
        request: {
            state?: string;
            stageName?: string;
            allocationPercentage?: number;
            ring?: string;
            experimentId?: number;
            [key: string]: string | number | object | undefined;
        };
        response: {
            id?: string | number;
            state?: string;
            title?: string;
            url?: string;
            status?: string;
            approvedBy?: UserInfo;
            filters?: { name: string; dataType: string; value: string }[];
            [key: string]: string | number | object | undefined;
        };
    };
    createdAt: string;
    timestamp: number;
};

export type UserInfo = {
    id: string;
    principalName: string;
    displayName: string;
};

export type ApprovalItem = {
    status: string;
    approvedBy?: UserInfo;
    approvedAt?: string;
    url?: string;
    rolloutId?: string;
};

export type Build = {
    ring: string;
    id: number;
    featureId?: number;
};

export type FeatureFlightRollout = {
    id: string;
    rolloutName: string;
    rolloutId: number;
    invocationId: string;
    featureFlags: FeatureFlags;
    audience: string;
    flightType: string;
    boardingPassId: number;
    filters: Filters;
    createdBy: UserInfo;
    createdAt: string;
    releaseId: number;
    ADOLink: string;
    ECSLink: string;
    experimentId?: number;
    experimentLink?: string;
    timestamp: number;
    lastUpdatedTimestamp: number;
    approval: ApprovalItem;
    currentState: FlightState;
    updates?: object[];
    controlConfigs?: FeatureFlags;
    testBuildId: number;
    testBuilds?: Build[];
    subscriberIds?: string[];
};

export type ProcessedFlightRollout = {
    id: string;
    rolloutName: string;
    rolloutId: number;
    featureFlags: FeatureFlags;
    audience: string;
    client: string;
    platforms: number[];
    experience?: string;
    minimumBuildVersion: string;
    flightType: string;
    boardingPassId: number;
    filters: Filters;
    createdBy: UserInfo;
    timestamp: number;
    lastUpdatedTimestamp: number;
    createdDate: Date;
    releaseId: number;
    ADOLink: string;
    ECSLink: string;
    approval: ApprovalItem;
    state: FlightState;
    blockers?: Blocker[];
    experimentId?: number;
    experimentLink?: string;
    controlConfigs?: FeatureFlags;
    testBuilds: Build[];
    isSubscribed: boolean;
};

export type FlightStatusFilter = {
    title: string;
    key: string;
    disabled: boolean;
};

export type FlightsFiltered = {
    [key: string]: ProcessedFlightRollout[];
};

export type ADOWorkItem = {
    id: number;
    url: string;
    title: string;
};

export type BlockFlightFormData = {
    title: string;
    description: string;
    assignedTo: UserInfo;
    areaPath: string;
    iterationPath: string;
    ring?: string;
    requestedBy: UserInfo;
};

export type ApprovalFormData = {
    status: string;
    user: UserInfo;
    comments?: string;
};

export type FeedbackFormData = {
    type: string;
    title: string;
    description: string;
    contact?: UserInfo;
    iterationPath?: string;
    screenshot?: File;
};

export type GeneralExceptionFormData = {
    title: string;
    description: string;
    exceptionType: string;
};

// eslint-disable-next-line no-shadow
export enum FlightEnvironment {
    Prod = 0,
    GCCH,
    DoD,
    AG08,
    AG09,
    Gallatin
}

export type FlightState = {
    ring: string;
    allocationPercentage: number;
    environment: FlightEnvironment;
    stageName: string;
    runningStatus: string;
    timestamp: number;
    progressingStatus?: string;
    failingGates?: string[];
};

export type Blocker = {
    id: number;
    url: string;
};

export type CheckBlockersResponse = {
    [key: number]: Blocker[];
};

export type SortOptions = {
    key: string;
    isSortedDescending: boolean;
};

export interface DataCache {
    data: unknown;
    timestamp: number;
    expirationMins: number;
}

export interface FlightsCache extends DataCache {
    data: ProcessedFlightRollout[];
}

export interface TeamMembersCache extends DataCache {
    data: UserInfo[];
}

export interface SupportedFiltersCache extends DataCache {
    data: SupportedFilters;
}

export type WorkItemCache = {
    featureFlags: string[];
    areaPath: string;
    state: string;
};
export interface WorkItemsCache extends DataCache {
    data: {
        [workItemId: number]: WorkItemCache;
    };
}

export type UpdateMinBuildVersionFormData = {
    version: string;
};

export type ExistingRolloutsData = {
    isInExistingRollout: boolean;
    existingRollouts: [];
    message: string;
};

export type FlightFilters = {
    status: string[];
    creatorIds: string[];
    stages: string[];
    searchKey: string;
    showOnlyBlocked: boolean;
    audiences: string[];
    showOnlyMyFlights: boolean;
    startDate?: Date;
    endDate?: Date;
};

export type EditFlightFormData = {
    rolloutName: string;
    featureFlags: FeatureFlags;
    controlConfigs: FeatureFlags;
    filters: Filters;
};

export type EditFlightFormAction = {
    type:
        | 'updateRolloutName'
        | 'updateClient'
        | 'updateFeatureFlags'
        | 'updateAudience'
        | 'updateExperience'
        | 'updatePlatforms'
        | 'updateMinimumBuildVersion'
        | 'addFilter'
        | 'deleteFilter'
        | 'mergeFilter'
        | 'reset';
    newValue: string;
    newFilters?: { [key: string]: string | number | string[] | number[] | boolean };
};

export type StartFlightFormState = {
    workItemFeatureFlags: string[];
    optionalFilters: string[];
    experienceOptions: string[];
    existingRolloutData: ExistingRolloutsData;
    audience: string;
    reset: boolean;
    showAllErrors: boolean;
    promiseResolver: (value: FlightSubmitResult) => void;
    promiseRejector: (reason?: unknown) => void;
};

export type StartFlightFormAction = {
    type: 'updateWorkItemFeatureFlags' | 'updateOptionalFilters' | 'updateAudience' | 'updateExperience' | 'reset' | 'showAllErrors';
    value: {
        flags?: string[];
        [key: string]: string | number | string[] | number[] | boolean | undefined;
    };
};

export type FlightSubmitResult = {
    isScorecardEligible: boolean;
    rolloutName: string;
    rolloutId: string;
    releaseId: string;
    ECSLink: string;
    ADOLink: string;
};

export type InitiateFlightState = {
    audience: string;
    results: FlightSubmitResult[];
    loading: boolean;
    error?: Error | null;
};

export type FreezeState = {
    state: string;
};
