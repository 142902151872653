import {
    ApiDashboardShiproomReportAddReleaseNoteMessageForClientAsyncPutRequest,
    ApiDashboardShiproomReportGetCurrentReleaseSnapshotGetRequest,
    ApiDashboardShiproomReportGetLatestTrainNamesGetRequest,
    ApiDashboardShiproomReportGetReleaseNotesByClientAsyncGetRequest,
    ApiDashboardShiproomReportGetRingBlockersGetRequest,
    ClientType,
    Cloud,
    Environment,
    InitOverrideFunction,
    ReleaseNotes,
    ReleaseRing,
    ReleaseStatus,
    ResponseError,
    ShiproomReportApi,
    WorkItem
} from 'skynet-client';

import { skynetConfiguration } from './configs/skynet.defaults';
import { getRingMapping } from './utils';

/**
 * The ShiproomV2Service class.
 */
class ShiproomV2Service extends ShiproomReportApi {
    /**
     * Initializes an instance of the ShiproomV2Service class.
     */
    constructor() {
        super(skynetConfiguration);
    }

    /**
     * Get the current release snapshot.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The current release snapshot.
     */
    async apiDashboardShiproomReportGetCurrentReleaseSnapshotGet(
        requestParameters: ApiDashboardShiproomReportGetCurrentReleaseSnapshotGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<ReleaseStatus[]> {
        try {
            if (requestParameters.clientType === ClientType.Desktop) {
                requestParameters.environment = Environment.Work;
            }
            const releaseStatus = await super.apiDashboardShiproomReportGetCurrentReleaseSnapshotGet(requestParameters, initOverrides);
            return this.transformReleaseStatus(releaseStatus);
        } catch (error) {
            let errorMessage = 'Failed to get current release snapshot';
            if (error instanceof ResponseError) {
                if (error.response.status === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.message) {
                    errorMessage = error.message;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Get release notes for the selected client.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The release notes.
     */
    async apiDashboardShiproomReportGetReleaseNotesByClientAsyncGet(
        requestParameters: ApiDashboardShiproomReportGetReleaseNotesByClientAsyncGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<ReleaseNotes> {
        try {
            return await super.apiDashboardShiproomReportGetReleaseNotesByClientAsyncGet(requestParameters, initOverrides);
        } catch (error) {
            let errorMessage = 'Failed to get release notes for the selected client';

            if (error instanceof ResponseError) {
                if (error.response.status === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.message) {
                    errorMessage = error.message;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Add release notes for the client.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The response.
     */
    async apiDashboardShiproomReportAddReleaseNoteMessageForClientAsyncPut(
        requestParameters?: ApiDashboardShiproomReportAddReleaseNoteMessageForClientAsyncPutRequest | undefined,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<void> {
        try {
            return await super.apiDashboardShiproomReportAddReleaseNoteMessageForClientAsyncPut(requestParameters, initOverrides);
        } catch (error) {
            const errorMessage = 'Failed to add release notes for the client';
            throw new Error(errorMessage);
        }
    }

    /**
     * Get the latest train names.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The latest train names.
     */
    async apiDashboardShiproomReportGetLatestTrainNamesGet(
        requestParameters: ApiDashboardShiproomReportGetLatestTrainNamesGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<string[]> {
        try {
            return await super.apiDashboardShiproomReportGetLatestTrainNamesGet(requestParameters, initOverrides);
        } catch (error) {
            let errorMessage = 'Failed to get latest train names';

            if (error instanceof ResponseError) {
                if (error.response.status === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.message) {
                    errorMessage = error.message;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Get the ring blockers.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The ring blockers.
     */
    async getRingBlockers(
        requestParameters: ApiDashboardShiproomReportGetRingBlockersGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<Record<string, Record<string, WorkItem[]>>> {
        const trainField = 'Custom.ReleaseBranch_';
        const ringField = 'MicrosoftTeamsCMMI.RingBlocker';
        try {
            const response = await super.apiDashboardShiproomReportGetRingBlockersGet(requestParameters, initOverrides);
            return this.transformWorkItemListToDict(response, trainField, ringField);
        } catch (error) {
            let errorMessage = 'Failed to get ringblockers';
            if (error instanceof ResponseError) {
                if (error.response.status === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.message) {
                    errorMessage = error.message;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Processes the work item list to a dictionary.
     *
     * @param response The work item list.
     * @param trainField The train field.
     * @param ringField The ring field.
     * @returns The dictionary.
     */
    private transformWorkItemListToDict(
        response: WorkItem[],
        trainField: string,
        ringField: string
    ): Record<string, Record<string, WorkItem[]>> {
        const itemsDict: Record<string, Record<string, WorkItem[]>> = {};
        for (let index = 0; index < response.length; index++) {
            const item = response[index];

            if (item.fields === undefined) continue;

            const trains = item.fields[trainField] ? item.fields[trainField].split(';') : [];
            const rings = item.fields[ringField] ? item.fields[ringField].split(';') : [];

            for (let j = 0; j < trains.length; j++) {
                const train = trains[j].trim();
                if (!itemsDict[train]) itemsDict[train] = {};

                for (let k = 0; k < rings.length; k++) {
                    const ring = getRingMapping(rings[k]).trim();
                    if (!itemsDict[train][ring]) itemsDict[train][ring] = [];
                    itemsDict[train][ring].push(item);
                }
            }
        }
        return itemsDict;
    }

    /**
     * Transforms the release status list.
     *
     * @param releaseStatusList The release status list.
     * @returns The transformed release status list.
     */
    private transformReleaseStatus(releaseStatusList: ReleaseStatus[]) {
        const releaseStatusDict: Record<string, Record<string, ReleaseStatus[]>> = {};
        // group the list of release status objects by cloud and ring
        for (let index = 0; index < releaseStatusList.length; index++) {
            const releaseStatus = releaseStatusList[index];
            const cloud = releaseStatus.cloud ?? Cloud.Prod;
            const ring = releaseStatus.ring ?? ReleaseRing.None;
            if (!releaseStatusDict[cloud]) releaseStatusDict[cloud] = {};
            if (!releaseStatusDict[cloud][ring]) releaseStatusDict[cloud][ring] = [];
            releaseStatusDict[cloud][ring].push(releaseStatus);
        }
        // iterate over the dict, and combine the values if their releaseDates are less than an 2.5 hours apart
        const combinedReleaseStatusList: ReleaseStatus[] = [];
        for (const cloud in releaseStatusDict) {
            for (const ring in releaseStatusDict[cloud]) {
                const releaseStatuses = releaseStatusDict[cloud][ring];
                const combinedReleaseStatus = this.combineReleaseStatus(releaseStatuses);
                combinedReleaseStatusList.push(combinedReleaseStatus);
            }
        }
        return combinedReleaseStatusList;
    }

    /**
     * Combines the release status objects if their release dates are less than an hour apart.
     *
     * @param releaseStatuses The list of release status objects.
     * @returns The combined release status object.
     */
    private combineReleaseStatus(releaseStatuses: ReleaseStatus[]): ReleaseStatus {
        if (releaseStatuses.length === 2) {
            const releaseDate1 = new Date(releaseStatuses[0].releaseDate ?? new Date()).getTime();
            const releaseDate2 = new Date(releaseStatuses[1].releaseDate ?? new Date()).getTime();

            if (Math.abs(releaseDate1 - releaseDate2) < 2.5 * 60 * 60 * 1000) {
                const percent = (releaseStatuses[0].percent ?? 0) + (releaseStatuses[1].percent ?? 0);
                const combinedReleaseStatus = { ...releaseStatuses[0], percent: percent };
                return combinedReleaseStatus;
            } else {
                return releaseDate1 > releaseDate2 ? releaseStatuses[0] : releaseStatuses[1];
            }
        }
        return releaseStatuses[0];
    }
}

export default ShiproomV2Service;
