import { TooltipHost, Link, IColumn } from '@fluentui/react';
import React from 'react';

import { ExperienceMetadataDto } from '../../services/models/Experience/ExperiencesDto';

import { ExperiencesDataItemType } from './models/ExperiencesDataItemType';

type TransformedExperienceData = {
    columnData: IColumn[];
    contentData: ExperiencesDataItemType[];
};

/**
 * Prepare data for DetailList.
 * Transforms data from ExperienceMetadataDto[] into TransformedExperienceData.
 *
 * @param jsonData Array of ExperienceMetadataDto for transform.
 * @returns Column and content for table.
 */
const ExperienceMetadataDtoTransformer = (jsonData: ExperienceMetadataDto[]): TransformedExperienceData => {
    const populateColumns = (props: ExperienceMetadataDto[]): IColumn[] => {
        const columnNames = ['Experience', ...new Set(props.map((item) => item.cloud + ' ' + item.ring))];

        const datalistColumns: IColumn[] = columnNames.map((key: string) => {
            return {
                key: key,
                name: key,
                fieldName: key,
                minWidth: 130,
                maxWidth: 130,
                // to prevent hide horizontal scroll bar
                isCollapsible: false
            };
        });

        return datalistColumns;
    };

    const populateDataTable = (props: ExperienceMetadataDto[]): ExperiencesDataItemType[] => {
        const indexOfExperiences: { [key: string]: number } = {};
        const experiences = [...new Set(props.map((item) => item.experience))];
        experiences.forEach((experience, index) => {
            indexOfExperiences[experience] = index;
        });
        const tableData: ExperiencesDataItemType[] = [];
        props.forEach((item) => {
            const { ring, cloud, experience } = item;
            const column = cloud + ' ' + ring;
            const index = indexOfExperiences[experience];
            if (!tableData[index]) {
                tableData[index] = { Experience: <>{experience}</> };
            }
            tableData[index][column] = buildCellValue(item);
        });
        return tableData;
    };

    const buildCellValue = (item: ExperienceMetadataDto): JSX.Element => {
        if (!item.buildVersion && !item.buildFinishTime && !item.latestCommit) return <>N/A</>;
        return (
            <>
                <div>Id: {showBuildVersion(item.buildVersion)}</div>
                <div>Build date: {showBuildDate(item.buildFinishTime)}</div>
                <div>Last Commit: {showLastCommit(item)}</div>
            </>
        );
    };

    const showBuildVersion = (buildVersion: string): string => {
        return buildVersion ?? 'N/A';
    };

    const showBuildDate = (date: string): string => {
        return date ? new Date(date).toLocaleDateString('en-in') : 'N/A';
    };

    const showLastCommit = (item: ExperienceMetadataDto): JSX.Element => {
        if (!item.latestCommit) return <>N/A</>;
        return (
            <>
                <TooltipHost content={item.latestCommit}>
                    <Link href={item.buildUrl} target="_blank" rel="noreferrer" title="Last Commit">
                        {item.latestCommit.substring(0, 9)}
                    </Link>
                </TooltipHost>
            </>
        );
    };

    return { columnData: populateColumns(jsonData), contentData: populateDataTable(jsonData) };
};

export default ExperienceMetadataDtoTransformer;
