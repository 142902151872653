import { Link, Pivot, PivotItem, Spinner, SpinnerSize, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import CommitTrackingService from '../../services/commitTracking.service';
import { ClientRepoMap } from '../../services/models/ClientRepoMap';
import { appInsightsClient } from '../../utils/appInsightsUtility';

import CommitProgression from './CommitProgression';
import { SupportedRepositories, SupportedTabs } from './configs/defaults';

type ICommitPivotViewProps = {
    commitIdentifier: string;
    defaultClientTab?: string;
};

/**
 * Displays the commit pivot view.
 *
 * @param props The props for the component.
 * @returns The component for the commit pivot view.
 */
const CommitPivotView: React.FC<ICommitPivotViewProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [noDataFlag, setNoDataFlag] = useState<boolean>(false);
    const [loaderFlag, setLoaderFlag] = useState<boolean>(true);

    const [tabs, setTabs] = useState<ClientRepoMap[]>();
    const [selectedTab, setSelectedTab] = useState<ClientRepoMap>();

    const commitLinkBase = `https://domoreexp.visualstudio.com/Teamspace/_git/`;
    const isCommitId = props.commitIdentifier.length === 40;

    // ========================= Functions ========================= //

    /**
     * Handles the tab click event.
     *
     * @param item The pivot item selected.
     * @param _ev The event.
     */
    const onTabClick = (item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) => {
        const key = item?.props.itemKey ?? 'Windows (T1)';

        const selectedClient = tabs?.find((clientRepoMap) => clientRepoMap.client === key);

        setSelectedTab(selectedClient);
        updateDeepLink(item?.props.itemKey ?? '');
    };

    /**
     * Updates the deep link.
     * This is used to update the URL with the selected tab.
     *
     * @param client The client selected.
     */
    const updateDeepLink = (client: string) => {
        searchParams.set('identifier', props.commitIdentifier);
        searchParams.set('client', client);

        setSearchParams(searchParams);
    };

    /**
     * Filters the tabs based on the supported tabs.
     *
     * @param response The response from the API.
     */
    const filterTabs = (response: ClientRepoMap[]) => {
        const filteredTabs = response.filter((clientRepoMap) => SupportedTabs.includes(clientRepoMap.client));

        if (filteredTabs.length > 0) {
            setTabs(filteredTabs);

            if (props.defaultClientTab) {
                const selectClient = response.find((client) => client.client === props.defaultClientTab);
                setSelectedTab(selectClient ?? filteredTabs[0]);
                updateDeepLink(props.defaultClientTab);
            } else {
                setSelectedTab(filteredTabs[0]);
                updateDeepLink(filteredTabs[0].client);
            }

            setLoaderFlag(false);
            setNoDataFlag(false);
            return;
        }

        setLoaderFlag(false);
        setNoDataFlag(true);
    };

    // ========================= Hooks ========================= //

    useEffect(() => {
        setLoaderFlag(true);
        setNoDataFlag(false);

        new CommitTrackingService()
            .getAvailableClientOptions(props.commitIdentifier)
            .then((response) => {
                filterTabs(response);
                appInsightsClient.logTrace(
                    { message: 'Fetched client Info for commit identifier' },
                    { commitIdentifier: props.commitIdentifier }
                );
            })
            .catch((error) => {
                console.error('Error in fetching data', error);
                setLoaderFlag(false);
                setNoDataFlag(true);

                appInsightsClient.logException(
                    { exception: error },
                    {
                        message: 'Caught error while fetching client Info for commit identifier',
                        commitIdentifier: props.commitIdentifier
                    }
                );
            });
    }, [props.commitIdentifier]);

    // ========================= Render ========================= //
    return (
        <>
            {noDataFlag && (
                <Card>
                    <ErrorNotification msg={renderErrorMessage()} />
                </Card>
            )}

            {loaderFlag && (
                <Card>
                    <Spinner size={SpinnerSize.large} label="Fetching client details..." />
                </Card>
            )}

            {!noDataFlag && !loaderFlag && tabs && selectedTab && (
                <>
                    <Card>
                        <Text variant="mediumPlus">
                            <u>Repository:</u> {tabs[0].repositoryName} | <u>{isCommitId ? 'Commit' : 'Pull Request'}:</u>{' '}
                            <Link
                                href={`${commitLinkBase}/${tabs[0]?.repositoryName}/${isCommitId ? 'commit' : 'pullrequest'}/${
                                    props.commitIdentifier
                                }`}
                                target="_blank"
                                rel="noreferrer"
                                title={isCommitId ? 'Commit' : 'Pull Request'}
                            >
                                {props.commitIdentifier}
                            </Link>
                        </Text>
                        <Pivot onLinkClick={onTabClick} selectedKey={selectedTab.client} overflowBehavior="menu">
                            {tabs.map((tab) => (
                                <PivotItem itemKey={tab.client} headerText={tab.client} key={tab.client}></PivotItem>
                            ))}
                        </Pivot>
                    </Card>

                    <Card>
                        <CommitProgression clientInfo={selectedTab} commitIdentifier={props.commitIdentifier} />
                    </Card>
                </>
            )}
        </>
    );
};

// ========================= Helpers ========================= //

/**
 * Renders the error message.
 *
 * @returns The error message.
 */
const renderErrorMessage = () => {
    const errorMessage = `Failed to get commit progression details.`;
    const possibleError = `Please ensure that the commit or pull request ID belongs to the supported list of Repositories [${SupportedRepositories}].`;

    return (
        <>
            <div>{errorMessage}</div>
            <div>{possibleError}</div>
            <div>
                Go to the
                <Link href="https://aka.ms/CommitTrackingWiki" target="_blank" rel="noreferrer" title="Help page">
                    help page
                </Link>{' '}
                for more details. If the issue persists, please contact us by `Report a Bug` or `Send Feedback` using links present in
                footer.
            </div>
        </>
    );
};
// ========================= Export ========================= //

export default CommitPivotView;
