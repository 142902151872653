import { Separator, Stack } from '@fluentui/react';
import React from 'react';
import { WorkItem } from 'skynet-client';

import { Card, CardHeader } from '../../../../components/Card';
import { ProcessedTrainMetadata } from '../../../../services/models/TrainMetadata';
import { LatestProdTrainRingProps } from '../../types/LatestProdTrainRingProps';

import { RedlineScorecard } from './RedlineScorecardCard';

import { BuildDetails, RingBlockersTable, RolloutProgressionTable } from '.';

type RingModalDetailsProps = {
    ring: string;
    trainData: ProcessedTrainMetadata;
    ringBlockers: Record<string, WorkItem[]>;
    latestProdRingPropsForTrain?: LatestProdTrainRingProps;
};

/**
 * Displays the ring details.
 *
 * @param props The props for the component.
 * @returns The ring details.
 */
export const RingModalDetails: React.FC<RingModalDetailsProps> = (props) => {
    const rolloutsForRing = props.trainData.rolloutDict[props.ring];
    const ringBlockersForRing = props.ringBlockers[props.ring.toLocaleLowerCase()];
    const buildReleasePairsForRing = props.trainData.buildReleaseMetadata?.buildReleaseList?.filter((buildRelease) => {
        return buildRelease.rollouts?.some(
            (rollout) => `${rollout.cloud}_${rollout.ring}` === props.ring && rollout.deploymentStatus !== 'Failed'
        );
    });
    const uniqueBuildsForRing = buildReleasePairsForRing
        ?.map((buildRelease) => buildRelease.buildMetadata)
        .filter((build, index, self) => {
            return self.findIndex((b) => b?.id === build?.id) === index;
        });

    return (
        <Stack tokens={{ childrenGap: '1%' }}>
            <h3>{props.ring}</h3>
            {ringBlockersForRing !== undefined && ringBlockersForRing.length > 0 && (
                <RingBlockersTable ringBlockers={ringBlockersForRing} showRing={false} />
            )}
            {rolloutsForRing !== undefined && (
                <Card>
                    <CardHeader>Ring Progression History</CardHeader>
                    <RolloutProgressionTable rollouts={rolloutsForRing} />
                </Card>
            )}

            {props.trainData.status === 'Active' && (
                <RedlineScorecard
                    trainData={props.trainData}
                    ring={props.ring}
                    latestProdRingPropsForTrain={props.latestProdRingPropsForTrain}
                />
            )}

            {uniqueBuildsForRing !== undefined && uniqueBuildsForRing.length > 0 && (
                <Card>
                    <CardHeader>Build Details</CardHeader>
                    {uniqueBuildsForRing.map(
                        (build) =>
                            build &&
                            props.trainData.clientType &&
                            props.trainData.environment && (
                                <div key={build.id}>
                                    <Separator />
                                    <BuildDetails
                                        clientType={props.trainData.clientType}
                                        os={props.trainData.os}
                                        environment={props.trainData.environment}
                                        experience={props.trainData.experience}
                                        ring={props.ring}
                                        build={build}
                                        rollout={rolloutsForRing[0]}
                                    />
                                </div>
                            )
                    )}
                </Card>
            )}
        </Stack>
    );
};
