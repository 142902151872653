import { DefaultButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Card } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import { displayName, teamsClientRelease } from '../../services/configs/msGraph.defaults';
import FeatureFlightService from '../../services/featureFlight.service';
import MsGraphService from '../../services/msGraph.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserDisplayName, getUserPrincipalName } from '../../utils/userUtility';

import EditFlightForm from './AdminFlightsComponents/EditFlightForm';
import FlightFlagSettingsCard from './FlightInfoComponents/FeatureFlagConfigsCard';
import FlightDetailsCard from './FlightInfoComponents/FlightDetailsCard';
import FlightLinksCard from './FlightInfoComponents/FlightLinksCard';
import FlightStatusCard from './FlightInfoComponents/FlightStatusCard';
import FlightTitleCard from './FlightInfoComponents/FlightTitleCard';
import FlightUpdates from './FlightInfoComponents/FlightUpdates';
import { gapStackTokensMedium } from './styles/FFv2Style';
import { Blocker, FlightUpdate, ProcessedFlightRollout } from './types/Types';
import { getCachedData, processFlights } from './utilities/FFv2Utils';

/**
 * Displays the page that includes extra flight information.
 * In this case, it is the flight object itself. But in the future
 * will be a more stylized version of it.
 *
 * @returns The page with flight info.
 */
const FlightInfo: React.FC = () => {
    const location = useLocation();
    const userPrincipalName = getUserPrincipalName();
    const { isOpenedFromMyFlights } = location.state || { isOpenedFromMyFlights: false };

    const { flightId } = useParams<{ flightId: string }>();
    const [flight, setFlight] = useState<ProcessedFlightRollout>();
    const [updates, setUpdates] = useState<FlightUpdate[]>([]);
    const [blockers, setBlockers] = useState<Blocker[]>([]);
    const [isEditFlightModalOpen, { setTrue: showEditFlightModal, setFalse: hideEditFlightModal }] = useBoolean(false);
    const [isTeamsClientRelease, { setTrue: setIsTeamsClientRelease, setFalse: setIsNotTeamsClientRelease }] = useBoolean(false);

    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);
    const msGraphService = new MsGraphService();

    useEffect(() => {
        fetchFlight();
        msGraphService
            .getTransitiveMemberOf(`${displayName} eq '${teamsClientRelease}'`, 'id, displayName')
            .then((groups) => {
                groups && groups.length > 0 ? setIsTeamsClientRelease() : setIsNotTeamsClientRelease();
            })
            .catch((fail) => {
                console.error(
                    `Caught error getting membership details for user: ${getUserDisplayName()} - ${userPrincipalName} and group: ${teamsClientRelease}, error: ${fail}`
                );
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTransitiveMemberOf in Feature Flight page`,
                        user: userPrincipalName,
                        group: teamsClientRelease
                    }
                );
            });
    }, []);

    const updateFlight = () => {
        fetchFlight();
    };

    const updateSubscribed = (isSubscribed: boolean) => {
        if (flight) {
            setFlight({ ...flight, isSubscribed });
            const currentCache = getCachedData('ffv2FlightData');
            if (currentCache) {
                const newData = (currentCache.data as ProcessedFlightRollout[]).map((item) => {
                    if (item.id === flight.id) {
                        return { ...item, isSubscribed };
                    }
                    return item;
                });
                localStorage.setItem('ffv2FlightData', JSON.stringify({ ...currentCache, data: newData }));
            }
        }
    };

    return (
        <>
            {!isLoading && !isDataLoaded && (
                <Card>
                    <ErrorNotification msg={`Uh oh looks like there was an issue fetching info for flight ${flightId}`} />
                </Card>
            )}
            {isLoading && (
                <Card>
                    <Spinner size={SpinnerSize.large} label="Fetching flight info" />
                </Card>
            )}
            {!isLoading && isDataLoaded && flight && (
                <Stack tokens={gapStackTokensMedium}>
                    <EditFlightForm
                        isOpen={isTeamsClientRelease && isEditFlightModalOpen}
                        onDismiss={hideEditFlightModal}
                        flight={flight}
                        updateFlight={updateFlight}
                    />
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        {isOpenedFromMyFlights && (
                            <DefaultButton
                                iconProps={{ iconName: 'RevToggleKey' }}
                                text="Return to My Flights"
                                ariaLabel="ReturnToMyFlights"
                                onClick={() => window.history.back()}
                                styles={{ root: { width: 'fit-content' } }}
                            />
                        )}
                        {isTeamsClientRelease && (
                            <DefaultButton
                                text="Edit Flight"
                                ariaLabel="EditFlight"
                                onClick={showEditFlightModal}
                                styles={{ root: { width: 'fit-content' } }}
                            />
                        )}
                    </Stack>
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <FlightTitleCard flight={flight} updateSubscribed={updateSubscribed} />
                        <FlightStatusCard flight={flight} blockers={blockers} />
                    </Stack>
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <FlightDetailsCard flight={flight} />
                        <Stack tokens={gapStackTokensMedium} styles={{ root: { width: '60%' } }}>
                            <Stack.Item grow>
                                <FlightFlagSettingsCard flight={flight} />
                            </Stack.Item>
                            <Stack.Item>
                                <FlightLinksCard flight={flight} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <FlightUpdates updates={updates} />
                </Stack>
            )}
        </>
    );

    function fetchFlight() {
        setIsLoading();
        setIsDataNotLoaded();

        if (!flightId) {
            setIsDataNotLoaded();
            setIsNotLoading();
            return;
        }
        new FeatureFlightService()
            .getRolloutsById(flightId)
            .then((response) => {
                if (response.length === 0) throw new Error('No flight found with id ' + flightId);

                setFlight(processFlights(response)[0]);
                setIsDataLoaded();

                appInsightsClient.logEvent({
                    name: 'FFv2:FlightInfo:LoadFlightInfo',
                    properties: {
                        flightId: flightId
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                setIsDataNotLoaded();
            })
            .finally(() => {
                setIsNotLoading();
            });

        new FeatureFlightService()
            .getRolloutBlockers([parseInt(flightId)])
            .then((response) => {
                setBlockers(response[parseInt(flightId)] ?? []);
            })
            .catch((error) => {
                console.error(error);
            });

        new FeatureFlightService()
            .getRolloutUpdates('rolloutId', flightId)
            .then((response) => {
                setUpdates(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }
};

export { FlightInfo };
