import { SKYNET_BASE_URL } from '../configs/env.config';
import ApiService from './api.service';
import { getRingMapping } from './utils';

const retrospectiveBaseUri = `${SKYNET_BASE_URL}/api/dashboard/retrospectiveReport`;
const releaseScheduleBaseUri = `${SKYNET_BASE_URL}/api/release/schedule`;
const apiService = new ApiService();

export function getTrainDetails(trainName) {
    return apiService.getApiData(`${retrospectiveBaseUri}/getTrainDetails?trainKey=${trainName}`);
}

export function getTrainNamesForMonth(client, os, environment, year, month) {
    return apiService.getApiData(
        `${retrospectiveBaseUri}/getTrainNamesForMonth?clientType=${client}&os=${os}&year=${year}&month=${month}${
            environment ? `&environment=${environment}` : ``
        }`
    );
}

export function getScheduledReleasePlan(client, os, environment = 'Work', experience = 'None', days = 60) {
    return apiService.getApiData(
        `${releaseScheduleBaseUri}/getByWindowInDays?clientType=${client}&os=${os}&environment=${environment}&experience=${experience}&days=${days}`
    );
}

export function getAllRingBlockers(client, os, environment, experience = 'None') {
    const trainField = 'Custom.ReleaseBranch_';
    const ringField = 'MicrosoftTeamsCMMI.RingBlocker';
    return apiService
        .getApiData(
            `${retrospectiveBaseUri}/getAllRingBlockers?clientType=${client}&os=${os}&environment=${environment}&experience=${experience}`
        )
        .then((response) => processWorkItemListToDict(response, trainField, ringField));
}

function processWorkItemListToDict(response, trainField, ringField) {
    let itemsDict = {};
    for (let index = 0; index < response.length; index++) {
        const item = response[index];

        let trains = item.fields[trainField] ? item.fields[trainField].split(';') : [];
        let rings = item.fields[ringField] ? item.fields[ringField].split(';') : [];

        for (let j = 0; j < trains.length; j++) {
            const train = trains[j].trim();
            if (!itemsDict[train]) itemsDict[train] = {};

            for (let k = 0; k < rings.length; k++) {
                const ring = getRingMapping(rings[k]).trim();
                if (!itemsDict[train][ring]) itemsDict[train][ring] = [];
                itemsDict[train][ring].push(item);
            }
        }
    }
    return itemsDict;
}
