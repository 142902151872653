import { Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Card } from '../../../components/Card';
import ErrorNotification from '../../../components/ErrorNotification/ErrorNotification';
import allClients from '../../../configs/clients.base.json';
import rings from '../../../configs/rings.json';
import { Ring } from '../../../services/models/common/ring';
import { JSONChartData } from '../../../services/models/RecentChangesReport/JSONChartData';
import { TransformedChartData } from '../../../services/models/RecentChangesReport/TransformedChartData';
import RecentChangesService from '../../../services/recentChanges.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { RecentChangeType } from '../configs/defaults';
import { FilterFormData } from '../FilterForm/FilterFormData';

import { RingRecentChanges } from './RingRecentChanges';

type RecentChangesViewProps = {
    filterFormData: FilterFormData;
    onDataLoad: (chartData: JSONChartData[]) => void;
    filteredFeatureFlags: string[];
};

/**
 * Displays the main view for the RecentChangesView component.
 *
 * @param props The props for the component.
 * @returns The JSX for the component.
 */
const RecentChangesView: React.FC<RecentChangesViewProps> = (props) => {
    const { filterFormData } = props;

    const [allRings, setAllRings] = useState<Ring[]>([]);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    const [combinedChartDataDict, setCombinedChartDataDict] = useState<Record<string, Record<string, TransformedChartData[]>>>({});
    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);

    useEffect(() => {
        setAllRings(rings.rings);
    }, []);

    useEffect(() => {
        setIsDataNotLoaded();
        setIsLoading();

        const promises: Promise<Record<string, Record<string, TransformedChartData[]>>>[] = [];
        const recentChangesService = new RecentChangesService();
        for (const changeType of filterFormData.changeTypes) {
            if (changeType === RecentChangeType.FeatureRolloutUpdate) {
                promises.push(
                    getFeatureFlagUpdateChartData(
                        filterFormData.clients,
                        filterFormData.rings,
                        filterFormData.startDate,
                        filterFormData.endDate
                    )
                );
            } else if (changeType === RecentChangeType.BuildRolloutUpdate) {
                promises.push(
                    getBuildRolloutChangeChartData(
                        filterFormData.clients,
                        filterFormData.rings,
                        filterFormData.startDate,
                        filterFormData.endDate
                    )
                );
            } else if (changeType === RecentChangeType.BitsReleaseUpdate) {
                promises.push(
                    getBitsReleaseUpdateChartData(
                        filterFormData.clients,
                        filterFormData.rings,
                        filterFormData.startDate,
                        filterFormData.endDate
                    )
                );
            }
        }

        Promise.all(promises).then((responses) => {
            const finalDataDict: Record<string, Record<string, TransformedChartData[]>> = {};
            for (const response of responses) {
                mergeDataDicts(response, filterFormData.rings, finalDataDict, filterFormData.clients);
            }
            setCombinedChartDataDict(finalDataDict);
            const flattenedChartData: JSONChartData[] = [];
            for (const cloudRing of Object.keys(finalDataDict)) {
                for (const client of Object.keys(finalDataDict[cloudRing])) {
                    for (const chartData of finalDataDict[cloudRing][client]) {
                        let changeType = 'Unknown';
                        switch (chartData.y) {
                            case 1:
                                changeType = RecentChangeType.FeatureRolloutUpdate;
                                break;
                            case 2:
                                changeType = RecentChangeType.BuildRolloutUpdate;
                                break;
                            case 3:
                                changeType = RecentChangeType.BitsReleaseUpdate;
                                break;
                        }
                        flattenedChartData.push({
                            timestamp: chartData.x,
                            changeType: changeType,
                            client: client,
                            cloudRing: cloudRing,
                            metadata: chartData.metadata
                        });
                    }
                }
            }
            props.onDataLoad(flattenedChartData.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()));
            setIsDataLoaded();
            setIsNotLoading();
        });

        function getBitsReleaseUpdateChartData(clients: string[], selectedRings: string[], startDatetime: Date, endDatetime: Date) {
            const bitsPromises: Promise<Record<string, Record<string, TransformedChartData[]>>>[] = [];
            for (const selectedClient of clients) {
                const clientMap = allClients.find((x) => x.client === selectedClient);
                if (clientMap === undefined) continue;

                const { clientType, os, environment, experience } = clientMap;
                bitsPromises.push(
                    recentChangesService.getBitsReleaseChanges(clientType, os, environment, experience, startDatetime, endDatetime)
                );
            }
            return Promise.all(bitsPromises).then((responses) => {
                const dataDict: Record<string, Record<string, TransformedChartData[]>> = {};

                for (const response of responses) {
                    mergeDataDicts(response, selectedRings, dataDict);
                }

                appInsightsClient.logTrace(
                    {
                        message: `Fetched recent changes bits release data from RecentChangeService`
                    },
                    {
                        changeTypes: filterFormData.changeTypes,
                        clients: filterFormData.clients,
                        rings: filterFormData.rings,
                        startDate: filterFormData.startDate,
                        endDate: filterFormData.endDate
                    }
                );
                return dataDict;
            });
        }

        function getBuildRolloutChangeChartData(clients: string[], selectedRings: string[], startDatetime: Date, endDatetime: Date) {
            return recentChangesService.getBuildRolloutChanges(startDatetime, endDatetime).then((response) => {
                const dataDict: Record<string, Record<string, TransformedChartData[]>> = {};
                mergeDataDicts(response, selectedRings, dataDict, clients);
                appInsightsClient.logTrace(
                    {
                        message: `Fetched recent changes build rollout data from RecentChangeService`
                    },
                    {
                        changeTypes: filterFormData.changeTypes,
                        clients: filterFormData.clients,
                        rings: filterFormData.rings,
                        startDate: filterFormData.startDate,
                        endDate: filterFormData.endDate
                    }
                );
                return dataDict;
            });
        }

        function getFeatureFlagUpdateChartData(clients: string[], selectedRings: string[], startDatetime: Date, endDatetime: Date) {
            const ffPromises: Promise<Record<string, Record<string, TransformedChartData[]>>>[] = [];
            for (const selectedClient of clients) {
                const clientMap = allClients.find((x) => x.client === selectedClient);
                if (clientMap === undefined) continue;

                const { clientType, os, environment, experience } = clientMap;
                ffPromises.push(
                    recentChangesService.getFeatureFlagChanges(clientType, os, environment, experience, startDatetime, endDatetime)
                );
            }
            return Promise.all(ffPromises).then((responses) => {
                const dataDict: Record<string, Record<string, TransformedChartData[]>> = {};

                for (const response of responses) {
                    mergeDataDicts(response, selectedRings, dataDict);
                }

                appInsightsClient.logTrace(
                    {
                        message: `Fetched recent changes feature flag update data from RecentChangeService`
                    },
                    {
                        changeTypes: filterFormData.changeTypes,
                        clients: filterFormData.clients,
                        rings: filterFormData.rings,
                        startDate: filterFormData.startDate,
                        endDate: filterFormData.endDate
                    }
                );
                return dataDict;
            });
        }
    }, [filterFormData]);

    if (!isLoading && !isDataLoaded) {
        return (
            <Card>
                <ErrorNotification msg="Unable to fetch recent change data" />
            </Card>
        );
    }
    if (isLoading) {
        return (
            <Card>
                <Spinner size={SpinnerSize.large} label="Fetching recent changes... Please be patient, this might take some time ⏳" />
            </Card>
        );
    }
    if (!isLoading && isDataLoaded) {
        return (
            <>
                {filterFormData.rings
                    .sort((x, y) => {
                        return allRings.findIndex((ring) => ring.key === x) - allRings.findIndex((ring) => ring.key === y);
                    })
                    .map((cloudRing) => {
                        const paddedEndDate = addDays(filterFormData.endDate, 1);
                        return (
                            <RingRecentChanges
                                key={cloudRing}
                                cloudRing={cloudRing}
                                startDate={filterFormData.startDate}
                                endDate={paddedEndDate}
                                selectedChangeTypes={filterFormData.changeTypes}
                                chartDataDict={combinedChartDataDict[cloudRing]}
                                filteredFeatureFlags={props.filteredFeatureFlags}
                            />
                        );
                    })}
            </>
        );
    }
    return <></>;
};

function mergeDataDicts(
    response: Record<string, Record<string, TransformedChartData[]>>,
    selectedRings: string[],
    dataDict: Record<string, Record<string, TransformedChartData[]>>,
    selectedClients?: string[]
) {
    for (const cloudRing of Object.keys(response)) {
        if (!selectedRings.includes(cloudRing)) continue;

        const clientChartData = response[cloudRing];
        if (dataDict[cloudRing] === undefined) {
            dataDict[cloudRing] = {};
        }

        for (const client of Object.keys(clientChartData)) {
            if (selectedClients && !selectedClients.includes(client)) continue;
            const chartData = clientChartData[client];
            if (dataDict[cloudRing][client] === undefined) {
                dataDict[cloudRing][client] = chartData;
            } else {
                dataDict[cloudRing][client] = [...chartData, ...dataDict[cloudRing][client]];
            }
        }
    }
}

export { RecentChangesView };
