import { SKYNET_BASE_URL } from '../configs/env.config';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { EnvironmentType } from './models/Experience/EnvironmentType';
import { ExperienceMetadataDto } from './models/Experience/ExperiencesDto';

/**
 * Service for experience info report.
 */
class ExperienceReportService {
    private apiService: ApiService;
    private baseUri: string;

    /**
     * Initializes an instance of the ExperienceReportService class.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.baseUri = `${SKYNET_BASE_URL}/api/dashboard/experienceReport`;
    }

    /**
     * Get all experiences' metadata for a given environment.
     *
     * @param environment The environment.
     * @returns List of experiences metadata.
     */
    public getExperiencesMetadata(environment: EnvironmentType): Promise<ExperienceMetadataDto[]> {
        return this.apiService.get<ExperienceMetadataDto[]>(`${this.baseUri}/getExperiencesMetadata?environment=${environment}`);
    }
}

export default ExperienceReportService;
