import { IconButton, Stack, TextField, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { ClientType, Environment, Experience, OS, ReleaseNotes } from 'skynet-client';

import { Card } from '../../../components/Card';
import ErrorNotification from '../../../components/ErrorNotification/ErrorNotification';
import ShiproomV2Service from '../../../services/shiproomV2.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { getUserPrincipalName } from '../../../utils/userUtility';
import { isUserReleaseManager } from '../utils';

type ReleaseNotesDetailsProps = {
    clientType: ClientType;
    os: OS;
    environment: Environment;
    experience: Experience;
};

/**
 * Displays the release notes details.
 *
 * @param props The props.
 * @returns The main component for the page.
 */
const ReleaseNotesDetails: React.FC<ReleaseNotesDetailsProps> = (props) => {
    const isUserAReleaseManager = isUserReleaseManager(getUserPrincipalName());
    const [isNoteReadOnly, setIsNoteReadOnly] = useState<boolean>(true);
    const [noteTextValue, setNoteTextValue] = useState<string>('');
    const [multiline, setMultiline] = useState<boolean>(false);

    const [areReleaseNotesLoaded, { setTrue: setAreReleaseNotesLoaded, setFalse: setAreReleaseNotesNotLoaded }] = useBoolean(false);
    const [areNotesLoading, { setTrue: setAreNotesLoading, setFalse: setAreNotesNotLoading }] = useBoolean(true);

    const onChangeNote = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void => {
        setNoteTextValue(newText ?? '');

        const newMultiline = newText && newText.length && newText.length > 250;
        if (newMultiline !== multiline) {
            setMultiline(true);
        }
    };

    useEffect(() => {
        setAreNotesLoading();
        setAreReleaseNotesNotLoaded();
        setIsNoteReadOnly(true);

        const shiproomService = new ShiproomV2Service();

        shiproomService
            .apiDashboardShiproomReportGetReleaseNotesByClientAsyncGet({
                clientType: props.clientType,
                os: props.os,
                environment: props.environment,
                experience: props.experience
            })
            .then((response) => {
                setAreNotesNotLoading();
                setAreReleaseNotesLoaded();

                setNoteTextValue(getLatestReleaseNoteForSelectedClient(response));

                appInsightsClient.logTrace(
                    { message: `Fetched release note from ShiproomService for ` },
                    {
                        clientType: props.clientType,
                        os: props.os,
                        environment: props.environment,
                        experience: props.experience
                    }
                );
            })
            .catch((fail) => {
                setAreNotesNotLoading();
                setAreReleaseNotesNotLoaded();

                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect in ReleaseNotesDetails`
                    }
                );
            });
    }, [props.clientType, props.os, props.environment, props.experience]);

    return (
        <div>
            {!areNotesLoading && !areReleaseNotesLoaded && (
                <Card>
                    <ErrorNotification msg="Unable to get release notes" />
                </Card>
            )}
            {areNotesLoading && (
                <Card>
                    <Spinner size={SpinnerSize.large} label="Fetching release notes..." />
                </Card>
            )}

            {!areNotesLoading && areReleaseNotesLoaded && (
                <div style={{ paddingTop: '1rem' }}>
                    {!isUserAReleaseManager && (
                        <Text
                            nowrap
                            block
                            styles={{
                                root: {
                                    flexGrow: 1,
                                    fontSize: '16px'
                                }
                            }}
                        >
                            {noteTextValue}
                        </Text>
                    )}

                    {isUserAReleaseManager && (
                        <Stack horizontal verticalAlign="center">
                            <TextField
                                multiline={multiline}
                                onChange={onChangeNote}
                                value={noteTextValue}
                                readOnly={isNoteReadOnly}
                                disabled={isNoteReadOnly}
                                styles={{
                                    root: { flexGrow: 1 },
                                    field: {
                                        fontSize: '16px'
                                    }
                                }}
                            />

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                    iconProps={{ iconName: 'Edit' }}
                                    title="Edit"
                                    ariaLabel="Edit"
                                    disabled={false}
                                    onClick={handleClickEdit}
                                    style={{ backgroundColor: 'white' }}
                                />
                                <IconButton
                                    iconProps={{ iconName: 'Save' }}
                                    title="Save"
                                    ariaLabel="Save"
                                    disabled={false}
                                    onClick={handleClickSave}
                                    style={{ backgroundColor: 'white' }}
                                />
                            </div>
                        </Stack>
                    )}
                </div>
            )}
        </div>
    );

    function getLatestReleaseNoteForSelectedClient(releaseNotes: ReleaseNotes) {
        let latestMessageForSelectedClient = 'No message from Release Managers for this client.';

        if (releaseNotes.messageDetails && releaseNotes?.messageDetails?.length > 0) {
            latestMessageForSelectedClient = releaseNotes?.messageDetails[0].message ?? '';
        }

        return latestMessageForSelectedClient;
    }

    function handleClickEdit() {
        setIsNoteReadOnly(false);
    }

    function handleClickSave() {
        if (!isNoteReadOnly) {
            setIsNoteReadOnly(true);

            const releaseNotePayload: ReleaseNotes = {
                // placeholder for id, it gets set in backend
                id: undefined,
                clientType: props.clientType,
                os: props.os,
                environment: props.environment,
                experience: props.experience,
                messageDetails: [
                    {
                        message: noteTextValue,
                        createdBy: getUserPrincipalName(),
                        createdAtUTC: new Date(new Date().toISOString())
                    }
                ]
            };

            const shiproomService = new ShiproomV2Service();

            shiproomService
                .apiDashboardShiproomReportAddReleaseNoteMessageForClientAsyncPut({ releaseNotes: releaseNotePayload })
                .catch((fail) => {
                    console.log(fail);

                    appInsightsClient.logException(
                        { exception: fail },
                        {
                            message: `Caught error in handleClickSave in ReleaseNotesDetails`,
                            releaseNotePayload: releaseNotePayload
                        }
                    );
                });
        }
    }
};

export default appInsightsClient.withAITracking(ReleaseNotesDetails, 'ReleaseNotesDetails');
