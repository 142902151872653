import { clientTypes, releaseManagers } from './configs/defaults';

/**
 * Determines if the user with the given email is a Release Manager.
 *
 * @param userEmail The email of the user to check.
 * @returns True if the user is an Release Manager, false otherwise.
 */
export function isUserReleaseManager(userEmail: string): boolean {
    const user = userEmail.split('@', 1)[0];
    return releaseManagers.includes(user);
}
/**
 * Returns a short date time string.
 *
 * @param date The date to convert.
 * @returns The short date time string.
 */
export function getShortDateTimeString(date: Date): string {
    date = new Date(date);
    return date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    });
}

/**
 * Returns a long date time string.
 *
 * @param date The date to convert.
 * @returns The long date time string.
 */
export function getLongDateTimeString(date: Date): string {
    date = new Date(date);
    return date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
    });
}

/**
 * Extracts the electron version from the build tags.
 * Relevant tag will be of the form `stable_electron_<version>` or `experimental_electron_<version>`.
 *
 * @param tags List of build tags.
 * @param identifier One of two values - 'stable' or 'experimental'.
 * @returns The version.
 */
export function getElectronVersion(tags: string[], identifier: string): string | undefined {
    if (!tags || tags.length === 0) return undefined;
    let version = '';
    tags.forEach((tag) => {
        const tagKeywords = tag.split('_');
        if (tagKeywords.length === 3 && tagKeywords[0] === identifier && tagKeywords[1] === 'electron') {
            version = tagKeywords[2];
            return;
        }
    });
    return version;
}

/**
 * Extracts the slimcore version from the build tags.
 * Relevant tag will be of the form `stable_slimcore_<version>` or `experimental_slimcore_<version>`.
 *
 * @param tags List of build tags.
 * @param clientType One of two values - 'desktop' or 'maglev'.
 * @param identifier One of two values - 'stable' or 'experimental'.
 * @returns The version.
 */
export function getSlimcoreVersion(tags: string[], clientType: string, identifier: string | undefined): string | undefined {
    if (!tags || tags.length === 0) return undefined;
    let version = '';
    tags.forEach((tag) => {
        const tagKeywords = tag.split('_');

        if (clientType === clientTypes.DESKTOP.toString()) {
            if (tagKeywords.length === 3 && tagKeywords[0] === identifier && tagKeywords[1] === 'slimcore') {
                version = tagKeywords[2];
                return;
            }
        } else if (tagKeywords.length === 2 && tagKeywords[0] === 'slimcore') {
            version = tagKeywords[1];
            return;
        }
    });
    return version;
}

/**
 * Extracts the stable and exp version from the build tags.
 * Relevant tag will be of the form `desktop-build-number_<version>`.
 *
 * @param tags List of build tags.
 * @returns The version.
 */
export function getStableAndExperimentalVersionForDesktop(tags: string[]): string[] | undefined {
    if (!tags || tags.length === 0) return undefined;

    const version: string[] = [];
    tags.forEach((tag) => {
        const tagKeywords = tag.split('_');
        if (tagKeywords.length === 2 && tagKeywords[0] === 'desktop-build-number') {
            version.push(tagKeywords[1]);
        }
    });

    return version.sort();
}

/**
 * Extracts the multi window and files version from the build tags.
 * Relevant tag will be of the form `orbital_multi-window_<version>`.
 *
 * @param tags List of build tags.
 * @param identifier One of two values - 'multi-window' or 'files'.
 * @returns The version.
 */
export function getWebClientRelatedDetails(tags: string[], identifier: string): string | undefined {
    if (!tags || tags.length === 0) return undefined;
    let version = '';
    tags.forEach((tag) => {
        const tagKeywords = tag.split('_');
        if (tagKeywords.length === 3 && tagKeywords[0] === 'orbital' && tagKeywords[1] === identifier) {
            version = tagKeywords[2];
            return;
        }
    });
    return version;
}

/**
 * Extracts the TsCalling version from the build tags.
 * Relevant tag will be of the form `tscalling_<version>`.
 *
 * @param tags List of build tags.
 * @returns The version.
 */
export function getTsCallingVersion(tags: string[]): string | undefined {
    if (!tags || tags.length === 0) return undefined;
    let version = '';
    tags.forEach((tag) => {
        const tagKeywords = tag.split('_');
        if (tagKeywords.length === 2 && tagKeywords[0] === 'tscalling') {
            version = tagKeywords[1];
            return;
        }
    });
    return version;
}

/**
 * Returns the supported cloud-ring pairs as a list.
 *
 * @param rings The supported rings dict.
 * @param includeProd Whether to include the prod ring or not.
 * @param delimiter The delimiter to use between the cloud and ring.
 * @returns A list of supported cloud-ring pairs.
 */
export function getFormattedCloudRingPairs(rings: Record<string, string[]>, includeProd = true, delimiter = '_') {
    const uniqueValues: string[] = [];
    Object.keys(rings).forEach((cloud) => {
        rings[cloud].forEach((ring) => {
            if (cloud === 'Prod' && !includeProd) {
                uniqueValues.push(`${ring}`);
            } else {
                uniqueValues.push(`${cloud}${delimiter}${ring}`);
            }
        });
    });
    return uniqueValues;
}

export const trainStatusValues = ['Active', 'Completed', 'Abandoned'];
