import { Label, Link, Spinner, SpinnerSize } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { BuildReleasePair, PullRequestDetails, SnapBranch } from 'skynet-client';

import { Card, CardHeader } from '../../../../components/Card';
import ErrorNotification from '../../../../components/ErrorNotification/ErrorNotification';
import ActiveCherryPicksService from '../../../../services/activeCherryPicksService';
import { appInsightsClient } from '../../../../utils/appInsightsUtility';

type ActiveCherryPicksDetailsProps = {
    buildReleaseList: BuildReleasePair[];
    snapBranches: SnapBranch[];
};

/**
 * Displays the active cherry picks for a train/snap-branch(s).
 *
 * @param props The props.
 * @returns The Active Cherry Picks Details component.
 */
const ActiveCherryPicksDetails: React.FC<ActiveCherryPicksDetailsProps> = (props) => {
    const [pendingPullRequests, setPendingPullRequests] = useState<PullRequestDetails[]>([]);
    const [pendingMergedCommits, setPendingMergedCommits] = useState<PullRequestDetails[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorLoadingData, setErrorLoadingData] = useState(false);
    const activeCherryPicksService = new ActiveCherryPicksService();

    const isPullRequestInBuildRelease = (pullRequest: PullRequestDetails, buildRelease: BuildReleasePair) => {
        return buildRelease.cherryPicks?.some((cherryPick) => {
            return cherryPick.id === pullRequest.commitId;
        });
    };

    const isPullRequestInBuildReleaseList = (pullRequest: PullRequestDetails, buildReleaseList: BuildReleasePair[]) => {
        return buildReleaseList.some((buildRelease) => isPullRequestInBuildRelease(pullRequest, buildRelease));
    };

    useEffect(() => {
        const projectName = props.buildReleaseList[0].buildMetadata?.projectName ?? '';
        const repositoryName = props.buildReleaseList[0].buildMetadata?.repositoryName ?? '';

        const fetchPullRequestStatus = (branchName: string, activePullRequestsOnly: boolean) => {
            activeCherryPicksService
                .getAllPullRequestStatus(projectName, repositoryName, branchName)
                .then((response) => {
                    setPendingPullRequests((prevPullRequests) => [...prevPullRequests, ...response.activePullRequests]);

                    if (!activePullRequestsOnly) {
                        const PendingMergedCommits = response.completedPullRequests.filter(
                            (pullRequest) => !isPullRequestInBuildReleaseList(pullRequest, props.buildReleaseList)
                        );
                        setPendingMergedCommits((prevPendingMergedCommits) => [...prevPendingMergedCommits, ...PendingMergedCommits]);
                    }

                    setIsLoading(false);
                })
                .catch((error) => {
                    appInsightsClient.logException({ exception: error }, { message: 'catch error in processing getAllPullRequestStatus' });
                    setIsLoading(false);
                    setErrorLoadingData(true);
                });
        };

        let snapBranch = props.snapBranches[0].branchName;
        // If there are multiple snap branches, then for the tmp branch, we need to fetch only the active pull requests.
        if (props.snapBranches.length > 1) {
            const tmpBranchName = props.snapBranches.find((branch) => branch.displayName?.endsWith('tmp'))?.branchName || '';
            snapBranch = props.snapBranches.find((branch) => branch.branchName !== tmpBranchName)?.branchName || '';
            fetchPullRequestStatus(tmpBranchName, true);
        }
        if (snapBranch !== undefined) {
            fetchPullRequestStatus(snapBranch, false);
        }
    }, []);

    return (
        <Card>
            <CardHeader>
                <span style={{ fontSize: '1.1rem' }}>Active Cherry Picks</span>
            </CardHeader>

            {isLoading ? (
                <div style={{ display: 'inline-flex', marginLeft: '5px' }}>
                    <Spinner labelPosition="right" size={SpinnerSize.small} style={{ marginRight: '5px' }} />
                    <Label> Fetching data...</Label>
                </div>
            ) : errorLoadingData ? (
                <div style={{ marginTop: '5px' }}>
                    <ErrorNotification msg="Unable to get active cherry picks" />
                </div>
            ) : (
                <div>
                    {pendingPullRequests.length > 0 && (
                        <div style={{ marginLeft: '5px' }}>
                            <Label>Pending Pull Request(s)</Label>
                            <ul style={{ margin: 0 }}>
                                {pendingPullRequests.map((pullRequest) => (
                                    <li key={pullRequest.commitId}>
                                        <Link href={pullRequest.url}>{pullRequest.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {pendingMergedCommits.length > 0 && (
                        <div style={{ marginLeft: '5px' }}>
                            <Label>Pending Merged Commit(s)</Label>
                            <ul style={{ margin: 0 }}>
                                {pendingMergedCommits.map((commit) => (
                                    <li key={commit.commitId}>
                                        <Link href={commit.url}>{commit.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {pendingPullRequests.length === 0 && pendingMergedCommits.length === 0 && (
                        <Label style={{ marginLeft: '5px' }}>No active cherry picks found.</Label>
                    )}
                </div>
            )}
        </Card>
    );
};

export { ActiveCherryPicksDetails };
