import {
    ActionButton,
    ComboBox,
    DefaultButton,
    IMessageBarStyles,
    ITextField,
    ITextFieldStyles,
    Icon,
    IconButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
    TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { FormEvent, createRef, useEffect, useState } from 'react';

import { categoryNames } from '../configs/categories';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { buttonStyles, pairInputStyle, removeIcon } from '../styles/StartFlightFormStyle';
import { FlagPair } from '../types/Types';
import { flattenJSON, isValidJSON } from '../utilities/FFv2Utils';

type FlagsInputProps = {
    workItemFeatureFlags: string[];
    reset: boolean;
    showAllErrors: boolean;
    update: (flag?: string, config?: string) => void;
    defaultFlags?: string;
    defaultControlConfigs?: string;
};

/**
 * Renders an input field for entering feature flags in JSON format and validates the input against the linked work item's feature flag list.
 *
 * @param props - The component's props.
 * @returns The JSX element representing the feature flags input field.
 */
const FlagsInput: React.FC<FlagsInputProps> = (props) => {
    // ========================= State =========================
    const { reset, showAllErrors, workItemFeatureFlags } = props;
    const defaultFlags = props.defaultFlags ?? '';
    const defaultControlConfigs = props.defaultControlConfigs ?? '';

    const [isJSONInput, setIsJSONInput] = useBoolean(false);

    const [flagsString, setFlagsString] = useState<string>(defaultFlags);
    const [configString, setConfigString] = useState<string>(defaultControlConfigs);
    const [flagPairs, setFlagPairs] = useState<FlagPair[]>(getDefaultFlagPairs());
    const [flagsErrorMessage, setFlagsErrorMessage] = useState<string>('');
    const [flagsWarningMessage, setFlagsWarningMessage] = useState<string>('');
    const textFieldRef = createRef<ITextField>();

    const flagOptions = flagPairs
        .map((flagPair) => flagPair.name)
        .filter((flagName) => !workItemFeatureFlags.includes(flagName) && flagName !== '')
        .concat(workItemFeatureFlags)
        .map((flagName) => ({
            key: flagName,
            text: flagName,
            disabled: flagPairs.map((flag) => flag.name).includes(flagName)
        }));

    // ========================= Hooks =========================
    useEffect(() => {
        setFlagsString(defaultFlags);
        setConfigString(defaultControlConfigs);
        setFlagPairs(getDefaultFlagPairs());
        setFlagsErrorMessage('');
        setFlagsWarningMessage('');
    }, [reset, workItemFeatureFlags]);

    useEffect(() => {
        const { errorMsg, flags, controls } = getErrorMessage();
        setFlagsWarningMessage('');
        setFlagsErrorMessage(errorMsg);
        if (errorMsg === '') {
            props.update(flags, controls);
        } else {
            props.update('{}');
        }
    }, [flagsString, flagPairs, configString]);

    useEffect(() => {
        if (!showAllErrors) return;
        if (isJSONInput && textFieldRef.current) {
            textFieldRef.current.focus();
            textFieldRef.current.blur();
        } else {
            const { errorMsg } = getErrorMessage();
            setFlagsErrorMessage(errorMsg);
        }
    }, [showAllErrors]);

    // ========================= Helper Function =========================

    const getErrorMessage = () => {
        let errorMsg = '';

        const flags = isJSONInput ? flagsString : pairsToJSON(flagPairs);
        const controls = isJSONInput ? configString : pairsToJSON(flagPairs.map((item) => ({ name: item.name, value: item.control })));

        if (!flags || flags === '{}' || !controls || controls === '{}') {
            errorMsg = 'Provide flags and control configs. ';
        } else if (!isValidJSON(flags) || !isValidJSON(controls)) {
            errorMsg = 'Invalid JSON!';
        } else {
            const flattenedFlags = flattenJSON(JSON.parse(flags));
            const flattenedControls = flattenJSON(JSON.parse(controls));
            if (!props.workItemFeatureFlags) {
                errorMsg = 'No feature flags found in the linked ADO work item!';
            } else if (JSON.stringify(Object.keys(flattenedFlags)) !== JSON.stringify(Object.keys(flattenedControls))) {
                errorMsg = 'Flags and control configs keys do not match!';
            } else {
                const missingKeys = Object.keys(flattenedFlags).filter((key) => !props.workItemFeatureFlags.includes(key));

                if (missingKeys.length !== 0) {
                    errorMsg = `Some keys listed in the JSON object do not exist in the work item: ${missingKeys.join(', ')}`;
                }
            }
        }

        return { errorMsg, flags, controls };
    };

    // ========================= Event handlers =========================
    const handleFlagsJSONChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            setFlagsString(newValue);
        }
    };

    const handleControlConfigJSONChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            setConfigString(newValue);
        }
    };

    const handleFlagPairChange = (index: number, name?: string, value?: string, isControlValue?: boolean) => {
        const updatedFlagPair = [...flagPairs];
        if (name !== undefined) updatedFlagPair[index].name = name;
        if (value !== undefined) isControlValue ? (updatedFlagPair[index].control = value) : (updatedFlagPair[index].value = value);
        setFlagPairs(updatedFlagPair);
    };

    const handleAddFlag = () => {
        setFlagPairs((prev) => [...prev, { name: '', value: undefined, control: undefined }]);
    };

    const handleRemoveFlag = (name: string) => {
        setFlagPairs((prev) => {
            const updatedFlagsOnPage = prev.filter((filter) => filter.name !== name);
            return updatedFlagsOnPage.length === 0 ? [{ name: '', value: undefined, control: undefined }] : updatedFlagsOnPage;
        });
    };

    const onSwitchInputMode = () => {
        if (isJSONInput) {
            const newFlagPairs = jsonToPairs(flagsString);
            const newConfigPairs = jsonToPairs(configString);
            newFlagPairs.forEach((item, index) => {
                item.control = newConfigPairs[index].value;
            });
            setFlagPairs(newFlagPairs);
        } else {
            const newFlagString = pairsToJSON(flagPairs);
            const newControlString = pairsToJSON(flagPairs.map((item) => ({ name: item.name, value: item.control })));
            setFlagsString(newFlagString);
            setConfigString(newControlString);
        }
        setIsJSONInput.toggle();
    };

    // ========================= Render =========================
    const renderJsonInput = () => (
        <Stack horizontal tokens={gapStackTokensMedium}>
            <TextField
                id="flagsJSONText"
                name="flagsJSON"
                placeholder="List flags in JSON format"
                value={flagsString}
                multiline
                componentRef={textFieldRef}
                styles={textFieldStyles}
                onChange={handleFlagsJSONChange}
                disabled={workItemFeatureFlags.length === 0}
            />
            <TextField
                id="controlConfigJSONText"
                name="controlConfigJSON"
                placeholder="List control configs in JSON format"
                value={configString}
                multiline
                componentRef={textFieldRef}
                styles={textFieldStyles}
                onChange={handleControlConfigJSONChange}
                disabled={workItemFeatureFlags.length === 0}
            />
        </Stack>
    );

    const renderPairInput = () => {
        return (
            <Stack tokens={gapStackTokensMedium}>
                {flagPairs.map((flag, index) => {
                    return (
                        <Stack horizontal key={index} tokens={gapStackTokensMedium} verticalAlign="center">
                            <ComboBox
                                selectedKey={flag.name === '' ? null : flag.name}
                                styles={pairInputStyle}
                                placeholder="Select flag name"
                                options={flagOptions}
                                onChange={(event, option, optionIndex, value) => {
                                    handleFlagPairChange(index, value, undefined);
                                }}
                                autoComplete="on"
                                disabled={workItemFeatureFlags.length === 0}
                            />
                            <TooltipHost content={'Flag value'}>
                                <TextField
                                    value={flag.value ?? ''}
                                    styles={pairInputStyle}
                                    placeholder="Flag value"
                                    onChange={(event, value) => {
                                        handleFlagPairChange(index, undefined, value);
                                    }}
                                    disabled={workItemFeatureFlags.length === 0}
                                />
                            </TooltipHost>
                            <TooltipHost content={'Control value (used only if eligible for experiment)'}>
                                <TextField
                                    value={flag.control ?? ''}
                                    styles={pairInputStyle}
                                    placeholder={flag.control === undefined ? 'Control value' : '(empty string)'}
                                    onChange={(_, value) => {
                                        handleFlagPairChange(index, undefined, value, true);
                                    }}
                                    disabled={workItemFeatureFlags.length === 0}
                                />
                            </TooltipHost>

                            {(flagPairs.length > 1 || flagPairs[0].name || flagPairs[0].value || flagPairs[0].control) && (
                                <IconButton iconProps={removeIcon} onClick={() => handleRemoveFlag(flag.name)} />
                            )}
                            {flag.name && !workItemFeatureFlags.includes(flag.name) && (
                                <TooltipHost content="This flag does not exist on the work item.">
                                    <Icon iconName="Warning" styles={{ root: { color: 'red' } }} />
                                </TooltipHost>
                            )}
                            {flag.name && (flag.name.split('.').length < 2 || !categoryNames.includes(flag.name.split('.')[0])) && (
                                <TooltipHost content="This flag may not have a valid category name">
                                    <Icon iconName="Warning" styles={{ root: { color: 'orange' } }} />
                                </TooltipHost>
                            )}
                        </Stack>
                    );
                })}
                <Stack horizontal tokens={gapStackTokensMedium}>
                    <PrimaryButton styles={buttonStyles} onClick={handleAddFlag}>
                        + Add Flag
                    </PrimaryButton>
                    <DefaultButton styles={buttonStyles} onClick={generateControls}>
                        Generate Controls
                    </DefaultButton>
                </Stack>
            </Stack>
        );
    };

    return (
        <Stack tokens={gapStackTokensMedium}>
            <ActionButton
                iconProps={{ iconName: 'Switch' }}
                onClick={onSwitchInputMode}
                text={isJSONInput ? 'Switch to text box' : 'Switch to JSON'}
                disabled={
                    workItemFeatureFlags && isJSONInput && flagsString !== '' && (!isValidJSON(flagsString) || !isValidJSON(flagsString))
                }
            />
            <MessageBar delayedRender={false} messageBarType={MessageBarType.info} styles={messageBarStyles}>
                Please include category in the flag names from ADO feature. Example: activity.activityComponentLoggerWaitTime
            </MessageBar>
            {isJSONInput ? renderJsonInput() : renderPairInput()}
            {!!workItemFeatureFlags?.length && flagsErrorMessage !== '' && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} styles={messageBarStyles}>
                    {flagsErrorMessage}
                </MessageBar>
            )}

            {!!workItemFeatureFlags?.length && flagsWarningMessage !== '' && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.warning} styles={messageBarStyles}>
                    {flagsWarningMessage}
                </MessageBar>
            )}
        </Stack>
    );

    function generateControls() {
        const newFlagPairs = [...flagPairs];
        newFlagPairs.forEach((item) => {
            if (!!item.control?.length || item.value === undefined) return item.control;
            if (item.value === 'true') {
                item.control = 'false';
            } else if (item.value === 'false') {
                item.control = 'true';
            } else if (!isNaN(parseFloat(item.value))) {
                item.control = '0';
            } else {
                item.control = '';
            }
        });
        setFlagPairs(newFlagPairs);
        setConfigString(pairsToJSON(newFlagPairs.map((item) => ({ name: item.name, value: item.control }))));
    }

    function getDefaultFlagPairs() {
        const pairs = jsonToPairs(defaultFlags);
        const configPairs = jsonToPairs(defaultControlConfigs);
        pairs.forEach((item, idx) => {
            item.control = configPairs[idx].value;
        });
        return pairs;
    }
};

const jsonToPairs = (jsonString: string): FlagPair[] => {
    if (!isValidJSON(jsonString)) return [{ name: '', value: undefined }];
    const convertedPair = Object.entries(flattenJSON(JSON.parse(jsonString))).map(
        ([name, value]) => ({ name, value: value.toString() }) as FlagPair
    );
    return convertedPair.length > 0 ? convertedPair : [{ name: '', value: undefined }];
};

const pairsToJSON = (nameValuePair: FlagPair[]): string => {
    const result = JSON.stringify(
        nameValuePair.reduce((acc, pair) => {
            if (pair.name === '' && pair.value === undefined) return acc;
            let convertedValue: unknown = pair.value;
            if (pair.value?.toLowerCase() === 'true' || pair.value?.toLowerCase() === 'false') {
                convertedValue = JSON.parse(pair.value.toLowerCase());
            }
            if (pair.value && !isNaN(parseFloat(pair.value))) convertedValue = parseFloat(pair.value);
            return { ...acc, [pair.name]: convertedValue };
        }, {})
    );
    return result === '{}' ? '' : result;
};

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 350 } };
const messageBarStyles: Partial<IMessageBarStyles> = {
    root: {
        alignItems: 'flex-start',
        marginTop: '5px',
        width: 500
    }
};
export default FlagsInput;
