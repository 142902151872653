/**
 * Determines whether the work item ID is valid.
 *
 * @param value A numeric character string representing the work item ID.
 * @returns Whether the work item ID is valid.
 */
export function isValidWorkItemId(value: string): boolean {
    return /^\d{5,}$/.test(value);
}

/**
 * Convert from workitem id to workitem url.
 *
 * @param id - The workitem id.
 * @param organization - The organization name.
 * @param project - The project name.
 * @returns The workitem url.
 */
export function getADOWorkItemURL(id: number | string, organization = 'domoreexp', project = 'MSTeams'): string {
    // Convert the id param to a string
    if (typeof id === 'number') id = id.toString();
    return `https://${organization}.visualstudio.com/${project}/_workitems/edit/${id}`;
}
