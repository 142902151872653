import { Pivot, PivotItem, Link, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../components/Card';
import { displayName, ringmasterRequestApprovalGroup, ringmasterRequestAssistanceGroup } from '../../services/configs/msGraph.defaults';
import MsGraphService from '../../services/msGraph.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserDisplayName, getUserPrincipalName } from '../../utils/userUtility';

import FindIdForm from './FindIdForm';
import RequestForm from './RequestForm';
import RequestsStatusDetails from './RequestsStatusDetails';
import { gapStackTokensMedium } from './styles/RingmasterPageStyle';

/**
 * This component is the main page for Ringmaster UI. It contains 3 tabs: Find, Request, and Requests Status.
 * The Find tab is to get the ring details for a given ID.
 * The Request tab is to request a ring change for a given ID.
 * The Requests Status tab is to view the status of the requests made by the user.
 *
 * @returns The actual view.
 */
const RingAssignmentPage: React.FC = () => {
    const userPrincipalName = getUserPrincipalName();

    // ============================== State ==============================
    const [activeTab, setActiveTab] = useState('find-id');

    const [
        isRingmasterRequestAssistanceGroupMember,
        { setTrue: setIsRingmasterRequestAssistanceGroupMember, setFalse: setIsNotRingmasterRequestAssistanceGroupMember }
    ] = useBoolean(false);

    const [
        isRingmasterRequestApprovalGroupMember,
        { setTrue: setIsRingmasterRequestApprovalGroupMember, setFalse: setIsNotRingmasterRequestApprovalGroupMember }
    ] = useBoolean(false);

    const msGraphService = new MsGraphService();

    const handleTabChange = (item?: PivotItem) => {
        if (item) {
            setActiveTab(item.props.itemKey || '');
        }
    };

    // ============================== Hooks ==============================
    useEffect(() => {
        Promise.all([
            msGraphService.getTransitiveMemberOf(`${displayName} eq '${ringmasterRequestAssistanceGroup}'`, 'id, displayName'),
            msGraphService.getTransitiveMemberOf(`${displayName} eq '${ringmasterRequestApprovalGroup}'`, 'id, displayName')
        ])
            .then(([contributorGroups, adminGroups]) => {
                contributorGroups && contributorGroups.length > 0
                    ? setIsRingmasterRequestAssistanceGroupMember()
                    : setIsNotRingmasterRequestAssistanceGroupMember();
                adminGroups && adminGroups.length > 0
                    ? setIsRingmasterRequestApprovalGroupMember()
                    : setIsNotRingmasterRequestApprovalGroupMember();
            })
            .catch((fail) => {
                console.error(
                    `Caught error getting membership details for user: ${getUserDisplayName()} - ${userPrincipalName} and groups: [${ringmasterRequestAssistanceGroup}, ${ringmasterRequestApprovalGroup}], error: ${fail}`
                );
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTransitiveMemberOf in RingAssignmentPage`,
                        user: userPrincipalName,
                        groups: [ringmasterRequestAssistanceGroup, ringmasterRequestApprovalGroup]
                    }
                );
            });
    }, []);

    return (
        <Stack tokens={gapStackTokensMedium}>
            <Card>
                <CardHeader>
                    Ring Management Center<Text variant="small">(MVP)</Text>
                </CardHeader>
            </Card>
            <Card>
                <CardHeader>Note(s) :</CardHeader>
                <ul>
                    <li>
                        <Text variant="medium">This page is used to manage ring membership for test users in Microsoft</Text>
                    </li>
                    <li>
                        <Text variant="medium">
                            For any questions, please visit{' '}
                            <Link href="https://aka.ms/teamsbqtrchannel" target="_blank">
                                Teams channel
                            </Link>
                            !
                        </Text>
                    </li>
                </ul>
            </Card>
            <Card>
                <Pivot onLinkClick={handleTabChange} selectedKey={activeTab} overflowBehavior="menu">
                    <PivotItem headerText="Find" itemKey="find-id" />
                    {(isRingmasterRequestAssistanceGroupMember || isRingmasterRequestApprovalGroupMember) && (
                        <PivotItem headerText="Request" itemKey="request-ring-change" />
                    )}
                    {(isRingmasterRequestAssistanceGroupMember || isRingmasterRequestApprovalGroupMember) && (
                        <PivotItem headerText="Requests Status" itemKey="requests-status" />
                    )}
                </Pivot>
            </Card>
            {activeTab && activeTab === 'find-id' && (
                <Card>
                    <FindIdForm />
                </Card>
            )}
            {activeTab && activeTab === 'request-ring-change' && (
                <Card>
                    <RequestForm />
                </Card>
            )}
            {activeTab && activeTab === 'requests-status' && (
                <Card>
                    <RequestsStatusDetails isRingmasterRequestApprovalGroupMember={isRingmasterRequestApprovalGroupMember} />
                </Card>
            )}
        </Stack>
    );
};

export default appInsightsClient.withAITracking(RingAssignmentPage, 'RingAssignmentPage');
