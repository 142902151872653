import {
    FontWeights,
    IButtonStyles,
    IDropdownStyles,
    IIconProps,
    IMessageBarStyles,
    IStackItemStyles,
    IStackTokens,
    ITextFieldStyles,
    getTheme,
    mergeStyleSets,
    mergeStyles
} from '@fluentui/react';

const theme = getTheme();

export const gapStackTokensMedium: IStackTokens = {
    childrenGap: 10
};

export const ringmasterRequestTableStyle = {
    borderRadius: '1em',
    paddingTop: '8px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '0px'
};

export const ringmasterRequestTableCellStyle = {
    padding: '10px'
};

export const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        width: '300px'
    }
};

export const messageBarStyles: IMessageBarStyles = {
    root: {
        alignItems: 'flex-start',
        marginLeft: '10px'
    }
};

export const stackItemStyles: IStackItemStyles = {
    root: {
        marginTop: '15px',
        marginBottom: '15px',
        alignItems: 'flex-start',
        display: 'grid',
        justifyContent: 'center',
        whiteSpace: 'nowrap'
    }
};

export const formButtonStyles: IButtonStyles = {
    root: {
        width: '150px',
        height: '35px',
        alignItems: 'flex-start'
    }
};

export const exampleChildClass = mergeStyles({
    display: 'block',
    marginBottom: '10px'
});

export const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

export const modalStyles = mergeStyleSets({
    container: {
        display: 'flex',
        maxWidth: '80%',
        maxHeight: '90%',
        borderRadius: '5px'
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px'
        }
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0'
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden'
    }
});
