import { Checkbox, DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import RingmasterService from '../../../services/ringmaster.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { getUserInfo } from '../../../utils/userUtility';
import { defaultRingChangeRequestForm } from '../configs/defaults';
import { formButtonStyles, gapStackTokensMedium, stackItemStyles } from '../styles/RingmasterPageStyle';
import { RingChangeRequestFormData } from '../types/Types';

type RequestFormButtonsProps = {
    formData: RingChangeRequestFormData;
    setFormData: React.Dispatch<React.SetStateAction<RingChangeRequestFormData>>;
    resetData: boolean;
    resetFormDataTrigger: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Renders the primary action buttons for the ring change request form.
 *
 * @param props - The props containing the form data to submit along with other helper functions and flags.
 * @returns The JSX element representing the primary action buttons for the form.
 */
const RequestFormButtons: React.FC<RequestFormButtonsProps> = (props) => {
    // ========================= State =========================
    const [isAcknowledgeChecked, setIsAcknowledgeChecked] = useState<boolean>(false);
    const [submitErrorMsg, setSubmitErrorMsg] = useState<string>('');
    const [submitNonErrorMsg, setSubmitNonErrorMsg] = useState<string>('');

    const ringmasterService = new RingmasterService();

    // ========================= Event handlers =========================
    const handleSubmit = async () => {
        setSubmitErrorMsg('');
        setSubmitNonErrorMsg('');

        await ringmasterService
            .processRingChangeRequest(props.formData)
            .then((response) => {
                appInsightsClient.logEvent({
                    name: 'Ringmaster:RequestForm:RequestFormButtons:processRingChangeRequest',
                    properties: { response: response }
                });

                // Handle no error case
                if (!response.error) {
                    const s500message = response.isS500Tenant
                        ? 'The GUID under consideration is a Strategic tenant. Approval will be granted once Ringmaster Admin team reviews the request.'
                        : `Request status is ${response.requestStatus}.`;
                    setSubmitNonErrorMsg(
                        `Ring change request initiated successfully with request id: ${response.userFriendlyRequestId}. ${s500message}`
                    );
                } else {
                    setSubmitErrorMsg('An error occurred during form submission. ' + response.error);
                }

                // Reset the form after successful submission
                handleReset();
            })
            .catch((error) => {
                appInsightsClient.logException(
                    { exception: new Error('Ringmaster:RequestForm:RequestFormButtons:processRingChangeRequest') },
                    { message: 'Ring change request initiation error' }
                );
                setSubmitErrorMsg('An error occurred during form submission. ' + error);
            });
    };

    const handleReset = () => {
        props.setFormData({ ...defaultRingChangeRequestForm, createdBy: getUserInfo() });
        props.resetFormDataTrigger(!props.resetData);
        setIsAcknowledgeChecked(false);

        appInsightsClient.logEvent({ name: 'Ringmaster:RequestForm:RequestFormButtons:FormClear' });
    };

    const isFormDataValid = (): boolean => {
        const formData = props.formData as RingChangeRequestFormData;

        return !(
            !formData.workItemId.length ||
            !formData.identityType.length ||
            !formData.ring.length ||
            !formData.cloud.length ||
            !formData.operation.length ||
            !formData.operationId.length
        );
    };

    // ========================= Render =========================
    return (
        <>
            <Checkbox
                label="I acknowledge and understand that the user/tenant id will
                                                    automatically progress to the targeted ring if they aren't a Strategic tenant.
                                                    In case of a Strategic tenant, the request will be sent to the Ringmaster team
                                                    for approval before progressing to the targeted ring"
                checked={isAcknowledgeChecked}
                required
                onChange={(_, isChecked) => {
                    setIsAcknowledgeChecked(isChecked ?? false);
                    appInsightsClient.logEvent({
                        name: 'Ringmaster:RequestForm:RequestFormButtons:AcknowledgeCheckbox',
                        properties: { isChecked: isChecked }
                    });
                }}
            />
            <Stack>
                <Stack.Item>
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <Stack.Item styles={stackItemStyles}>
                            <PrimaryButton
                                text="Submit Request"
                                iconProps={{ iconName: 'Checkmark' }}
                                onClick={handleSubmit}
                                styles={formButtonStyles}
                                allowDisabledFocus
                                disabled={!isAcknowledgeChecked || !isFormDataValid()}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <DefaultButton
                                text="Reset"
                                iconProps={{ iconName: 'Clear' }}
                                onClick={handleReset}
                                styles={formButtonStyles}
                                allowDisabledFocus
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            {submitErrorMsg && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} onDismiss={() => setSubmitErrorMsg('')}>
                    {submitErrorMsg}
                </MessageBar>
            )}

            {submitNonErrorMsg && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.info} onDismiss={() => setSubmitNonErrorMsg('')}>
                    {submitNonErrorMsg}
                </MessageBar>
            )}
        </>
    );
};

export default RequestFormButtons;
