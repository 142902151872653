import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { RecentChangeType } from '../configs/defaults';
import { getSelectableOptions } from '../utils';

import { FilterFormData } from './FilterFormData';

type ChangeTypeComboBoxProps = {
    setFilterFormData: React.Dispatch<React.SetStateAction<FilterFormData>>;
};

const changeTypeOptions = [
    {
        key: 'selectAll',
        text: 'Select All',
        itemType: SelectableOptionMenuItemType.SelectAll
    },
    {
        key: RecentChangeType.BitsReleaseUpdate,
        text: 'Bits Release Updates',
        itemType: SelectableOptionMenuItemType.Normal
    },
    {
        key: RecentChangeType.BuildRolloutUpdate,
        text: 'Build Rollout Updates',
        itemType: SelectableOptionMenuItemType.Normal
    },
    {
        key: RecentChangeType.FeatureRolloutUpdate,
        text: 'Feature Flag Updates',
        itemType: SelectableOptionMenuItemType.Normal
    }
];

/**
 * Component for the change type combo box.
 *
 * @param props The props for the component.
 * @returns The component to display the change type combo box.
 */
export const ChangeTypeComboBox: React.FC<ChangeTypeComboBoxProps> = (props) => {
    const { setFilterFormData } = props;

    const [changeTypeComboBoxOptions, setChangeTypeComboBoxOptions] = useState<IComboBoxOption[]>([]);
    const [selectedChangeTypeComboBoxKeys, setSelectedChangeTypeComboBoxKeys] = useState<string[]>([]);

    useEffect(() => {
        setChangeTypeComboBoxOptions(changeTypeOptions);
    }, []);

    return (
        <ComboBox
            placeholder="Select Change Type(s)"
            multiSelect
            options={changeTypeComboBoxOptions}
            onChange={onChangeTypeComboBoxChange}
            calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
        />
    );

    function onChangeTypeComboBoxChange(_event: React.FormEvent<IComboBox>, option?: IComboBoxOption, _index?: number, _value?: string) {
        if (!option) {
            return;
        }

        const selected = option.selected;
        const currentSelectedOptionKeys = selectedChangeTypeComboBoxKeys.filter((key) => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === getSelectableOptions(changeTypeComboBoxOptions).length;

        if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
            if (selectAllState) {
                setSelectedChangeTypeComboBoxKeys([]);
                setFilterFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        changeTypes: []
                    };
                });
            } else {
                setSelectedChangeTypeComboBoxKeys([
                    'selectAll',
                    ...getSelectableOptions(changeTypeComboBoxOptions).map((o) => o.key as string)
                ]);
                setFilterFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        changeTypes: [...getSelectableOptions(changeTypeComboBoxOptions).map((o) => o.key as string)]
                    };
                });
            }
        } else {
            const updatedKeys = selected
                ? [...currentSelectedOptionKeys, option.key as string]
                : currentSelectedOptionKeys.filter((k) => k !== option.key);
            if (updatedKeys.length === getSelectableOptions(changeTypeComboBoxOptions).length) {
                updatedKeys.push('selectAll');
            }
            setSelectedChangeTypeComboBoxKeys(updatedKeys);
            setFilterFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    changeTypes: updatedKeys
                };
            });
        }
    }
};
