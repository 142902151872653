import {
    Dropdown,
    IconButton,
    IDropdownOption,
    Label,
    Modal,
    PrimaryButton,
    Stack,
    Text,
    TextField,
    Checkbox,
    Link,
    DefaultButton
} from '@fluentui/react';
import { useBoolean, useConst, useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';

import FeatureFlightService from '../../services/featureFlight.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import { feedbackOptions } from './configs/defaults';
import { gapStackTokensSmall } from './styles/FFv2Style';
import { formStyles, iconButtonStyles } from './styles/MyFlightsStyle';
import { ADOWorkItem, FeedbackFormData } from './types/Types';
import { getCurrentIterationPath } from './utilities/FFv2Utils';

/**
 * Displays the form to send feedback.
 *
 * @returns The feedback form.
 */
const FeedbackForm: React.FC = () => {
    const user = useConst(() => getUserInfo());

    const [formState, setFormState] = useState<FeedbackFormData>(getDefaultFormState());
    const [isFeedbackModalOpen, { setTrue: showFeedbackModal, setFalse: hideFeedbackModal }] = useBoolean(false);
    const [isSubmitEnabled, { setTrue: enableSubmit, setFalse: disableSubmit }] = useBoolean(false);
    const [submitResult, setSubmitResult] = useState<ADOWorkItem | Error | undefined>();

    const titleId = useId('title');
    const cancelIcon = { iconName: 'Cancel' };

    const getTitleText = () => {
        if (!submitResult) return 'Sending feedback';
        else if ('message' in submitResult) return 'Submission error';
        else return 'Thanks for providing feedback';
    };

    return (
        <>
            <DefaultButton iconProps={{ iconName: 'Feedback' }} onClick={handleDisplay}>
                Send Feedback
            </DefaultButton>
            <Modal
                className={formStyles.container}
                titleAriaId={titleId}
                isOpen={isFeedbackModalOpen}
                onDismiss={hideFeedbackModal}
                isBlocking={false}
            >
                <div className={formStyles.header}>
                    <h3 id={titleId}>{getTitleText()}</h3>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideFeedbackModal}
                    />
                </div>
                <div className={formStyles.body}>
                    {submitResult && 'url' in submitResult && (
                        <>
                            <br />
                            <Text variant="large">
                                Your valuable feedback has been submitted at{' '}
                                <Link href={submitResult.url} target="_blank">
                                    {submitResult.title}
                                </Link>
                                . Our team will review it and get back to you if we have any questions.
                                <br />
                                <br />
                                Thanks for helping us improve!
                            </Text>
                        </>
                    )}
                    {submitResult && 'message' in submitResult && (
                        <>
                            <br />
                            <Text variant="large">
                                Sorry, some error occurred while submitting your feedback. You may try again later or post your feedback in
                                our{' '}
                                <Link
                                    href="https://teams.microsoft.com/l/channel/19%3Askypespaces_60599f608e5542939a1fe4932dea4326%40thread.skype/Build%2C%20Quality%2C%20Testing%20and%20Release%20%5BBQTR%5D?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47"
                                    target="_blank"
                                >
                                    Teams channel
                                </Link>
                                .
                                <br />
                                <br />
                                Thanks for helping us improve!
                            </Text>
                        </>
                    )}
                    {!submitResult && (
                        <form>
                            <Stack tokens={gapStackTokensSmall}>
                                <Stack.Item>
                                    <Dropdown
                                        id="type"
                                        label="Feedback Type"
                                        placeholder="Select the type of feedback you want to provide"
                                        options={feedbackOptions}
                                        onChange={onDropdownChange}
                                        selectedKey={formState.type}
                                        required
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Label required>Title</Label>
                                    <TextField
                                        id="title"
                                        prefix={'[FFv2][Feedback]'}
                                        defaultValue={formState.title}
                                        onChange={onTextFieldChange}
                                        validateOnLoad={false}
                                        deferredValidationTime={500}
                                        onGetErrorMessage={getTitleErrorMessage}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Label required>Description</Label>
                                    <TextField
                                        id="description"
                                        defaultValue={formState.description}
                                        multiline
                                        rows={3}
                                        onChange={onTextFieldChange}
                                        validateOnLoad={false}
                                        deferredValidationTime={500}
                                        onGetErrorMessage={getDescriptionErrorMessage}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Label>Screenshot (size&lt;10 MB)</Label>
                                    <input id="screenshot" type="file" onChange={handleFileUpload} accept="image/*" />
                                </Stack.Item>
                                <Stack.Item>
                                    <br />
                                    <Checkbox
                                        id="contact"
                                        label="I would like to leave my contact information for follow-up."
                                        defaultChecked={true}
                                        required
                                        onChange={onCheckboxChange}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <br />
                                    <PrimaryButton text="Submit" onClick={onSubmit} allowDisabledFocus disabled={!isSubmitEnabled} />
                                </Stack.Item>
                            </Stack>
                        </form>
                    )}
                </div>
            </Modal>
        </>
    );

    function onCheckboxChange(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if (checked !== undefined) {
            setFormState({ ...formState, contact: checked ? user : undefined });
        }
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) {
        const target = event.target as HTMLInputElement;
        switch (target.id) {
            case 'type':
                if (!option || option.key.toString() === formState.type) break;
                if (option.key === 'Bug') {
                    setFormState({ ...formState, type: 'Bug' });
                } else {
                    setFormState({ ...formState, type: 'Requirement' });
                }
                break;
            default:
                break;
        }
        checkFormState(target.id, option ? option.key.toString() : '');
    }

    function onTextFieldChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
        const target = event.target as HTMLInputElement;

        switch (target.id) {
            case 'title':
                setFormState({ ...formState, title: newValue ? newValue : '' });
                break;
            case 'description':
                setFormState({ ...formState, description: newValue ? newValue : '' });
                break;
            default:
                break;
        }

        checkFormState(target.id, newValue ? newValue : '');
    }

    function isTitleError(value: string): boolean {
        return value.length < 5 || value.length > 240 - '[FFv2][Feedback]'.length;
    }

    function isDescriptionError(value: string) {
        return value.length < 10;
    }

    function getTitleErrorMessage(value: string): string {
        return isTitleError(value) ? 'Title must be at least 5 characters and no more than 240 characters.' : '';
    }

    function getDescriptionErrorMessage(value: string) {
        return isDescriptionError(value) ? `Description must be at least 10 characters.` : '';
    }

    function checkFormState(updatedField: string, updatedValue: string) {
        const type = updatedField === 'type' ? updatedValue : formState.type;
        const title = updatedField === 'title' ? updatedValue : formState.title;
        const description = updatedField === 'description' ? updatedValue : formState.description;
        if (type.length && !isTitleError(title) && !isDescriptionError(description)) {
            enableSubmit();
        } else if (isSubmitEnabled) {
            disableSubmit();
        }
    }

    function onSubmit() {
        appInsightsClient.logEvent({ name: 'FeedbackFormSubmit', properties: { response: 'good' } });

        new FeatureFlightService()
            .createFeedbackWorkItem({ ...formState, title: `[FFv2][Feedback] ${formState.title}` })
            .then((response) => {
                setSubmitResult(response);
                appInsightsClient.logEvent({ name: 'FeedbackFormSubmit', properties: { response: response } });
            })
            .catch((fail) => {
                setSubmitResult(fail);
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'caught error in processing block rollout in FeedbackForm' }
                );
            });
    }

    function handleDisplay() {
        showFeedbackModal();
        setSubmitResult(undefined);
        setFormState(getDefaultFormState());
        disableSubmit();
    }

    function getDefaultFormState(): FeedbackFormData {
        return {
            type: '',
            title: '',
            description: '',
            contact: user,
            iterationPath: getCurrentIterationPath('quarter')
        };
    }

    function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target) return;
        setFormState({
            ...formState,
            screenshot: event.target.files?.length && event.target.files[0].type.startsWith('image/') ? event.target.files[0] : undefined
        });
    }
};

export default FeedbackForm;
