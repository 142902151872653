import { RINGMASTER_BASE_URL } from '../configs/env.config';
import { FindIdFormData, RingChangeRequestFormData, UserInfo } from '../pages/RingAssignmentPage/types/Types';
import { appInsightsClient } from '../utils/appInsightsUtility';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { Service } from './configs/apiServiceConfig';
import ApiResponseError from './models/error/ApiResponseError';
import RingmasterRequestDetailsObject from './models/Ringmaster/RingmasterRequestDetailsObject';
import { FetchIDDetailsResponse, RingmasterRingChangeResponse } from './models/Ringmaster/RingmasterResponse';

/**
 * Service for Ringmaster.
 */
class RingmasterService {
    private apiService: ApiService;
    private ringmasterRequestBaseUri: string;

    /**
     * Initializes an instance of RingmasterService.
     */
    constructor() {
        this.apiService = ApiManager.getApiService(Service.RINGMASTER);
        this.ringmasterRequestBaseUri = `${RINGMASTER_BASE_URL}/api/ringAssignRequest`;
    }

    /**
     * Process ring change request by passing ringChangeRequestFormData to backend Ringmaster service.
     *
     * @param ringChangeRequestFormData An object of type RingChangeRequestFormData.
     * @returns An object of type RingmasterRingChangeResponse.
     */
    public async processRingChangeRequest(ringChangeRequestFormData: RingChangeRequestFormData) {
        const url = `${this.ringmasterRequestBaseUri}/initiateRequest`;
        try {
            const response = await this.apiService.post<RingmasterRingChangeResponse>(url, ringChangeRequestFormData);

            appInsightsClient.logTrace(
                {
                    message: `Fetched response data from RingmasterService for url: ${url}`
                },
                response
            );

            return response;
        } catch (error) {
            appInsightsClient.logException(
                { exception: new Error('RingmasterService:processRingChangeRequest') },
                { message: 'Ring change request initiation error', error: error }
            );
            throw this.handleApiError(url, error, 'Failed to get RingmasterService results');
        }
    }

    /**
     * Mark the request as Approved/Rejected by passing requestId to backend Ringmaster service.
     *
     * @param requestId The request id.
     * @param requestStatus Either 'Approved' or 'Rejected'.
     * @param processedBy The user details who has processed the request.
     * @returns An object of type RingmasterRingChangeResponse.
     */
    public async resolveRequest(requestId: string, requestStatus: string, processedBy: UserInfo) {
        const url = `${this.ringmasterRequestBaseUri}/resolve`;
        try {
            const response = await this.apiService.post<RingmasterRingChangeResponse>(url, {
                id: requestId,
                requestStatus: requestStatus,
                processedBy: processedBy
            });

            appInsightsClient.logTrace(
                {
                    message: `Fetched response data from RingmasterService for url: ${url}`
                },
                response
            );

            return response;
        } catch (error) {
            appInsightsClient.logException(
                { exception: new Error('RingmasterService:resolveRequest') },
                { message: 'Resolve request error', error: error }
            );
            throw this.handleApiError(url, error, 'Failed to get RingmasterService results');
        }
    }

    /**
     * Process find id info request by passing findIdFormData to backend Ringmaster service.
     *
     * @param findIdFormData This contains the id and identityType.
     * @returns An object of type RingmasterFindIdInfoResponse.
     */
    public async processFindIdInfoRequest(findIdFormData: FindIdFormData) {
        const operationId = findIdFormData.operationId;
        const params = new URLSearchParams({ operationId });
        const url = `${this.ringmasterRequestBaseUri}/searchIdInfo?${params.toString()}`;
        try {
            const response = await this.apiService.get<FetchIDDetailsResponse>(url);

            appInsightsClient.logTrace(
                {
                    message: `Fetched response data from RingmasterService for url: ${url}`
                },
                response
            );

            return response;
        } catch (error) {
            appInsightsClient.logException(
                { exception: new Error('RingmasterService:processFindIdInfoRequest') },
                { message: 'Find id request error', error: error }
            );
            throw this.handleApiError(url, error, 'Failed to get RingmasterService results');
        }
    }

    /**
     * Returns all historical requests from Ringmaster service for the given lookbackDays.
     *
     * @param lookbackDays The number of days to look back.
     * @returns An array of type RingmasterRequestDetailsObject.
     */
    public async getAllHistoricalRequests(lookbackDays: number) {
        const params = new URLSearchParams({ lookbackDays: lookbackDays.toString() });
        const url = `${this.ringmasterRequestBaseUri}/getAll?${params.toString()}`;
        try {
            const response = await this.apiService.get<RingmasterRequestDetailsObject[]>(url);

            appInsightsClient.logTrace(
                {
                    message: `Fetched response data from RingmasterService for url: ${url}`
                },
                response
            );

            return response;
        } catch (error) {
            appInsightsClient.logException(
                { exception: new Error('RingmasterService:getAllHistoricalRequests') },
                { message: 'Get all historical requests error', error: error }
            );
            throw this.handleApiError(url, error, 'Failed to get RingmasterService results');
        }
    }

    /**
     *
     * @param url The url.
     * @param error The error.
     * @param defaultMessage The default message.
     */
    private handleApiError(url: string, error: unknown, defaultMessage: string) {
        let errorMessage = defaultMessage;
        if (error instanceof ApiResponseError) {
            if (error.statusCode === 404) {
                errorMessage = 'Could not find the requested resource!';
            } else if (error.statusCode === 403) {
                errorMessage = 'Unable to connect to the server. Please try again later.';
            } else if (error.statusCode === 400) {
                errorMessage = `Bad Request: ${url}`;
            } else if (error.errorDetail) {
                errorMessage = error.errorDetail;
            }
        }
        throw new Error(errorMessage);
    }
}

export default RingmasterService;
