import {
    ApiReleaseScheduleGetByTimeRangeGetRequest,
    ApiReleaseScheduleGetByWindowInDaysGetRequest,
    ApiReleaseScheduleUpdatePutRequest,
    InitOverrideFunction,
    ReleaseSchedule,
    ReleaseScheduleApi,
    ResponseError
} from 'skynet-client';

import { skynetConfiguration } from './configs/skynet.defaults';
import { ProcessedReleaseSchedule } from './models/ReleaseSchedule';
import { transformSchedules } from './utils';

/**
 * Service for release schedule API.
 */
class ScheduleService extends ReleaseScheduleApi {
    /**
     * Constructor.
     */
    constructor() {
        super(skynetConfiguration);
    }

    /**
     * Get release schedule by time range.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns A list of Release schedules.
     */
    async apiReleaseScheduleGetByTimeRangeGet(
        requestParameters: ApiReleaseScheduleGetByTimeRangeGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<ReleaseSchedule[]> {
        return await super.apiReleaseScheduleGetByTimeRangeGet(requestParameters, initOverrides);
    }

    /**
     * Get the processed release schedule by window in days.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns A list of processed release schedule.
     */
    async getScheduledReleasePlan(
        requestParameters: ApiReleaseScheduleGetByWindowInDaysGetRequest,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<ProcessedReleaseSchedule[]> {
        try {
            const schedules = await super.apiReleaseScheduleGetByWindowInDaysGet(requestParameters, initOverrides);
            return transformSchedules(schedules);
        } catch (error) {
            let errorMessage = 'Failed to get release schedule';
            if (error instanceof ResponseError) {
                if (error.response.status === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.message) {
                    errorMessage = error.message;
                }
            }
            throw new Error(errorMessage);
        }
    }

    /**
     * Update the release schedule.
     *
     * @param requestParameters The request parameters.
     * @param initOverrides The init overrides.
     * @returns The updated release schedule.
     */
    async apiReleaseScheduleUpdatePut(
        requestParameters?: ApiReleaseScheduleUpdatePutRequest | undefined,
        initOverrides?: RequestInit | InitOverrideFunction | undefined
    ): Promise<ReleaseSchedule> {
        return await super.apiReleaseScheduleUpdatePut(requestParameters, initOverrides);
    }
}

export default ScheduleService;
