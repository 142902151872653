import React, { useState, useEffect } from 'react';
import { Stack, SpinnerSize, Spinner, TooltipHost } from '@fluentui/react';
import useTrainDetail from './useTrainDetail';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import Common from './Common/Common';
import './Retrospective.css';
import homeConstants from '../../constants/home.constants';
import retrospectiveConstants from '../../constants/retrospective.constants';
import { useLocation } from 'react-router-dom';
import TrainDetailModal from './TrainDetailModal';
import PropTypes from 'prop-types';
import CardWrapper from '../../components/CardWrapper/CardWrapper';


// ===============================================================

const TrainRetrospective = (props) => {
    const trainName = props.trainName;
    const { noDataFlag, loaderFlag, data } = useTrainDetail(trainName);
    const location = useLocation();

    const MAX_RB_TO_SHOW = 4;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [trainDetailMetadata, setTrainDetailMetadata] = useState({
        details: null,
        ringBlockers: [],
        isDesktop: false,
        trainName: null,
        ring: null
    });

    const tableStyles = {
        root: { width: '100%' }
    };

    const ringTokens = { childrenGap: '0.5%' };

    // ===============================================================
    function showRingBlockerAndReleaseActivity(isRBPresent, activeRingBlockers) {
        const tooltipId = '1';
        const calloutProps = { gapSpace: 0 };
        const hostStyles = {
            root: { display: 'inline' }
        };

        let rbCount = isRBPresent ? activeRingBlockers.length : 0;

        return (
            <>
                <span className="ringblocker-detail">
                    <TooltipHost content={`Ring Blocker(s) : ${rbCount}`} id={tooltipId} calloutProp={calloutProps} hostStyles={hostStyles}>
                        {isRBPresent && (
                            <span>
                                <u>Ring Blocker(s)</u>
                                {activeRingBlockers.slice(0, MAX_RB_TO_SHOW).map((ringBlocker) => (
                                    <li key={ringBlocker._links.html.href}>
                                        <a href={ringBlocker._links.html.href} target="_blank" rel="noopener noreferrer">
                                            {ringBlocker.id}
                                        </a>
                                    </li>
                                ))}
                                {activeRingBlockers.length > MAX_RB_TO_SHOW && <span>...</span>}
                            </span>
                        )}
                    </TooltipHost>
                </span>
            </>
        );
    }

    function getRingID(data, id) {
        let info = data.releaseInfo;
        let trainName = data.trainName;
        let details = info ? info[id] : null;
        let ringId = '';
        if (details) {
            if (details?.length === 1) {
                ringId = trainName + details[0].ring + details[0].percent;
            } else {
                ringId = trainName + details[0].ring + (details[0].percent + details[1].percent);
            }
        }
        return ringId;
    }

    function rollout(details) {
        if (!details || details?.length === 0) return '-';
        return details?.length === 1 ? details[0].percent : details[0].percent + details[1].percent;
    }

    function dateToLocalDateString(date) {
        return date
            ? new Date(date).toLocaleDateString('en-in', {
                  day: 'numeric',
                  month: 'short',
                  timeZone: 'GMT'
              })
            : '-';
    }

    function startedDate(details) {
        return details && details[0] ? details[0].rolloutEarlyStartDate : null;
    }

    function fullReleasedDate(details) {
        let stableBuild = details?.filter((item) => item.buildFlavor === 'Stable'); // ? it's better to use constant which will be shared with Skynet somehow
        if (!details || details?.length === 0) return null;
        let percentPenetration = details.length === 1 ? details[0].percent : details[0].percent + details[1].percent;
        return percentPenetration === 100 && stableBuild?.length > 0 ? stableBuild[0].releaseDate : null;
    }

    function getRingTemplate(data, id) {
        let info = data.releaseInfo;
        let trainName = data.trainName;

        let details = info ? info[id] : null;
        let numberOfRelease = details ? details[0]?.buildMetaData.length : '-';

        return (
            <>
                <div className={`ring`} style={{ padding: 10 }}>
                    <div className={`ring-item`}>
                        <div>Rollout: {rollout(details)}</div>
                        <div>Expected arrival: {dateToLocalDateString(getExpectedReleaseDate(trainName, id))}</div>
                        <div>First release started: {dateToLocalDateString(startedDate(details))}</div>
                        <div>Planned complete: {dateToLocalDateString(getExpectedReleaseDate(trainName, id + retrospectiveConstants.KEY_END_ROLLOUT))}</div>
                        <div>Released 100%: {dateToLocalDateString(fullReleasedDate(details))}</div>
                        <div>Number of releases: {numberOfRelease}</div>
                    </div>
                </div>
            </>
        );
    }

    // ===============================================================
    function closeModal() {
        setIsModalOpen(false);
    }

    function openModal(data, ringBlockers, isDesktop, trainName, ring) {
        setTrainDetailMetadata({
            details: data,
            ringBlockers: ringBlockers,
            isDesktop: isDesktop,
            trainName: trainName,
            ring: ring
        });
        setIsModalOpen(true);
    }

    function showRingCard(trainRingStatus) {
        return [homeConstants.TRAIN_ARRIVING, homeConstants.TRAIN_IN_PROGRESS, homeConstants.TRAIN_PASSED, homeConstants.TRAIN_DELAYED, homeConstants.TRAIN_STATUS_ABANDONED].includes(trainRingStatus);
    }

    function getPercent(info, id) {
        let details = info ? info[id] : null;

        return details ? (details.length > 1 ? details[0].percent + details[1].percent : details[0].percent) : null;
    }

    function getExpectedReleaseDate(trainName, ringID) {
        let items = props.releaseData;
        let date;

        items.forEach((item) => {
            if (item.name === trainName) {
                date = item[ringID]?.props?.children;
            }
        });
        return date ? date : null;
    }

    function getTrainStatusForRing(data, id) {
        let trainName = data.trainName;

        if (trainName === null) return homeConstants.INVALID_TRAIN_NAME;

        let expectedReleaseDate = getExpectedReleaseDate(trainName, id);
        let expectedFullRelease = getExpectedReleaseDate(trainName, id + retrospectiveConstants.KEY_END_ROLLOUT);
        let info = data.releaseInfo;
        let details = info ? info[id] : null;
        let actualdate = startedDate(details);
        let actualReleased = fullReleasedDate(details);
        if (expectedReleaseDate == null && expectedFullRelease == null && actualdate == null && actualReleased == null)
            return homeConstants.MISSING_RELEASE_SCHEDULE;
        if (actualdate && new Date(actualdate).getTime() - expectedReleaseDate?.getTime() >= 24 * 60 * 60 * 1000) return homeConstants.TRAIN_DELAYED;
        if (actualReleased && new Date(actualReleased).getTime() - expectedFullRelease?.getTime() >= 24 * 60 * 60 * 1000)
            return homeConstants.TRAIN_DELAYED;
        if (actualdate && new Date(actualdate).getTime() - expectedReleaseDate?.getTime() < 24 * 60 * 60 * 1000) return homeConstants.TRAIN_PASSED;
        return homeConstants.TRAIN_IN_PROGRESS;
    }

    function getRingCardBorder(trainRingStatus, isRBPresent) {
        if (trainRingStatus === homeConstants.TRAIN_DELAYED) return 'ring-data-error';
        else if (isRBPresent) return 'ring-data-warning';
        else if (trainRingStatus === homeConstants.TRAIN_PASSED) return 'ring-data-success';
        else return 'ring-data-passed';
    }

    // ===============================================================

    useEffect(() => {
        if (location.hash.toLowerCase().includes(trainName.toLowerCase())) {
            let el = document.getElementById(trainName.toLowerCase());
            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }

        window.location.hash = '#/retrospective';
    }, [location.hash, trainName]);

    // ===============================================================
    return (
        <>
            <>
                {noDataFlag && (
                    <CardWrapper>
                        <ErrorNotification msg={`Train progression Data is not Available for train ${props.trainName}`} />
                    </CardWrapper>
                )}
            </>
            <>
                <div tabIndex="0" className="release-data-container">
                    <>
                        {loaderFlag && <Spinner size={SpinnerSize.large} label="Fetching records..." />}
                        {!loaderFlag && !noDataFlag && (
                            <>
                                <Stack className="release-data-container" horizontal tokens={ringTokens} styles={tableStyles}>
                                    <Stack className={`${props.isExpanded ? 'ring-sticky-expanded' : 'ring-sticky'}`}>
                                        <div
                                            className={`card train-release-data-card ${
                                                props.clientType === 'Desktop' ? 'train-release-data-card-big' : ''
                                            }`}
                                        >
                                            <div className="card-body" role="group">
                                                {data.trainName && (
                                                    <>
                                                        <div id={data.trainName.toLowerCase()} className="release" style={{ padding: 10 }}>
                                                            {Common.getTrainNameDetails(data.teamsThread, data.trainName)}
                                                            {Common.getTrainStatus(data.trainStatus)}
                                                            {Common.getSnapBranches(data.snapInfo)}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Stack>
                                    {props.headers.map((header) => {
                                        if (header.id === 'release') return '';
                                        let percent = getPercent(data.releaseInfo, header.id);

                                        let trainRingStatus = getTrainStatusForRing(data, header.id, percent);
                                        let isRBPresent = props.ringBlockers[`${header.cloud}_${header.ring}`] ? true : false;
                                        let activeRingBlockers = isRBPresent
                                            ? props.ringBlockers[`${header.cloud}_${header.ring}`]
                                            : undefined;

                                        let info = data.releaseInfo;
                                        let details = info ? info[header.id] : null;

                                        let key = header.id + header.cloud + header.ring;
                                        return (
                                            <Stack key={key}>
                                                {showRingCard(trainRingStatus) && (
                                                    <div
                                                        id={getRingID(data, header.id)}
                                                        className={`card train-ring-data-card 
                                                            ${props.clientType === 'Desktop' ? 'train-ring-data-card-big' : ''} 
                                                            ${getRingCardBorder(trainRingStatus, isRBPresent)}
                                                            ${data.trainStatus === homeConstants.TRAIN_STATUS_ABANDONED ? 'ring-abandoned-card' : ''}`}
                                                        onClick={() =>
                                                            openModal(
                                                                details,
                                                                activeRingBlockers,
                                                                props.clientType === 'Desktop',
                                                                data.trainName,
                                                                header.name
                                                            )
                                                        }
                                                        tabIndex="0"
                                                    >
                                                        <div className="fontIcon">
                                                            {showRingBlockerAndReleaseActivity(isRBPresent, activeRingBlockers)}
                                                        </div>

                                                        <div className={percent === null ? 'expected-train' : 'present-train'}>
                                                            <div className={`cardBody`} role="group">
                                                                {getRingTemplate(data, header.id)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Stack>
                                        );
                                    })}
                                    <TrainDetailModal isModalOpen={isModalOpen} hideModal={closeModal} data={trainDetailMetadata} />
                                </Stack>
                            </>
                        )}
                    </>
                </div>
            </>
        </>
    );
};

TrainRetrospective.propTypes = {
    trainName: PropTypes.string,
    clientType: PropTypes.string,
    releaseData: PropTypes.array,
    ringBlockers: PropTypes.object,
    headers: PropTypes.array,
    isExpanded: PropTypes.bool
};
TrainRetrospective.defaultProps = {};

export default TrainRetrospective;
