import { Dropdown, IComboBoxOption, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

import { clientTypes } from '../configs/defaults';
import { comboBoxStyles } from '../styles/styles';

interface ISortingFilterOption {
    key: string;
    text: string;
}

type TrainSortingProps = {
    setTrainFilteringMethod: React.Dispatch<React.SetStateAction<string>>;
    sortingFilterOptions: ISortingFilterOption[];
    selectedOption: string;
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
    clientType: string;
};

/**
 *  Displays the sorting dropdown.
 *
 * @param props The props for the component.
 * @returns The train sorting dropdown - sorting done based on Start Date & Completion Date.
 */
const TrainSorting: React.FC<TrainSortingProps> = (props) => {
    useEffect(() => {
        localStorage.setItem('selectedOption', props.selectedOption);
    }, [props.selectedOption]);

    useEffect(() => {
        if (!localStorage.getItem('selectedOption')) {
            if (props.clientType === clientTypes.MOBILE) {
                props.setSelectedOption(
                    props.sortingFilterOptions.find((option) => option.key === 'CompletedDate')?.key || 'CompletedDate'
                );
            } else {
                props.setSelectedOption(props.sortingFilterOptions.find((option) => option.key === 'StartDate')?.key || 'StartDate');
            }
        }
    }, [props.clientType]);

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <label>Sort trains by</label>
            <Dropdown
                selectedKey={props.selectedOption}
                options={props.sortingFilterOptions}
                onChange={onFilterSelected}
                calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
                styles={comboBoxStyles}
            />
        </Stack>
    );

    function onFilterSelected(_event: React.FormEvent<HTMLDivElement>, option?: IComboBoxOption) {
        if (option === undefined) {
            console.error('Error: chosen option is undefined');
        } else if (typeof option.key === 'string') {
            props.setSelectedOption(option.key);
            props.setTrainFilteringMethod(option.key);
        } else {
            console.error(`Error: ${option.key} is not a string`);
        }
    }
};

export default TrainSorting;
