import { IStyle, IStyleFunctionOrObject, ITheme, assign, mergeStyles } from '@fluentui/react';
import * as React from 'react';

import { defaultCardStyles } from './styles/styles';

import { CardFooter, CardHeader } from '.';

interface ICardProps {
    id?: string;
    styles?: IStyleFunctionOrObject<ICardStyleProps, ICardStyles>;
    tokens?: ICardTokens;
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface ICardStyleProps {
    theme: ITheme;
}

interface ICardTokens {
    headerColor?: string;
    footerColor?: string;
    bodyColor?: string;
}

interface ICardStyles {
    root?: IStyle;
    header?: IStyle;
    footer?: IStyle;
    body?: IStyle;
}

/**
 * A Custom Card Component.
 *
 * @param props The props for the component.
 * @returns The Card component.
 */
const Card: React.FC<ICardProps> = (props: ICardProps) => {
    const { styles, tokens, children } = props;
    // Merge the default styles with the custom styles
    const mergedStyles: ICardStyles = {
        root: assign({}, defaultCardStyles.root, (styles as ICardStyles)?.root),
        header: assign({}, defaultCardStyles.header, (styles as ICardStyles)?.header),
        body: assign({}, defaultCardStyles.body, (styles as ICardStyles)?.body),
        footer: assign({}, defaultCardStyles.footer, (styles as ICardStyles)?.footer)
    };

    // Merge the default tokens with the custom tokens
    const mergedTokens: ICardTokens = { ...defaultCardTokens, ...tokens };

    const cardClass = mergeStyles(mergedStyles.root);
    const headerClass = mergeStyles(mergedStyles.header);
    const bodyClass = mergeStyles(mergedStyles.body);
    const footerClass = mergeStyles(mergedStyles.footer);

    const header = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === CardHeader) {
            return child;
        }
        return undefined;
    });

    const body = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type !== CardHeader && child.type !== CardFooter) {
            return child;
        }
        return undefined;
    });

    const footer = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === CardFooter) {
            return child;
        }
        return undefined;
    });

    return (
        <div id={props.id} className={cardClass} onClick={props.onClick}>
            {header !== undefined ? (
                <div className={headerClass} style={{ color: mergedTokens.headerColor }}>
                    {header}
                </div>
            ) : null}
            {body !== undefined ? (
                <div className={bodyClass} style={{ color: mergedTokens.bodyColor }}>
                    {body}
                </div>
            ) : null}
            {footer !== undefined ? (
                <div className={footerClass} style={{ color: mergedTokens.footerColor }}>
                    {footer}
                </div>
            ) : null}
        </div>
    );
};

const defaultCardTokens: ICardTokens = {};

export { Card };
export type { ICardStyles };
